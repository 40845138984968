/**
 * TableArea.tsx
 *
 * This is the component used when localPlayer goes into /table route
 * It starts the configuration around socket and twilio and initialization before ActionArea
 */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";

import { PlayerStatus } from "poker-cows-common";
import { BottomLeft } from "./BottomLeft";
import { TableColumn } from "./TableColumn";
import { BottomRight } from "./BottomRight";
import { ActionArea } from "../ActionArea/ActionArea";
import Pot from "../Pot";
import { TableCards } from "../TableCards";
import { selectPotTotalAmount } from "../../../logic/gameInstance/slice";
import { GameInfo } from "../GameInfo";
import { ErrorBoundary } from "../../../error_handling/ErrorBoundary";
import { SeatContext } from "../Seat/SeatContext";
import { selectTableGroup, selectTableId } from "../../../logic/table/slice";
import { ConnectionLost } from "../ConnectionLost/ConnectionLost";
import { selectPlayerStatusById } from "../../../logic/player/slice";
import { selectLocalPlayerId } from "../../../logic/localPlayer/slice";
import { useRedirect } from "../../../hooks/useRedirect";
import Loading from "../Loading";
import { RESET_STATE } from "../../../logic/store";
import { TwilioClient } from "../../../logic/twilio/TwilioClient";
import { PlayerNotification } from "../ActionArea/PlayerNotification";
import { useTableSocket } from "../../../logic/table/hooks/TableSocket";
import { selectAnyDelayPrompt } from "../../../logic/prompts/slice";

import "./GameArea.css";

export const TableArea = ({ id }: { id: string }) => {
  const dispatch = useDispatch();

  const goToRemovedByHostPage = useRedirect("/removed-by-host");
  const goToDeclinePage = useRedirect("/join-request-declined");
  const goToCashedOutPage = useRedirect("/cashed-out");

  const { localPlayerId } = useSelector(selectLocalPlayerId);
  const { tableId } = useSelector(selectTableId);
  const { tableGroup } = useSelector(selectTableGroup);
  const { playerStatus } = useSelector(selectPlayerStatusById(localPlayerId));
  const { potTotalAmount } = useSelector(selectPotTotalAmount);
  const delayPrompt = !!useSelector(selectAnyDelayPrompt).anyDelayPrompt;

  // open a socket connection to the server
  useTableSocket(id, tableGroup, localPlayerId);
  const twilioClient = TwilioClient.get(tableId.toString(), localPlayerId);

  useEffect(() => {
    /**
     * We manually disconnect twilio client here
     *
     * TODO:
     * we want to restructure the app to render different components here instead of redirecting
     * so that useEffect unmount functions are called on components inside of the app
     * as it is, those unmounts aren't called if the redirect is rendered before they are
     */
    if (
      playerStatus === PlayerStatus.KICKED ||
      playerStatus === PlayerStatus.BANNED
    ) {
      twilioClient?.disconnect();
      dispatch(RESET_STATE);
      goToRemovedByHostPage();
    }
    if (playerStatus === PlayerStatus.DECLINED) {
      twilioClient?.disconnect();
      dispatch(RESET_STATE);
      goToDeclinePage();
    }
    if (playerStatus === PlayerStatus.LEFT) {
      twilioClient?.disconnect();
      dispatch(RESET_STATE);
      goToCashedOutPage();
    }
  }, [
    playerStatus,
    twilioClient,
    goToCashedOutPage,
    goToDeclinePage,
    goToRemovedByHostPage,
    dispatch,
  ]);

  // we haven't heard back from express-server that table is created and player has joined
  if (!tableId || !localPlayerId || !playerStatus) {
    return <Loading />;
  }

  // Display request when player is requesting to join
  if (playerStatus === PlayerStatus.JOINING) {
    return (
      <PlayerNotification
        title="REQUEST TO JOIN"
        description="The host of the game has been sent your request to join. Standby to join the table."
      />
    );
  }

  const isPlayerReady =
    playerStatus === PlayerStatus.ADMITTED ||
    playerStatus === PlayerStatus.WAITING ||
    playerStatus === PlayerStatus.HOST;

  if (!isPlayerReady) {
    return (
      <PlayerNotification
        title="YOU HAVE LEFT THE TABLE"
        description="Your poker game session has ended."
      />
    );
  }

  return (
    <div id="gameArea">
      <div className="mainLogo" />
      <ConnectionLost />

      <TableColumn
        bottomContent={<BottomLeft />}
        screenPositions={[1, 2, 3]}
        className={classNames("left", {
          zIndexLow: !delayPrompt,
          zIndexHigh: delayPrompt,
        })}
      />

      <div
        className={classNames("middle", {
          zIndexLow: delayPrompt,
          zIndexHigh: !delayPrompt,
        })}
      >
        {isPlayerReady && (
          <>
            <ErrorBoundary>
              <GameInfo />
            </ErrorBoundary>
            <ErrorBoundary>
              <TableCards />
            </ErrorBoundary>
            <Pot amount={potTotalAmount} />
          </>
        )}
        <ErrorBoundary>
          <SeatContext.Provider value={7}>
            <ActionArea />
          </SeatContext.Provider>
        </ErrorBoundary>
      </div>

      <TableColumn
        bottomContent={<BottomRight />}
        screenPositions={[4, 5, 6]}
        className={classNames("right", {
          zIndexLow: !delayPrompt,
          zIndexHigh: delayPrompt,
        })}
      />
    </div>
  );
};
