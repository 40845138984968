/**
 * Dropdown.tsx
 *
 * This new Dropdown in-houses the basic composed dropdown which defines the span and the dropdown list around it
 * This component has no styling around it and is dependent upon the classes passed to it
 * The component itself is wrapped around @reach/dialog
 */
import React from "react";

import {
  ListboxList,
  ListboxInput,
  ListboxButton,
  ListboxPopover,
  ListboxOption,
} from "@reach/listbox";
import classNames from "classnames";

import { DownIcon } from "./icons/DownIcon";

import "@reach/listbox/styles.css";

export const Dropdown = ({
  value,
  setValue,
  options,
  defaultLabel,
  wrapperClass = "dropdownWrapper",
  buttonClass = "dropdownButton",
  popoverClass = "dropdownPopover",
  arrowStyle = { color: "white", width: 14, height: 7 },
  disabled,
  isBottomRow = false
}: {
  defaultLabel?: string;
  value: string | number;
  setValue: (value: string) => void;
  options: { id?: string | number; value: string | number; label: string }[];
  wrapperClass?: classNames.Argument;
  buttonClass?: classNames.Argument;
  popoverClass?: classNames.Argument;
  arrowStyle?: { color?: string; width?: number; height?: number };
  disabled?: boolean;
  isBottomRow?: boolean
}) => {
  if (!defaultLabel) {
    defaultLabel = options.find(
      (option) => value.toString() === option.value.toString()
    )?.label;
  }

  return (
    <ListboxInput
      disabled={disabled}
      value={value.toString()}
      onChange={setValue}
      className={classNames(wrapperClass)}
    >
      <ListboxButton
        className={classNames(buttonClass)}
        arrow={
          <DownIcon
            width={arrowStyle.width}
            height={arrowStyle.height}
            color={arrowStyle.color}
            className={""}
          />
        }
      >
        <span></span>
        <span>{defaultLabel ?? "Choose an option"}</span>
      </ListboxButton>
      <ListboxPopover portal={false} className={classNames(popoverClass, {raisedPopover: isBottomRow})}>
        <ListboxList>
          {options.map((option) => (
            <ListboxOption
              key={option.value.toString()}
              value={option.value.toString()}
            >
              {option.label}
            </ListboxOption>
          ))}
        </ListboxList>
      </ListboxPopover>
    </ListboxInput>
  );
};
