import classNames from "classnames";
import { TableCodeLength, TableErrorType } from "poker-cows-common";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { getTable } from "../../logic/table/api";
import {
  JOIN_TABLE_CODE_BUTTON,
  JOIN_TABLE_CODE_INPUT,
} from "../../test-identifiers";
import { freeTrialIsOver } from "../../utils/FreeTrial";
import Button from "./Button";
import "./JoinGameWidget.css";

const internalWidgetErrors = {
  empty: (name: string) => `You entered empty table code!`,
  maxLength: (name: string) => `You entered incorrect table code!`,
  notFound: (name: string) => `You entered incorrect table code!`,
  noSeatsAvailable: (name: string) => `The table is full currently!`,
};

const JoinGameWidget = () => {
  const [inputValue, setInputValue] = useState("");
  const [redirectToTable, setRedirectToTable] = useState(false);
  const [tableError, setTableError] = useState<string | null>(null);

  function onChangeTableCode(event: React.ChangeEvent<HTMLInputElement>) {
    if (tableError) {
      setTableError(null);
    }

    const currentValue = event.target.value.trim() ?? "";
    if (!currentValue || currentValue.match(/^[0-9a-zA-Z]+$/)) {
      setInputValue(currentValue);
    }
  }

  // use getTable to check for table existing. if it does,
  // switch internal state which will allow the render of the redirect
  // if broken, switch internal state to display error message
  function goToTable() {
    if (!inputValue) {
      setTableError(internalWidgetErrors.empty(""));
      return;
    }

    if (inputValue.length > TableCodeLength) {
      setTableError(internalWidgetErrors.maxLength(inputValue));
      return;
    }

    getTable(inputValue).then((res) => {
      if (res?.response?.data?.errorType === TableErrorType.TABLE_NOT_FOUND) {
        setTableError(internalWidgetErrors.notFound(inputValue));
      } else if (
        res?.response?.data?.errorType === TableErrorType.NO_SEATS_AVAILABLE
      ) {
        setTableError(internalWidgetErrors.noSeatsAvailable(inputValue));
      } else {
        // if there was an error displayed, clear it
        setTableError(null);
        // triggers the render of redirect
        setRedirectToTable(true);
      }
    });
  }

  if (redirectToTable) {
    const url = freeTrialIsOver()
      ? "/payment-plan?join=" + inputValue
      : "/table/" + inputValue;
    return <Redirect to={url} />;
  }

  return (
    <div
      className={classNames("JoinGameWidget", {
        JoinGameWidgetError: tableError,
      })}
    >
      <form
        className="JoinGameWidgetErrorContainer"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          goToTable();
        }}
      >
        <input
          id="join-table-code"
          data-testid={JOIN_TABLE_CODE_INPUT}
          maxLength={TableCodeLength}
          className="JoinGameWidgetInput"
          type="text"
          placeholder="Enter Table Code"
          value={inputValue}
          onChange={onChangeTableCode}
        />
        {tableError && <div className="JoinGameError">{tableError}</div>}
        <Button
          data-testid={JOIN_TABLE_CODE_BUTTON}
          disabled={inputValue.length < TableCodeLength}
          className="JoinGameButton"
          type="submit"
        >
          JOIN GAME
        </Button>
      </form>
    </div>
  );
};

export default JoinGameWidget;
