/*
 * This file is a component that is displayed within GameInfo.tsx
 * It is responsible for determining which options (winner [high or low or hi-lo split], buy last card up / roll your own, and wild cards)
 * have been chosen by the current dealer and displaying them at the center top of screen above the pot.
 */

import React from "react";
import { useSelector } from "react-redux";

import {
  BuyLastCardUpSetting,
  Game,
  RollYourOwnSetting,
  WildcardOption,
} from "poker-cows-common";
import { selectPrefs } from "../../logic/gameInstance/slice";
import { getGameOptions } from "../../utils/GameInfo";
import { selectGameType } from "../../logic/table/slice";

const NONE = "None";
const ONE_EYED_JACKS = "One Eyed Jacks";

export const GameInfoOptions: React.FC = () => {
  const { prefs } = useSelector(selectPrefs);
  const { gameType } = useSelector(selectGameType);
  const gameOptions = getGameOptions(gameType);

  if (gameType === Game.TexasHoldem) {
    return null;
  }

  // Determine which winner option to display in Game Info header
  // The function checks the highLowSetting, and returns appropriate description
  const getWinners = () => {
    return (
      gameOptions?.winners?.find(({ value }) => value === prefs?.highLowSetting)
        ?.description ?? NONE
    );
  };

  // Determine which option selection to display in Game Info header
  // The function checks the rollYourOwnSetting, and if it is not disabled, returns appropriate description
  // The function checks the buyLastCardUpSetting, and if it is not disabled and wildcard is not Low Hole w/ Buy, returns appropriate description
  const getOptions = () => {
    const isValueInSettings = (value: number) => {
      if (
        prefs?.rollYourOwnSetting &&
        prefs?.rollYourOwnSetting > RollYourOwnSetting.DISABLED
      ) {
        return (
          RollYourOwnSetting[value] ===
          RollYourOwnSetting[prefs.rollYourOwnSetting]
        );
      }
      if (
        prefs?.buyLastCardUpSetting &&
        prefs?.buyLastCardUpSetting > BuyLastCardUpSetting.DISABLED &&
        prefs?.wildcardSetting !== WildcardOption.LOW_HOLE_WITH_BUY
      ) {
        return (
          BuyLastCardUpSetting[value] ===
          BuyLastCardUpSetting[prefs.buyLastCardUpSetting]
        );
      }
    };

    return (
      gameOptions?.options?.find(({ value }) => isValueInSettings(value))
        ?.description ?? NONE
    );
  };

  // Determine which wild card option to display in Game Info header
  // The function checks the wildcardSetting, and returns appropriate description
  // PS. Also show the suit icon if the associated wildcard option is chosen
  const getWildcard = () => {
    const wildcardName =
      gameOptions?.wildcards?.find(
        ({ value }) => value === prefs?.wildcardSetting
      )?.description ?? NONE;

    if (wildcardName === ONE_EYED_JACKS) {
      return <span>{ONE_EYED_JACKS} &#9824; &#9829;</span>;
    }

    return wildcardName ?? NONE;
  };

  return (
    <>
      <div className="gameInfoText">
        <div className="gameInfoLabel">Win:</div>
        <div className="gameInfoValue">{getWinners()}</div>
      </div>

      <div className="gameInfoText">
        <div className="gameInfoLabel">Opt:</div>
        <div className="gameInfoValue">{getOptions()}</div>
      </div>

      <div className="gameInfoText">
        <div className="gameInfoLabel">Wild:</div>
        <div className="gameInfoValue">{getWildcard()}</div>
      </div>
    </>
  );
};
