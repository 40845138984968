/**
 * This component renders the entirety of the hand value. It may show that a hand has a high value, a low value,
 * or both. The hand technically always has both, but it conditionally renders one or both depending on multiple
 * factors.
 */

import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Game, HighLowSetting } from "poker-cows-common";
import { useWinners } from "../../../logic/gameInstance/hooks";
import { selectGameType } from "../../../logic/table/slice";
import {
  selectPrefs,
  selectIsGameRunning,
  selectHasPlayerFoldedAtTablePos,
  selectDeclarationForPlayerById,
} from "../../../logic/gameInstance/slice";
import { useSeatContext } from "../Seat/SeatContext";
import { selectPlayerIdAtTablePos } from "../../../logic/player/slice";
import {
  HandValueItem,
  HandValueItemProps,
} from "../HandValueItem/HandValueItem";
import styles from "./HandValue.module.css";
import {
  selectHighlightedPosition,
  resetHighlight,
  setHighlight,
} from "../../../logic/positionHighlight/slice";

// The description is introduced to mark the value that will be used for the low/high evaluation of the hand.
// We use second kicker (kicker[1]) that is the tie breaker to tell whether the highlighted value is inside or outside the range
// ie. look in highMatch and lowMatch in SevenTwentySevenCount.ts
// PS. This is specifically for 7-27 as the description is of format XX.X || XX.X-YY.Y
export const FormattedDescription = ({
  description,
  gameType,
  kickers,
  variant,
}: {
  description?: string;
  gameType?: string;
  kickers?: number[] | null;
  variant?: string;
}): JSX.Element => {
  if (
    !description ||
    !variant ||
    !kickers ||
    kickers.length === 0 ||
    gameType !== Game.SevenTwentySeven
  ) {
    return <span data-testid="original-description">{description}</span>;
  }

  const isValueBetween7n27 = (value: number) => value >= 7 && value <= 27;

  const closestNumberInList = (list: number[], to: number) =>
    list.reduce((a, b) => {
      const aDiff = Math.abs(a - to);
      const bDiff = Math.abs(b - to);

      if (aDiff === bDiff) {
        return a > b ? a : b;
      }

      return bDiff < aDiff ? b : a;
    }, list[0] ?? 0);

  const variantNumber = variant === "low" ? 7 : 27;
  const cardValueList = description
    .split("-")
    .map((cardValue) => parseFloat(cardValue));

  let valueToBeUsed = 0;
  if (
    (variant === "low" && kickers[1] === 0) ||
    (variant === "high" && kickers[1] === 1)
  ) {
    const listBetween7n27 = cardValueList.filter((cardValue) =>
      isValueBetween7n27(cardValue)
    );
    valueToBeUsed = closestNumberInList(listBetween7n27, variantNumber);
  } else {
    valueToBeUsed = closestNumberInList(cardValueList, variantNumber);
  }

  return (
    <span data-testid="updated-description">
      {cardValueList.map((cardValue: number | JSX.Element, index) => {
        const dash = index === cardValueList.length - 1 ? "" : "-";

        if (cardValue === valueToBeUsed) {
          cardValue = (
            <span className={styles.boldDescription}>{cardValue}</span>
          );
        }

        return (
          <span key={index}>
            {cardValue}
            {dash}
          </span>
        );
      })}
    </span>
  );
};

const THREE_SECONDS = 3000;

export const HandValue = (props: { tablePosition?: number }): JSX.Element => {
  const dispatch = useDispatch();
  const [highlightTimer, setHighlightTimer] = useState(0);

  const { isThisSeatTaken, tablePosition, isLocalSeat } = useSeatContext();
  const { playerHand, isGameWinner, isBothWinner, isHighWinner, isLowWinner } =
    useWinners({ tablePosition });

  const isChicagoBothWinner = isGameWinner && isHighWinner;

  const highPositionCardDealNumbers = useMemo(
    () =>
      playerHand?.highPositionValue?.position?.cards?.map(
        (card) => card?.orderDealt
      ) ?? [],
    [playerHand?.highPositionValue?.position?.cards]
  );

  const lowPositionCardDealNumbers = useMemo(
    () =>
      playerHand?.lowPositionValue?.position?.cards?.map(
        (card) => card?.orderDealt
      ) ?? [],
    [playerHand?.lowPositionValue?.position?.cards]
  );

  const { playerId } = useSelector(selectPlayerIdAtTablePos(tablePosition));
  const { prefs } = useSelector(selectPrefs);
  const highLowSetting = prefs?.highLowSetting;
  const { playerDeclaration } = useSelector(
    selectDeclarationForPlayerById(playerId)
  );
  const hasNoDeclaration = !playerDeclaration;
  const { isGameRunning } = useSelector(selectIsGameRunning);
  const { hasPlayerFolded } = useSelector(
    selectHasPlayerFoldedAtTablePos(tablePosition)
  );
  const { highlightedPosition } = useSelector(selectHighlightedPosition);
  const { gameType } = useSelector(selectGameType);

  const lowDesc = (playerHand?.lowDescription ?? "").toString().trim();
  const highDesc = (playerHand?.description ?? "").toString().trim();
  const hasLowDesc = !!lowDesc;
  const hasHighDesc = !!highDesc;

  const canUseLowInGame =
    highLowSetting === HighLowSetting.LOW ||
    highLowSetting === HighLowSetting.HI_LO;
  const canUseHighInGame =
    highLowSetting === HighLowSetting.HIGH ||
    highLowSetting === HighLowSetting.HI_LO;

  const isPlayingWithoutDeclaration = isGameRunning && hasNoDeclaration;

  const shouldRenderLow =
    canUseLowInGame &&
    hasLowDesc &&
    (isPlayingWithoutDeclaration ||
      playerDeclaration === HighLowSetting.LOW ||
      playerDeclaration === HighLowSetting.HI_LO);

  const shouldRenderHigh =
    canUseHighInGame &&
    hasHighDesc &&
    (isPlayingWithoutDeclaration ||
      playerDeclaration === HighLowSetting.HIGH ||
      playerDeclaration === HighLowSetting.HI_LO);

  const isRenderingBothValues = shouldRenderLow && shouldRenderHigh;
  const isRenderingHighValueOnly = !shouldRenderLow && shouldRenderHigh;

  const isLowTogglePressed =
    highlightedPosition?.positionType === "low" &&
    highlightedPosition?.tablePosition === tablePosition;

  const isHighTogglePressed =
    highlightedPosition?.positionType === "high" &&
    highlightedPosition?.tablePosition === tablePosition;

  const onToggleLow: HandValueItemProps["onToggleClick"] = () => {
    clearTimeout(highlightTimer);

    if (isLowTogglePressed) {
      return dispatch(resetHighlight());
    }

    const timer = window.setTimeout(() => {
      dispatch(resetHighlight());
    }, THREE_SECONDS);

    setHighlightTimer(timer);

    return dispatch(
      setHighlight({
        dealNumbers: lowPositionCardDealNumbers,
        playerId,
        tablePosition,
        positionType: "low",
      })
    );
  };

  const onToggleHigh: HandValueItemProps["onToggleClick"] = () => {
    clearTimeout(highlightTimer);

    if (isHighTogglePressed) {
      return dispatch(resetHighlight());
    }

    const timer = window.setTimeout(() => {
      dispatch(resetHighlight());
    }, THREE_SECONDS);

    setHighlightTimer(timer);

    return dispatch(
      setHighlight({
        dealNumbers: highPositionCardDealNumbers,
        playerId,
        tablePosition,
        positionType: "high",
      })
    );
  };

  useEffect(() => {
    // prevent memory leak by clearing any outstanding timer on unmount
    return () => {
      dispatch(resetHighlight());
      clearTimeout(highlightTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const highlightedDealNumbers = highlightedPosition?.dealNumbers ?? [];

    if (isLowTogglePressed) {
      const requiresUpdateToHighlight = lowPositionCardDealNumbers.some(
        (dealNumber) =>
          dealNumber && !highlightedDealNumbers.includes(dealNumber)
      );

      if (requiresUpdateToHighlight) {
        dispatch(
          setHighlight({
            dealNumbers: lowPositionCardDealNumbers,
            playerId,
            tablePosition,
            positionType: "low",
          })
        );
      }
    }

    if (isHighTogglePressed) {
      const requiresUpdateToHighlight = highPositionCardDealNumbers.some(
        (dealNumber) =>
          dealNumber && !highlightedDealNumbers.includes(dealNumber)
      );

      if (requiresUpdateToHighlight) {
        dispatch(
          setHighlight({
            dealNumbers: highPositionCardDealNumbers,
            playerId,
            tablePosition,
            positionType: "high",
          })
        );
      }
    }
  }, [
    dispatch,
    highPositionCardDealNumbers,
    highlightedPosition?.dealNumbers,
    isHighTogglePressed,
    isLowTogglePressed,
    lowPositionCardDealNumbers,
    playerId,
    tablePosition,
  ]);

  if (!isThisSeatTaken || hasPlayerFolded) {
    return <></>;
  }

  return (
    <div
      className={classNames(styles.handValue, {
        [styles.bothHandValues]: isRenderingBothValues,
        [styles.highOnlyHandValue]: isRenderingHighValueOnly,
        [styles.winningHandValue]:
          isGameWinner || isHighWinner || isLowWinner || isBothWinner,
      })}
    >
      {shouldRenderLow && gameType && (
        <HandValueItem
          variant="low"
          className={classNames(styles.handValueItem, {
            [styles.local]: isLocalSeat,
          })}
          description={
            <FormattedDescription
              variant="low"
              gameType={gameType}
              description={lowDesc}
              kickers={playerHand?.lowPositionValue?.kickers}
            />
          }
          gameType={gameType}
          isGameWinner={isGameWinner}
          isBothWinner={isBothWinner}
          isLowWinner={isLowWinner}
          isHighWinner={isHighWinner}
          isTogglePressed={isLowTogglePressed}
          onToggleClick={onToggleLow}
        />
      )}

      {shouldRenderHigh && gameType && (
        <HandValueItem
          variant="high"
          className={classNames(styles.handValueItem, {
            [styles.local]: isLocalSeat,
          })}
          description={
            <FormattedDescription
              variant="high"
              gameType={gameType}
              description={highDesc}
              kickers={playerHand?.highPositionValue?.kickers}
            />
          }
          gameType={gameType}
          isGameWinner={isGameWinner}
          isBothWinner={isBothWinner || (isChicagoBothWinner && isGameWinner)}
          isLowWinner={isLowWinner}
          isHighWinner={isHighWinner && !isChicagoBothWinner}
          isTogglePressed={isHighTogglePressed}
          onToggleClick={onToggleHigh}
        />
      )}
    </div>
  );
};
