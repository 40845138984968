// this file renders Twilio media and player seat info, etc
// for remote players' seats,
// not localPlayer's own. (ie. screenPosition !== 7)

import { PlayerStatus, TableStatus, TwilioErrorType } from "poker-cows-common";
import React from "react";
import { useSelector } from "react-redux";
import {
  selectIsPlayerBotAtTablePos,
  selectPlayerIdAtTablePos,
  selectPlayerNameAtTablePos,
  selectPlayerStatusAtTablePos,
} from "../../../../logic/player/slice";
import {
  selectIsLocalPlayerHost,
  selectTableId,
  selectTableStatus,
} from "../../../../logic/table/slice";
import { useSeatContext } from "../SeatContext";
import { selectLocalPlayerId } from "../../../../logic/localPlayer/slice";
import { selectLocalPlayerStatus } from "../../../../logic/gameInstance/slice";
import { JoiningPlaceholder, PauseOverlay } from "./TwilioMedia";
import { useTwilioMedia } from "../../../../logic/twilio/hooks";
import { AdmitOverlay } from "../AdmitOverlay";
import { SeatInfo } from "../SeatInfo";
import { TwilioClient } from "../../../../logic/twilio/TwilioClient";
import { isAdmitted } from "../../../../utils/PlayerInfo";
import {
  selectLatestTwilioVideoMedia,
  selectTwilioError,
} from "../../../../logic/twilio/slice";
import { getBotImage } from "../../../../utils/BotImage";

import styles from "./TwilioMedia.module.css";

export const RemoteTwilioMedia = () => {
  const { tablePosition } = useSeatContext();
  const { tableId } = useSelector(selectTableId);
  const { playerStatus } = useSelector(
    selectPlayerStatusAtTablePos(tablePosition)
  );
  const { isPlayerBot } = useSelector(
    selectIsPlayerBotAtTablePos(tablePosition)
  );
  const { playerName } = useSelector(selectPlayerNameAtTablePos(tablePosition));
  const { tableStatus } = useSelector(selectTableStatus);
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);
  const { localPlayerId } = useSelector(selectLocalPlayerId);
  const { playerId } = useSelector(selectPlayerIdAtTablePos(tablePosition));
  const { localPlayerStatus } = useSelector(selectLocalPlayerStatus);
  const { twilioLatestVideoMedia } = useSelector(
    selectLatestTwilioVideoMedia(playerId)
  );
  const { twilioError } = useSelector(selectTwilioError);

  const twilioClient = TwilioClient.get(
    tableId.toString(),
    localPlayerId
  ) as TwilioClient;
  const { videoRef, audioRef } = useTwilioMedia(twilioClient, false);

  let showVideo = false;
  let audioMuted = true;
  let showAdmitOverlay = false;
  let showSeatInfo = false;
  let showJoiningPlaceholder = false;
  let showPauseOverlay = false;

  // localPlayer IS host
  if (isLocalPlayerHost) {
    // block against remotePlayerStatus === undefined
    // which occurs when a player is on cash out screen
    if (playerStatus) {
      showVideo = true;
    }
    // the player we're rendering is joining
    if (playerStatus === PlayerStatus.JOINING) {
      showAdmitOverlay = true;
    } else if (playerStatus === PlayerStatus.ADMITTED) {
      // player is admitted
      showSeatInfo = true;
    }

    // the player we're rendering is "waiting"
    // (they joined during a NL full round)
    if (playerStatus === PlayerStatus.WAITING) {
      showSeatInfo = true;
    }
  } else {
    // localPlayer is NOT host
    // (localPlayer is admitted), AND (remote player is admitted or host)
    if (isAdmitted(localPlayerStatus)) {
      if (playerStatus && isAdmitted(playerStatus)) {
        showVideo = true;
        showSeatInfo = true;
        // localPlayer not host and not admitted
      } else if (playerStatus === PlayerStatus.JOINING) {
        showJoiningPlaceholder = true;
      }
    }
  }

  // the player is admitted but has no video track,
  // or the player is host but has no video track
  if (!twilioLatestVideoMedia && playerStatus && isAdmitted(playerStatus)) {
    showPauseOverlay = true;
  }

  // players must both be admitted or host to play audio
  if (
    localPlayerStatus &&
    isAdmitted(localPlayerStatus) &&
    playerStatus &&
    isAdmitted(playerStatus)
  ) {
    audioMuted = false;
  }

  // if uninitialized, show nothing
  if (tableStatus === TableStatus.UNINITIALIZED) {
    showVideo = false;
    showAdmitOverlay = false;
    showJoiningPlaceholder = false;
    showSeatInfo = false;
    showPauseOverlay = false;
    audioMuted = true;
  }

  // TODO: check if remote error belongs to this seat,
  // and handle accordingly. ie. localPlayer could have
  // an error subscribing to this remote player's publication
  if (twilioError.type === TwilioErrorType.REMOTE) {
    showPauseOverlay = true;
  }

  return (
    <div className="twilio-media">
      {!isPlayerBot && showPauseOverlay && <PauseOverlay />}
      {isPlayerBot && (
        <img
          src={getBotImage(playerName)}
          alt={playerName}
          className={styles.botImage}
        ></img>
      )}
      <video
        ref={videoRef}
        className={showVideo ? "" : styles.videoHidden}
        autoPlay={true}
      />
      <audio muted={audioMuted} ref={audioRef} />
      {showAdmitOverlay && <AdmitOverlay />}
      {showJoiningPlaceholder && <JoiningPlaceholder />}
      {showSeatInfo && <SeatInfo />}
    </div>
  );
};
