/**
 * CashOutModal.tsx
 *
 * This is just a wrapper around the CashOut
 */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { TableStatus } from "poker-cows-common";
import { CashOut } from "../../Settings/HostControls/CashOut";
import { selectTableStatus } from "../../../../logic/table/slice";

import { useModal } from "../hooks/useModal";
import { ModalName } from "../ModalConfig";
import { ModalProps } from "../types/modalTypes";

export const CashOutModal = (props: ModalProps): JSX.Element => {
  const { showModal } = useModal();

  const { tableStatus } = useSelector(selectTableStatus);

  useEffect(() => {
    if (tableStatus === TableStatus.POST_GAME) {
      showModal({
        name: ModalName.CashOutModal,
        title: "Final Balance Report",
      });
    }
  }, [tableStatus, showModal]);

  return <CashOut {...props} />;
};
