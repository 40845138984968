import TheSlutRulesImagePlayer from "../../static/graphics/rules/The_Slut_Player_Hand.svg";
import TheSlutRulesImagePlayerTwoCard from "../../static/graphics/rules/Player_Hand_2_Cards.svg";
import TheSlutRulesImagePlayerFourCard from "../../static/graphics/rules/Player_Hand_4_Cards.svg";
import TheSlutRulesImageCommunity from "../../static/graphics/rules/The_Slut_Community_Cards.svg";
import { GameRulesInterface } from "poker-cows-common";

export const TheSlutRules: GameRulesInterface = {
  objectives: [
    "Best 5 card poker hand utilizing EXACTLY 2 of the player’s cards and 3 community cards.",
  ],
  card: {
    default: ["4 player cards, MUST USE 2", "5 community cards, MUST USE 3"],
  },
  image: {
    player: TheSlutRulesImagePlayer,
    playerTwoCard: TheSlutRulesImagePlayerTwoCard,
    playerFourCard: TheSlutRulesImagePlayerFourCard,
    community: TheSlutRulesImageCommunity,
  },
  actions: [
    "1. Each player is dealt 4 cards down with 5 community cards.",
    "2. Bet, starting with the player left of the dealer. Bet rotates each round.",
    "3. 1 community card flipped, bet. Repeat 2 more times.",
    "4. Players simultaneously discard 2 cards, bet.",
    "5. 1 community card flipped, bet. Repeat 1 time.",
    "6. Players declare “High” or “Low” or “Both” (if a High-Low Split game).",
    "7. Cards displayed; winners paid.",
  ],
};
