/*
 * StartMenu.tsx
 * This is a react component tied to StartMenuModal.tsx which gives user the option to choose practice mode and real mode at the beginning of the game
 * Unused as of now
 */
import React from "react";
import { useDispatch } from "react-redux";

import Button from "../Button";
import { submitStartNormalGamePlay } from "../../../logic/prompts/slice";
import { ModalProps } from "../Modal/types/modalTypes";

export const StartMenu = (props: ModalProps) => {
  const dispatch = useDispatch();

  const saveNormalModeClick = () => {
    dispatch(submitStartNormalGamePlay(false /* practiceMode*/));
  };
  const startPracticeModeClick = () => {
    dispatch(submitStartNormalGamePlay(true /* practiceMode*/));
  };

  return (
    <div className="startMenuContainer">
      <div className="startMenuSubtitle">
        How would you like to play the first hand?
      </div>
      <div className="startMenuSubcontainer">
        <div className="startMenuButtonRow">
          <Button
            className="actionButton practiceButton startMenuButtons"
            onClick={startPracticeModeClick}
          >
            START IN
            <br />
            PRACTICE
            <br />
            MODE
          </Button>
          <Button
            className="actionButton green startMenuButtons"
            onClick={saveNormalModeClick}
          >
            START PLAYING
            <br />
            FOR REAL
          </Button>
        </div>
        <div className="startMenuDescriptionRow">
          <div className="startMenuTextContainer">
            <div className="startMenuDescriptionText">
              Chips are returned
              <br />
              at the end of each hand
            </div>
          </div>
          <div className="startMenuTextContainer">
            <div className="startMenuDescriptionText">
              Chips are active so they
              <br />
              can be won or lost
            </div>
          </div>
        </div>
      </div>
      <div className="startMenuText">
        This choice isn't permanent. The dealer can switch between practice mode
        and real mode after each hand.
      </div>
    </div>
  );
};
