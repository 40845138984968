/**
 * This file is all about defining the data that renders in the "Hand Rankings" page of the "Rules" dialog. We
 * dynamically compose the graphics used in the "Hand Rankings" tab on the "Rules" dialog. This file is the holds the
 * data and relavant types used by the HankRankings component to render those graphics.
 */

import { CardElementProps } from "../../../Elements/CardElement/CardElement";

// A "Hand Rank Card" can either be wild or typical.
type HandRankCardType =
  | (Pick<CardElementProps, "rank" | "suit"> & {
      isTranslucent?: boolean;
      isWild?: false;
    })
  | { isWild: true };

type HandRankCards = [
  HandRankCardType,
  HandRankCardType,
  HandRankCardType,
  HandRankCardType,
  HandRankCardType
];

const ace: Pick<CardElementProps, "rank"> = { rank: 0 };
const king: Pick<CardElementProps, "rank"> = { rank: 12 };
const queen: Pick<CardElementProps, "rank"> = { rank: 11 };
const jack: Pick<CardElementProps, "rank"> = { rank: 10 };
const ten: Pick<CardElementProps, "rank"> = { rank: 9 };
const nine: Pick<CardElementProps, "rank"> = { rank: 8 };
const eight: Pick<CardElementProps, "rank"> = { rank: 7 };
const seven: Pick<CardElementProps, "rank"> = { rank: 6 };
const six: Pick<CardElementProps, "rank"> = { rank: 5 };
const five: Pick<CardElementProps, "rank"> = { rank: 4 };
const four: Pick<CardElementProps, "rank"> = { rank: 3 };
const three: Pick<CardElementProps, "rank"> = { rank: 2 };
const two: Pick<CardElementProps, "rank"> = { rank: 1 };
const club: Pick<CardElementProps, "suit"> = { suit: 0 };
const diamond: Pick<CardElementProps, "suit"> = { suit: 1 };
const heart: Pick<CardElementProps, "suit"> = { suit: 2 };
const spade: Pick<CardElementProps, "suit"> = { suit: 3 };

export const handRanks: {
  identifier: string,
  title: string;
  summary: string;
  cards: HandRankCards;
}[] = [
  {
    identifier: "1",
    title: "FIVE OF A KIND",
    summary:
      "Five cards of the same value. This hand is only possible when playing with wild cards.",
    cards: [
      { ...ace, ...heart },
      { ...ace, ...spade },
      { ...ace, ...club },
      { ...ace, ...diamond },
      { isWild: true },
    ],
  },
  {
    identifier: "2",
    title: "ROYAL FLUSH",
    summary: "Straight flush from 10 to Ace.",
    cards: [
      { ...ace, ...heart },
      { ...king, ...heart },
      { ...queen, ...heart },
      { ...jack, ...heart },
      { ...ten, ...heart },
    ],
  },
  {
    identifier: "3",
    title: "STRAIGHT FLUSH",
    summary: "A straight but all of the cards are the same suit.",
    cards: [
      { ...ten, ...heart },
      { ...nine, ...heart },
      { ...eight, ...heart },
      { ...seven, ...heart },
      { ...six, ...heart },
    ],
  },
  {
    identifier: "4",
    title: "FOUR OF A KIND",
    summary: "Four cards of the same value.",
    cards: [
      { ...ace, ...heart },
      { ...ace, ...club },
      { ...ace, ...diamond },
      { ...ace, ...spade },
      { ...king, ...heart, isTranslucent: true },
    ],
  },
  {
    identifier: "5",
    title: "FULL HOUSE",
    summary:
      "A combination of three of a kind with a pair. The highest three of a kind cards wins against other full houses.",
    cards: [
      { ...ace, ...heart },
      { ...ace, ...club },
      { ...ace, ...diamond },
      { ...king, ...spade },
      { ...king, ...heart },
    ],
  },
  {
    identifier: "6",
    title: "FLUSH",
    summary:
      "Five cards that are all of the same suit. If two players have a flush, the player with the highest card wins.",
    cards: [
      { ...king, ...club },
      { ...ten, ...club },
      { ...eight, ...club },
      { ...seven, ...club },
      { ...five, ...club },
    ],
  },
  {
    identifier: "7",
    title: "STRAIGHT",
    summary:
      "A series of five cards in a row but are not of the same suit. Aces can follow a king or start a straight before a two.",
    cards: [
      { ...ten, ...heart },
      { ...nine, ...club },
      { ...eight, ...diamond },
      { ...seven, ...spade },
      { ...six, ...heart },
    ],
  },
  {
    identifier: "8",
    title: "THREE OF A KIND",
    summary: "Three cards of the same value.",
    cards: [
      { ...ace, ...heart },
      { ...ace, ...diamond },
      { ...ace, ...club },
      { ...king, ...spade, isTranslucent: true },
      { ...queen, ...heart, isTranslucent: true },
    ],
  },
  {
    identifier: "9",
    title: "TWO PAIR",
    summary: "Two sets of two cards of the same value.",
    cards: [
      { ...ace, ...heart },
      { ...ace, ...club },
      { ...king, ...diamond },
      { ...king, ...spade },
      { ...seven, ...heart, isTranslucent: true },
    ],
  },
  {
    identifier: "10",
    title: "PAIR",
    summary: "Two cards of the same value.",
    cards: [
      { ...ace, ...heart },
      { ...ace, ...club },
      { ...king, ...diamond, isTranslucent: true },
      { ...jack, ...spade, isTranslucent: true },
      { ...seven, ...heart, isTranslucent: true },
    ],
  },
  {
    identifier: "11",
    title: "HIGH CARD",
    summary: "If none of the above, then the highest card in the hand.",
    cards: [
      { ...ace, ...heart },
      { ...king, ...club, isTranslucent: true },
      { ...queen, ...diamond, isTranslucent: true },
      { ...nine, ...spade, isTranslucent: true },
      { ...seven, ...heart, isTranslucent: true },
    ],
  },
  {
    identifier: "12a",
    title: "LOW HAND: THE WHEEL",
    summary: "5-4-3-2-Ace no straights or flushes.",
    cards: [
      { ...five, ...heart },
      { ...four, ...club },
      { ...three, ...diamond },
      { ...two, ...spade },
      { ...ace, ...heart },
    ],
  },
  {
    identifier: "12b",
    title: "LOW HAND: 7-5 LOW",
    summary: "7-5-4-3-2 unsuited.",
    cards: [
      { ...seven, ...heart },
      { ...five, ...club },
      { ...four, ...diamond },
      { ...three, ...spade },
      { ...two, ...heart },
    ],
  },
  {
    identifier: "12c",
    title: "LOW HAND: 6-4 LOW",
    summary: "6-4-3-2-Ace unsuited.",
    cards: [
      { ...six, ...heart },
      { ...four, ...club },
      { ...three, ...diamond },
      { ...two, ...spade },
      { ...ace, ...heart },
    ],
  },
];
