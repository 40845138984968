/**
 * BetArea.tsx
 *
 * This component is used during Bet to five users either fixed bet or spread bet based on prefs
 */
import React from "react";
import { useSelector } from "react-redux";

import {
  selectIsBetFixedLimit,
  selectIsBetNoLimit,
  selectIsBetSpreadLimit,
} from "../../../logic/gameInstance/slice";
import FixedBetArea from "./BetArea/FixedBetArea";
import { SpreadBetArea } from "./BetArea/SpreadBetArea";
import { NoLimitBetArea } from "./BetArea/NoLimitBetArea";
import { useAutomatedBet } from "./BetArea/hooks/AutomatedBet";

import "./BetArea.css";

export const BetArea = () => {
  const { isBetNoLimit } = useSelector(selectIsBetNoLimit);
  const { isBetFixedLimit } = useSelector(selectIsBetFixedLimit);
  const { isBetSpreadLimit } = useSelector(selectIsBetSpreadLimit);

  useAutomatedBet();

  if (isBetNoLimit) {
    return <NoLimitBetArea />;
  }
  if (isBetFixedLimit) {
    return <FixedBetArea />;
  }
  if (isBetSpreadLimit) {
    return <SpreadBetArea />;
  }

  return <></>;
};

export default BetArea;
