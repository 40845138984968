import { useSelector } from "react-redux";
import { selectTwilioSessionMediaState } from "../../logic/twilio/slice";
import React from "react";
import {
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./MediaControls.css";
import { selectTableId } from "../../logic/table/slice";
import { TwilioClient } from "../../logic/twilio/TwilioClient";
import { selectLocalPlayerId } from "../../logic/localPlayer/slice";
import Button from "./Button";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const MediaControls = () => {
  // selectors
  const { twilioSessionMediaState: twilioAudioSessionMediaState } = useSelector(
    selectTwilioSessionMediaState("audio")
  );
  const { twilioSessionMediaState: twilioVideoSessionMediaState } = useSelector(
    selectTwilioSessionMediaState("video")
  );
  const { tableId } = useSelector(selectTableId);
  const { localPlayerId } = useSelector(selectLocalPlayerId);

  const twilioClient = TwilioClient.get(tableId.toString(), localPlayerId);

  // twilioClient will be undefined until created by <LocalTwilioMedia/>
  // so in case this component is rendered before <LocalTwilioMedia/>,
  // we put a check here
  if (!twilioClient) {
    return <></>;
  }

  // functions
  const showCam = () => twilioClient.startLocalMedia("video");
  const hideCam = () => twilioClient.stopLocalMedia("video");
  const muteMic = () => twilioClient.stopLocalMedia("audio");
  const unmuteMic = () => twilioClient.startLocalMedia("audio");

  const micFunc = !twilioAudioSessionMediaState ? unmuteMic : muteMic;
  const micIcon = !twilioAudioSessionMediaState
    ? faMicrophoneSlash
    : faMicrophone;

  const vidFunc = !twilioVideoSessionMediaState ? showCam : hideCam;
  const vidIcon = !twilioVideoSessionMediaState ? faVideoSlash : faVideo;

  return (
    <div className="media-controls">
      <Button className="actionButton" onClick={vidFunc}>
        <FontAwesomeIcon icon={vidIcon as IconProp} />
      </Button>

      <Button className="actionButton" onClick={micFunc}>
        <FontAwesomeIcon icon={micIcon as IconProp} />
      </Button>
    </div>
  );
};

export default MediaControls;
