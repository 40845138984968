import React from "react";
import { BoundaryType } from "./ErrorBoundary";
import "./ErrorPage.css";

export function ErrorPage(props: BoundaryType) {
  return (
    <div className={"error-page"}>
      <div>
        An error has occurred
        <br />
        Please try refreshing the webpage
        <br />
      </div>
      <details style={{ whiteSpace: "pre-wrap", marginTop: "1em" }}>
        <summary>Technical Details</summary>
        {props.error && props.error.toString()}
        <br />
        {props.errorInfo?.componentStack}
      </details>
    </div>
  );
}
