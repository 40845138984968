/**
 * CashOut.tsx
 *
 * This is a component which is visible for host to cash out and ask for confirmation.
 * Commented out code is because a redirect to a survey may need to be implemented or removed as we move through
 * alpha and bea phases of release.
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TableStatus } from "poker-cows-common";
import {
  beginShutdown,
  selectPreShutdownTime,
  shutdownNow,
  togglePreShutdown,
} from "../../../../logic/prompts/slice";
import {
  selectPokerSessionId,
  selectTableId,
  selectHostId,
  selectIsLocalPlayerHost,
  selectIsTableInPreShutdown,
  selectTableStatus,
  selectWhenToArchiveTable,
  selectIsBotTable,
} from "../../../../logic/table/slice";
import { BankingLedgerTable } from "./BankingLedger/BankingLedger";
import { Timer } from "../../Timer";
import { ModalProps } from "../../Modal/types/modalTypes";
import Button from "../../Button";
import { redirectToSurvey } from "../../../../utils/RedirectToSurvey";
import {
  END_GAME_SESSION_BY_HOST_BUTTON,
  SHUTDOWN_GAME_BUTTON,
  SHUTDOWN_TIMER_AREA,
} from "../../../../test-identifiers";

export const CashOut = (props: ModalProps) => {
  const dispatch = useDispatch();
  const { tableStatus } = useSelector(selectTableStatus);
  const { archiveTableTime } = useSelector(selectWhenToArchiveTable);
  const { preShutdownTime } = useSelector(selectPreShutdownTime);

  const [endGameSession, setEndGameSession] = useState(false);

  useEffect(() => {
    if (tableStatus === TableStatus.POST_GAME) {
      setEndGameSession(true);
    }
  }, [tableStatus]);

  // redirecting to survey may be re-implemented in the future, commenting out for now according to SoW
  const { isBotTable } = useSelector(selectIsBotTable);
  /* const { tableId } = useSelector(selectTableId);
  const { hostId } = useSelector(selectHostId);
  const { pokerSessionId } = useSelector(selectPokerSessionId); */
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);
  const { isTableInPreShutdown } = useSelector(selectIsTableInPreShutdown);

  const handleBeginShutdown = () => {
    dispatch(beginShutdown());
    // TODO: cash players out
    // TODO: generate game report
    setEndGameSession(true);
    // redirecting to survey may be re-implemented in the future, commenting out for now according to SoW
    // redirectToSurvey({tableId, isBotTable, hostId, pokerSessionId});
  };

  const handleCancel = () => {
    if (isLocalPlayerHost && isTableInPreShutdown) {
      dispatch(togglePreShutdown());
    }
    props.onBack();
  };

  const handleShutdownNow = () => {
    // redirectToSurvey({ tableId, isBotTable, hostId, pokerSessionId });
    dispatch(shutdownNow());
    window.location.replace("/end-session");
  };

  return (
    <>
      <div className="hostControlsSubpageContainer hostControlsCashoutContainer">
        <BankingLedgerTable static={true} navigate={props.onBack} />

        {/* body contents before clicking end game */}
        <footer className="modalFooter blackBackground">
          <div className="hostControlsCashoutFooter">
            {!endGameSession && (
              <>
                <div>
                  <Button
                    data-testid={END_GAME_SESSION_BY_HOST_BUTTON}
                    className="resetHandResetButton actionButton green"
                    onClick={() => handleBeginShutdown()}
                  >
                    YES, FINALIZE BALANCE REPORT
                    {/* END THE GAME SESSION* */}
                  </Button>
                </div>
                {!isBotTable && (
                  <p className="resetButtonSubtext">
                    *Free video chat will remain active for 10 minutes
                  </p>
                )}
                <Button
                  className="resetHandCancelButton actionButton cancelButton"
                  onClick={handleCancel}
                >
                  NO, CANCEL
                </Button>
              </>
            )}

            {/* body contents if clicked end game */}
            {endGameSession && (
              <>
                {(archiveTableTime || preShutdownTime) && (
                  <div className="verticalTimer" data-testid={SHUTDOWN_TIMER_AREA}>
                    Video chat will remain active for:
                    <Timer deadline={archiveTableTime ?? preShutdownTime} />
                    <div className="minutesText"> MINUTES </div>
                  </div>
                )}
                <Button
                  data-testid={SHUTDOWN_GAME_BUTTON}
                  className="endVideoButton actionButton"
                  onClick={() => handleShutdownNow()}
                >
                  {isBotTable
                    ? "EXIT DEMO"
                    : "END VIDEO CHAT NOW & DISCONNECT FROM POKERCOWS"}
                </Button>
              </>
            )}
          </div>
        </footer>
      </div>
    </>
  );
};
