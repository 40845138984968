import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  call as callReduxAction,
  check as checkReduxAction,
  fold as foldReduxAction,
  raise as raiseReduxAction,
  selectBetPrompt,
} from "../../../../../logic/prompts/slice";
import foldSfx from "../../../../../static/sounds/fold.wav";
import checkSfx from "../../../../../static/sounds/check.wav";
import callSfx from "../../../../../static/sounds/chips.wav";
import {
  selectCurrentBet,
  selectPrefs,
  selectBetRoundID,
  selectBigBlind,
  selectLocalPlayerCallAmount,
  selectCurrentRaise,
  selectRaises,
  selectCanLocalPlayerCall,
  selectLocalPlayerBank,
  selectLocalPlayerBet,
  selectSidePots,
  selectIsBetNoLimit,
} from "../../../../../logic/gameInstance/slice";
import {
  selectPlayerBankAtTablePos,
  selectPlayerIdAtTablePos,
} from "../../../../../logic/player/slice";
import { SidePotInterface } from "poker-cows-common";

/**
 * This hook is used to return a value used to check if a player is "all in".
 * Ideally this value gets returned from `useBetting`, but - today - that hook requires
 * properties for `minRaise` and `maxRaise`. There are scenarios where we won't readily
 * have access to those values, but need to know if a player is all in regardless.
 *
 * In the near future, we should be able to get this property from some other existing hook into a store synced with
 * back end. In other words, back end will keep track of if this is true or not.
 */
export function useAllInCheck({ tablePosition }: { tablePosition: number }) {
  const { playerId } = useSelector(selectPlayerIdAtTablePos(tablePosition));
  const { sidePots } = useSelector(selectSidePots);
  const { playerBank } = useSelector(selectPlayerBankAtTablePos(tablePosition));

  const isAllIn =
    playerBank === 0 &&
    sidePots.some((sidePot: SidePotInterface) => {
      const hasSidePotRelevantToPlayer = sidePot.playerIds.includes(playerId);
      const hasPotValue = sidePot.limitPerPlayer !== -1; // TODO: What happens if it's zero?
      return hasSidePotRelevantToPlayer && hasPotValue;
    });

  return { isAllIn };
}

export interface BettingProps {
  minRaise: number;
  maxRaise: number;
}

export function useBetting(props: BettingProps) {
  const dispatch = useDispatch();
  const { localPlayerCallAmount } = useSelector(selectLocalPlayerCallAmount);
  const { currentBet } = useSelector(selectCurrentBet);
  const { localPlayerBet } = useSelector(selectLocalPlayerBet);
  const { prefs } = useSelector(selectPrefs);
  const { currentRaise } = useSelector(selectCurrentRaise);
  const { localPlayerBank } = useSelector(selectLocalPlayerBank);
  const maxRaises = prefs?.maxRaises ?? 0;
  const { canLocalPlayerCall } = useSelector(selectCanLocalPlayerCall);
  const { currentRaises } = useSelector(selectRaises);
  const { bigBlind } = useSelector(selectBigBlind);
  const { isBetNoLimit } = useSelector(selectIsBetNoLimit);
  const hasMoney = localPlayerBank > 0;
  const hasCall = localPlayerCallAmount > 0;
  const hasCurrentBet = currentBet > 0;
  const noLimitMinRaise = Math.max(currentRaise, bigBlind);

  const minRaise = prefs?.raiseMustBeAtLeastPreviousRaise
    ? Math.max(props?.minRaise, currentRaise)
    : props?.minRaise;

  const maxRaise = useMemo(
    () => Math.min(props?.maxRaise, localPlayerBank),
    [props?.maxRaise, localPlayerBank]
  );

  const fold = (): void => {
    dispatch(foldReduxAction());
  };

  const check = (): void => {
    dispatch(checkReduxAction());
  };

  const call = (): void => {
    dispatch(callReduxAction(localPlayerCallAmount));
  };

  const raiseTo = (amount: number): void => {
    if (isBetNoLimit) {
      dispatch(raiseReduxAction(amount));
    } else {
      dispatch(raiseReduxAction(amount - localPlayerBet));
    }
  };

  const { betRoundID } = useSelector(selectBetRoundID);
  const isTurnToBet = !!useSelector(selectBetPrompt).betPrompt;
  const canCheck = localPlayerCallAmount <= 0;

  // Sorted alphabetically
  return {
    /**
     * Each game can have many rounds of bettings. Each of those rounds has a
     * unique identifier. This is that.
     */
    betRoundId: betRoundID,

    /**
     * @see https://en.wikipedia.org/wiki/Blind_(poker)
     * In no-limit games, a small and big blind are defined.
     */
    bigBlindValue: bigBlind,

    /**
     * This is an action which - when called - communicates to the back end
     * that the player wishes to call the current bet.
     * @see https://en.wikipedia.org/wiki/Betting_in_poker#Call
     */
    call,

    /**
     * This is the amount that the player must call to stay in the hand.
     * @see https://en.wikipedia.org/wiki/Betting_in_poker#Call
     */
    callAmount: localPlayerCallAmount,

    /**
     * This is a boolean which indicates if the player can check.
     * @see https://en.wikipedia.org/wiki/Betting_in_poker#Check
     */
    canCheck,

    /**
     * This is an action which - when called - communicates to the back end
     * that the player is checking.
     * @see https://en.wikipedia.org/wiki/Betting_in_poker#Check
     */
    check,

    /**
     * This is the current bet amount.
     * @see https://en.wikipedia.org/wiki/Betting_in_poker#Open,_bet,_raise
     */
    currentBet,

    /**
     * This is the current raise amount.
     * @see https://en.wikipedia.org/wiki/Betting_in_poker#Open,_bet,_raise
     */
    currentRaise,

    /**
     * This is an action which - when called - communicates to the back end
     * that the player wishes to fold.
     * @see https://en.wikipedia.org/wiki/Betting_in_poker#Fold
     */
    fold,

    /**
     * This is a boolean which indicates if the player is expected to (at
     * least) call to continue.
     * @see https://en.wikipedia.org/wiki/Betting_in_poker#Call
     */
    hasCall,

    /**
     * This is a boolean which indicates if the round has an active bet.
     * @see https://en.wikipedia.org/wiki/Betting_in_poker#Open,_bet,_raise
     */
    hasCurrentBet,

    /**
     * This is a boolean which indicates if the player has money in "the bank".
     * @see https://en.wikipedia.org/wiki/Betting_in_poker#Open,_bet,_raise
     */
    hasMoney,

    /**
     * This is a boolean which indicates if it is the current player's turn.
     * @see https://en.wikipedia.org/wiki/Betting_in_poker#Order_of_betting
     */
    isTurnToBet,

    /**
     * In spread limit games, the maximum raise is defined. It is the largest
     * value with which a player can raise the current bet.
     */
    maxRaise,

    /**
     * In spread limit games, there is a number of raises allowed in a round.
     * This is that value.
     */
    maxRaises,

    /**
     * In spread limit games, the minimum raise is defined. It is the smallest
     * value with which a player can raise the current bet.
     */
    minRaise,

    /**
     * In no-limit games, there is a dynamically computed minimum raise. It is
     * represented as the maximum value between the big blind and the current
     * raise.
     */
    noLimitMinRaise,

    /**
     * This is the number of raises that have been made in the current round.
     */
    numberOfRaises: currentRaises,

    /**
     * This is an action which - when called with an amount - communicates to
     * the back end that the player wishes to raise the current bet (by the
     * passed amount).
     */
    raiseTo,

    /**
     * This is a boolean which indicates if game is meant to allow a player see
     * the "Call" button.
     */
    showCall: canLocalPlayerCall,

    /**
     * This is the amount of money available to a player.
     */
    bank: localPlayerBank,

    /**
     * This is the amount of money that the player has bet in the current round.
     */
    bet: localPlayerBet,
  };
}
