/**
 * ResetHandModal.tsx
 *
 * This is just a wrapper around the ResetHand
 */
import React from "react";

import { ResetHand } from "../../Settings/HostControls/ResetHand";

import { ModalProps } from "../types/modalTypes";

export const ResetHandModal = (props: ModalProps): JSX.Element => {
  return <ResetHand {...props} />;
};
