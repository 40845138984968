/** LowMethodContent.tsx
 *
 * This is a rulesRow component used in table rules
 * It displays low-hand options with tooltip i.e. WHEEL, 6-4 and 7-5
 */
import React from "react";
import { LowMethods, LowMethodInterface } from "poker-cows-common";
import classNames from "classnames";
import { selectNextPrefs } from "../../../../../logic/table/slice";
import { useSelector } from "react-redux";

const LowMethodContent = () => {
  const { nextPrefs } = useSelector(selectNextPrefs);
  const LowMethodOptions = () => (
    <div className="lowMethodOptions">
      {LowMethods.map((method: LowMethodInterface, index: number) => {
        const checked: boolean = nextPrefs?.lowestHand === method.value;
        return (
          <div className="lowMethodBox" key={index}>
            <label
              className={classNames("lowMethodLabel", {
                selected: checked,
              })}
            >
              {method.label}
            </label>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="lowMethod">
      <LowMethodOptions />
    </div>
  );
};

export default LowMethodContent;
