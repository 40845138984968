/**
 * PlayerNotification.tsx
 * This component is used to notify a player
 * Props required Notify `title` and `description`
 */
import React from "react";
import "./PlayerNotification.css";

interface PlayerNotificationProps {
  title: string;
  description: string;
}

export const PlayerNotification = (props: PlayerNotificationProps) => {
  return (
    <div className="playerNotification">
      <div className="playerNotificationTitle" data-testid={props.title}>
        {props.title}
      </div>
      <div className="playerNotificationText">{props.description}</div>
    </div>
  );
};
