import React from "react";
import { useSelector } from "react-redux";

import { Chip } from "../../../Chip";
import { selectNextPrefs } from "../../../../../logic/table/slice";

export const ChipDenominationContent = () => {
  const { nextPrefs } = useSelector(selectNextPrefs);

  return (
    <div className="chipAreaRow chips">
      <Chip denom="low" value={nextPrefs?.chipDenomLow ?? 0} />
      <Chip denom="mid" value={nextPrefs?.chipDenomMid ?? 0} />
      <Chip denom="high" value={nextPrefs?.chipDenomHigh ?? 0} />
    </div>
  );
};
