/**
 * ChipNotification.tsx
 * 
 * Displays the result of a chip movement (whether the host has sold chips, transferred chips
 * or something else)
 */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";

type ChipNotificationProps = {
  className?: string;
  message: React.ReactNode;
  back?: () => void;
};

export const ChipNotification = (props: ChipNotificationProps) => {
  const closeButton = (
    <span className="close" onClick={props.back}>
      <FontAwesomeIcon
        className="close-icon"
        title="Close"
        icon={faClose as IconProp}
      />
    </span>
  );

  return (
    <div className={classNames("chipNotification", props.className)}>
      {props.message}
      {props.back && closeButton}
    </div>
  );
};
