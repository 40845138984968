import { GameRulesInterface } from "poker-cows-common";
import PassTheTrashRulesImagePlayer from "../../static/graphics/rules/Pass_The_Trash_Player_Hand.svg";

export const PassTheTrashRules: GameRulesInterface = {
  objectives: ["Best 5 card poker hand"],
  card: { default: ["7 player cards dealt down"] },
  image: { player: PassTheTrashRulesImagePlayer },
  actions: [
    "1. Each player is dealt 7 cards down.",
    "2. Bet, starting left of dealer.",
    "3. Each player simultaneously selects and passes 3 cards to their left player, bet.",
    "4. Players simultaneously discard 2 cards and roll 1 card, then bet based on best hand.",
    "5. Players simultaneously roll 1 card, then bet for 3 more rounds.",
    "6. Players declare “High” or “Low” or “Both” (if a High-Low Split game).",
    "7. Cards displayed; winners paid.",
  ],
};
