/*
  SessionSettings.ts
  This in-houses the the constants used(directly, prefix, suffix) and the different functions that is set,get,remove for Session Storage
*/

export enum SessionKeys {
  isLocalPlayerHost = "pokercows-is-localPlayer-host",
  username = "pokercows-username",
  hasSeenDiscardInfo = "pokercows-has-seen-discard-info",
  twilioMedia = "pokercows-twilio-media",
  isBotTable = "pokercows-is-bot-table",
  localPlayerId = "pokercows-local-player-id",
}

export const setSession = (key: string, value: string): void => {
  return sessionStorage.setItem(key, value);
};

export const getSession = (key: string): string | null => {
  const value = sessionStorage.getItem(key);
  return value;
};

export const removeSession = (key: string): void => {
  return sessionStorage.removeItem(key);
};
