/**
 * This component renders the hand's low or high value with a corresponding icon inside of a dark box. It purposefully
 * is only driven by props so that we can place it into Storybook some day run visual regression diff tests against it.
 */

import React from "react";
import classNames from "classnames";

import { Game } from "poker-cows-common";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as EyeFilled } from "../../../static/graphics/eye-filled.svg";
import { ReactComponent as EyeUnfilled } from "../../../static/graphics/eye-unfilled.svg";

import { WinnerGlowBox } from "../WinnerGlowBox/WinnerGlowBox";
import styles from "./HandValueItem.module.css";

export interface HandValueItemProps {
  className?: string;
  gameType: Game;
  isGameWinner: boolean;
  isBothWinner: boolean;
  isHighWinner: boolean;
  isLowWinner: boolean;
  variant: "high" | "low";
  description: JSX.Element;
  isTogglePressed: boolean;
  onToggleClick: () => void;
}

export const HandValueItem = ({
  className,
  gameType,
  isGameWinner,
  isBothWinner,
  isHighWinner,
  isLowWinner,
  variant,
  description,
  isTogglePressed,
  onToggleClick,
}: HandValueItemProps): JSX.Element => {
  const isLowHandValue = variant === "low";
  const isHighHandValue = variant === "high";

  let valueIcon: JSX.Element;

  if (isLowHandValue) {
    valueIcon = (
      <FontAwesomeIcon icon={faArrowDown as IconProp} aria-label="Low value" />
    );
  } else {
    valueIcon = (
      <FontAwesomeIcon icon={faArrowUp as IconProp} aria-label="High value" />
    );
  }

  const isHighlightButtonAvailable = ![
    Game.FiveCardDraw,
    Game.FiveCardStud,
  ].includes(gameType);

  return (
    <WinnerGlowBox
      as="div"
      className={classNames(className, styles.handValueItem)}
      isGameWinner={isGameWinner}
      isBothWinner={isBothWinner}
      isHighWinner={isHighWinner}
      isLowWinner={isLowWinner}
    >
      <span
        className={classNames(styles.valueIcon, {
          [styles.lowValueIcon]: isLowHandValue,
          [styles.highValueIcon]: isHighHandValue,
        })}
      >
        {valueIcon}
      </span>

      <span className={styles.description}>{description}</span>

      {isHighlightButtonAvailable && (
        <button
          onClick={onToggleClick}
          aria-pressed={isTogglePressed}
          type="button"
          className={styles.eyeballActionUI}
        >
          <span className="sr-only">
            {isTogglePressed ? "Unhighlight" : "Highlight"} cards
          </span>

          {isTogglePressed ? <EyeFilled /> : <EyeUnfilled />}
        </button>
      )}
    </WinnerGlowBox>
  );
};
