/**
 * useDeveloper.ts
 *
 * This is a custom hook used by /dev route to save and get the developer features using local storage and the routing scheme
 */
import { useEffect, useState } from "react";

import { Toggle } from "poker-cows-common";
import { useRedirect } from "../../../../hooks/useRedirect";
import { isToggleOn } from "../../../../logic/toggle/api";

export function useDeveloper() {
  const gotoDevTable = useRedirect("/dev/");

  const [developer, setDeveloper] = useState(false);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    isToggleOn(Toggle.DEV_TABLE).then((on) => {
      setDeveloper(on);
      setFetched(true);
    });
  }, []);

  return {
    isDeveloper: fetched ? developer : undefined,
    gotoDevTable,
  };
}
