import React from "react";
import { useSelector } from "react-redux";

import { selectTableId } from "../../../../logic/table/slice";

import "./LobbyTableCode.css";

export const LobbyTableCode = () => {
  const { tableId } = useSelector(selectTableId);

  return (
    <div className="lobbyTableCodeContainer">
      <div className="lobbyTableCodeText">
        TABLE CODE:&nbsp;
        <div className="lobbyTableCode">{tableId}</div>
      </div>
    </div>
  );
};
