/*
  this component is what hangs out to the left of the user's
  seat/video in the actionArea.
  it displays the fold button, the show cards button, and
  the fold in turn button
*/

import React from "react";
import { FoldInTurnButton } from "./FoldInTurnButton";
import { FoldButton } from "./FoldButton";
import { useSelector } from "react-redux";
import { selectPlayerShowdownPrompt } from "../../../../logic/prompts/slice";
import { selectHasPlayerFoldedAtTablePos } from "../../../../logic/gameInstance/slice";
import { useSeatContext } from "../../Seat/SeatContext";
import { ShowCardsButton } from "./ShowCardsButton";
import { selectPlayerHandAtTablePos } from "../../../../logic/player/slice";
import "./ExtraActionArea.css";

interface extraActionAreaProps {
  isWaiting: boolean;
  isBetting: boolean;
  isDiscarding: boolean;
}

export const ExtraActionArea = (props: extraActionAreaProps) => {
  const { playerShowdownPrompt } = useSelector(selectPlayerShowdownPrompt);
  const { tablePosition } = useSeatContext();
  const { hasPlayerFolded } = useSelector(
    selectHasPlayerFoldedAtTablePos(tablePosition)
  );
  const { playerHand } = useSelector(selectPlayerHandAtTablePos(tablePosition));
  const { showdownOverride } = playerHand ?? { showdownOverride: false };

  const showFold = props.isDiscarding || props.isBetting;
  const showShowCards =
    (playerShowdownPrompt || showdownOverride) && !hasPlayerFolded;

  return (
    <div className="foldArea">
      {showFold && (
        <FoldButton
          isSelectingCard={!props.isBetting}
          tablePosition={tablePosition}
        />
      )}
      {props.isWaiting && <FoldInTurnButton />}
      {showShowCards && <ShowCardsButton disabled={showdownOverride} />}
    </div>
  );
};
