import React from "react";
import { WinnersSection } from "./WinnersSection";
import { OptionSection } from "./OptionSection";
import { WildcardSection } from "./WildcardSection";
import { getGameOptions, getGameRules } from "../../../../utils/GameInfo";
import { WildcardOption } from "poker-cows-common";

// This is the options tab found when choosing a game or clicking "rules" button as a non-dealer player
// it contains all sections that show in options tab
export const OptionsPage = (props: {
  isRulePage: boolean;
  isCurrentGameOption: boolean;
  gameRules: ReturnType<typeof getGameRules>;
  gameOptions: ReturnType<typeof getGameOptions>;
  playerHandImage?: string;
}) => {
  const { gameOptions, isRulePage, isCurrentGameOption } = props;

  const optionSelection = () => {
    if (gameOptions?.options && gameOptions.options.length > 1) {
      return (
        <OptionSection
          isRulePage={isRulePage}
          isCurrentGameOption={isCurrentGameOption}
          gameOptions={gameOptions}
        ></OptionSection>
      );
    }
  };

  const wildcardSelection = () => {
    const wildcards = gameOptions?.wildcards;
    const onlyNoneOption =
      wildcards?.length &&
      wildcards.length === 1 &&
      wildcards[0].value === WildcardOption.NONE;

    // hide wildcard option if only none available
    if (wildcards && wildcards.length && !onlyNoneOption) {
      return (
        <WildcardSection
          isRulePage={isRulePage}
          isCurrentGameOption={isCurrentGameOption}
          gameOptions={gameOptions}
        ></WildcardSection>
      );
    }
  };

  return (
    <div className="options">
      <div className="optionsCaption">{`${
        !isRulePage ? "SELECT GAME SETTINGS BELOW:" : "GAME SETTINGS"
      }`}</div>
      <WinnersSection
        isRulePage={isRulePage}
        isCurrentGameOption={isCurrentGameOption}
        gameOptions={gameOptions}
      />
      {optionSelection()}
      {wildcardSelection()}
    </div>
  );
};
