/* eslint-disable @typescript-eslint/ban-types */
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { PromptInterface } from "poker-cows-common";
import * as Requests from "./api";
import { RootState } from "../store";
import { updateTable } from "../shared";
import { selectLocalPlayerId } from "../localPlayer/slice";
import { selectCurrentPlayerId } from "../gameInstance/slice";
import { selectTableGroup, selectTableId } from "../table/slice";
import {
  resetHighlight,
  resetHighlightByPlayerId,
} from "../positionHighlight/slice";
import { ModalName } from "../../ui/components/Modal/ModalConfig";
import { checkIsBotTable } from "../../utils/BotImage";

// =============================== //
//          Initial State          //
// ================================//
export const initialState: { fullPrompts: PromptInterface[] } = {
  fullPrompts: [],
};

// =============================== //
//              Slice              //
// ================================//
export const slice = createSlice({
  name: "prompt",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPrompts.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });
    builder.addCase(
      updateTable,
      (
        state,
        action: { type: string; payload: { prompts: PromptInterface[] } }
      ) => {
        const prompts = action.payload.prompts;

        if (Array.isArray(prompts)) {
          return { ...state, fullPrompts: prompts };
        }

        return initialState;
      }
    );
  },
});

// =============================== //
//            Reducers             //
// ================================//
export default slice.reducer;

// =============================== //
//            Selectors            //
// ================================//
const selectPrompts = (state: RootState) => state.prompt.fullPrompts ?? [];
const selectPromptBySuffix = (promptTypeSuffix: string) =>
  createSelector(
    [selectPrompts, selectLocalPlayerId],
    (prompts, { localPlayerId }) => {
      const relevantPrompt = prompts.find((prompt) => {
        const isMatchingPrompt = prompt?.prefix?.endsWith(promptTypeSuffix);
        const isMatchingPlayer = prompt?.playerId === localPlayerId;
        return isMatchingPrompt && isMatchingPlayer;
      });
      return relevantPrompt ? { prompt: relevantPrompt } : { prompt: null };
    }
  );
const selectAnyPromptBySuffix = (promptTypeSuffix: string) =>
  createSelector(selectPrompts, (prompts) => {
    const relevantPrompt = prompts.find((prompt) =>
      prompt?.prefix?.endsWith(promptTypeSuffix)
    );
    return relevantPrompt ? { prompt: relevantPrompt } : { prompt: null };
  });
const selectAnyPrompt = (promptType: string) =>
  createSelector(selectPrompts, (prompts) => {
    const relevantPrompt = prompts.find(
      (prompt) => prompt?.prefix === promptType
    );
    return relevantPrompt ? { prompt: relevantPrompt } : { prompt: null };
  });
// "bypassPromptFidelity" is a boolean you can optionally pass (currently only into changePrefs)
// which allows localPlayer to get a prompt that belongs to another player
// currently this is only used when a host wants to change prefs through host controls -> tableSettings
// *when they are NOT a dealer*
// (because as of today the changePrefsPrompt is only assigned to current dealer)
const selectLocalPlayerPrompt = (
  promptType: string,
  bypassPromptFidelity?: boolean
) =>
  createSelector(
    [selectPrompts, selectLocalPlayerId],
    (prompts, { localPlayerId }) => {
      const relevantPrompt = prompts.find((prompt) => {
        const isMatchingPrompt = prompt?.prefix === promptType;
        const isMatchingPlayer = prompt?.playerId === localPlayerId;
        return isMatchingPrompt && (bypassPromptFidelity || isMatchingPlayer);
      });
      return relevantPrompt ? { prompt: relevantPrompt } : { prompt: null };
    }
  );
const selectPromptForPlayer = (promptType: string, playerId: string) =>
  createSelector(selectPrompts, (prompts) => {
    const relevantPrompt = prompts.find((prompt) => {
      const isMatchingPrompt = prompt?.prefix === promptType;
      const isMatchingPlayer = prompt?.playerId === playerId;
      return isMatchingPrompt && isMatchingPlayer;
    });
    return relevantPrompt ? { prompt: relevantPrompt } : { prompt: null };
  });
const selectAction = (promptName: string, actionName: string) =>
  createSelector(
    [selectPrompts, selectLocalPlayerId],
    (prompts, { localPlayerId }) => {
      for (let i = 0; i < prompts.length; i++) {
        const prompt = prompts[i];
        const actions = prompt.actionsAvailable;
        const playerId = prompt?.playerId;
        if (prompt?.prefix === promptName && playerId === localPlayerId) {
          for (let j = 0; j < actions.length; j++) {
            const action = actions[j];
            if (action?.nameOfClassInstance.endsWith(actionName)) {
              return { action: { ...action, promptIndex: j } };
            }
          }
        }
      }
      return { action: null };
    }
  );
const selectActionIndex = (promptName: string, actionName: string) =>
  createSelector(
    selectAction(promptName, actionName),
    ({ action }) => action?.promptIndex
  );
export const selectGameTypeActions = createSelector(
  selectAnyPrompt("GameTypePrompt"),
  ({ prompt }) => ({ gameTypeActions: prompt?.actionsAvailable ?? [] })
);
// TODO - DRY against getPrompt, very next function in this file.
export const selectHostAdmitByPlayerIdPrompt = (playerToAdmitId: string) =>
  createSelector(
    [selectPrompts, selectLocalPlayerId],
    (prompts, { localPlayerId }) => {
      const relevantPrompt = prompts.find((prompt) => {
        const isMatchingPrompt = prompt?.prefix?.endsWith(
          "HostAdmitPlayerPrompt"
        );
        const isMatchingPlayer = prompt?.playerId === localPlayerId;
        const isMatchingJoiningPlayerId =
          prompt?.actionsAvailable[0].data?.joiningPlayerId === playerToAdmitId;
        return (
          isMatchingPrompt && isMatchingPlayer && isMatchingJoiningPlayerId
        );
      });
      return relevantPrompt
        ? { hostAdmitByPlayerIdPrompt: relevantPrompt }
        : { hostAdmitByPlayerIdPrompt: null };
    }
  );
export const selectFoldablePromptForPlayer = (playerId = "") =>
  createSelector(selectPrompts, (prompts) => {
    for (let i = 0; i < prompts.length; i++) {
      const prompt = prompts[i];
      const actions = prompt.actionsAvailable;
      if (prompt?.playerId === playerId) {
        for (let j = 0; j < actions.length; j++) {
          const action = actions[j];
          if (action?.nameOfClassInstance.endsWith("FoldAction")) {
            return { foldablePromptForPlayer: prompt };
          }
        }
      }
    }
    return { foldablePromptForPlayer: null };
  });
export const selectPlayerBreakPromptForPlayer = (playerId = "") =>
  createSelector(
    selectPromptForPlayer("PlayerBreakPrompt", playerId),
    ({ prompt }) => ({ playerBreakPromptForPlayer: prompt })
  );
export const selectAntePrompt = createSelector(
  selectLocalPlayerPrompt("AntePrompt"),
  ({ prompt }) => ({ antePrompt: prompt })
);
export const selectDeclarePrompt = createSelector(
  selectLocalPlayerPrompt("DeclarePrompt"),
  ({ prompt }) => ({ declarePrompt: prompt })
);
export const selectDeclareInOutPrompt = createSelector(
  selectLocalPlayerPrompt("DeclareInOutPrompt"),
  ({ prompt }) => ({ declareInOutPrompt: prompt })
);
export const selectAnyDeclareInOutPrompt = createSelector(
  selectAnyPrompt("DeclareInOutPrompt"),
  ({ prompt }) => ({ anyDeclareInOutPrompt: prompt })
);
export const selectBuyLastCardUpPrompt = createSelector(
  selectLocalPlayerPrompt("BuyCardUpPrompt"),
  ({ prompt }) => ({ buyLastCardUpPrompt: prompt })
);
export const selectDealPrompt = createSelector(
  [
    selectPromptBySuffix("DealCardPrompt"),
    selectPromptBySuffix("FlipCardPrompt"),
  ],
  ({ prompt: dealPrompt }, { prompt: flipPrompt }) => ({
    dealPrompt: dealPrompt || flipPrompt,
  })
);
export const selectAnyDealPrompt = createSelector(
  [
    selectAnyPromptBySuffix("DealCardPrompt"),
    selectAnyPromptBySuffix("FlipCardPrompt"),
  ],
  ({ prompt: dealPrompt }, { prompt: flipPrompt }) => ({
    anyDealPrompt: dealPrompt || flipPrompt,
  })
);
export const selectAnyCommunityCardPrompt = createSelector(
  [
    selectAnyPromptBySuffix("CommunityDealCardPrompt"),
    selectAnyPromptBySuffix("CommunityFlipCardPrompt"),
  ],
  ({ prompt: dealCardPrompt }, { prompt: flipCardPrompt }) => ({
    anyCommunityCardPrompt: dealCardPrompt || flipCardPrompt,
  })
);
export const selectChooseGamePrompt = createSelector(
  selectLocalPlayerPrompt("GameTypePrompt"),
  ({ prompt }) => ({ chooseGamePrompt: prompt })
);
export const selectAnyChooseGamePrompt = createSelector(
  selectAnyPrompt("GameTypePrompt"),
  ({ prompt }) => ({ anyChooseGamePrompt: prompt })
);
export const selectBetPrompt = createSelector(
  selectLocalPlayerPrompt("BetPrompt"),
  ({ prompt }) => ({ betPrompt: prompt })
);
export const selectBetPromptForPlayer = (playerId = "") =>
  createSelector(
    selectPromptForPlayer("BetPrompt", playerId),
    ({ prompt }) => ({
      betPromptForPlayer: prompt,
    })
  );
export const selectAnyBetPrompt = createSelector(
  selectAnyPrompt("BetPrompt"),
  ({ prompt }) => ({ anyBetPrompt: prompt })
);
export const selectAnyShortDelayPrompt = createSelector(
  selectAnyPrompt("ShortDelayPrompt"),
  ({ prompt }) => ({ shortDelayPrompt: prompt })
);
export const selectAlertPrompt = createSelector(
  selectLocalPlayerPrompt("AlertPrompt"),
  ({ prompt }) => ({ alertPrompt: prompt })
);
export const selectOutOfCardsPrompt = createSelector(
  selectLocalPlayerPrompt("OutOfCardsPrompt"),
  ({ prompt }) => ({ outOfCardsPrompt: prompt })
);
export const selectOfferCardPrompt = createSelector(
  selectLocalPlayerPrompt("OfferCardPrompt"),
  ({ prompt }) => ({ offerCardPrompt: prompt })
);
export const selectChangePrefsPrompt = (bypassPromptFidelity?: boolean) =>
  createSelector(
    selectLocalPlayerPrompt("ChangePrefsPrompt", bypassPromptFidelity),
    ({ prompt }) => ({ changePrefsPrompt: prompt })
  );
export const selectUndoHandPrompt = createSelector(
  selectLocalPlayerPrompt("UndoHandPrompt"),
  ({ prompt }) => ({ undoHandPrompt: prompt })
);
export const selectStartPrompt = createSelector(
  [
    selectPromptBySuffix("DealerPrompt"),
    selectPromptBySuffix("StartGamePrompt"),
  ],
  ({ prompt: handPrompt }, { prompt: gamePrompt }) => ({
    startPrompt: handPrompt || gamePrompt,
  })
);
export const selectAnyDealerPrompt = createSelector(
  selectAnyPrompt("DealerPrompt"),
  ({ prompt }) => ({ anyDealerPrompt: prompt })
);
export const selectInitializeTablePrompt = createSelector(
  selectPromptBySuffix("InitializeTablePrompt"),
  ({ prompt }) => ({ initializeTablePrompt: prompt })
);
export const selectShortDelayPrompt = createSelector(
  selectPromptBySuffix("ShortDelayPrompt"),
  ({ prompt }) => ({ shortDelayPrompt: prompt })
);
export const selectPlaySoundPrompt = createSelector(
  selectPromptBySuffix("PlaySoundPrompt"),
  ({ prompt }) => ({ playSoundPrompt: prompt })
);
export const selectStartNormalGamePlayPrompt = createSelector(
  selectPromptBySuffix("StartNormalGamePlayPrompt"),
  ({ prompt }) => ({ startNormalGamePlayPrompt: prompt })
);
export const selectPlayerShowdownPrompt = createSelector(
  selectPromptBySuffix("PlayerShowdownPrompt"),
  ({ prompt }) => ({ playerShowdownPrompt: prompt })
);
export const selectPlayerBreakPrompt = createSelector(
  selectPromptBySuffix("PlayerBreakPrompt"),
  ({ prompt }) => ({ playerBreakPrompt: prompt })
);
export const selectPausePrompt = createSelector(
  selectPromptBySuffix("TogglePausePrompt"),
  ({ prompt }) => ({ pausePrompt: prompt })
);
export const selectPreShutdownPrompt = createSelector(
  selectPromptBySuffix("TogglePreShutdownPrompt"),
  ({ prompt }) => ({ preShutdownPrompt: prompt })
);
export const selectDelayOfGamePrompt = createSelector(
  selectPromptBySuffix("DelayOfGamePrompt"),
  ({ prompt }) => ({ delayOfGamePrompt: prompt })
);
export const selectTexasDelayPrompt = createSelector(
  selectPromptBySuffix("TexasDiscardDelayPrompt"),
  ({ prompt }) => ({ texasDelayPrompt: prompt })
);
export const selectAnyDelayPrompt = createSelector(
  selectAnyPrompt("DelayOfGamePrompt"),
  ({ prompt }) => ({ anyDelayPrompt: prompt })
);
export const selectAnyDeclarePrompt = createSelector(
  selectAnyPrompt("DeclarePrompt"),
  ({ prompt }) => ({ anyDeclarePrompt: prompt })
);
export const selectRemainingSessionTimePrompt = createSelector(
  selectAnyPromptBySuffix("RemainingSessionTimePrompt"),
  ({ prompt }) => ({ remainingSessionTimePrompt: prompt })
);
export const selectSelectCardsPrompt = createSelector(
  selectPromptBySuffix("SelectCardsPrompt"),
  ({ prompt }) => ({ selectCardsPrompt: prompt })
);
export const selectAnyCardSelectionPrompt = createSelector(
  [
    selectPromptBySuffix("DiscardCardsPrompt"),
    selectPromptBySuffix("RedrawCardsPrompt"),
    selectSelectCardsPrompt,
  ],
  (
    { prompt: discardPrompt },
    { prompt: redrawPrompt },
    { selectCardsPrompt }
  ) => ({
    anyCardSelectionPrompt: discardPrompt ?? redrawPrompt ?? selectCardsPrompt,
  })
);
export const selectAnyTrashSelectCardsPrompt = createSelector(
  selectAnyPrompt("TrashSelectCardsPrompt"),
  ({ prompt }) => ({ anyTrashSelectCardsPrompt: prompt })
);
export const selectAnyDiscardCardsPrompt = createSelector(
  selectAnyPrompt("DiscardCardsPrompt"),
  ({ prompt }) => ({ anyDiscardCardsPrompt: prompt })
);
export const selectAnyRevealSelectCardsPrompt = createSelector(
  selectAnyPrompt("RevealSelectCardsPrompt"),
  ({ prompt }) => ({ anyRevealSelectCardsPrompt: prompt })
);
export const selectAnyMultiplayerPrompt = createSelector(
  [
    selectAnyTrashSelectCardsPrompt,
    selectAnyDiscardCardsPrompt,
    selectAnyRevealSelectCardsPrompt,
    selectAnyDeclarePrompt,
    selectAnyDeclareInOutPrompt,
  ],
  (
    { anyTrashSelectCardsPrompt },
    { anyDiscardCardsPrompt },
    { anyRevealSelectCardsPrompt },
    { anyDeclarePrompt },
    { anyDeclareInOutPrompt }
  ) => ({
    anyMultiplayerPrompt:
      anyTrashSelectCardsPrompt ||
      anyDiscardCardsPrompt ||
      anyRevealSelectCardsPrompt ||
      anyDeclarePrompt ||
      anyDeclareInOutPrompt,
  })
);
export const selectTrashSelectCardsPrompt = createSelector(
  selectLocalPlayerPrompt("TrashSelectCardsPrompt"),
  ({ prompt }) => ({ trashSelectCardsPrompt: prompt })
);
export const selectDiscardCardsPrompt = createSelector(
  selectLocalPlayerPrompt("DiscardCardsPrompt"),
  ({ prompt }) => ({ discardCardsPrompt: prompt })
);
export const selectRevealSelectCardsPrompt = createSelector(
  selectLocalPlayerPrompt("RevealSelectCardsPrompt"),
  ({ prompt }) => ({ revealSelectCardsPrompt: prompt })
);
export const selectMultiplayerPrompt = createSelector(
  [
    selectTrashSelectCardsPrompt,
    selectDiscardCardsPrompt,
    selectRevealSelectCardsPrompt,
    selectDeclarePrompt,
    selectDeclareInOutPrompt,
  ],
  (
    { trashSelectCardsPrompt },
    { discardCardsPrompt },
    { revealSelectCardsPrompt },
    { declarePrompt },
    { declareInOutPrompt }
  ) => ({
    multiplayerPrompt:
      trashSelectCardsPrompt ||
      discardCardsPrompt ||
      revealSelectCardsPrompt ||
      declarePrompt ||
      declareInOutPrompt,
  })
);
export const selectMultiplayerPromptForPlayer = (playerId = "") =>
  createSelector(
    [
      selectPromptForPlayer("TrashSelectCardsPrompt", playerId),
      selectPromptForPlayer("DiscardCardsPrompt", playerId),
      selectPromptForPlayer("RevealSelectCardsPrompt", playerId),
      selectPromptForPlayer("DeclarePrompt", playerId),
      selectPromptForPlayer("DeclareInOutPrompt", playerId),
    ],
    (
      { prompt: trashSelectCardsPrompt },
      { prompt: discardCardsPrompt },
      { prompt: revealSelectCardsPrompt },
      { prompt: declarePrompt },
      { prompt: declareInOutPrompt }
    ) => ({
      multiplayerPromptForPlayer:
        trashSelectCardsPrompt ||
        discardCardsPrompt ||
        revealSelectCardsPrompt ||
        declarePrompt ||
        declareInOutPrompt,
    })
  );
export const selectAdjustPlayerBankPrompt = createSelector(
  selectPromptBySuffix("HostGiveTakeMoneyPrompt"),
  ({ prompt }) => ({ adjustPlayerBankPrompt: prompt })
);
export const selectPlayerRequestChipsPrompt = createSelector(
  selectPromptBySuffix("PlayerRequestChipsPrompt"),
  ({ prompt }) => ({ playerRequestChipsPrompt: prompt })
);
export const selectKickPlayerPrompt = createSelector(
  selectPromptBySuffix("HostKickPlayerPrompt"),
  ({ prompt }) => ({ kickPlayerPrompt: prompt })
);
export const selectHostTransferChipsPrompt = createSelector(
  selectPromptBySuffix("HostTransferChipsPrompt"),
  ({ prompt }) => ({ hostTransferChipsPrompt: prompt })
);
export const selectHostSellChipsPrompt = createSelector(
  selectPromptBySuffix("HostSellChipsPrompt"),
  ({ prompt }) => ({ hostSellChipsPrompt: prompt })
);
export const selectPlayerLeaveGamePrompt = createSelector(
  selectPromptBySuffix("PlayerLeaveGamePrompt"),
  ({ prompt }) => ({ playerLeaveGamePrompt: prompt })
);
export const selectHostEndSessionPrompt = createSelector(
  selectPromptBySuffix("HostEndSessionPrompt"),
  ({ prompt }) => ({ hostEndSessionPrompt: prompt })
);
export const selectShutdownPrompt = createSelector(
  selectPromptBySuffix("ShutdownPrompt"),
  ({ prompt }) => ({ shutdownPrompt: prompt })
);
export const selectAnyShutdownPrompt = createSelector(
  selectAnyPrompt("ShutdownPrompt"),
  ({ prompt }) => ({ anyShutdownPrompt: prompt })
);
export const selectSelectActiveModalPrompt = createSelector(
  selectPromptBySuffix("SelectActiveModalPrompt"),
  ({ prompt }) => ({ selectActiveModalPrompt: prompt })
);
export const selectAnyLowChipPrompt = createSelector(
  selectAnyPrompt("LowChipPrompt"),
  ({ prompt }) => ({ anyLowChipPrompt: prompt })
);
export const selectLowChipPrompt = createSelector(
  selectPromptBySuffix("LowChipPrompt"),
  ({ prompt }) => ({ lowChipPrompt: prompt })
);
export const selectPreShutdownTime = createSelector(
  selectAnyShutdownPrompt,
  ({ anyShutdownPrompt }) => ({
    preShutdownTime: anyShutdownPrompt?.data?.preShutdownTime,
  })
);
export const selectIsGameActive = createSelector(
  [
    selectAnyBetPrompt,
    selectAnyDealPrompt,
    selectAnyShortDelayPrompt,
    selectAnyCommunityCardPrompt,
  ],
  (
    { anyBetPrompt },
    { anyDealPrompt },
    { shortDelayPrompt },
    { anyCommunityCardPrompt }
  ) => ({
    isGameActive: Boolean(
      anyBetPrompt ||
        anyDealPrompt ||
        shortDelayPrompt ||
        anyCommunityCardPrompt
    ),
  })
);
export const selectAnteAction = createSelector(
  selectAction("AntePrompt", "ForcedBetAction"),
  ({ action }) => ({ anteAction: action })
);

// =============================== //
//              Thunks             //
// ================================//
export const getPrompts = createAsyncThunk(
  "table/getPrompts",
  async (unused, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    return Requests.getPrompts(
      selectTableId(state).tableId.toString(),
      selectTableGroup(state).tableGroup,
      selectLocalPlayerId(state).localPlayerId
    );
  }
);
export const submitPromptByAction = async (
  selector: Function,
  actionName: string,
  thunkApi: any,
  args = ""
) => {
  const state = selector(thunkApi.getState());
  const promptName = Object.keys(state)?.[0] ?? "";
  const prefix = state[promptName]?.prefix ?? "";

  const returnedIndex = selectActionIndex(
    prefix,
    actionName
  )(thunkApi.getState());
  return submitPrompt(selector, returnedIndex, thunkApi, args);
};
export const submitPrompt = async (
  selector: Function,
  selection: number | undefined,
  thunkApi: any,
  args = ""
) => {
  if (!selector || (!selection && selection !== 0)) {
    return;
  }

  const state = selector(thunkApi.getState());
  const promptName = Object.keys(state)?.[0] ?? "";
  const prefix = state[promptName]?.prefix ?? "";
  const id = state[promptName]?.id ?? "";
  const isBotTable = checkIsBotTable();

  const payload: Requests.PromptSelection = {
    actionType: "electAction",
    electedActionDto: {
      promptId: prefix + "-" + id,
      selection: selection,
      argsString: args,
    },
    isBotTable,
    localPlayerId: selectLocalPlayerId(thunkApi.getState() as RootState)
      .localPlayerId,
  };
  return Requests.submitPrompt(
    selectTableId(thunkApi.getState() as RootState).tableId.toString(),
    selectTableGroup(thunkApi.getState()).tableGroup,
    payload
  );
};
export const startGame = createAsyncThunk(
  "table/startGame",
  async (unused, thunkApi) => {
    thunkApi.dispatch(resetHighlight());
    submitPromptByAction(selectStartPrompt, "StartGameAction", thunkApi);
  }
);
export const shortDelayAction = createAsyncThunk(
  "table/shortDelayAction",
  async (unused, thunkApi) =>
    submitPrompt(selectShortDelayPrompt, 0, thunkApi, "")
);
export const undoGameSelection = createAsyncThunk(
  "table/UndoGameSelectAction",
  async (unused, thunkApi) =>
    submitPromptByAction(selectStartPrompt, "UndoGameSelectAction", thunkApi)
);
export const playAutomatically = createAsyncThunk(
  "table/playAutomatically",
  async (unused, thunkApi) => {
    const payload = {
      actionType: "playAutomatically",
      electedActionDto: {
        promptId: "",
        selection: 0,
        argsString: "",
      },
    };

    const state = thunkApi.getState() as RootState;
    const { localPlayerId } = selectLocalPlayerId(state);
    const isBotTable = checkIsBotTable();

    return Requests.submitPrompt(
      selectTableId(state).tableId.toString(),
      selectTableGroup(state).tableGroup,
      { ...payload, localPlayerId, isBotTable }
    );
  }
);
export const playAutomaticallyForBot = createAsyncThunk(
  "table/playAutomatically",
  async (unused, thunkApi) => {
    const payload = {
      actionType: "playAutomatically",
      electedActionDto: {
        promptId: "",
        selection: 0,
        argsString: "",
      },
    };
    const state = thunkApi.getState() as RootState;
    const { currentPlayerId } = selectCurrentPlayerId(state);
    const { localPlayerId } = selectLocalPlayerId(state);
    const isBotTable = checkIsBotTable();

    // We do an early exit in case we play automatically for bots, but current player is the host ie. local player
    if (currentPlayerId === localPlayerId) {
      return;
    }

    return Requests.submitPrompt(
      selectTableId(state).tableId.toString(),
      selectTableGroup(state).tableGroup,
      { ...payload, localPlayerId, isBotTable }
    );
  }
);
export const declinePlayerAdmission = createAsyncThunk(
  "prompt/declinePlayerAdmission",
  async (playerToAdmitId: string, thunkApi) =>
    submitPrompt(
      selectHostAdmitByPlayerIdPrompt(playerToAdmitId),
      0,
      thunkApi,
      "false"
    )
);
export const grantPlayerAdmission = createAsyncThunk(
  "prompt/grantPlayerAdmission",
  async (playerToAdmitId: string, thunkApi) =>
    submitPrompt(
      selectHostAdmitByPlayerIdPrompt(playerToAdmitId),
      0,
      thunkApi,
      "true"
    )
);
export const skipAnte = createAsyncThunk(
  "prompt/skipAnte",
  async (unused, thunkApi) =>
    submitPromptByAction(selectAntePrompt, "FoldAction", thunkApi)
);
export const payAnte = createAsyncThunk(
  "table/payAnte",
  async (unused, thunkApi) =>
    submitPromptByAction(selectAntePrompt, "ForcedBetAction", thunkApi)
);
export const acceptCard = createAsyncThunk(
  "table/acceptCard",
  async (unused, thunkApi) =>
    submitPromptByAction(selectOfferCardPrompt, "AcceptCardAction", thunkApi)
);
export const declineCard = createAsyncThunk(
  "table/declineCard",
  async (unused, thunkApi) =>
    submitPromptByAction(selectOfferCardPrompt, "DeclineCardAction", thunkApi)
);
export const declareHigh = createAsyncThunk(
  "table/declareHigh",
  async (unused, thunkApi) =>
    submitPromptByAction(selectDeclarePrompt, "DeclareHighAction", thunkApi)
);
export const declareLow = createAsyncThunk(
  "table/declareLow",
  async (unused, thunkApi) =>
    submitPromptByAction(selectDeclarePrompt, "DeclareLowAction", thunkApi)
);
export const declareBoth = createAsyncThunk(
  "table/declareBoth",
  async (unused, thunkApi) =>
    submitPromptByAction(
      selectDeclarePrompt,
      "DeclareHighAndLowAction",
      thunkApi
    )
);
export const declareIn = createAsyncThunk(
  "table/declareIn",
  async (unused, thunkApi) =>
    submitPromptByAction(selectDeclareInOutPrompt, "DeclareInAction", thunkApi)
);
export const declareOut = createAsyncThunk(
  "table/declareOut",
  async (unused, thunkApi) =>
    submitPromptByAction(selectDeclareInOutPrompt, "DeclareOutAction", thunkApi)
);
export const buyLastCardUp = createAsyncThunk(
  "table/buyLastCardUp",
  async (unused, thunkApi) =>
    submitPromptByAction(selectBuyLastCardUpPrompt, "BuyCardUpAction", thunkApi)
);
export const declineBuyLastCardUp = createAsyncThunk(
  "table/declineBuyLastCardUp",
  async (unused, thunkApi) =>
    submitPromptByAction(
      selectBuyLastCardUpPrompt,
      "DeclineBuyCardUpAction",
      thunkApi
    )
);
export const dealCard = createAsyncThunk(
  "table/dealCard",
  async (unused, thunkApi) => submitPrompt(selectDealPrompt, 0, thunkApi)
);
export const foldDuringSelection = createAsyncThunk(
  "table/fold",
  async (unused, thunkApi) =>
    submitPrompt(selectAnyCardSelectionPrompt, 1, thunkApi)
);
export const fold = createAsyncThunk("table/fold", async (unused, thunkApi) =>
  submitPrompt(selectBetPrompt, 1, thunkApi)
);
export const check = createAsyncThunk("table/check", async (unused, thunkApi) =>
  submitPrompt(selectBetPrompt, 0, thunkApi, "0")
);
export const call = createAsyncThunk(
  "table/call",
  async (amount: number, thunkApi) =>
    submitPrompt(selectBetPrompt, 0, thunkApi, amount.toString())
);
export const foldForPlayer = createAsyncThunk(
  "table/fold",
  async (playerId: string, thunkApi) =>
    submitPrompt(selectFoldablePromptForPlayer(playerId), 1, thunkApi)
);
export const foldAndTakeBreakForPlayer = createAsyncThunk(
  "table/takeABreakAndFold",
  async (playerId: string, thunkApi) => {
    const actionIndex = selectActionIndex(
      "PlayerBreakPrompt",
      "TakeABreakAndFoldAction"
    )(thunkApi.getState() as RootState);
    return submitPrompt(
      selectPlayerBreakPromptForPlayer(playerId),
      actionIndex,
      thunkApi
    );
  }
);
export const checkForPlayer = createAsyncThunk(
  "table/check",
  async (playerId: string, thunkApi) =>
    submitPrompt(selectBetPromptForPlayer(playerId), 0, thunkApi, "0")
);
export const callForPlayer = createAsyncThunk(
  "table/call",
  async (
    { playerId, amount }: { playerId: string; amount: number },
    thunkApi
  ) =>
    submitPrompt(
      selectBetPromptForPlayer(playerId),
      0,
      thunkApi,
      String(amount)
    )
);
export const raise = createAsyncThunk(
  "table/raise",
  async (amount: number, thunkApi) =>
    submitPrompt(selectBetPrompt, 0, thunkApi, String(amount))
);
export const showHand = createAsyncThunk(
  "table/showHand",
  async (unused, thunkApi) =>
    submitPrompt(selectPlayerShowdownPrompt, 0, thunkApi)
);
export const dismiss = createAsyncThunk(
  "table/dismiss",
  async (unused, thunkApi) =>
    submitPrompt(selectPlayerShowdownPrompt, 1, thunkApi)
);
export const changePrefs = createAsyncThunk(
  "table/changePreferences",
  async (
    {
      newPrefs,
      bypassPromptFidelity,
    }: { newPrefs: string; bypassPromptFidelity?: boolean },
    thunkApi
  ) =>
    submitPrompt(
      selectChangePrefsPrompt(bypassPromptFidelity),
      0,
      thunkApi,
      newPrefs
    )
);
export const initializeTable = createAsyncThunk(
  "table/initializeTable",
  async (newPrefs: string, thunkApi) =>
    submitPrompt(selectInitializeTablePrompt, 0, thunkApi, newPrefs)
);
export const dealerChangePrefs = createAsyncThunk(
  "table/dealerChangePreferences",
  async (newPrefs: string, thunkApi) =>
    submitPromptByAction(
      selectStartPrompt,
      "ChangePrefsAction",
      thunkApi,
      newPrefs
    )
);
export const undoHand = createAsyncThunk(
  "table/undoHand",
  async (newPrefs: string, thunkApi) =>
    submitPrompt(selectUndoHandPrompt, 0, thunkApi, newPrefs)
);
export const selectGame = createAsyncThunk(
  "selectGame/selectGame",
  async (index: number, thunkApi) =>
    submitPrompt(selectChooseGamePrompt, index, thunkApi)
);
export const toggleBreak = createAsyncThunk(
  "prompts/toggleBreak",
  async (unused, thunkApi) => submitPrompt(selectPlayerBreakPrompt, 0, thunkApi)
);
export const selectActiveModal = createAsyncThunk(
  "prompts/selectActiveModal",
  async (args: ModalName | null, thunkApi) =>
    submitPrompt(selectSelectActiveModalPrompt, 0, thunkApi, args ?? "nothing")
);
export const togglePause = createAsyncThunk(
  "prompts/toggleBreak",
  async (unused, thunkApi) => submitPrompt(selectPausePrompt, 0, thunkApi)
);
export const togglePreShutdown = createAsyncThunk(
  "prompts/togglePreShutdown",
  async (unused, thunkApi) => submitPrompt(selectPreShutdownPrompt, 0, thunkApi)
);
export const discardCards = createAsyncThunk(
  "prompts/discardCard",
  async (cardString: string, thunkApi) =>
    submitPrompt(selectAnyCardSelectionPrompt, 0, thunkApi, cardString)
);
export const adjustPlayerBank = createAsyncThunk(
  "prompts/adjustBank",
  async (adjustArgumentsString: string, thunkApi) =>
    submitPrompt(
      selectAdjustPlayerBankPrompt,
      0,
      thunkApi,
      adjustArgumentsString
    )
);
export const playerRequestChips = createAsyncThunk(
  "prompts/requestChips",
  async (requestAmountString: string, thunkApi) =>
    submitPrompt(
      selectPlayerRequestChipsPrompt,
      0,
      thunkApi,
      requestAmountString
    )
);
export const kickPlayer = createAsyncThunk(
  "prompts/kickPlayer",
  async (kickedPlayerIdString: string, thunkApi) => {
    thunkApi.dispatch(resetHighlightByPlayerId(kickedPlayerIdString));
    return submitPrompt(
      selectKickPlayerPrompt,
      0,
      thunkApi,
      kickedPlayerIdString
    );
  }
);
export const leaveGame = createAsyncThunk(
  "prompts/leaveGame",
  async (unused, thunkApi) =>
    submitPrompt(selectPlayerLeaveGamePrompt, 0, thunkApi)
);
export const hideSessionEndTime = createAsyncThunk(
  "prompts/hideSessionEndTime",
  async (playerId: string, thunkApi) =>
    submitPrompt(selectRemainingSessionTimePrompt, 0, thunkApi, playerId)
);
export const beginShutdown = createAsyncThunk(
  "prompts/beginShutdown",
  async (unused, thunkApi) => {
    submitPrompt(selectHostEndSessionPrompt, 0, thunkApi);
  }
);
export const alertOkay = createAsyncThunk(
  "prompts/alertOkay",
  async (unused, thunkApi) => submitPrompt(selectAlertPrompt, 0, thunkApi)
);
export const acknowledgeOutOfCards = createAsyncThunk(
  "prompts/acknowledgeOutOfCards",
  async (unused, thunkApi) => submitPrompt(selectOutOfCardsPrompt, 0, thunkApi)
);
export const submitLowChipPrompt = createAsyncThunk(
  "prompts/submitLowChipPrompt",
  async (unused, thunkApi) => submitPrompt(selectLowChipPrompt, 0, thunkApi)
);
export const submitTexasDelayPrompt = createAsyncThunk(
  "prompts/submitTexasDelayPrompt",
  async (unused, thunkApi) => submitPrompt(selectTexasDelayPrompt, 0, thunkApi)
);
export const submitPlaySoundPrompt = createAsyncThunk(
  "prompts/submitPlaySoundPrompt",
  async (unused, thunkApi) => submitPrompt(selectPlaySoundPrompt, 0, thunkApi)
);
export const endShowdownPhase = createAsyncThunk(
  "prompts/endShowdownPhase",
  async (toContinue: boolean | null, thunkApi) => {
    thunkApi.dispatch(resetHighlight());
    return submitPrompt(
      selectDelayOfGamePrompt,
      !!toContinue ? 1 : 0,
      thunkApi
    );
  }
);
export const hostTransferChips = createAsyncThunk(
  "prompts/hostTransferChips",
  async (argString: string, thunkApi) =>
    submitPrompt(selectHostTransferChipsPrompt, 0, thunkApi, argString)
);
export const hostSellChips = createAsyncThunk(
  "prompts/hostSellChips",
  async (argString: string, thunkApi) =>
    submitPrompt(selectHostSellChipsPrompt, 0, thunkApi, argString)
);
export const shutdownNow = createAsyncThunk(
  "prompts/shutdownNow",
  async (unused, thunkApi) => submitPrompt(selectShutdownPrompt, 0, thunkApi)
);
export const addTime = createAsyncThunk(
  "prompts/addTime",
  async (unused, thunkApi) => submitPrompt(selectShutdownPrompt, 1, thunkApi)
);
// temporary submit start game, for passing through lobby
export const submitStartNormalGamePlay = createAsyncThunk(
  "prompts/startNormalGamePlay",
  async (practiceMode: boolean, thunkApi) =>
    submitPrompt(
      selectStartNormalGamePlayPrompt,
      0,
      thunkApi,
      `${practiceMode}`
    )
);
