// slice.ts
//
// All the user info needed for the admin functionality

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { extendUserPremium, getAllUsers } from "./thunks";

export interface UserInterface {
  id: string;
  email: string;
  authId: string;
  paying: boolean;
  premiumExpiresAt: string;
}

const dateAfterToday = (date: string) => new Date(date).getTime() > new Date().getTime();

// =============================== //
//          Initial State          //
// ================================//
const initialState: { users: UserInterface[] | undefined } = {
  users: undefined,
};

// =============================== //
//              Slice              //
// ================================//
export const slice = createSlice({
  name: "admin/users",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      const users: UserInterface[] = action.payload.map(
        (input: any) =>
          ({
            id: input.id,
            email: input.email,
            authId: input.authId,
            premiumExpiresAt: input.freeTrialExpiration,
            paying: dateAfterToday(input.paymentExpiration),
          } as UserInterface)
      );
      return { ...state, users };
    });

    builder.addCase(getAllUsers.rejected, (state, action) => {
      return { ...state, users: undefined };
    });

    builder.addCase(extendUserPremium.fulfilled, (state, action: any) => {
      if (!state.users) {
        return { ...state };
      }

      const users = state.users.map((user) => {
        if (user.id === action.payload.id)
          return {
            ...user,
            premiumExpiresAt: action.payload.freeTrialExpiration,
          };
        return user;
      });

      return { ...state, users };
    });
  },
});

// =============================== //
//            Reducers             //
// ================================//
export default slice.reducer;

// =============================== //
//            Selectors            //
// ================================//
export const selectAllUsers = (state: RootState) => state.users;
