/*
 * OfferCardArea.tsx
 * This is a react component responsible for displaying buttons that allow a player to take a card
 * or decline taking another card. It's rendered conditionally by ActionArea.tsx
 */
import React from "react";
import { useDispatch } from "react-redux";

import { acceptCard, declineCard } from "../../../logic/prompts/slice";
import Button from "../Button";
import {
  ANTE_NO_CARD_BUTTON,
  ANTE_TAKE_CARD_BUTTON,
} from "../../../test-identifiers";

import "./AnteArea.css";

export const OfferCardArea = () => {
  const dispatch = useDispatch();

  const accept = () => {
    dispatch(acceptCard());
  };

  const skipClick = () => {
    dispatch(declineCard());
  };

  return (
    <div className="declareActions">
      <div className="declareButtonsContainer">
        <div className="anteActions">
          <Button
            data-testid={ANTE_NO_CARD_BUTTON}
            className="actionButton blue"
            onClick={skipClick}
          >
            No Card
          </Button>
          <br />
          <Button
            data-testid={ANTE_TAKE_CARD_BUTTON}
            className="actionButton green"
            onClick={accept}
          >
            Take a Card
          </Button>
        </div>
      </div>
    </div>
  );
};
