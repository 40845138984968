/*
 * OutOfCards.tsx
 * This is a react component that is only shown when the OutOfCards prompt is showing. i.e. when the deck is completely out of cards in 7-27
 * It is ultimately rendered by ActionArea.tsx, and the prompt is invoked in SevenTwentySevenController.ts
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";
import {
  selectIsLocalPlayerDealer,
  selectTableId,
} from "../../../logic/table/slice";
import { acknowledgeOutOfCards, undoHand } from "../../../logic/prompts/slice";

export const OutOfCards = () => {
  const dispatch = useDispatch();
  const { tableId } = useSelector(selectTableId);
  const { isLocalPlayerDealer } = useSelector(selectIsLocalPlayerDealer);

  const click = () => {
    dispatch(undoHand(tableId.toString()));
    dispatch(acknowledgeOutOfCards());
  };

  return (
    <div>
      No cards available to be drawn, resetting the hand
      <br />
      {isLocalPlayerDealer && <Button onClick={click}> Ok, Restart </Button>}
    </div>
  );
};
