/**
 * GameTypeEntry.tsx
 * A single game in the "dealer choose game" prompt.
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Game } from "poker-cows-common";
import { getPrettyGameType } from "../../../utils/GameInfo";
import {
  selectGame,
  selectGameTypeActions,
} from "../../../logic/prompts/slice";
import "./GameTypeEntry.css";
import { selectGameType } from "../../../logic/table/slice";
import { selectGameUUID, selectPrefs } from "../../../logic/gameInstance/slice";
import { useModal } from "../Modal/hooks/useModal";
import { ModalName } from "../Modal/ModalConfig";
import { selectAllPremiumGames } from "../../../logic/admin/game/slice";
import classNames from "classnames";

interface Props {
  id: string;
  gameType: Game;
  isRulePage: boolean;
  premiumEnabled: boolean;
}

/**
 * CurrentGameRibbon
 * Component used to show yellow coloured ribbon on current game
 */
const CurrentGameRibbon = () => <div className="gameTypeRibbon">CURRENT</div>;

const GameTypeEntry = (props: Props) => {
  const { showModal } = useModal();

  const dispatch = useDispatch();
  const { gameType } = useSelector(selectGameType);
  const { gameTypeActions } = useSelector(selectGameTypeActions);
  const { gameUUID } = useSelector(selectGameUUID);
  const { prefs } = useSelector(selectPrefs);
  const premiumGames = useSelector(selectAllPremiumGames);

  const isCurrentGame =
    props.isRulePage && gameUUID && gameType === props.gameType;

  const isPremium = premiumGames?.includes(props.gameType);
  const gameName = getPrettyGameType(props.gameType);

  function updateGameRules() {
    showModal({
      name: ModalName.CurrentGameRulesModal,
      additionalProps: {
        selectedGame: props.gameType,
        selectedGameFlavor: isCurrentGame ? prefs?.gameFlavor : null,
      },
    });
  }

  function updateGameSelection() {
    const index = gameTypeActions.findIndex(
      (action) => action.data?.gameType === props.gameType
    );

    if (index < 0) {
      return;
    }

    dispatch(selectGame(index));
  }

  const premiumText = props.premiumEnabled ? "Premium" : "Premium only";
  return (
    <div key={props.id} data-testid={props.id} className="gameSelectEntryBox">
      <div
        className={classNames("gameSelectEntry", {
          premium: isPremium,
          disabled: !props.premiumEnabled,
        })}
        onClick={() => {
          if (isPremium && !props.premiumEnabled) {
            return;
          }
          if (props.isRulePage) {
            updateGameRules();
            return;
          }
          updateGameSelection();
        }}
      >
        {isCurrentGame && <CurrentGameRibbon />}
        {isPremium && <span className="premiumText">{premiumText}</span>}
        <div className="gameText">{gameName}</div>
      </div>
    </div>
  );
};

export default GameTypeEntry;
