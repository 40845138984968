/**
 * DealArea.tsx
 *
 * This component is used as descriptive bottom component during game play when card are being dealt.
 */
import React from "react";
import { CARDS_BEING_DEALT_TEXT } from "../../../../test-identifiers";

import "../DeclareArea.css";

export const DealArea = () => {
  return (
    <div data-testid={CARDS_BEING_DEALT_TEXT} className="dea">
      Cards are being dealt.
    </div>
  );
};
