/** RollYourOwnOptions.tsx
 *
 * Game Options for RollYourOwn.
 */
import { BetLimit } from "../bets/BetLimit";
import { CardVersionSetting } from "../enums/CardVersionSetting";
import { GameOptionsInterface } from "../interfaces";
import {
  optionRollYourOwnAlwaysDefault,
  wildcardDeuces,
  wildcardLowHoleWithBuy,
  wildcardNoneDefault,
  wildcardOneEyedJacks,
  winnerHighLowSplit,
  winnerHighOnlyDefault,
  winnerLowOnly,
} from "./defaultOptions";

export const RollYourOwnOptions: GameOptionsInterface = {
  versions: [
    {
      title: "7 Cards",
      description: "",
      value: CardVersionSetting.SEVEN_CARD,
      default: true,
      flavor: 7,
      betLimit: BetLimit.SPREAD_LIMIT,
    },
  ],
  winners: [winnerHighOnlyDefault, winnerLowOnly, winnerHighLowSplit],
  options: [{ ...optionRollYourOwnAlwaysDefault, description: "None" }],
  wildcards: [
    wildcardNoneDefault,
    wildcardLowHoleWithBuy,
    wildcardDeuces,
    wildcardOneEyedJacks,
  ],
};
