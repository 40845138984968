// BettingStructure.ts
//
// Contains the base class for the betting structure for the games. Each of its
// subclasses implements the specific rules and checks for that betting structure

import { BetInfo } from "./BetInfo";
import { RoundBetInfo } from "./RoundBetInfo";

/**
 * The parent class for all bet options (No Limit, Spread Limit, Cap Limit, ... )
 */
export abstract class BettingStructure {
  private maxRaisesCount: number;
  private raiseMustBeAtLeastPreviousRaise: boolean;

  constructor(prefs: any) {
    this.validatePositiveNumber(prefs.maxRaises, "maxRaises");
    this.maxRaisesCount = prefs.maxRaises;
    this.raiseMustBeAtLeastPreviousRaise =
      prefs.raiseMustBeAtLeastPreviousRaise;
  }

  // checks if the amount provided is a positive, non zero number
  protected validatePositiveNumber(number: number, field: string) {
    if ((!number && number !== 0) || number < 0) {
      throw new Error(`Not a valid value: ${number} provided for: ${field}`);
    }
  }

  protected validateRaises(bet: BetInfo, round: RoundBetInfo) {
    // check if
    // 1. is actual raise
    // 2. the game allows for raises
    // 3. the number of raises has been exceeded
    if (
      bet.raise > 0 &&
      this.maxRaisesCount > 0 &&
      round.raisesCount >= this.maxRaisesCount
    ) {
      throw new Error("Maximum amount of raises reached");
    }

    if (
      this.raiseMustBeAtLeastPreviousRaise &&
      bet.raise > 0 &&
      bet.raise < round.currentRaise
    ) {
      throw new Error("New raise must be at least previous raise");
    }
  }

  protected validateBetSize(bet: BetInfo, round: RoundBetInfo) {
    if (bet.amount + bet.personalInPot < round.currentBet) {
      throw new Error("newBet must at least reach the currentBet");
    }
  }

  abstract validateBet(bet: BetInfo, round: RoundBetInfo);
}
