/** SpreadLimitSetupTitle.tsx
 *
 * This component displays Title for Spread Limit Setup and Tooltip
 */
import React from "react";
import { Tooltip } from "../../Tooltip/Tooltip";

export const SpreadLimitSetupTitle = () => {
  return (
    <div className="flexRulesTitle">
      <span className="title">
        <span className="rowCaption">Ante, Min Bet & Max Bet</span> &nbsp;
        <Tooltip          
          title="Spread Limit"
          body="All games (except No-Limit Texas Hold'em) on PokerCows are played with spread limit betting; meaning they utilize antes, min bets and max bets. The dealer selects and deals a game, and the deal passes to the next player."
        />
      </span>
      <span className="description">HOMESTYLE DEALER’S CHOICE GAMES</span>
    </div>
  );
};
