/**
 * JoinRequestDeclined.tsx
 * 
 * This Component is reached when host declines user's request to join the table
 */
import React from "react";
import { ExtraSession } from "../ExtraSession";
import "../ExtraSession.css";

export const JoinRequestDeclined = () => {
  const handleClick = () => {
    window.location.href = "./";
  };
  const title = "JOIN REQUEST DECLINED";
  const contents = (
    <>
      <div className="extraSessionSubtext">
        The host has declined your your request to join the table.
      </div>
    </>
  );
  return (
    <ExtraSession
      title={title}
      contents={contents}
      actionButtonOnClick={handleClick}
      actionButtonText={"Continue"}
    />
  );
};
