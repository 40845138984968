/**
 * LedgerModal.tsx
 *
 * This is just a wrapper around the TableLedger
 */
import React from "react";

import TableLedger from "../../Table/TableLedger";

import { ModalProps } from "../types/modalTypes";

export const LedgerModal = (props: ModalProps): JSX.Element => {
  return <TableLedger {...props} />;
};
