/** FiveCardDrawOptions.ts
 *
 * Game Options for FiveCardDraw.
 */
import { BetLimit } from "../bets/BetLimit";
import { CardVersionSetting } from "../enums/CardVersionSetting";
import { GameOptionsInterface } from "../interfaces";
import {
  optionNoneDefault,
  wildcardDeuces,
  wildcardNoneDefault,
  wildcardOneEyedJacks,
  winnerHighLowSplit,
  winnerHighOnlyDefault,
  winnerLowOnly,
} from "./defaultOptions";

export const FiveCardDrawOptions: GameOptionsInterface = {
  versions: [
    {
      title: "5 Cards",
      description: "",
      value: CardVersionSetting.FIVE_CARD,
      default: true,
      flavor: 5,
      betLimit: BetLimit.SPREAD_LIMIT,
    },
  ],
  winners: [winnerHighOnlyDefault, winnerLowOnly, winnerHighLowSplit],
  options: [optionNoneDefault],
  wildcards: [wildcardNoneDefault, wildcardDeuces, wildcardOneEyedJacks],
};
