/**
 * HelpPage.tsx
 *
 * Refresh table data and Report an issue to poker-cows
 */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Sentry from "@sentry/react";

import classNames from "classnames";

import { refreshTable } from "../../../logic/table/thunks";
import Button from "../Button";
import { ModalName } from "../Modal/ModalConfig";
import { useModal } from "../Modal/hooks/useModal";
import { ModalProps } from "../Modal/types/modalTypes";

import "./Settings.css";
import LogRocket from "logrocket";
import { HELP_BUTTON } from "../../../test-identifiers";

const ReportAnIssue = (props: { setIssueSent: (sent: boolean) => void }) => {
  const [issueMessage, setIssueMessage] = useState("");
  const isEmpty = !issueMessage;

  const onMessageInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIssueMessage(event.target.value ?? "");
  };

  // send report
  const onSendReport = () => {
    // Apply LogRocket session URL to incoming Sentry reports.
    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra("sessionURL", sessionURL);
      });
    });

    const message = `
      Customer Reported Error

      message: ${issueMessage}
  `;

    Sentry.captureMessage(message);
    setIssueMessage("");
    props.setIssueSent(true);
  };

  return (
    <>
      <div className="helpPoint">Describe your issue and submit.</div>
      <textarea
        value={issueMessage}
        onChange={onMessageInput}
        className="reportIssueTextarea"
        placeholder="Describe your issue"
      />
      <br />
      <Button
        className={classNames("submitIssueButton actionButton green", {
          disabledButton: isEmpty
        })}
        onClick={onSendReport}
        disabled={isEmpty}
      >
        Submit Issue
      </Button>
    </>
  );
};

export const HelpContents = (props: ModalProps) => {
  const dispatch = useDispatch();
  const [issueSent, setIssueSent] = useState(false);

  const onRefreshTableData = () => {
    dispatch(refreshTable());
    props.onClose();
  };

  return (
    <div className="helpContainer">
      <div className="technicalProblem">
        <div className="helpTitle">Technical Problems ?</div>
        <div className="helpPoint">
          Most can be solved with a simple browser refresh.
        </div>
        <Button
          className="refreshBrowserButton green"
          onClick={onRefreshTableData}
        >
          Click Here to <br/> Refresh Browser
        </Button>
      </div>
      <div className="reportIssue">
        <div className="helpTitle">Report an Issue</div>
        {!issueSent && <ReportAnIssue setIssueSent={setIssueSent} />}
        {issueSent && (
          <div className="helpPoint">Issue reported to the team.</div>
        )}
      </div>
    </div>
  );
};

export const HelpButton = () => {
  const { currentModalName, showModal } = useModal();

  return (
    <div className="rules">
      <Button
        data-testid={HELP_BUTTON}
        className={classNames("actionButton", {
          activeHelpButton: currentModalName === ModalName.HelpModal,
        })}
        onClick={() => showModal({ name: ModalName.HelpModal })}
      >
        HELP
      </Button>
    </div>
  );
};
