/**
 * CashMeOut.tsx
 *
 * This is the central component of CashMeOutModal to confirm that the player wants to cash out
 */
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { leaveGame } from "../../../logic/prompts/slice";
import {
  selectTableId,
  selectPokerSessionId,
  selectHostId,
  selectIsBotTable,
} from "../../../logic/table/slice";
import { CASH_ME_OUT_AND_LEAVE_BUTTON } from "../../../test-identifiers";
import { redirectToSurvey } from "../../../utils/RedirectToSurvey";
import Button from "../Button";
import { ModalProps } from "../Modal/types/modalTypes";

export const CashMeOut = (props: ModalProps) => {
  const dispatch = useDispatch();

  // redirecting to survey may be re-implemented in the future, commenting out for now according to SoW
  // these were the URL parameters that were passed along
  /* const { tableId } = useSelector(selectTableId);
  const { isBotTable } = useSelector(selectIsBotTable);
  const { hostId } = useSelector(selectHostId);
  const { pokerSessionId } = useSelector(selectPokerSessionId); */

  // the actual redirect to /cash-out is housed in TableArea.tsx
  const leaveGameClick = () => {
    dispatch(leaveGame());
    // redirecting to survey may be re-implemented in the future, commenting out for now according to SoW
    // redirectToSurvey({ tableId, isBotTable, hostId, pokerSessionId });
  };

  return (
    <div className="section cashMeOutContainer">
      <div className="cashMeOutText">
        This cashes you out of the game and
        <br />
        disconnects you from PokerCows.
      </div>
      <Button
        data-testid={CASH_ME_OUT_AND_LEAVE_BUTTON}
        onClick={leaveGameClick}
        className="leaveGameButton actionButton green"
      >
        CASH ME OUT & LEAVE GAME
      </Button>
      {/* TODO: make button work */}
      <Button
        onClick={props.onBack}
        className="actionButton cancelButton playerBank"
      >
        NO, CANCEL
      </Button>
    </div>
  );
};
