// thunk.ts
//
// All the thunks for the games interactions part of the admin functionality

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getEndpoint, postEndpoint } from "../../api";

/** Fetches all premium games from the server */
export const getAllPremiumGames = createAsyncThunk(
  "admin/games/all",
  async () => getEndpoint(`api/game/premium/all`, "")
);

export const toggleGamePremium = createAsyncThunk(
  "admin/games/premium",
  async (body: { gameName: string }) =>
    postEndpoint(`api/game/premium/toggle`, "", body)
);
