import React from "react";
import { useSelector } from "react-redux";

import { formatMoneyString } from "../../../../../utils/MoneyFormat";
import { selectNextPrefs } from "../../../../../logic/table/slice";
import { Tooltip } from "../../../Tooltip/Tooltip";
import { BetLimit } from "poker-cows-common";

export const BettingStructureContent = () => {
  const { nextPrefs } = useSelector(selectNextPrefs);

  const anteValue = formatMoneyString(nextPrefs?.ante);
  const minValue = formatMoneyString(
    nextPrefs?.limitType === BetLimit.FIXED_LIMIT
      ? nextPrefs?.fixedLimitAmount
      : nextPrefs?.spreadMinRaise
  );
  const maxValue = formatMoneyString(
    nextPrefs?.limitType === BetLimit.FIXED_LIMIT
      ? nextPrefs?.fixedLimitAmount
      : nextPrefs?.spreadMaxRaise
  );

  return (
    <div className="betReadingsContainer">
      <div className="ante flexColumn">
        <span className="caption">
          <span>Ante:</span>
          <Tooltip
            title="Ante"
            body="Forced bet on all players to start each game."
          />
        </span>
        <span className="value">{anteValue}</span>
      </div>
      <div className="minBet flexColumn">
        <span className="caption">Min Bet: </span>
        <span className="value">{minValue}</span>
      </div>
      <div className="maxBet flexColumn">
        <span className="caption">Max Bet: </span>
        <span className="value">{maxValue}</span>
      </div>
    </div>
  );
};
