/** ChipDenomTitle.tsx
 *
 * This component displays Title for Chip Denomination and Tooltip
 */
import React from "react";
import { Tooltip } from "../../Tooltip/Tooltip";

export const ChipDenomTitle = () => {
  return (
    <div className="flexRulesTitle">
      <span className="title">
      <span className="rowCaption">Chip Values</span>&nbsp;
        <Tooltip        
          title="Chip Denominations"
          body="Set the values for the chips."      
        />
      </span>
      <span className="description">ALL GAMES</span>
    </div>
  );
};
