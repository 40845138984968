import classNames from "classnames";
import React, { ReactNode } from "react";

interface RulesRowProps {
  title: string | ReactNode;
  className?: string;
  content: string | ReactNode | JSX.Element;
  isTitleBold?: boolean;
  isContentBigger?: boolean;  
}

export const RulesRow = (props: RulesRowProps) => (
  <div className={classNames("rulesRow", props.className)}>
    <div className={classNames("leftColumn", {
      smallContent: props.isContentBigger,
    })}>
      <div
        className={classNames({
          boldTitle: props.isTitleBold,
          title: !props.isTitleBold          
        })}
      >
        <>{props.title}</>
      </div>
    </div>
    <div
      className={classNames("rightColumn", {
        boldTitle: props.isTitleBold,
        largeContent: props.isContentBigger,
      })}
    >
      <>{props.content}</>
    </div>
  </div>
);
