import { createAsyncThunk } from "@reduxjs/toolkit";

import * as Requests from "./api";
import { setLocalPlayerId } from "../api";
import { RootState } from "../store";
import { selectTableId } from "./slice";
import { selectLocalPlayerId } from "../localPlayer/slice";
import { checkIsBotTable } from "../../utils/BotImage";

export const createTable = createAsyncThunk(
  "table/createTable",
  async (
    payload: {
      name: string;
      nickName?: string;
      seed?: string;
      isBotTable?: boolean;
      isTestTable?: boolean;
      numberOfPlayers?: number;
    },
    thunkApi
  ) =>
    Requests.createTable({
      ...payload,
      localPlayerId: selectLocalPlayerId(thunkApi.getState() as RootState)
        .localPlayerId,
    })
);

export const joinTable = createAsyncThunk(
  "table/joinTable",
  async (
    payload: { nickName: string; tableId: string; tableGroup: string },
    thunkApi
  ) => {
    const isBotTable = checkIsBotTable();
    const response = await Requests.joinTable({
      ...payload,
      isBotTable,
      localPlayerId: selectLocalPlayerId(thunkApi.getState() as RootState)
        .localPlayerId,
    });
    if (response.status === 200) {
      setLocalPlayerId(response.id);
      return response;
    } else {
      return thunkApi.rejectWithValue(response.data);
    }
  }
);

export const chooseSeat = createAsyncThunk(
  "table/chooseSeat",
  async (
    payload: {
      seatPosition: number;
    },
    thunkApi
  ) => {
    const tableId = selectTableId(
      thunkApi.getState() as RootState
    ).tableId.toString();
    const localPlayerId = selectLocalPlayerId(
      thunkApi.getState() as RootState
    ).localPlayerId;

    return Requests.chooseSeat({
      ...payload,
      tableLocalPlayerId: localPlayerId,
      tableId,
      localPlayerId,
    });
  }
);

export const resetGame = createAsyncThunk(
  "table/resetGame",
  async (unused, thunkApi) =>
    Requests.resetGame(
      selectTableId(thunkApi.getState() as RootState).tableId.toString(),
      selectLocalPlayerId(thunkApi.getState() as RootState).localPlayerId
    )
);

export const refreshTable = createAsyncThunk(
  "table/refreshTable",
  async (unused, thunkApi) => {
    window.location.reload();
  }
);
