/**
 * This component harbors the UI to the right of the user's avatar in the action area
 * for "No Limit" games.
 */
import React from "react";
import { useSelector } from "react-redux";
import { selectPrefs } from "../../../../logic/gameInstance/slice";
import { ChipBetArea } from "./ChipBetArea";

export const NoLimitBetArea = () => {
  const { prefs } = useSelector(selectPrefs);

  return (
    <ChipBetArea
      showTopButtons={false}
      showSlider={true}
      isNoLimit
      maxRaise={prefs?.spreadMaxRaise ?? 0}
      minRaise={prefs?.spreadMinRaise ?? 0}
    />
  );
};
