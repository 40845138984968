/**
 * StartBotTable.tsx
 *
 * Start a new table to play with bots (a table with one user player and four bot players).
 */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectLocalPlayerFreeTrial } from "../../../logic/localPlayer/slice";
import { selectTableCode } from "../../../logic/table/slice";
import { createTable } from "../../../logic/table/thunks";
import { checkIsBotTable } from "../../../utils/BotImage";
import {
  SessionKeys,
  getSession,
  setSession,
} from "../../../utils/SessionSettings";
import Loading from "../../components/Loading";
import { ModalName } from "../../components/Modal/ModalConfig";
import { useModal } from "../../components/Modal/hooks/useModal";

export const StartBotTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const { tableCode } = useSelector(selectTableCode);
  const { freeTrial } = useSelector(selectLocalPlayerFreeTrial);

  // create bot table after received details of the local player
  useEffect(() => {
    setSession(SessionKeys.isBotTable, "true");

    dispatch(
      createTable({
        name: "Bot Table",
        isBotTable: true,
        nickName: getSession(SessionKeys.username) ?? "",
      })
    );
  }, [dispatch]);

  // trigger detail when we have table code
  useEffect(() => {
    if (tableCode) {
      if (
        freeTrial?.isPaying ||
        freeTrial?.daysLeft > 0 ||
        freeTrial?.handsLeft > 0 ||
        checkIsBotTable()
      ) {
        history.push(`bot/table/${tableCode}`);
        return;
      }

      showModal({
        name: ModalName.WelcomeModal,
        requiresBackendVerification: false,
        additionalProps: {
          isBotTable: true,
        },
      });
    }
  }, [tableCode]);

  return <Loading />;
};
