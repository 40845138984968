import IronCrossRulesImagePlayerTwoCard from "../../static/graphics/rules/Player_Hand_2_Cards.svg";
import IronCrossRulesImagePlayerFourCard from "../../static/graphics/rules/Player_Hand_4_Cards.svg";
import IronCrossRulesImagePlayer from "../../static/graphics/rules/Iron_Cross_Player_Hand.svg";
import IronCrossRulesImageCommunity from "../../static/graphics/rules/Iron_Cross_Community_Cards.svg";
import IronCrossRulesImageCommunityNumbered from "../../static/graphics/rules/Iron_Cross_Community_Cards_Numbered.svg";
import { GameRulesInterface } from "poker-cows-common";

export const IronCrossRules: GameRulesInterface = {
  objectives: [
    "Best 5 card hand utilizing any or all player hole cards and up to 3 community cards up and down, or left to right.",
  ],
  card: {
    twoCard: ["2 Player Cards"],
    fourCard: ["4 Player Cards"],
  },
  image: {
    player: IronCrossRulesImagePlayer,
    playerTwoCard: IronCrossRulesImagePlayerTwoCard,
    playerFourCard: IronCrossRulesImagePlayerFourCard,
    community: IronCrossRulesImageCommunity,
    communityNumbered: IronCrossRulesImageCommunityNumbered,
  },
  actions: [
    "1. After all cards are dealt, a betting round starts left of the dealer. (Opening bet position rotates each betting round.)",
    "2. Next, the community card in position 1 is turned up, followed by a round of betting. This process is repeated for the remaining community cards 2 through 5.",
    "3. After final betting round, each remaining player will declare “High” or “Low” or “Both” (if High/Low split game).",
    "4. Cards displayed; winners paid.",
  ],
};
