/**
 * This function redirects a user to a url in a new window.
 */

import { StateIdType } from "poker-cows-common";

export interface SurveyURLParams {
  tableId: StateIdType;
  isBotTable: boolean;
  hostId: string;
  pokerSessionId: string;
}

export function redirectToSurvey(props: SurveyURLParams) {
  if (process.env.CI) {
    return;
  }

  if (props.isBotTable) {
    window.location.href = "https://www.pokercows.com";
  }

  const baseURL = "https://www.surveymonkey.com/r/KDC7L79";
  const fullURL =
    baseURL +
    "?tableid=" +
    (props.tableId || "none") +
    "&hostid=" +
    (props.hostId || "none") +
    "&pokersessionid=" +
    (props.pokerSessionId || "none");
  return window.open(fullURL);
}
