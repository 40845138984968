import "../BetArea.css";
import React from "react";
import { selectFixedLimitAmount } from "../../../../logic/gameInstance/slice";

import { useSelector } from "react-redux";
import { GenericBetArea } from "./GenericBetArea";

const FixedBetArea = () => {
  const { fixedLimitAmount } = useSelector(selectFixedLimitAmount);

  return (
    <GenericBetArea
      showTopButtons={false}
      showSlider={false}
      maxRaise={fixedLimitAmount}
      minRaise={fixedLimitAmount}
    />
  );
};

export default FixedBetArea;
