/**
 * This file contains all the test IDs used in frontend unit, integration, and e2e tests. The test IDs are used to
 * select elements in the DOM and to identify elements in the test runner. Relying on class names, `id` attributes, or
 * other attributes is not recommended as they can change and break tests.
 */

export const SIGN_IN_BUTTON = "SIGN_IN_BUTTON";
export const ENTER_PLAYER_NAME_INPUT = "ENTER_PLAYER_NAME_INPUT";
export const HOST_GAME_BUTTON = "HOST_GAME_BUTTON";
export const JOIN_TABLE_CODE_INPUT = "JOIN_TABLE_CODE_INPUT";
export const JOIN_TABLE_CODE_BUTTON = "JOIN_TABLE_CODE_BUTTON";

export const ACTIVE_SUBSCRIPTION_PLAN_CONTAINER = "ACTIVE_SUBSCRIPTION_PLAN_CONTAINER";

export const MASTER_STARTUP_BUTTON = "MASTER_STARTUP_BUTTON";

export const SHARE_TABLE_CODE_BUTTON = "SHARE_TABLE_CODE_BUTTON";

export const MY_ACCOUNT_HEADER = "MY_ACCOUNT_HEADER";
export const GAME_TYPE_LIST_HEADER = "GAME_TYPE_LIST_HEADER";
export const NO_LIMIT_HEADS_UP_CONTINUE_BUTTON =
  "NO_LIMIT_HEADS_UP_CONTINUE_BUTTON";

export const PRACTICE_BUTTON = "PRACTICE_BUTTON";
export const SELECTED_GAME_IMAGE = "SELECTED_GAME_IMAGE";
export const START_GAME_BUTTON = "START_GAME_BUTTON";

export const TURN_INDICATOR_ARROW = "TURN_INDICATOR_ARROW";
export const BREAK_BUTTON = "BREAK_BUTTON";
export const TAKING_BREAK_AREA = "TAKING_BREAK_AREA";
export const WAITING_TEXT = "WAITING_TEXT";
export const CARDS_BEING_DEALT_TEXT = "CARDS_BEING_DEALT_TEXT";

export const TABLE_CARDS_CONTAINER = "TABLE_CARDS_CONTAINER";
export const SELECTED_CARDS_CONTAINER = "SELECTED_CARDS_CONTAINER";
export const FACE_UP_CARD = "FACE_UP_CARD";
export const FACE_DOWN_CARD = "FACE_DOWN_CARD";

export const FOLD_CARDS_BUTTON = "FOLD_CARDS_BUTTON";
export const BUY_LAST_CARD_UP_ACCEPT_BUTTON = "BUY_LAST_CARD_UP_ACCEPT_BUTTON";
export const DECLARE_IN_BUTTON = "DECLARE_IN_BUTTON";
export const DECLARE_OUT_BUTTON = "DECLARE_OUT_BUTTON";

export const NO_LIMIT_CHIP_BET_AREA = "NO_LIMIT_CHIP_BET_AREA";
export const SPREAD_LIMIT_CHIP_BET_AREA = "SPREAD_LIMIT_CHIP_BET_AREA";
export const DECLARE_IN_AND_OUT_AREA = "DECLARE_IN_AND_OUT_AREA";

export const MODAL_BACK_BUTTON = "MODAL_BACK_BUTTON";
export const MODAL_TITLE = "MODAL_TITLE";
export const MODAL_CLOSE_BUTTON = "MODAL_CLOSE_BUTTON";

export const CALL_BUTTON = "CALL_BUTTON";
export const CHECK_BUTTON = "CHECK_BUTTON";
export const ALLIN_BUTTON = "ALLIN_BUTTON";
export const RAISE_BUTTON = "RAISE_BUTTON";
export const NO_LIMIT_CALL_ALLIN_BUTTON = "NO_LIMIT_CALL_ALLIN_BUTTON";
export const SELECT_CARDS_BUTTON = "SELECT_CARDS_BUTTON";

export const ANTE_NO_CARD_BUTTON = "ANTE_NO_CARD_BUTTON";
export const ANTE_TAKE_CARD_BUTTON = "ANTE_TAKE_CARD_BUTTON";

export const DECLARE_LOW_BUTTON = "DECLARE_LOW_BUTTON";
export const DECLARE_BOTH_BUTTON = "DECLARE_BOTH_BUTTON";
export const DECLARE_HIGH_BUTTON = "DECLARE_HIGH_BUTTON";

export const SHOW_CARDS_BUTTON = "SHOW_CARDS_BUTTON";
export const CONTINUE_PRACTICE_BUTTON = "CONTINUE_PRACTICE_BUTTON";
export const PASS_THE_DECK_BUTTON = "PASS_THE_DECK_BUTTON";
export const CONTINUE_GAME_BUTTON = "CONTINUE_GAME_BUTTON";

export const CASH_ME_OUT_AND_LEAVE_BUTTON = "CASH_ME_OUT_AND_LEAVE_BUTTON";
export const CASH_OUT_AND_EXIT_BUTTON = "CASH_OUT_AND_EXIT_BUTTON";
export const END_GAME_SESSION_BY_HOST_BUTTON =
  "END_GAME_SESSION_BY_HOST_BUTTON";
export const SHUTDOWN_TIMER_AREA = "SHUTDOWN_TIMER_AREA";
export const SHUTDOWN_GAME_BUTTON = "SHUTDOWN_GAME_BUTTON";

export const HOST_CONTROLS_BUTTON = "HOST_CONTROLS_BUTTON";
export const HELP_BUTTON = "HELP_BUTTON";
export const RULES_BUTTON = "RULES_BUTTON";

export const LEDGER_BUTTON = "LEDGER_BUTTON";
export const TOP_OFF_LEDGER_BUTTON = "TOP_OFF_LEDGER_BUTTON";
export const ONE_X_BUY_LEDGER_BUTTON = "ONE_X_BUY_LEDGER_BUTTON";
export const TWO_X_BUY_LEDGER_BUTTON = "TWO_X_BUY_LEDGER_BUTTON";
