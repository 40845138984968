/**
 * HeartIcon.tsx
 *
 * The icon is used in to show Heart Icon with predefined white stroke width
 */
import React from "react";

export const HeartIcon = ({
  width = 26,
  height = 28,
  color = "#D7263D",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  return (
    <svg
      width={`${width / 14}em`}
      height={`${height / 14}em`}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8773 5.77318C13.9657 4.75672 15.4 4 17.0936 4H17.0947C20.7686 4 23.7556 6.97684 23.7556 10.6542C23.7556 14.0646 21.8324 16.2279 19.9533 17.9733C19.5039 18.3908 19.0577 18.7864 18.6186 19.1759C17.1307 20.4954 15.7238 21.7432 14.5452 23.5226L12.9191 25.9777L11.2364 23.561C10.01 21.7995 8.62946 20.5902 7.18732 19.3268C6.70707 18.9061 6.21999 18.4794 5.7295 18.0244C3.83426 16.2661 2 14.1177 2 10.6542C2.00084 8.88897 2.70347 7.19592 3.95257 5.9486C5.20158 4.70138 6.89475 4.00084 8.65988 4C10.3536 4 11.7889 4.75682 12.8773 5.77318Z"
        fill="white"
      />
      <path
        d="M17.0936 6C15.2271 6 13.6213 7.51593 12.8778 9.09946C12.1332 7.51593 10.5274 6 8.66083 6C7.42489 6.00059 6.23972 6.49113 5.36578 7.36383C4.49183 8.23653 4.00059 9.42 4 10.6542C4 15.8802 9.28006 17.2509 12.8778 22.4182C16.2791 17.2831 21.7556 15.7139 21.7556 10.6542C21.7556 8.08441 19.6671 6 17.0947 6H17.0936Z"
        fill={color}
      />
    </svg>
  );
};
