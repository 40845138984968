/**
 * This is a component responsible for rendering and styling a typical playing card.
 * It is explicitly devoid of logic relating to who is using the card and in what context it's being used.
 *
 * This is an "Element" component. For an expanded explanation of what that means, see the Elements' folder's README.
 * @see /sub_projects/frontend/src/ui/components/Elements/README.md
 */

import React, { ButtonHTMLAttributes, CSSProperties } from "react";
import classNames from "classnames";
import styles from "./CardElement.module.css";
import { getCardRankToShortString, Rank, Suit } from "poker-cows-common";
import { FACE_DOWN_CARD, FACE_UP_CARD } from "../../../../test-identifiers";

export interface CardElementProps {
  className?: string;
  isFaceUp: boolean;
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>["onClick"];
  rank?: Rank | null;
  style?: CSSProperties;
  suit?: Suit | null;
}

export const CardElement = ({
  className,
  isFaceUp,
  onClick,
  rank,
  style,
  suit,
}: CardElementProps): JSX.Element => {
  const Element = onClick ? "button" : "div";

  if (!isFaceUp) {
    return (
      <Element
        data-testid={FACE_DOWN_CARD}
        className={classNames(className, styles.card, styles.faceDown)}
        // @ts-expect-error - onClick is only defined if Element is a button
        onClick={onClick}
        style={style}
      />
    );
  }

  // Rank.ACE is 0, so we need an explicit check to make sure that we don't ignore it
  const validRank = rank || rank === Rank.ACE;
  const rankString = validRank ? getCardRankToShortString(rank) : "";

  return (
    <Element
      data-testid={FACE_UP_CARD}
      className={classNames(className, styles.card, {
        [styles.club]: suit === Suit.CLUB,
        [styles.diamond]: suit === Suit.DIAMOND,
        [styles.heart]: suit === Suit.HEART,
        [styles.spade]: suit === Suit.SPADE,
      })}
      // @ts-expect-error - onClick is only defined if Element is a button
      onClick={onClick}
      style={style}
    >
      <div className={styles.cardContent}>
        <section className={styles.left}>
          <div className={styles.rank}>{rankString}</div>
          <span className={styles.suit} />
        </section>

        <section className={styles.right}>
          <span className={styles.suit} />
        </section>
      </div>
    </Element>
  );
};
