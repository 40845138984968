/**
 * GameInfo.ts
 *
 * Organize various information about all the available games
 *
 * Information is based on the following figma, under "Select a game":
 *   - https://www.figma.com/file/RPfXM0eOC23wvmMby2oGBD/Poker-Cows---Final-Screens?node-id=9%3A136
 *
 * Information includes:
 *   - A human readable name
 *   - The category of the game
 *   - A React component for displaying the rules of the game
 */

import {
  FiveCardDrawOptions,
  FiveCardStudOptions,
  FollowTheQueenOptions,
  Game,
  HighChicagoOptions,
  HollywoodSquaresOptions,
  IronCrossOptions,
  LowChicagoOptions,
  OmahaOptions,
  PassTheTrashOptions,
  RollYourOwnOptions,
  SevenCardStudOptions,
  SevenTwentySevenOptions,
  TexasDropemOptions,
  TexasHoldemOptions,
  TheSlutOptions,
  GutsOptions,
  GameRulesInterface,
  GameOptionsInterface,
} from "poker-cows-common";
import { FollowTheQueenRules } from "../data/rules/FollowTheQueenRules";
import { HollywoodSquaresRules } from "../data/rules/HollywoodSquaresRules";
import { IronCrossRules } from "../data/rules/IronCrossRules";
import { PassTheTrashRules } from "../data/rules/PassTheTrashRules";
import { SevenTwentySevenRules } from "../data/rules/SevenTwentySevenRules";
import { SevenCardStudRules } from "../data/rules/SevenCardStudRules";
import { FiveCardDrawRules } from "../data/rules/FiveCardDrawRules";
import { TexasHoldemRules } from "../data/rules/TexasHoldemRules";
import { TexasDropemRules } from "../data/rules/TexasDropemRules";
import { TheSlutRules } from "../data/rules/TheSlutRules";
import { HighChicagoRules } from "../data/rules/HighChicagoRules";
import { LowChicagoRules } from "../data/rules/LowChicagoRules";
import { FiveCardStudRules } from "../data/rules/FiveCardStudRules";
import { RollYourOwnRules } from "../data/rules/RollYourOwnRules";
import { OmahaRules } from "../data/rules/OmahaRules";
import { GutsRules } from "../data/rules/GutsRules";

export enum GameCategory {
  CLASSIC = "CLASSIC GAMES",
  STUD = "STUD VARIATIONS",
  COMMUNITY = "COMMUNITY CARDS",
  OTHER = "COUNTING/OTHER",
}

export enum GameTitle {
  FiveCardDraw = "5 Card Draw",
  FiveCardStud = "5 Card Stud",
  SevenCardStud = "7 Card Stud",
  TexasHoldem = "Texas Hold'em",
  FollowTheQueen = "Follow The Queen",
  LowChicago = "Low Chicago",
  HighChicago = "High Chicago",
  RollYourOwn = "Roll Your Own",
  IronCross = "Iron Cross",
  HollywoodSquares = "Hollymoo'd Squares",
  TheSlut = "Piña Colada",
  Omaha = "Omaha Spread",
  SevenTwentySeven = "7-27",
  Texas = "Texas Drop'em",
  PassTheTrash = "Pass The Trash",
  Guts = "Guts",
}

export const flavorToCard: Record<string, string> = {
  "2": "twoCard",
  "3": "threeCard",
  "4": "fourCard",
  "7": "sevenCard",
  "-1": "equalCard",
};

export const flavorToImage: Record<string, string> = {
  "2": "playerTwoCard",
  "3": "playerThreeCard",
  "4": "playerFourCard",
};

export interface GameInfoInterface {
  title: GameTitle;
  rules: GameRulesInterface;
  options: GameOptionsInterface;
  category: GameCategory;
}

export const GamesInfo: Record<Game, GameInfoInterface> = {
  [Game.FiveCardDraw]: {
    title: GameTitle.FiveCardDraw,
    rules: FiveCardDrawRules,
    options: FiveCardDrawOptions,
    category: GameCategory.CLASSIC,
  },
  [Game.FiveCardStud]: {
    title: GameTitle.FiveCardStud,
    rules: FiveCardStudRules,
    options: FiveCardStudOptions,
    category: GameCategory.CLASSIC,
  },
  [Game.SevenCardStud]: {
    title: GameTitle.SevenCardStud,
    rules: SevenCardStudRules,
    options: SevenCardStudOptions,
    category: GameCategory.CLASSIC,
  },
  [Game.TexasHoldem]: {
    title: GameTitle.TexasHoldem,
    rules: TexasHoldemRules,
    options: TexasHoldemOptions,
    category: GameCategory.CLASSIC,
  },
  [Game.FollowTheQueen]: {
    title: GameTitle.FollowTheQueen,
    rules: FollowTheQueenRules,
    options: FollowTheQueenOptions,
    category: GameCategory.STUD,
  },
  [Game.LowChicago]: {
    title: GameTitle.LowChicago,
    category: GameCategory.STUD,
    rules: LowChicagoRules,
    options: LowChicagoOptions,
  },
  [Game.HighChicago]: {
    title: GameTitle.HighChicago,
    category: GameCategory.STUD,
    rules: HighChicagoRules,
    options: HighChicagoOptions,
  },
  [Game.RollYourOwn]: {
    title: GameTitle.RollYourOwn,
    category: GameCategory.STUD,
    rules: RollYourOwnRules,
    options: RollYourOwnOptions,
  },
  [Game.IronCross]: {
    title: GameTitle.IronCross,
    rules: IronCrossRules,
    options: IronCrossOptions,
    category: GameCategory.COMMUNITY,
  },
  [Game.HollywoodSquares]: {
    title: GameTitle.HollywoodSquares,
    rules: HollywoodSquaresRules,
    options: HollywoodSquaresOptions,
    category: GameCategory.COMMUNITY,
  },
  [Game.TheSlut]: {
    title: GameTitle.TheSlut,
    rules: TheSlutRules,
    options: TheSlutOptions,
    category: GameCategory.COMMUNITY,
  },
  [Game.Omaha]: {
    title: GameTitle.Omaha,
    rules: OmahaRules,
    options: OmahaOptions,
    category: GameCategory.COMMUNITY,
  },
  [Game.SevenTwentySeven]: {
    title: GameTitle.SevenTwentySeven,
    rules: SevenTwentySevenRules,
    options: SevenTwentySevenOptions,
    category: GameCategory.OTHER,
  },
  [Game.Texas]: {
    title: GameTitle.Texas,
    rules: TexasDropemRules,
    options: TexasDropemOptions,
    category: GameCategory.OTHER,
  },
  [Game.Guts]: {
    title: GameTitle.Guts,
    rules: GutsRules,
    options: GutsOptions,
    category: GameCategory.OTHER,
  },
  [Game.PassTheTrash]: {
    title: GameTitle.PassTheTrash,
    rules: PassTheTrashRules,
    options: PassTheTrashOptions,
    category: GameCategory.OTHER,
  },
};

export function getGamesByCategory(category: string) {
  return Object.entries(GamesInfo).filter(
    ([unused, value]) => value?.category === category
  );
}

export function getPrettyGameType(game?: Game | null | undefined) {
  if (!game) {
    return "";
  }

  const foundName = GamesInfo[game]?.title;

  if (foundName) {
    return foundName;
  }

  console.error(`Could not find human-friendly name for ${game.toString()}`);
  return "";
}

export function getGameRules(game: Game | null | undefined) {
  if (!game) {
    return undefined;
  }

  const rules = GamesInfo[game]?.rules;
  if (rules) {
    return rules;
  }

  console.error(`Could not find game rules for ${game}`);
  return undefined;
}

export function getGameOptions(game: Game | null | undefined) {
  if (!game) {
    return undefined;
  }

  const options = GamesInfo[game]?.options;
  if (options) {
    return options;
  }

  console.error(`Could not find game options for ${game}`);
  return undefined;
}

export function getCardRules(
  gameRules: ReturnType<typeof getGameRules>,
  gameFlavor: number | string | undefined | null
) {
  const card = gameRules?.card;

  if (!card) {
    return [];
  }

  if ("default" in card) {
    return card.default;
  }

  if (typeof gameFlavor === "number" && Object.keys(card).length > 0) {
    const flavorCard = flavorToCard[gameFlavor.toString()];

    if (flavorCard && card[flavorCard]) {
      return card[flavorCard];
    }
  }

  return null;
}

export function getCardImages(
  gameRules: ReturnType<typeof getGameRules>,
  gameFlavor: number | null | undefined = null,
  isCommunity = false
) {
  const image = gameRules?.image;

  if (!image) {
    return "";
  }

  const keys = Object.keys(image);

  if (keys.length !== 0 && isCommunity) {
    if ("communityNumbered" in image) {
      return image.communityNumbered;
    }
    if ("community" in image) {
      return image.community;
    }
    return "";
  }

  if (typeof gameFlavor === "number" && keys.length > 0) {
    const flavorImage = flavorToImage[gameFlavor.toString()];

    if (flavorImage && image[flavorImage]) {
      return image[flavorImage];
    }
  }

  if (keys.length === 1) {
    return image[keys[0]];
  }

  if ("player" in image) {
    return image.player;
  }

  return "";
}
