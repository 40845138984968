import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PrefInterface,
  WildcardOption,
  RollYourOwnSetting,
  BuyLastCardUpSetting,
  GameOptionInterface,
} from "poker-cows-common";
import { getGameOptions } from "../../../../utils/GameInfo";
import { selectNextPrefs } from "../../../../logic/table/slice";
import { dealerChangePrefs } from "../../../../logic/prompts/slice";
import { Tooltip } from "../../Tooltip/Tooltip";
import classNames from "classnames";

type OptionSectionProps = {
  isRulePage: boolean;
  isCurrentGameOption: boolean;
  gameOptions: ReturnType<typeof getGameOptions>;
};

type OptionProps = {
  isRulePage: boolean;
  isCurrentGameOption: boolean;
  option: GameOptionInterface;
  setOptionSetting: (option: any) => void;
  isChecked: (option: any) => boolean;
};

/**
 * Option
 * Component used to render option along with selected
 */
const Option = (props: OptionProps) => {
  const { option, isChecked } = props;
  const isSelected = isChecked(option);
  if (props.isRulePage) {
    return (
      <div className="winnersRuleOption" key={option.description}>
        <div
          className={classNames("optionDescription", {
            selected: props.isCurrentGameOption && isSelected,
          })}
        >
          {option.description}
        </div>
      </div>
    );
  }

  return (
    <div
      key={option.description}
      data-testid={`OptionSection-${option.description}`}
      className={classNames("winnersOption", {
        selected: isSelected,
      })}
    >
      <label
        htmlFor="option"
        className="radio"
        onClick={() => {
          props.setOptionSetting(option);
        }}
      >
        <input
          readOnly
          type="radio"
          id={`option_${option.value}`}
          name="option"
          className="radioInput"
          checked={isSelected}
        />
        <div className="customRadio"></div>
        <div className="radioOptionText">{option.description}</div>
        {option.tooltip && <Tooltip body={option.tooltip} />}
      </label>
    </div>
  );
};

// This is the Option section in the OptionPage when picking a game or looking at "Rules" within the game,
// meant for selecting Roll your Own, Buy Last card up, or others options. In theory multiple options could be selected at one time
// but for sake of simplicity the client has decided to only support one option at a time for now.
export const OptionSection = (props: OptionSectionProps) => {
  const { nextPrefs } = useSelector(selectNextPrefs);
  const dispatch = useDispatch();

  // See notes in RollYourOwnOptions.tsx in poker-cows-common
  // for Roll Your Own we have to force as "Always" even when "None".
  const rollYourOwnDefaultValue = (() => {
    const defaults = props.gameOptions?.options?.filter(
      (option) => option.description.toUpperCase() === "NONE"
    );
    if (!defaults || defaults.length !== 1) {
      console.error(
        "Roll Your Own Game with options that are missing None as value",
        props.gameOptions
      );
      return RollYourOwnSetting.DISABLED;
    }
    return defaults[0].value as RollYourOwnSetting;
  })();

  // If the option is selected within our state, we need to show it as selected in the ui.
  // It effects the default button selected and brighter text on selected options.
  function isChecked(option: GameOptionInterface) {
    // For NONE, display true if:
    // - wildcard is LOW_HOLE_WITH_BUY
    // - buyLastCardUpSetting is disabled and rollYourOwnSetting is disabled || always
    if (option.description === "None") {
      const { rollYourOwnSetting, wildcardSetting, buyLastCardUpSetting } =
        nextPrefs ?? {};
      const isRollYourOwn = rollYourOwnSetting === option.value;
      const isLowHoleWildcardWithBuy =
        wildcardSetting === WildcardOption.LOW_HOLE_WITH_BUY;
      const isBuyLastCardDisabled =
        buyLastCardUpSetting === BuyLastCardUpSetting.DISABLED;

      return (
        isRollYourOwn && (isLowHoleWildcardWithBuy || isBuyLastCardDisabled)
      );
    }

    // For BLCU, display true if:
    // - buyLastCardUpSetting is enabled
    if (option.description === "Buy Last Card Up") {
      return nextPrefs?.buyLastCardUpSetting === option.value;
    }

    // For other, ie RYO display true if:
    // - rollYourOwnSetting is enabled
    return nextPrefs?.rollYourOwnSetting === option.value;
  }

  const setOptionSetting = (option: GameOptionInterface) => {
    const settings: Partial<PrefInterface> = {
      rollYourOwnSetting: rollYourOwnDefaultValue,
    };

    // if the value of the option is BUY_LAST_CARD_UP_SETTINGS.ENABLED, and the options description is "Buy Last Card Up", buyLastCardUpSetting is set as value
    // else if the rollYourOwn setting is "first" or "always", rollYourOwnSetting is set as value
    if (
      option.value === BuyLastCardUpSetting.ENABLED &&
      option.description === "Buy Last Card Up"
    ) {
      settings["buyLastCardUpSetting"] = option.value;
    } else if (
      option.value === RollYourOwnSetting.FIRST ||
      option.value === RollYourOwnSetting.ALWAYS
    ) {
      settings["rollYourOwnSetting"] = option.value;
    }

    // if the description is none and BLCU exists in Options, buyLastCardUpSetting is disabled
    if (
      option.description === "None" &&
      props.gameOptions?.options?.find(
        ({ description }) => description === "Buy Last Card Up"
      )
    ) {
      settings["buyLastCardUpSetting"] = BuyLastCardUpSetting.DISABLED;
    }

    dispatch(dealerChangePrefs(JSON.stringify(settings)));
  };

  return (
    <div className="section">
      <div className="optionsSubSection optionTitle">
        {/* If this label changes, check out rule for `.optionTitle > span` in Settings.css */}
        <span>Option:</span>

        <div className="optionSelectionContainer">
          {props.gameOptions?.options?.map((option) => (
            <Option
              key={option.description}
              option={option}
              setOptionSetting={setOptionSetting}
              isChecked={isChecked}
              isCurrentGameOption={props.isCurrentGameOption}
              isRulePage={props.isRulePage}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
