export const HollywoodSquaresMap = {
  "1 / 2": 0,
  "2 / 3": 1,
  "2 / 1": 2,
  "3 / 2": 3,
  "1 / 3": 4,
  "3 / 3": 5,
  "3 / 1": 6,
  "1 / 1": 7,
  "2 / 2": 8,
};
