import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLocalPlayerStatus,
  selectPrefs,
} from "../../logic/gameInstance/slice";
import { getGameOptions, getPrettyGameType } from "../../utils/GameInfo";
import {
  selectAnyChooseGamePrompt,
  selectAnyDealerPrompt,
  selectInitializeTablePrompt,
  selectStartNormalGamePlayPrompt,
  selectStartPrompt,
  hideSessionEndTime,
} from "../../logic/prompts/slice";
import { PlayerStatus, GameVersionInterface } from "poker-cows-common";
import {
  selectIsLocalPlayerDealer,
  selectGameType,
  selectIsNoLimitFullRound,
  selectIsSessionEndTimeDismissible,
  selectTableSessionEndTime,
  selectIsPreviousGameContinued,
} from "../../logic/table/slice";
import { GameInfoOptions } from "./GameInfoOptions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  selectIsRemainingSessionTimeVisibleForPlayerById,
  selectPlayerIdAtTablePos,
} from "../../logic/player/slice";
import { useSeatContext } from "./Seat/SeatContext";
import { Timer } from "./Timer";
import { selectLocalPlayerId } from "../../logic/localPlayer/slice";

/**
 * GameSessionTime
 * Show game session timer
 */
const GameSessionTime = () => {
  const dispatch = useDispatch();
  const { tableSessionEndTime } = useSelector(selectTableSessionEndTime);
  const { isSessionEndTimeDismissible } = useSelector(
    selectIsSessionEndTimeDismissible
  );
  const { tablePosition } = useSeatContext();
  const { playerId } = useSelector(selectPlayerIdAtTablePos(tablePosition));

  const onHideSessionEndTime = () => {
    dispatch(hideSessionEndTime(playerId));
  };

  if (!tableSessionEndTime) {
    return <></>;
  }

  const closeButton = (
    <span className="close" onClick={onHideSessionEndTime}>
      <FontAwesomeIcon
        className="close-icon"
        title="Close"
        icon={faClose as IconProp}
      />
    </span>
  );

  return (
    <div className="gameSession">
      <span>Game Session Ends in:</span>
      <span className="gameSessionTime">
        <Timer deadline={tableSessionEndTime} />
      </span>
      {isSessionEndTimeDismissible && closeButton}
    </div>
  );
};

export function GameInfo(): JSX.Element {
  // get the localPlayer and their status
  const { localPlayerId } = useSelector(selectLocalPlayerId);
  const { localPlayerStatus } = useSelector(selectLocalPlayerStatus);
  const { isRemainingSessionTimeVisibleForPlayer } = useSelector(
    selectIsRemainingSessionTimeVisibleForPlayerById(localPlayerId)
  );

  // get the game name, game options, and preferences
  const { gameType } = useSelector(selectGameType);
  const gameOptions = getGameOptions(gameType);
  const { prefs } = useSelector(selectPrefs);

  // get the prompts that are currently available in order to
  // determine if we should show the game information or not.
  const { anyChooseGamePrompt } = useSelector(selectAnyChooseGamePrompt);
  const { startPrompt } = useSelector(selectStartPrompt);
  const { anyDealerPrompt } = useSelector(selectAnyDealerPrompt);
  const { initializeTablePrompt } = useSelector(selectInitializeTablePrompt);
  const { isPreviousGameContinued } = useSelector(
    selectIsPreviousGameContinued
  );
  const { startNormalGamePlayPrompt } = useSelector(
    selectStartNormalGamePlayPrompt
  );
  const { isNoLimitFullRound } = useSelector(selectIsNoLimitFullRound);

  // determine if the localPlayer is the dealer or not
  const { isLocalPlayerDealer } = useSelector(selectIsLocalPlayerDealer);

  // display the game name and "flavor" as a string,
  // game "flavor" is the card number variants i.e. 7 vs. 9 card
  const gameTitle = getPrettyGameType(gameType);
  const gameRowName = useMemo(() => {
    if (anyChooseGamePrompt) {
      return "";
    }

    const gameHasMultipleVersions = gameOptions?.versions
      ? gameOptions.versions.length > 1
      : false;
    const gameFlavor = gameOptions?.versions?.find(
      (versionType: GameVersionInterface) =>
        versionType.flavor === prefs?.gameFlavor &&
        versionType.betLimit === prefs?.limitType
    );

    // the second conditional here turns off the version/flavor titling for
    // that long ugly version title in texas drop 'em
    let flavorName = "";
    if (
      gameHasMultipleVersions &&
      gameFlavor?.title &&
      gameFlavor.title.indexOf("Equal") < 0
    ) {
      flavorName = ` (${gameFlavor?.title})`;
    }

    return `${gameTitle.toString()}${flavorName}`;
  }, [
    gameTitle,
    anyChooseGamePrompt,
    prefs?.gameFlavor,
    gameOptions?.versions,
  ]);

  // is dealer and is selecting, show no game info (because it's behind prompt)
  if (
    !isPreviousGameContinued &&
    (anyChooseGamePrompt || startPrompt || initializeTablePrompt) &&
    isLocalPlayerDealer
  ) {
    return <></>;
  }

  if (localPlayerStatus === PlayerStatus.JOINING) {
    return <></>;
  }

  // determine if we should show the game information header
  let showHeader = gameTitle.length > 0;

  if (isLocalPlayerDealer && !showHeader) {
    return <></>;
  }

  if (isLocalPlayerDealer) {
    showHeader =
      isPreviousGameContinued ||
      (!anyChooseGamePrompt &&
        !startPrompt &&
        !initializeTablePrompt &&
        !startNormalGamePlayPrompt);
  } else {
    showHeader =
      isNoLimitFullRound ||
      isPreviousGameContinued ||
      !(anyChooseGamePrompt || startPrompt || anyDealerPrompt);
  }

  return (
    <>
      <div className="gameInfoHeader">
        {/* game has NOT been selected */}
        {!showHeader && <div className="game">Dealer selecting game.</div>}
        {/* game has started */}
        {showHeader && (
          <>
            <div className="game">{gameRowName}</div>
            <GameInfoOptions />
          </>
        )}
      </div>
      {showHeader && isRemainingSessionTimeVisibleForPlayer && (
        <GameSessionTime />
      )}
    </>
  );
}
