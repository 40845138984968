/**
 * NonHostShutdown.tsx
 * 
 * This Component is reached when the table is archived
 */
import React from "react";
import { ExtraSession } from "../ExtraSession";
import "../ExtraSession.css";

export const NonHostShutdown = () => {
  const handleClick = () => {
    window.location.href = "https://pokercows.com";
  };
  const title = "POKER GAME SESSION ENDED";
  const contents = (
    <>
      <div className="extraSessionSubtext">
        Thank you for playing a game on PokerCows!
      </div>
    </>
  );
  return (
    <ExtraSession
      title={title}
      contents={contents}
      actionButtonOnClick={handleClick}
      actionButtonText={"Continue"}
    />
  );
};
