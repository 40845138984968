/*
    This component renders two buttons for the dealer
    for deciding to continue or end practice mode
    during the showdown phase of a game
*/

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changePrefs, endShowdownPhase } from "../../../logic/prompts/slice";
import { CONTINUE_PRACTICE_BUTTON } from "../../../test-identifiers";
import Button from "../Button";

export const PracticeHandCompleteButtons = () => {
  const dispatch = useDispatch();

  const endRound = (practiceMode: boolean) => {
    const prefsString = JSON.stringify({ practiceMode: practiceMode });
    dispatch(changePrefs({ newPrefs: prefsString }));
    dispatch(endShowdownPhase(false /** toContinue */));
  };

  // if press enter start practice mode
  const downHandler = ({ key }: { key: string }) => {
    if (key === "Enter") {
      endRound(true);
    }
  };
  // Add keydown event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return (
    <div className="practiceHandCompleteButtons">
      <Button
        data-testid={CONTINUE_PRACTICE_BUTTON}
        className="actionButton"
        onClick={() => endRound(true)}
      >
        Continue Practice
      </Button>
      <Button className="actionButton green" onClick={() => endRound(false)}>
        Play for Real
      </Button>
    </div>
  );
};
