/**
 * Dev.tsx
 *
 * This is the component shown when devs moves to /dev route to define multiple stores, and dev related actions
 */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Provider, useDispatch } from "react-redux";
import { withRouter, useParams } from "react-router-dom";

import { Table } from "../table/Table";
import { stores } from "./DevStores";
import { makeStore } from "../../../logic/store";
import { useDeveloper } from "../../components/Dev/hooks/useDeveloper";
import { useRedirect } from "../../../hooks/useRedirect";
import { setTableId } from "../../../logic/table/slice";
import { FeaturesList } from "../../components/Feature/FeaturesList";
import { playAutomatically } from "../../../logic/prompts/slice";
import { LocalKeys, setLocal } from "../../../utils/LocalSettings";
import { ModalWrapper } from "../../components/Modal/ModalWrapper";

import { ModalName } from "../../components/Modal/ModalConfig";
import { useModal } from "../../components/Modal/hooks/useModal";
import { ModalProps } from "../../components/Modal/types/modalTypes";

import "./Dev.css";

const hidden = { opacity: "0", zIndex: 0 };

export function getName(index: number): string {
  const char = String.fromCharCode("A".charCodeAt(0) + index);
  return char + char + char + "-Dev";
}

export const FeatureList = (props: ModalProps): JSX.Element => {
  return (
    <div className="devPage">
      <FeaturesList />
    </div>
  );
};

export const DevPlayerNameContext = React.createContext("");

function Dev() {
  const { code }: { code: string } = useParams();
  const dispatch = useDispatch();

  const { showModal } = useModal();

  const [active, setActive] = useState(0);
  const [players, setPlayers] = useState(2);
  const [autoSelect, setAutoSelect] = useState(true);
  const [autoPlay, setAutoPlay] = useState(false);
  const [lastPlayerActed, setLastPlayerActed] = useState(-1);

  const playerIndexes = useMemo(
    () => Array.from(Array(players).keys()),
    [players]
  );
  const [hasAction, setHasAction] = useState(playerIndexes.map(() => false));

  const [events, setEvents] = useState([]) as any;
  const callBacks = useMemo(
    () =>
      playerIndexes.map((index: number) => (event: string) => {
        const eventObj = [index, event];
        setEvents((_events: any[]) => {
          const newEvents = [..._events, eventObj];
          return newEvents;
        });
      }),
    [playerIndexes]
  );

  const developer = useDeveloper();
  const redirectHome = useRedirect("/");

  useEffect(() => {
    setLocal(LocalKeys.isDeveloper, "true");
  }, []);

  const goHome = useCallback(() => {
    dispatch(setTableId(0));
    redirectHome();
  }, [dispatch, redirectHome]);

  const selectActive = useCallback(
    (hasAction: boolean[]) => {
      if (!autoSelect) {
        return;
      }
      if (lastPlayerActed >= 0 && hasAction[lastPlayerActed]) {
        setActive(lastPlayerActed);
        return;
      }
      for (let i = 0; i < players; i++) {
        if (hasAction[i]) {
          setActive(i);
          return;
        }
      }
    },
    [players, autoSelect, lastPlayerActed]
  );

  const mapTab = (index: number) => {
    const classes = [];
    if (active === index) {
      classes.push("activeTab");
    }
    if (hasAction[index]) {
      classes.push("hasAction");
    }
    return (
      <div
        key={getName(index)}
        className={classes.join(" ")}
        onClick={() => setActive(index)}
      >
        {getName(index)}
      </div>
    );
  };

  // updatedActions hold updated value of hasAction
  // when multiple event trigger from events object
  let updatedActions: any = null;

  const handleEvent = useCallback(
    (index: number, event: string) => {
      updatedActions = updatedActions ?? [...hasAction];

      if (event === "waiting") {
        updatedActions[index] = false;
        setHasAction(updatedActions);
      } else if (event === "active") {
        updatedActions[index] = true;
        setLastPlayerActed(index);
        setHasAction(updatedActions);
      }
    },
    [hasAction]
  );

  useEffect(() => {
    if (!autoPlay) {
      return;
    }
    dispatch(playAutomatically());

    const repeatSecondsDelay = 2;
    const repeat = setInterval(() => {
      dispatch(playAutomatically());
    }, repeatSecondsDelay * 1000);

    return () => {
      clearInterval(repeat);
    };
  }, [autoPlay, dispatch]);

  useEffect(() => {
    selectActive(hasAction);
  }, [hasAction, selectActive]);

  useEffect(() => {
    if (events.length === 0) {
      return;
    }

    events.forEach((event: [number, string]) => {
      handleEvent(event[0], event[1]);
    });

    setEvents((oldEvents: any) =>
      oldEvents.filter(
        (event: [number, string]) =>
          events.find(
            (_event: [number, string]) =>
              event[0] === _event[0] && event[1] === _event[1]
          ) === undefined
      )
    );
  }, [events, handleEvent]);

  const mapTable = (index: number) => {
    if (stores[index] === undefined) {
      stores[index] = makeStore(getName(index));
    }

    return (
      <Provider key={getName(index) + "-provider"} store={stores[index]}>
        <span className={"devTable"} style={active === index ? {} : hidden}>
          <Table
            eventHandler={callBacks[index]}
            delay={400 * index}
            code={code}
            username={getName(index)}
          />
        </span>
        <ModalWrapper />
      </Provider>
    );
  };

  return (
    <DevPlayerNameContext.Provider value={getName(active)}>
      <div className="devPage">
        <div className="devTabs">
          {playerIndexes.map(mapTab)}
          <div
            key="ADDPLAYER"
            className="addPlayer"
            onClick={() => setPlayers((players) => Math.min(players + 1, 7))}
          >
            +
          </div>
          <div
            key="AUTOSELECT"
            className={autoSelect ? "activeTab" : ""}
            onClick={() => setAutoSelect(!autoSelect)}
          >
            Auto-select Player
          </div>
          <div
            key="AUTOPLAY"
            className={autoPlay ? "activeTab" : ""}
            onClick={() => setAutoPlay(!autoPlay)}
          >
            Auto-Play
          </div>
          <div key="HOME" className="home" onClick={goHome}>
            Home
          </div>
          <div
            key="EXPERIMENTAL"
            className="experimental"
            onClick={() => showModal({ name: ModalName.FeaturePreviewModal })}
          >
            Experimental
          </div>
          <div
            key="NEWTABLE"
            className="newTable"
            onClick={developer.gotoDevTable}
          >
            New Table
          </div>
        </div>
        <div className="devTables">{playerIndexes.map(mapTable)}</div>
      </div>
    </DevPlayerNameContext.Provider>
  );
}

export default withRouter(Dev);
