/**
 * TableSettingsModal.tsx
 *
 * This is just a wrapper around the TableSettings
 */
import React from "react";

import { TableSettings } from "../../Settings/HostControls/TableSettings";

import { ModalProps } from "../types/modalTypes";

export const TableSettingsModal = (props: ModalProps): JSX.Element => {
  return <TableSettings {...props} />;
};
