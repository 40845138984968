/**
 * ExitIcon.tsx
 *
 * The icon is used in to show Cross Icon for all closeable modals
 */
import React from "react";

export const ExitIcon = ({
  width = 40,
  height = 40,
  color = "#B53C3C",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H32C36.4183 0 40 3.58172 40 8V32C40 36.4183 36.4183 40 32 40H8C3.58172 40 0 36.4183 0 32V8Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2965 10.7036C29.9383 11.3454 29.9383 12.3859 29.2965 13.0277L22.3242 20L29.2965 26.9723C29.9383 27.6141 29.9383 28.6547 29.2965 29.2964C28.6547 29.9382 27.6142 29.9382 26.9724 29.2964L20.0001 22.3241L13.0277 29.2964C12.386 29.9382 11.3454 29.9382 10.7036 29.2964C10.0618 28.6547 10.0618 27.6141 10.7036 26.9723L17.676 20L10.7036 13.0277C10.0618 12.3859 10.0618 11.3454 10.7036 10.7036C11.3454 10.0618 12.386 10.0618 13.0277 10.7036L20.0001 17.6759L26.9724 10.7036C27.6142 10.0618 28.6547 10.0618 29.2965 10.7036Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M8 2.18182H32V-2.18182H8V2.18182ZM37.8182 8V32H42.1818V8H37.8182ZM32 37.8182H8V42.1818H32V37.8182ZM2.18182 32V8H-2.18182V32H2.18182ZM8 37.8182C4.78671 37.8182 2.18182 35.2133 2.18182 32H-2.18182C-2.18182 37.6233 2.37674 42.1818 8 42.1818V37.8182ZM37.8182 32C37.8182 35.2133 35.2133 37.8182 32 37.8182V42.1818C37.6233 42.1818 42.1818 37.6233 42.1818 32H37.8182ZM32 2.18182C35.2133 2.18182 37.8182 4.78671 37.8182 8H42.1818C42.1818 2.37674 37.6233 -2.18182 32 -2.18182V2.18182ZM8 -2.18182C2.37674 -2.18182 -2.18182 2.37674 -2.18182 8H2.18182C2.18182 4.78671 4.78671 2.18182 8 2.18182V-2.18182Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};
