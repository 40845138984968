import { postEndpoint } from "../api";

export interface PromptSelection {
  actionType: string;
  electedActionDto: {
    promptId: string;
    selection: number;
    argsString: string;
  };
  localPlayerId: string;
  isBotTable: boolean;
}

export function getPrompts(
  tableId: string,
  tableGroup: string,
  localPlayerId: string
) {
  const body = { actionType: "showPrompts", localPlayerId };
  return postEndpoint(`api/table/${tableId}/action`, tableGroup, body);
}

export function submitPrompt(
  tableId: string,
  tableGroup: string,
  body: PromptSelection
) {
  return postEndpoint(`api/table/${tableId}/action`, tableGroup, body);
}
