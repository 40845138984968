/**
 * ActForCertainPlayerModal.tsx
 *
 * This is just a wrapper around the ActForPlayerSubpage
 */
import React from "react";

import { ActForPlayerSubpage } from "../../Settings/HostControls/ActForPlayerSubpage";

import { ModalProps } from "../types/modalTypes";

export const ActForCertainPlayerModal = (props: ModalProps): JSX.Element => {
  return <ActForPlayerSubpage {...props} />;
};
