// OmahaOptions.tsx
//
//  Game Options for the Omaha game.
import { BetLimit } from "../bets/BetLimit";
import { CardVersionSetting } from "../enums/CardVersionSetting";
import { GameOptionsInterface } from "../interfaces";
import {
  optionNoneDefault,
  wildcardDeuces,
  wildcardLowHole,
  wildcardNoneDefault,
  wildcardOneEyedJacks,
  winnerHighLowSplit,
  winnerHighOnlyDefault,
  winnerLowOnly,
} from "./defaultOptions";

export const OmahaOptions: GameOptionsInterface = {
  versions: [
    {
      title: "9 Card",
      description: "4 Player Cards, | 5 Community Cards",
      value: CardVersionSetting.NINE_CARD,
      default: true,
      flavor: 9,
      betLimit: BetLimit.SPREAD_LIMIT,
    },
  ],
  winners: [winnerHighOnlyDefault, winnerLowOnly, winnerHighLowSplit],
  options: [optionNoneDefault],
  wildcards: [
    wildcardNoneDefault,
    wildcardLowHole,
    wildcardDeuces,
    wildcardOneEyedJacks,
  ],
};
