/*
  For checking the free trial end date.
  Currently set to indefinite future
*/
export const freeTrialIsOver = () => {
  const curTime = new Date().getTime();
  const endTrialTime = new Date("2900-10-31 23:59:59").getTime();
  const timeDiff = endTrialTime - curTime;
  return timeDiff <= 0;
};
