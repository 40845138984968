/* eslint-disable @typescript-eslint/ban-types */
/**
 * GameTypeList.tsx
 * A prompt for the dealer to choose which game to play next
 */

import classNames from "classnames";
import { Game } from "poker-cows-common";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPremiumGames } from "../../../logic/admin/game/thunks";
import { selectIsRequestPending } from "../../../logic/connection/slice";
import { selectLocalPlayerActiveModalName } from "../../../logic/gameInstance/slice";
import { selectHostFreeTrial } from "../../../logic/table/slice";
import { GAME_TYPE_LIST_HEADER } from "../../../test-identifiers";
import { GameCategory, getGamesByCategory } from "../../../utils/GameInfo";
import { HostFreeTrial } from "../ActionArea/FreeTrial/HostFreeTrial";
import { ModalName } from "../Modal/ModalConfig";
import { useModal } from "../Modal/hooks/useModal";
import { ModalProps } from "../Modal/types/modalTypes";
import GameTypeEntry from "./GameTypeEntry";
import "./GameTypeList.css";

const GAME_CATEGORY_COLORS = {
  [GameCategory.CLASSIC]: "#1a9d27",
  [GameCategory.STUD]: "#018bd9",
  [GameCategory.COMMUNITY]: "#a42aca",
  [GameCategory.OTHER]: "#d6263d",
};

export const GameTypeListContents = (props: ModalProps) => {
  const dispatch = useDispatch();
  const isRulePage = props.name === ModalName.AllGamesRulesModal;
  const { hostFreeTrial } = useSelector(selectHostFreeTrial);

  const categoryColumns = useMemo(() => {
    const premiumEnabled =
      hostFreeTrial && (hostFreeTrial.isPaying || hostFreeTrial.daysLeft > 0);
    const columns: Record<string, JSX.Element[]> = {};
    for (const category of Object.values(GameCategory)) {
      const games = getGamesByCategory(category);
      columns[category] = [
        <div
          className="gameCategoryLabel"
          style={{ backgroundColor: GAME_CATEGORY_COLORS[category] }}
          key={category}
        >
          {category}
        </div>,
        <div className="gameSelectEntryContainer">
          {games.map((game) => (
            <GameTypeEntry
              isRulePage={isRulePage}
              gameType={game[0] as Game}
              id={game[0]}
              key={game[0]}
              premiumEnabled={premiumEnabled}
            />
          ))}
        </div>,
      ];
    }
    return columns;
  }, [isRulePage, hostFreeTrial?.isPaying, hostFreeTrial?.daysLeft]);

  const renderedColumns = useMemo(
    () =>
      Object.entries(categoryColumns).map(([key, value]) => (
        <div className="gameTypeListColumn" key={key}>
          {" "}
          {value}{" "}
        </div>
      )),
    [categoryColumns]
  );

  useEffect(() => {
    dispatch(getAllPremiumGames());
  }, [dispatch]);

  if (isRulePage) {
    return <div className="gameSelectorGames">{renderedColumns}</div>;
  }

  return (
    <>
      <div className="gameTypeTitle" data-testid={GAME_TYPE_LIST_HEADER}>
        PLEASE SELECT A GAME
      </div>
      <div className="gameSelectorGames">{renderedColumns}</div>
    </>
  );
};

export const GameTypeList = () => {
  const { currentModalName, showModal } = useModal();
  const { isRequestPending } = useSelector(selectIsRequestPending);
  const { localPlayerActiveModalName } = useSelector(
    selectLocalPlayerActiveModalName
  );
  const { hostFreeTrial } = useSelector(selectHostFreeTrial);

  useEffect(() => {
    if (!isRequestPending && !currentModalName) {
      showModal({ name: ModalName.GameTypeListModal });
    }
  }, [
    isRequestPending,
    currentModalName,
    localPlayerActiveModalName,
    showModal,
  ]);

  return (
    <div className={classNames({ columnLayout: !hostFreeTrial?.isPaying })}>
      <span className="gameTypeDescription">PLEASE SELECT A GAME ABOVE</span>
      <HostFreeTrial />
    </div>
  );
};

export default GameTypeList;
