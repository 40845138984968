/**
 * HouseRules.tsx
 *
 * This is a central component around HouseRulesModal to give static house rules
 */
import React, { ReactNode } from "react";

import { RulesRow } from "../RulesRow";
import { Tooltip } from "../../../Tooltip/Tooltip";
import { ModalProps } from "../../../Modal/types/modalTypes";

const TextWithTooltip = (props: { title?: ReactNode; body: ReactNode }) => {
  return (
    <>
      {props.title}
      <Tooltip title={props.title} body={props.body} />
    </>
  );
};

export const HouseRules = (props: ModalProps) => {
  return (
    <div className="rulesSection">
      <div className="tableRules">
        <RulesRow
          title="Topic"
          content="House Rule"
          isTitleBold={true}
          className="tableHeader yellowBorder"
        />
        <div className="tableContent">
          <div className="houseRulesTextArea">
            <span className="houseRulesTextAreaTitle">General:</span>
            <ol>
              <li>
                The person who set up the game will be the{" "}
                <span className="keyword">Host</span>,{" "}
                <span className="keyword">Banker</span> and{" "}
                <span className="keyword">First Dealer</span> of the poker
                session.
              </li>
              <li>
                <span className="keyword">Dealer</span> will deal one hand, then
                pass the deck to the player to their left for the next deal.
              </li>
              <li>
                If there is a technical issue, the{" "}
                <span className="keyword">Host</span> may attempt to resolve the
                matter by various methods, including canceling the current hand
                and resetting player balances.{" "}
                <span className="keyword">Host/Banker</span> may alter player
                chip balances if necessary. 
              </li>
            </ol>
          </div>
          <RulesRow
            title={
              <div>
                <div className="flexRowCenter">
                  <TextWithTooltip
                    title="Best Low Hand"
                    body="Worst possible poker hand."
                  />
                </div>
                <div className="flexRowCenter">
                  <TextWithTooltip
                    title="Low Hand Evaluation Methods:"
                    body="Unless the low hand eval method is specified within the game, the default method is “The Wheel.” If your group prefers the 7-5 or 6-4 method, you can select that at table setup."
                  />
                </div>
              </div>
            }
            content={
              <ul className="lowHandSuggestion">
                <li>
                  <div className="flexRowCenter">
                    <TextWithTooltip
                      title="The Wheel 5-4-3-2-A (default)"
                      body="Straights and flushes don’t count in the “low” hand eval, but are recognized for “high” hand eval. Aces can be used both low and high within the hand."
                    />
                  </div>
                </li>
                <li>
                  <div className="flexRowCenter">
                    <TextWithTooltip
                      title="7-5-4-3-2 Unsuited"
                      body="Straights and flushes DO count in the low hand eval. Aces may only be used high within the hand."
                    />
                  </div>
                </li>
                <li>
                  <div className="flexRowCenter">
                    <TextWithTooltip
                      title="6-4-3-2-A Unsuited"
                      body="Straights and flushes DO count in the low hand eval. Aces may be used both high and low within the hand."
                    />
                  </div>
                </li>
              </ul>
            }
          />

          <RulesRow
            title={
              <TextWithTooltip
                title="Best High Hand"
                body="Best possible poker hand."
              />
            }
            content="5 of a kind if using wild cards. Royal flush if no wild cards."
          />
          <RulesRow
            title={
              <TextWithTooltip
                title="In “High-Low Split” games, “High-Low-Both” Decision Method"
                body="How do players pick to go high, low or both ways?"
              />
            }
            content="Prior to revealing cards (showdown), players click a button to simultaneously declare their hand is to be evaluated as “High” or “Low” or “Both”."
          />
          <RulesRow
            title={
              <TextWithTooltip
                title="Going Both Ways Rule"
                body="Declaring both “high” and “low” in a high-low split game"
              />
            }
            content={
              <TextWithTooltip
                title="Tie or Lose either high or low, player loses both ways"
                body="Other players going high or low determine the winner as if the person going both ways never existed. If the only players that stayed in and declared all go both ways, and all have ties within the hand, they split the pot accordingly."
              />
            }
          />
          <RulesRow
            title={
              <TextWithTooltip
                title="Low Hand Qualifier?"
                body="Does the low hand have to meet any conditions?"
              />
            }
            content={
              <TextWithTooltip
                title="None."
                body="There is no qualifier or min low required. Any hand can potentially win the low."
              />
            }
          />
          <RulesRow
            title="Aces used as high and low in creating hands?"
            content={
              <TextWithTooltip
                title="Yes, unless your group is playing with the 7-5 Low Eval Method."
                body="An Ace can be used low as in an A-2-3-4-5 straight, as well as high as in an 10-J-Q-K-A straight."
              />
            }
          />
          <RulesRow
            title="Lowest Pair of Cards"
            content={
              <TextWithTooltip
                title="A pair of deuces (2,2) is the lowest pair."
                body="In The Wheel and 6-4, although an Ace can be used low within the hand, a pair of Aces is always considered a high pair. A pair of deuces (2,2) is the lowest pair."
              />
            }
          />
          <RulesRow
            title="Highest Pair of Cards"
            content="Pair of Aces (A,A)"
          />
          <RulesRow
            title="Wild Card Usage"
            content={
              <div>
                Wild card can be used either:
                <ul>
                  <li>
                    to represent any card not already present in the hand, or
                  </li>
                  <li>to make "five of a kind"</li>
                </ul>
                <br />
                No "multi Ace flush" allowed.
              </div>
            }
          />
          <RulesRow
            title={
              <TextWithTooltip
                title='Lowest Hole Card for "Low Hole Wild Card" Games'
                body="When selected, each player's lowest hole card is wild for them."
              />
            }
            content={
              <TextWithTooltip
                title="Each player's lowest hole card is wild for them. The lowest possible card for wild card purposes is a 2. Although an Ace may be utilized low within the hand (except in 7-5 eval), the deuce (2) is considered the lowest possible hole card for “low hole wild” purposes."
                body="If a player has a 2 (Deuce) and an Ace (A), the 2 is the lowest card for low hole wild card purposes.. The Ace can still be used low in the hand (except in 7-5 eval), but the wildcard will be the 2."
              />
            }
          />
          <RulesRow
            title={
              <TextWithTooltip
                title='Are other cards that match my "Low Hole Wild Card" considered wild also?'
                body="When selected, each player's lowest hole card is wild for them."
              />
            }
            content="Yes, cards within your hand and the community cards that match your low hole wild card value are also wild."
          />
          <RulesRow
            title={
              <TextWithTooltip
                title='Are cards that match the "Center Card Wild Card" considered wild also?'
                body="When selected, the center card of the the community cards is wild for everybody."
              />
            }
            content="Yes, all player cards and community cards that match the center wild card value are also wild."
          />
          <RulesRow
            title={
              <TextWithTooltip
                title='Can I buy my last card up in games with "Low Hole Wild Card" selected?'
                body="Buying the last card up will prevent your wild card from changing."
              />
            }
            content='Yes, all games with "Low Hole Wild Card" selected will give the player an option to buy their last card up at a cost of 2 times the min bet.'
          />
          <RulesRow
            title="Value of Up Wild Card when alone"
            content="Face Value."
          />
          <RulesRow
            title={
              <TextWithTooltip
                title="Wild Card value in hand evaluations"
                body="How is the wild card value chosen within the hand?"
              />
            }
            content={
              <TextWithTooltip
                title="The value that creates the best possible hand will automatically be selected."
                body="For example, Wild-8-7-6-5 beats Q-8-7-6-5 for the high hand, as the wild card would assume the role of a 9 to make a straight. If going for a low hand, the wild card would become an Ace to win the low with 8-7-6-5-A."
              />
            }
          />
          <RulesRow
            title="Can Wild Card values and cards used vary between high and low hands?"
            content="Yes, different cards and wild card values can be utilized for creating high and low hands."
          />
          <RulesRow
            title={
              <TextWithTooltip
                title="Betting and Raising Increments"
                body="What's the min betting and raising increment?"
              />
            }
            content="The min bet is set by the host in the table settings. Raises may be in any increment of the min bet up to the max bet, and do not have to equal or exceed the previous raise amount. (In NL TX Hold'em, the raises must equal or exceed the previous raise and there is no max amount.)"
          />
          <RulesRow
            title="Max # of Raises allowed per round of betting"
            content="3 (In No-Limit Texas Hold'em, there is no limit on raises.)"
          />
          <RulesRow
            title={
              <TextWithTooltip
                title="Auto approval of chip requests?"
                body="Request chip from the bank."
              />
            }
            content={
              <TextWithTooltip
                title="Yes, players may obtain chips simply by requesting them from the bank."
                body="Chips are added to the player's stack, and their bank ledger is adjusted to reflect the purchase of chips."
              />
            }
          />
          <RulesRow
            title={
              <TextWithTooltip
                title="Auto Antes/Blinds?"
                body="All games require antes or blinds."
              />
            }
            content={
              "Yes, ante and blind bets are automatically placed for the players each game."
            }
          />
          <RulesRow
            title="Check then Raise Allowed?"
            content="Yes, players may check and later raise in the same betting round"
          />
          <RulesRow
            title="Extra chip of uneven split pot"
            content="High hand winner gets extra chip. Otherwise, 1st player left of dealer."
          />
        </div>
      </div>
    </div>
  );
};
