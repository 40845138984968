/**
 * modal/slice.ts
 *
 * This adapter is what is used to store, get, show, pop and hide modal(s) and is used in tandem with useModal hook
 */
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { ModalState } from "../logicTypes";
import { ActionlessModalProps } from "../../ui/components/Modal/types/modalTypes";
import { ModalName, ModalEntity } from "../../ui/components/Modal/ModalConfig";

// =============================== //
//              Slice              //
// ================================//
const modalAdapter = createEntityAdapter<ModalState>({
  selectId: (modal) => modal.name,
  sortComparer: (a, b) => a.priority.localeCompare(b.priority),
});
export const slice = createSlice({
  name: "modal",
  initialState: modalAdapter.getInitialState(),
  reducers: {
    show(state, action: { type: string; payload: ActionlessModalProps }) {
      const { name } = action.payload;
      const defaultProps = ModalEntity[name]?.props;
      const value = {
        name,
        priority: (state.ids.length + 1).toString(),
        props: {
          ...(defaultProps ?? {}),
          ...action.payload,
          requiresBackendVerification:
            !!action.payload.requiresBackendVerification,
        },
      };

      modalAdapter.removeOne(state, name);
      modalAdapter.addOne(state, value);
    },
    pop(state) {
      const currentModalName = state.ids[state.ids.length - 1];

      modalAdapter.removeOne(state, currentModalName);
    },
    popUntil(state, action: { type: string; payload: string }) {
      const lastNonCloseableModalIndex = state.ids.findIndex(
        (name) => name === action.payload
      );

      modalAdapter.removeMany(
        state,
        state.ids.slice(lastNonCloseableModalIndex + 1)
      );
    },
    closeAll(state) {
      modalAdapter.removeAll(state);
    },
  },
});

// =============================== //
//            Actions              //
// ================================//
export const { show, pop, popUntil, closeAll } = slice.actions;

// =============================== //
//            Reducers             //
// ================================//
export default slice.reducer;

// =============================== //
//            Selectors            //
// ================================//
const modalSelectors = modalAdapter.getSelectors<RootState>(
  (state) => state.modal
);
export const selectModals = (state: RootState) => ({
  modals: modalSelectors.selectAll(state),
});
export const selectPreviousModalName = (state: RootState) => {
  const modalNames = modalSelectors.selectIds(state);
  if (modalNames.length > 1) {
    return {
      previousModalName: modalNames[modalNames.length - 2] as ModalName,
    };
  }
  return {
    previousModalName: null,
  };
};
export const selectCurrentModalName = (state: RootState) => {
  const modalNames = modalSelectors.selectIds(state);
  if (modalNames.length > 0) {
    return {
      currentModalName: modalNames[modalNames.length - 1] as ModalName,
    };
  }
  return {
    currentModalName: null,
  };
};
