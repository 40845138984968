/**
 * Phone.tsx
 * This component used to add/update user phone number and open from `MyAccount` page.
 */
import React, { useState } from "react";
import styles from "./Phone.module.css";
import Button from "../../components/Button";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { updateLocalPlayer } from "../../../logic/localPlayer/thunks";
import { ModalProps } from "../../components/Modal/types/modalTypes";
import { selectLocalPlayerPhone } from "../../../logic/localPlayer/slice";

export const Phone = (props: ModalProps) => {
  const dispatch = useDispatch();
  const { localPlayerPhone } = useSelector(selectLocalPlayerPhone);
  const [phone, setPhone] = useState(localPlayerPhone ?? undefined);  
  const addOrUpdateText = localPlayerPhone ? "Update" : "Add";
  const isValidLength = phone?.match(/\d/g)?.join("").length === 10; // check 10 digit length exist of number
  const isTouched = localPlayerPhone !== phone;
  const isValidForm = isTouched && isValidLength;
  const onSubmitForm = () => {
    if (isValidForm) {
      dispatch(updateLocalPlayer({ phone }));
      props.onClose();
    }
    return false;
  };

  const onChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPhone(value);
  };

  return (
    <form className={styles.form} onSubmit={onSubmitForm}>
      <div className={styles.phoneContent}>
        <div className={styles.content}>
          <div className={styles.groupBoxPhoneNumber}>
            <span className={styles.countryCode}>+1</span>
            <InputMask
              value={phone}
              mask="(999) 999 9999"
              maskPlaceholder="_"
              alwaysShowMask
              className={styles.inputPhoneNumber}
              onChange={onChangePhoneNumber}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button disabled={!isValidForm} type="submit" variant="green">
            {addOrUpdateText}
          </Button>
        </div>
      </div>
    </form>
  );
};
