/**
 * BankingLedger.tsx
 *
 * This is the central component BankingLedgerSubpageModal which gives you the bank information of a certain player
 */
import React from "react";
import { useSelector } from "react-redux";

import { BankingTransactionTypes } from "poker-cows-common";
import { TableLedgerRow } from "../../../Table/TableLedgerRow";
import { formatMoneyString } from "../../../../../utils/MoneyFormat";
import { selectPlayerById } from "../../../../../logic/player/slice";
import { ModalProps } from "../../../Modal/types/modalTypes";

const makeDateString = (timestamp: number) => {
  const date = new Date(timestamp);
  const string = date.toISOString().substr(0, 19).replace("T", " ");
  return string;
};

export const BankingLedgerSubpage = (props: ModalProps) => {
  const { player } = useSelector(
    selectPlayerById(props?.additionalProps?.selectedPlayerId)
  );
  if (!player) {
    return <></>;
  }

  // remove win or loss transactions from ledger, showing net balance is sufficient according to client
  const filteredHistory = player?.bankingHistory?.filter(
    (entry) => entry.description !== "banking_type_win_or_loss"
  );

  return (
    <>
      <div className="section tableLedgerContainer">
        <div className="tableContainer">
          <div className="table">
            <TableLedgerRow
              isHeader={true}
              leftContent="Date/Time"
              centerContent="Amount"
              rightContent="Description"
            />
            {filteredHistory?.map((entry, i) => {
              const dateString = makeDateString(entry.datetime);
              return (
                <TableLedgerRow
                  key={i}
                  isHeader={false}
                  leftContent={dateString}
                  centerContent={formatMoneyString(entry.amount)}
                  rightContent={
                    BankingTransactionTypes[
                      entry.description as keyof typeof BankingTransactionTypes
                    ]
                  }
                />
              );
            })}
          </div>
        </div>
        <div className="playerBankRow">
          <div className="playerBankIndicator totalBuyInsIndicator">
            <div className="playerBankIndicatorTitle">Total Buy Ins</div>
            <div className="playerBankIndicatorContent">
              {player?.deposit > 0
                ? `-` + formatMoneyString(player?.deposit)
                : formatMoneyString(player?.deposit)}
            </div>
          </div>
          <div className="playerBankIndicator currentChipCountIndicator">
            <div className="playerBankIndicatorTitle">Current Chip Count</div>
            <div className="playerBankIndicatorContent">
              {formatMoneyString(player?.bank)}
            </div>
          </div>
          <div className="playerBankIndicator netPlayerBalanceIndicator">
            <div className="playerBankIndicatorTitle">Net Player Balance</div>
            <div className="playerBankIndicatorContent">
              {formatMoneyString(player?.bank - player?.deposit)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
