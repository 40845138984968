/**
 * MyAccountModal.tsx
 *
 * This is just a wrapper around the MyAccount
 */
import React from "react";

import { MyAccount } from "../../Settings/HostControls/MyAccount";

import { ModalProps } from "../types/modalTypes";

export const MyAccountModal = (props: ModalProps): JSX.Element => {
  return <MyAccount {...props} />;
};
