/**
 * HandRankings.tsx
 *
 * This is a component responsible for rendering and styling a "Hand Rank" within the "Rules" dialog.
 * It's rendered to explain winning hands, how they're determined, what they're called, and how they rank.
 */
import React from "react";
import classNames from "classnames";

import { handRanks } from "./handRanks";
import { CardElement } from "../../../Elements/CardElement/CardElement";
import { ModalProps } from "../../../Modal/types/modalTypes";

import styles from "./HandRankings.module.css";

export const HandRankings = (props: ModalProps): JSX.Element => {
  return (
    <ol className={styles.container}>
      {handRanks.map((hand) => {
        return (
          <li className={styles.hand} key={hand.title}>
            <section className={styles.handCards}>
              {hand.cards.map((card, index) => {
                // Index usually not ideal for keys, but we're not re-sorting or ordering the items.
                const key = `${hand.title}_${index}`;

                if (card.isWild) {
                  return (
                    <CardElement
                      isFaceUp
                      className={classNames(styles.handRankCard, styles.wild)}
                      key={key}
                    />
                  );
                }

                return (
                  <CardElement
                    isFaceUp
                    rank={card.rank}
                    suit={card.suit}
                    className={classNames(styles.handRankCard, {
                      [styles.translucent]: card.isTranslucent,
                    })}
                    key={key}
                  />
                );
              })}
            </section>

            <section>
              <h3 className={styles.handTitle}>
                {/** @example 1) FIVE OF A KIND */}
                {`${hand.identifier})`} {hand.title}
              </h3>
              <p className={styles.handSummary}>{hand.summary}</p>
            </section>
          </li>
        );
      })}
    </ol>
  );
};
