/**
 * DownIcon.tsx
 *
 * The icon is used for dropdown arrow in ListboxButton
 */
import React from "react";

export const DownIcon = ({
  width = 14,
  height = 7,
  color = "white",
  className = "",
}: {
  width?: number;
  height?: number;
  color?: string;
  className: string;
}) => {
  return (
    <span className={className}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={width} height={height} rx="4" fill="#272D2D" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.88917 7.55292C5.18671 7.26003 5.66913 7.26003 5.96667 7.55292L9.99935 11.5226L14.032 7.55292C14.3296 7.26003 14.812 7.26003 15.1095 7.55292C15.4071 7.84582 15.4071 8.32069 15.1095 8.61358L10.5381 13.1136C10.2406 13.4065 9.75814 13.4065 9.4606 13.1136L4.88917 8.61358C4.59163 8.32069 4.59163 7.84582 4.88917 7.55292Z"
          fill="white"
        />
        <rect width={width} height={height} rx="4" stroke="#D5D5D7" />
      </svg>
    </span>
  );
};
