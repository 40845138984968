import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./DeclareArea.css";
import { useSeatContext } from "../Seat/SeatContext";
import { useCurrentPlayer } from "../../../logic/gameInstance/hooks";
import {
  selectCheckCallInTurn,
  selectTargetBetRound,
  setCheckCallInTurn,
  setFoldInTurn,
  setTargetBetRound,
} from "../../../logic/betting/slice";
import "./WaitingArea.css";
import "./ActionArea.css";
import "./BetArea/ChipBetArea.css";
import {
  selectPlayerAtTablePos,
  selectAreAnyPlayersBanking,
} from "../../../logic/player/slice";
import {
  selectGameUUID,
  selectBetRoundID,
  selectIsBetNoLimit,
  selectHasPlayerFoldedAtTablePos,
} from "../../../logic/gameInstance/slice";
import {
  EXPERIMENTAL_FEATURES,
  useExperimentalFeature,
} from "../Feature/FeatureContext";
import { RaisesCounter } from "./Components/RaisesCounter";
import {
  selectIsNoLimitFullRound,
  selectTableStatus,
} from "../../../logic/table/slice";
import {
  selectAnyDealerPrompt,
  selectAnyDeclareInOutPrompt,
  selectAnyLowChipPrompt,
} from "../../../logic/prompts/slice";
import Button from "../Button";
import { WAITING_TEXT } from "../../../test-identifiers";
import { PlayerStatus } from "poker-cows-common";

export const WaitingArea = () => {
  const dispatch = useDispatch();

  const checkCallInTurnEnabled = useExperimentalFeature(
    EXPERIMENTAL_FEATURES.CHECK_CALL_IN_TURN
  ).isEnabled;

  const { tablePosition } = useSeatContext();
  const { currentPlayer } = useCurrentPlayer(tablePosition);
  const { hasPlayerFolded } = useSelector(
    selectHasPlayerFoldedAtTablePos(tablePosition)
  );
  const { player } = useSelector(selectPlayerAtTablePos(tablePosition));

  const { tableStatus } = useSelector(selectTableStatus);
  const { checkCallInTurn } = useSelector(selectCheckCallInTurn);
  const { targetBetRound } = useSelector(selectTargetBetRound);
  const { betRoundID } = useSelector(selectBetRoundID);
  const { gameUUID } = useSelector(selectGameUUID);
  const isCurrentGame = !!gameUUID;
  const { isBetNoLimit } = useSelector(selectIsBetNoLimit);
  const { isNoLimitFullRound } = useSelector(selectIsNoLimitFullRound);
  const { anyDealerPrompt } = useSelector(selectAnyDealerPrompt);
  const { anyDeclareInOutPrompt } = useSelector(selectAnyDeclareInOutPrompt);
  const { anyLowChipPrompt } = useSelector(selectAnyLowChipPrompt);
  const { areAnyPlayersBanking } = useSelector(selectAreAnyPlayersBanking);

  const checkCallClick = () => {
    dispatch(setTargetBetRound(betRoundID));
    dispatch(setCheckCallInTurn(!checkCallInTurn));
  };

  useEffect(() => {
    // If bet round changes and does not match target, reset "in turn" action status
    if (targetBetRound !== betRoundID) {
      dispatch(setCheckCallInTurn(false));
      dispatch(setFoldInTurn(false));
      return;
    }
  }, [betRoundID, dispatch, targetBetRound]);

  const waitingForPlayer = currentPlayer?.name
    ? "Waiting for " + currentPlayer.name
    : "";

  if (player?.status === PlayerStatus.WAITING && player?.bank === 0) {
    return (
      <div data-testid={WAITING_TEXT}>
        You are out of money. You can request chips once dealer orbit is
        complete.
      </div>
    );
  }

  if (player?.status === PlayerStatus.WAITING && player?.bank !== 0) {
    return (
      <div data-testid={WAITING_TEXT}>
        Waiting for the dealer orbit to finish before joining game.
      </div>
    );
  }

  if (!!anyLowChipPrompt) {
    return (
      <div className="waitingText" data-testid={WAITING_TEXT}>
        Waiting for players to complete buying chips.
      </div>
    );
  }

  return (
    <div
      className={
        !hasPlayerFolded && checkCallInTurnEnabled
          ? "waitingArea"
          : "waitingArea waitingCentered"
      }
    >
      {areAnyPlayersBanking && isNoLimitFullRound && (
        <div className="waitingText">
          Waiting for Players to complete buying chips
        </div>
      )}
      {!areAnyPlayersBanking && anyDealerPrompt && isNoLimitFullRound && (
        <div className="waitingText">Waiting for Dealer to deal the cards</div>
      )}
      {anyDeclareInOutPrompt && (
        <div className="waitingText">
          Waiting for Players to declare in or out
        </div>
      )}
      {!hasPlayerFolded && checkCallInTurnEnabled && (
        <Button
          className={
            checkCallInTurn
              ? "selected actionButton checkCallInTurnButton"
              : "yellow actionButton checkCallInTurnButton"
          }
          onClick={checkCallClick}
        >
          {checkCallInTurn
            ? "Cancel\nCheck/Call\nin Turn"
            : "Check/Call\nin Turn"}
        </Button>
      )}
      {tableStatus === "IN_LOBBY" && (
        <>
          <div className="actionAreaCallout">
            WAITING FOR THE HOST TO START THE GAME
          </div>
        </>
      )}
      {tableStatus !== "IN_LOBBY" &&
        isCurrentGame &&
        !areAnyPlayersBanking &&
        isBetNoLimit && (
          <div data-testid={WAITING_TEXT} className="waitingText">
            Waiting for {currentPlayer?.name}
          </div>
        )}
      {tableStatus !== "IN_LOBBY" &&
        isCurrentGame &&
        !isBetNoLimit &&
        !isNoLimitFullRound &&
        !anyDeclareInOutPrompt && (
          <div className="waitingTextRaises">
            <RaisesCounter />
            {/* empty div for layout */}
            <div style={{ clear: "both" }}></div>
            <span data-testid={WAITING_TEXT}>{waitingForPlayer}</span>
          </div>
        )}
      {/* empty div for correct text-centering layout */}
      <div></div>
    </div>
  );
};
