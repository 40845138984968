/**
 * Email.tsx
 * This component used to add/update user email number and open from `MyAccount` page.
 */
import React, { useState, useEffect } from "react";
import styles from "./Email.module.css";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { checkEmail, updateEmail } from "../../../logic/localPlayer/thunks";
import { ModalProps } from "../../components/Modal/types/modalTypes";
import {
  resetEmailField,
  selectIsLocalPlayerDetailFetching,
  selectIsEmailInUse,
  selectLocalPlayerEmail,
} from "../../../logic/localPlayer/slice";
import Loader from "../../components/Loader/Loader";
import classNames from "classnames";

const EmailRegex = /^\S+@\S+\.\S+$/;
export const Email = (props: ModalProps) => {
  const dispatch = useDispatch();
  const { localPlayerEmail } = useSelector(selectLocalPlayerEmail);
  const { isFetching } = useSelector(selectIsLocalPlayerDetailFetching);
  const { isEmailInUse } = useSelector(selectIsEmailInUse);
  const [email, setEmail] = useState(localPlayerEmail ?? undefined);
  const addOrUpdateText = localPlayerEmail ? "Update" : "Add";
  const isValid = EmailRegex.test(email);
  const isTouched = localPlayerEmail !== email;
  const isValidForm = isTouched && isValid && !isEmailInUse && !isFetching;
  const onUpdateEmail = () => {
    if (isValidForm) {
      dispatch(updateEmail(email));
      props.onClose();
    }
    return false;
  };

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);

    if (EmailRegex.test(value)) {
      dispatch(checkEmail(value));
    }
  };

  useEffect(() => {
    dispatch(resetEmailField());
  }, [dispatch, resetEmailField]);

  return (
    <form className={styles.form} onSubmit={onSubmitForm}>
      <div className={styles.emailContent}>
        <div className={styles.content}>
          <div className={styles.groupBoxEmailNumber}>
            <input
              value={email}
              className={styles.inputEmail}
              onChange={onChangeEmail}
            />
            <div className={styles.loader}>{isFetching && <Loader />}</div>
          </div>
          {isEmailInUse && (
            <div className={classNames("alert danger", styles.emailExistText)}>
              This email is already used.
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <Button
            disabled={!isValidForm}
            type="button"
            variant="green"
            onClick={onUpdateEmail}
          >
            {addOrUpdateText}
          </Button>
        </div>
      </div>
    </form>
  );
};
