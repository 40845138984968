/**
 * Bot.ts
 * Bots details i.e bot name, email etc..
 */

export const Bots = [
  {
    name: "Bot-1",
    email: "bot-1@pokercows.com",
  },
  {
    name: "Bot-2",
    email: "bot-2@pokercows.com",
  },
  {
    name: "Bot-3",
    email: "bot-3@pokercows.com",
  },
  {
    name: "Bot-4",
    email: "bot-4@pokercows.com",
  },
];
