/** ChipDenomSetupContent.tsx
 *
 * This component displays 3 dropdown select inputs for setting the value of each chip
 */
import React from "react";

import { Chip } from "../../Chip";
import { ChipDropdown } from "../../Dropdown/ChipDropdown";
import { PrefInterface, filteredDenominations } from "poker-cows-common";

import "../../Chip.css";

interface ChipDenomSetupProps {
  startupPrefs: Partial<PrefInterface>;
  onUpdateInitialSettingsByPref: (
    prefsName: string,
    prefsValue: string
  ) => void;
}

export const ChipDenomSetupContent = (props: ChipDenomSetupProps) => {
  return (
    <div className="chipDenomSetupContainer">
      <div className="minBet">
        <ChipDropdown
          chip={<Chip denom={"low"} value={props.startupPrefs.chipDenomLow ?? 0} />}
          defaultLabel={" "}
          value={props.startupPrefs.chipDenomLow ?? ""}
          setValue={(newValue) =>
            props.onUpdateInitialSettingsByPref("chipDenomLow", newValue)
          }
          options={filteredDenominations}
          wrapperClass="dropdownWrapper"
          popoverClass="dropdownPopover"
        />
      </div>

      <div className="minBet">
        
        <ChipDropdown
          chip={<Chip denom={"mid"} value={props.startupPrefs.chipDenomMid ?? 0} />}
          defaultLabel={" "}
          value={props.startupPrefs.chipDenomMid ?? ""}
          setValue={(newValue) =>
            props.onUpdateInitialSettingsByPref("chipDenomMid", newValue)
          }
          options={filteredDenominations}
          wrapperClass="dropdownWrapper"
          popoverClass="dropdownPopover"
        />
      </div>

      <div className="minBet">
        <ChipDropdown
          chip={<Chip denom={"high"} value={props.startupPrefs.chipDenomHigh ?? 0} />}
          defaultLabel={" "}
          value={props.startupPrefs.chipDenomHigh ?? ""}
          setValue={(newValue) =>
            props.onUpdateInitialSettingsByPref("chipDenomHigh", newValue)
          }
          options={filteredDenominations}
          wrapperClass="dropdownWrapper"
          popoverClass="dropdownPopover"
        />
      </div>
    </div>
  );
};
