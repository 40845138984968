import React from "react";
import { useSeatContext } from "./SeatContext";
import { useSelector } from "react-redux";
import { selectPlayerIdAtTablePos } from "../../../logic/player/slice";
import { selectRemainingPassesForPlayerById } from "../../../logic/gameInstance/slice";

export const PassesIndicator = () => {
  const { tablePosition } = useSeatContext();
  const { playerId } = useSelector(selectPlayerIdAtTablePos(tablePosition));
  const { remainingPasses } = useSelector(
    selectRemainingPassesForPlayerById(playerId)
  );

  function getPassDot(index: number) {
    const classes = ["passDot"];
    if (index + 1 > remainingPasses) {
      classes.push("passDotFilled");
    }
    return <div className={classes.join(" ")} key={index} />;
  }

  function getPassDots() {
    const dots = [];
    for (let i = 0; i < 3; i++) {
      dots.push(getPassDot(i));
    }

    return dots;
  }

  return <div className="passesIndicator">{getPassDots()}</div>;
};
