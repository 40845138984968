import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { BetLimit, HighLowSetting } from "poker-cows-common";
import {
  selectCurrentPlayer,
  selectPotTotalBetPerPlayer,
  selectPrefs,
  selectAllWinners,
  selectGameWinners,
  selectHighWinners,
  selectLowWinners,
  selectDeclarationForPlayerById,
  selectPotTotalAmount,
} from "./slice";
import {
  selectPlayerHandAtTablePos,
  selectPlayerIdAtTablePos,
  selectPlayersInvolvedInLedger,
} from "../player/slice";
import { selectAnyDelayPrompt } from "../prompts/slice";
import { selectNextPrefs } from "../table/slice";

export const useWinners = ({ tablePosition }: { tablePosition: number }) => {
  const { playerHand } = useSelector(selectPlayerHandAtTablePos(tablePosition));
  const { playerId } = useSelector(selectPlayerIdAtTablePos(tablePosition));
  const { prefs } = useSelector(selectPrefs);
  const { playerDeclaration } = useSelector(
    selectDeclarationForPlayerById(playerId)
  );

  const { potTotalBetPerPlayer } = useSelector(selectPotTotalBetPerPlayer);

  const { anyDelayPrompt } = useSelector(selectAnyDelayPrompt);

  const { gameWinners } = useSelector(selectGameWinners);
  const { highHandWinners } = useSelector(selectHighWinners);
  const { lowHandWinners } = useSelector(selectLowWinners);

  const isHighDeclaration = playerDeclaration === HighLowSetting.HIGH;
  const isLowDeclaration = playerDeclaration === HighLowSetting.LOW;

  const isGameHighWinnerOnly = prefs?.highLowSetting === HighLowSetting.HIGH;
  const isGameLowWinnerOnly = prefs?.highLowSetting === HighLowSetting.LOW;
  const isGameHighLowWinner = prefs?.highLowSetting === HighLowSetting.HI_LO;

  const hasHighWinner =
    isHighDeclaration || isGameHighWinnerOnly || isGameHighLowWinner;
  const hasLowWinner =
    isLowDeclaration || isGameLowWinnerOnly || isGameHighLowWinner;

  const isGameWinner =
    gameWinners.some((gameWinnerID) => gameWinnerID === playerId) &&
    !!anyDelayPrompt;

  const isHighWinner =
    highHandWinners.some((highWinnerID) => highWinnerID === playerId) &&
    hasHighWinner &&
    !!anyDelayPrompt;

  const isLowWinner =
    lowHandWinners.some((lowWinnerID) => lowWinnerID === playerId) &&
    hasLowWinner &&
    !!anyDelayPrompt;

  const isBothWinner = isHighWinner && isLowWinner;
  const hasWinner =
    gameWinners.length > 0 ||
    lowHandWinners.length > 0 ||
    highHandWinners.length > 0;

  return {
    playerHand,

    hasWinner,
    isBothWinner,

    gameWinners,
    isGameWinner,

    highWinners: highHandWinners,
    isHighWinner,

    lowWinners: lowHandWinners,
    isLowWinner,

    isWinner: isGameWinner || isBothWinner || isHighWinner || isLowWinner,

    playerLostBy: potTotalBetPerPlayer?.[playerId],
  };
};

export const useCurrentPlayer = (tablePosition: number) => {
  const { currentPlayer } = useSelector(selectCurrentPlayer);
  const isCurrentPlayer = useMemo(
    () => currentPlayer && tablePosition === currentPlayer.seatNumber,
    [currentPlayer, tablePosition]
  );

  return { isCurrentPlayer, currentPlayer };
};

export const useNetBalance = () => {
  const { playersInvolvedInLedger } = useSelector(
    selectPlayersInvolvedInLedger
  );
  const { potTotalBetPerPlayer } = useSelector(selectPotTotalBetPerPlayer);
  const { winners } = useSelector(selectAllWinners);

  const { nextPrefs } = useSelector(selectNextPrefs);
  const isNoLimit = nextPrefs?.limitType === BetLimit.NO_LIMIT;

  let totalDeposits = 0;
  let totalPlayerBanks = 0;

  playersInvolvedInLedger.forEach((player) => {
    // deposits = buy-ins and request chips transactions
    totalDeposits += player.deposit;
    totalPlayerBanks += player.bank;

    const areBets = potTotalBetPerPlayer && potTotalBetPerPlayer[player.id];

    // we don't clear the pot at the end of no limit games,
    // so if there are bets, and it's not a no limit game,
    // or if there are bets, and it's no limit but not at end game (no winners yet),
    // then add bets to net balance calculation
    if (areBets && ((isNoLimit && winners.length === 0) || !isNoLimit)) {
      totalPlayerBanks += potTotalBetPerPlayer[player.id];
    }
  });

  const netBalance = totalDeposits - totalPlayerBanks;
  const offBalance = netBalance !== 0;

  useEffect(() => {
    if (offBalance) {
      console.error("Ledger net balance !== 0");
    }
  }, [offBalance]);

  return { netBalance, offBalance };
};
