/**
 * This component renders the "tab content" for the billing tab of the account page.
 */

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { getAccountInfo } from "../../../logic/payment/api";
import Loading from "../../components/Loading";
import styles from "./AccountPageBillingTab.module.css";

interface AccountInfoInterface {
  portalUrl?: string;
  subscription?: {
    name: string;
    expiration: number;
    billingActive: boolean;
    cancelAt?: number;
  };
  history?: {
    lastCharge: number;
  };
}

export const useAccountInfo = () => {
  const [accountInfo, setAccountInfo] = useState<AccountInfoInterface | null>(
    null
  );

  useEffect(() => {
    getAccountInfo().then((accountInfo) => {
      setAccountInfo(accountInfo);
    });
  }, []);

  return {
    accountInfo,
  };
};

const formatTimestamp = (timestamp: number) =>
  new Date(timestamp).toLocaleString("default", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

const PlanSection = (props: { accountInfo: AccountInfoInterface }) => {
  const { accountInfo } = props;
  const hasSubscription = Boolean(accountInfo.subscription);

  const goToPortal = () => (window.location.href = accountInfo.portalUrl || "");
  const goToPaymentPlan = () => (window.location.href = "/payment-plan");

  const subscriptionInfo = (
    <>
      <h6>{accountInfo.subscription?.name} Plan</h6>
      <span>
        {accountInfo.subscription?.cancelAt
          ? `Cancels on ${formatTimestamp(accountInfo.subscription?.cancelAt)}`
          : `Renews on ${formatTimestamp(
              accountInfo.subscription?.expiration || 0
            )}`}
      </span>
    </>
  );
  const noSubscription = (
    <>
      <h6>Basic Host Plan</h6>
      <span>No Subscription</span>
    </>
  );

  return (
    <li>
      <div className={styles.labelAndValue}>
        {hasSubscription ? subscriptionInfo : noSubscription}
      </div>

      <div className={styles.multiButtonContainer}>
        {hasSubscription && (
          <Button
            variant="base"
            onClick={goToPortal}
            className={styles.smallButton}
          >
            {accountInfo.subscription?.cancelAt ? "Renew" : "Cancel"}
            <br />
            Subscription
          </Button>
        )}

        <Button variant="green" onClick={goToPaymentPlan}>
          {hasSubscription ? "Change Subscription" : "Add Subscription"}
        </Button>
      </div>
    </li>
  );
};

const BillingSection = (props: { accountInfo: AccountInfoInterface }) => {
  const { accountInfo } = props;
  const hasSubscription = Boolean(accountInfo.subscription);
  const hasBillingError = !Boolean(accountInfo.subscription?.billingActive);

  const goToPortal = () => (window.location.href = accountInfo.portalUrl || "");

  const billingInfo = hasBillingError ? (
    <span className={styles.errorText}>
      <FontAwesomeIcon icon={faExclamationCircle as IconProp} /> Error: Please
      update billing info
    </span>
  ) : (
    "Up to date"
  );
  const noBilling = "No billing info on file";

  return (
    <li>
      <div className={styles.labelAndValue}>
        <h6>Billing Info</h6>
        <span>{hasSubscription ? billingInfo : noBilling}</span>
      </div>
      {hasSubscription && (
        <Button
          variant={hasBillingError ? "red" : "green"}
          onClick={goToPortal}
        >
          Update Billing
        </Button>
      )}
    </li>
  );
};

const HistorySection = (props: { accountInfo: AccountInfoInterface }) => {
  const { accountInfo } = props;
  const hasHistory = Boolean(accountInfo.history);

  const goToPortal = () => (window.location.href = accountInfo.portalUrl || "");

  return (
    <li>
      <div className={styles.labelAndValue}>
        <h6>Payment History</h6>
        {hasHistory ? (
          <span>
            Last Charge on{" "}
            {formatTimestamp(accountInfo.history?.lastCharge || 0)}
          </span>
        ) : (
          <span>No payment history</span>
        )}
      </div>

      {hasHistory && (
        <Button variant="green" onClick={goToPortal}>
          View History
        </Button>
      )}
    </li>
  );
};

export const AccountPageBillingTab = () => {
  const { accountInfo } = useAccountInfo();

  if (!accountInfo) {
    return <Loading />;
  }

  return (
    <section className={styles.tabContent}>
      <ul>
        <PlanSection accountInfo={accountInfo} />
        <BillingSection accountInfo={accountInfo} />
        <HistorySection accountInfo={accountInfo} />
      </ul>
    </section>
  );
};
