/*
 * TurnIndicator.tsx
 * This file renders the Turn indicator component which is ultimately rendered in Seat.tsx.
 * When TurnIndicator is rendered it plays a sound to alert the localPlayer it is their turn. The sound
 * plays on loop when appropriate. The color of the indicator is determined by the inclusion of filterWhite
 * in the css class; it will be included if the isMultiplayerIndicator prop passed in Seat.tsx is true.
 */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";

import { selectIsGamePaused } from "../../../logic/table/slice";
import { selectIsLocalPlayerCurrentPlayer } from "../../../logic/gameInstance/slice";
import { TURN_INDICATOR_ARROW } from "../../../test-identifiers";
import { selectAnyDealPrompt } from "../../../logic/prompts/slice";
import { playSound } from "../../../utils/Sound";
import { Sound } from "poker-cows-common";

const REPEAT_SECONDS_DELAY = 15;

interface TurnIndicatorProps {
  isMultiplayerIndicator: boolean;
}

export const TurnIndicator = (props: TurnIndicatorProps) => {
  const { isLocalPlayerCurrentPlayer } = useSelector(
    selectIsLocalPlayerCurrentPlayer
  );
  const { anyDealPrompt } = useSelector(selectAnyDealPrompt);
  const { isGamePaused } = useSelector(selectIsGamePaused);
  useEffect(() => {
    if (!isLocalPlayerCurrentPlayer || !!anyDealPrompt || isGamePaused) {
      return;
    }

    playSound(Sound.YOUR_TURN);

    const repeat = setInterval(
      () => playSound(Sound.YOUR_TURN),
      REPEAT_SECONDS_DELAY * 1000
    );

    return () => {
      clearInterval(repeat);
    };
  }, [anyDealPrompt, isLocalPlayerCurrentPlayer, isGamePaused]);

  if (!!anyDealPrompt) {
    return null;
  }

  const turnIndicatorClassName = classNames("turnIndicator", {
    filterWhite: props.isMultiplayerIndicator,
  });

  return (
    <div className="turnIndicatorContainer" data-testid={TURN_INDICATOR_ARROW}>
      <div className={turnIndicatorClassName} />
    </div>
  );
};
