/**
 * InviteFriendsModal.tsx
 *
 * This is just a wrapper around the InviteFriends
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { InviteFriends } from "../../ActionArea/Lobby";
import { selectAdmittedPlayers } from "../../../../logic/player/slice";
import {
  selectAnyBetPrompt,
  selectAnyDealPrompt,
  selectChooseGamePrompt,
  selectStartNormalGamePlayPrompt,
  submitStartNormalGamePlay,
} from "../../../../logic/prompts/slice";
import { ModalProps } from "../types/modalTypes";
import { useModal } from "../hooks/useModal";
import { ModalName } from "../ModalConfig";
import useDevice from "../../../../hooks/useDevice";
import { selectIsBotTable } from "../../../../logic/table/slice";

export const InviteFriendsModal = ({
  onClose,
  ...props
}: ModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const { isDesktop, isMobile } = useDevice();
  const [isPlayerAdmitted, setIsPlayerAdmitted] = useState(false);

  const { anyBetPrompt } = useSelector(selectAnyBetPrompt);
  const { anyDealPrompt } = useSelector(selectAnyDealPrompt);
  const { admittedPlayers } = useSelector(selectAdmittedPlayers);
  const { chooseGamePrompt } = useSelector(selectChooseGamePrompt);
  const { isBotTable } = useSelector(selectIsBotTable);
  const { startNormalGamePlayPrompt } = useSelector(
    selectStartNormalGamePlayPrompt
  );

  useEffect(() => {
    if (!isPlayerAdmitted && admittedPlayers && admittedPlayers.length >= 1) {
      setIsPlayerAdmitted(true);
    }
  }, [isPlayerAdmitted, admittedPlayers]);

  useEffect(() => {
    const isBotOnMobile = isMobile && isBotTable;

    // if a player has been admitted, move to the next screen.
    if (
      (isDesktop && isPlayerAdmitted) ||
      (isBotOnMobile && isPlayerAdmitted)
    ) {
      dispatch(submitStartNormalGamePlay(false /* isPracticeMode */));
    }
  }, [
    isDesktop,
    isMobile,
    isPlayerAdmitted,
    startNormalGamePlayPrompt,
    dispatch,
  ]);

  useEffect(() => {
    if (chooseGamePrompt) {
      showModal({ name: ModalName.GameTypeListModal });
    }
  }, [chooseGamePrompt, showModal]);

  useEffect(() => {
    if (anyDealPrompt || anyBetPrompt) {
      onClose();
    }
  }, [anyDealPrompt, anyBetPrompt, onClose]);

  return <InviteFriends onClose={onClose} {...props} />;
};
