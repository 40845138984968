/**
 * This is the fold button, displayed to the left of the user seat/video
 */

import classNames from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFixedLimitAmount,
  selectIsBetSpreadLimit,
  selectPrefs,
} from "../../../../logic/gameInstance/slice";
import { selectPlayerIdAtTablePos } from "../../../../logic/player/slice";
import { resetHighlightByPlayerId } from "../../../../logic/positionHighlight/slice";
import { BettingProps, useBetting } from "../BetArea/hooks/Betting";
import { useCardSelection } from "../CardSelection/useCardSelection";
import styles from "./FoldButton.module.css";
import Button from "../../Button";
import { FOLD_CARDS_BUTTON } from "../../../../test-identifiers";

interface FoldButtonProps {
  className?: string;

  /** @default false */
  isSelectingCard?: boolean;

  tablePosition: number;
}

export const FoldButton = ({
  className,
  isSelectingCard = false,
  tablePosition,
}: FoldButtonProps) => {
  const dispatch = useDispatch();
  const { prefs } = useSelector(selectPrefs);
  const { isBetSpreadLimit } = useSelector(selectIsBetSpreadLimit);
  const { fixedLimitAmount } = useSelector(selectFixedLimitAmount);
  const { playerId } = useSelector(selectPlayerIdAtTablePos(tablePosition));

  let bettingProps = {
    minRaise: fixedLimitAmount,
    maxRaise: fixedLimitAmount,
  } as BettingProps;

  if (prefs && isBetSpreadLimit) {
    bettingProps = {
      minRaise: prefs?.spreadMinRaise,
      maxRaise: prefs?.spreadMaxRaise,
    };
  }
  const betting = useBetting(bettingProps);
  const selecting = useCardSelection();

  return (
    <>
      <Button
        data-testid={FOLD_CARDS_BUTTON}
        className={classNames(
          className,
          styles.foldButton,
          "actionButton",
          "red",
          "foldButton"
        )}
        onClick={() => {
          dispatch(resetHighlightByPlayerId(playerId));
          // TODO: We should consolidate `selecting.fold` and `betting.fold` into one function
          return isSelectingCard ? selecting.fold() : betting.fold();
        }}
        type="button"
      >
        FOLD
      </Button>
    </>
  );
};
