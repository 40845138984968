/** LowMethodSetup.tsx
 *
 * This is a rulesRow component used in Master Startup Modal
 * It displays low-hand options with tooltip i.e. WHEEL, 6-4 and 7-5
 */
import React from "react";
import { LowMethods, PrefInterface } from "poker-cows-common";
import classNames from "classnames";
import { Tooltip } from "../../Tooltip/Tooltip";

interface LowMethodProps {
  startupPrefs: Partial<PrefInterface>;
  onUpdateInitialSettingsByPref: (
    prefsName: string,
    prefsValue: string
  ) => void;
}

const LowMethodSetup = (props: LowMethodProps) => {
  const onChangeLowMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onUpdateInitialSettingsByPref("lowestHand", event.target.value);
  };

  const LowMethodOptions = () => (
    <div className="lowMethodOptions">
      {LowMethods.map(
        (
          method: {
            label: string;
            value: string;
            tooltip: { title: string; description: string };
          },
          index: number
        ) => {
          const checked: boolean =
            props.startupPrefs?.lowestHand === method.value;
          return (
            <div className="radiobox" key={index}>
              <input
                type="radio"
                value={method.value}
                checked={checked}
                id={method.value}
                onChange={onChangeLowMethod}
              />
              <label
                htmlFor={method.value}
                className={classNames("lowMethodLabel", {
                  selected: checked,
                })}
              >
                {method.label}
              </label>
              <Tooltip
                buttonClassNames="lowMethodTooltip"
                title={method.tooltip.title}
                body={method.tooltip.description}
              />
            </div>
          );
        }
      )}
    </div>
  );

  return (
    <div className="lowMethod">
      <LowMethodOptions />
    </div>
  );
};

export default LowMethodSetup;
