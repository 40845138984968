/**
 * Rules.tsx
 *
 * This component give you the header component and the button around RulesModal
 */
import React from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";

import { TableStatus } from "poker-cows-common";
import {
  selectGameType,
  selectTableStatus,
  selectIsLocalPlayerHost,
} from "../../../logic/table/slice";
import { selectPrefs } from "../../../logic/gameInstance/slice";
import { selectInitializeTablePrompt } from "../../../logic/prompts/slice";
import { ModalName } from "../Modal/ModalConfig";
import { useModal } from "../Modal/hooks/useModal";
import { ModalProps } from "../Modal/types/modalTypes";
import Button from "../Button";
import { RULES_BUTTON } from "../../../test-identifiers";

// it appears when choosing a game or TODO: when a non-dealer player clicks the "rules button"
export const RulesHeader = (props: ModalProps) => {
  const { currentModalName, showModal } = useModal();

  const headers = [
    {
      title: "HOUSE RULES",
      modalName: ModalName.HouseRulesModal,
    },
    {
      title: "TABLE SETTINGS",
      modalName: ModalName.TableRulesModal,
    },
    {
      title: "ALL GAME RULES",
      modalName: ModalName.AllGamesRulesModal,
    },
    {
      title: "HAND RANKINGS",
      modalName: ModalName.HandRankingsRulesModal,
    },
  ];

  return (
    <div className="gameSetupButtonsContainer">
      {headers.map(({ modalName, title }) => {
        return (
          <div
            key={modalName}
            data-testid={modalName}
            className={classNames("rulesTab", {
              activeButton: modalName === currentModalName,
              inactiveButton: modalName !== currentModalName,
            })}
            onClick={() => showModal({ name: modalName })}
          >
            {title}
          </div>
        );
      })}
      <div
        className="exitModalButton"
        onClick={props.onClose}
        style={{ position: "unset" }}
      />
    </div>
  );
};

export const RulesButton = () => {
  const { gameType } = useSelector(selectGameType);
  const { tableStatus } = useSelector(selectTableStatus);
  const { initializeTablePrompt } = useSelector(selectInitializeTablePrompt);
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);
  const { prefs } = useSelector(selectPrefs);

  const { currentModalName, showModal } = useModal();

  const rulesButtonDisabled =
    tableStatus === TableStatus.POST_GAME ||
    (isLocalPlayerHost && initializeTablePrompt);

  function onClickRulesButton() {
    if (gameType) {
      showModal({
        name: ModalName.CurrentGameRulesModal,
        additionalProps: {
          selectedGame: gameType,
          selectedGameFlavor: prefs?.gameFlavor,
        },
      });
      return;
    }
    showModal({
      name: ModalName.AllGamesRulesModal,
    });
  }

  return (
    <div className="rules">
      <Button
        data-testid={RULES_BUTTON}
        className={classNames("actionButton", {
          activeRulesButton: [
            ModalName.HouseRulesModal,
            ModalName.TableRulesModal,
            ModalName.AllGamesRulesModal,
            ModalName.CurrentGameRulesModal,
            ModalName.HandRankingsRulesModal,
          ].find((modalName) => modalName === currentModalName),
          disabled: rulesButtonDisabled,
        })}
        onClick={onClickRulesButton}
      >
        RULES & SETTINGS
      </Button>
    </div>
  );
};
