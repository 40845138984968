/**
 * Reflects the status of the player. Depending on the actions taken by the table host
 * a player can move through the different statuses.
 */
export enum PlayerStatus {
  // player joining a table (not yet admitted by the host)
  JOINING = "joining",

  // if player joins during no limit full orbit they go from JOINING -> WAITING
  // after full orbit is complete they are changed to ADMITTED
  WAITING = "waiting",

  // the host has acted on the player request and has granted access to the table
  ADMITTED = "admitted",

  // this player is the host of the table
  HOST = "host",

  /* player is out of the table */

  // when the host has declined the player admission
  DECLINED = "declined",

  // the host has removed the player from the table (e.g. temporary access revoked)
  KICKED = "kicked",

  // the host has banned the player from the table (e.g., permanently the player access is revoked)
  BANNED = "banned",

  // the player has left the game voluntary
  LEFT = "left",
}
