/**
 * BankingLedgerForCertainPlayerModal.tsx
 *
 * This is just a wrapper around the BankingLedgerSubpage
 */
import React from "react";

import { BankingLedgerSubpage } from "../../Settings/HostControls/BankingLedger/BankingLedgerSubpage";

import { ModalProps } from "../types/modalTypes";

export const BankingLedgerForCertainPlayerModal = (
  props: ModalProps
): JSX.Element => {
  return <BankingLedgerSubpage {...props} />;
};
