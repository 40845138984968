/**
 * CashOutModal.tsx
 *
 * This is just a wrapper around the CashOut
 */
import React from "react";

import { CashMeOut } from "../../Table/CashMeOut";

import { ModalProps } from "../types/modalTypes";

export const CashMeOutModal = (props: ModalProps): JSX.Element => {
  return <CashMeOut {...props} />;
};
