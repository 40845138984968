/**
 * RemovedByHost.tsx
 * 
 * This Component is reached when host removes a player from the table
 */
import React from "react";
import { ExtraSession } from "../ExtraSession";
import "../ExtraSession.css";

export const RemovedByHost = () => {
  const handleClick = () => {
    window.location.href = "./";
  };
  const title = "REMOVED FROM THE TABLE";
  const contents = (
    <>
      <div className="extraSessionSubtext">
        The host has removed you from the table.
      </div>
    </>
  );
  return (
    <ExtraSession
      title={title}
      contents={contents}
      actionButtonOnClick={handleClick}
      actionButtonText={"Continue"}
    />
  );
};
