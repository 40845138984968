/**
 * LowMethod Options
 * Here we define ui options for low-hand for table setting/setup
 */
import { LowestHand } from "../enums/LowestHand";

export interface LowMethodInterface {
  label: string;
  value: LowestHand;
  tooltip: {
    title: string;
    description: string;
  };
}

export const LowMethods: LowMethodInterface[] = [
  {
    label: "The Wheel (5-4-3-2-A no straights/flushes)",
    value: LowestHand.WHEEL,
    tooltip: {
      title: "The Wheel",
      description:
        "5-4-3-2-A. Straights and flushes don’t count in the “low” hand eval, but are recognized for “high” hand eval. Aces can be used both low and high within the hand.",
    },
  },
  {
    label: "7-5 Low (7-5-4-3-2 unsuited)",
    value: LowestHand.SEVEN_FIVE,
    tooltip: {
      title: "7-5",
      description:
        "7-5-4-3-2 Unsuited. Straights and flushes DO count in the low hand eval. Aces may only be used high within the hand.",
    },
  },
  {
    label: "6-4 Low (6-4-3-2-A unsuited)",
    value: LowestHand.SIX_FOUR,
    tooltip: {
      title: "6-4",
      description:
        "6-4-3-2-A Unsuited. Straights and flushes DO count in the low hand eval. Aces may be used both high and low within the hand.",
    },
  },
];
