/**
 * Auth0ProviderWithHistory.tsx
 *
 * An Auth0Provider with extra functionality to account for redirecting a user back to the
 * actualy page they tried to log in from, instead of the default one.
 *
 */
import React from "react";
import { useHistory } from "react-router-dom";
import { AppState, Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }: { children: JSX.Element }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  if (!domain || !clientId || !audience) {
    throw new Error(
      "Missing Auth0 env settings\n" +
        `REACT_APP_AUTH0_DOMAIN=${process.env.REACT_APP_AUTH0_DOMAIN}\n` +
        `REACT_APP_AUTH0_CLIENT_ID=${process.env.REACT_APP_AUTH0_CLIENT_ID}\n` +
        `REACT_APP_AUTH0_AUDIENCE=${process.env.REACT_APP_AUTH0_AUDIENCE}`
    );
  }

  const history = useHistory();

  const onRedirectCallback = (appState?: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
