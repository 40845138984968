/**
 * Winnings.tsx
 * this component displays the amount won over a player's seat
 * a single component if the player has won a single pot
 * and multiple indicators for winning multiple side pots
 */

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectSidePots,
  selectWinningsPerPlayer,
} from "../../../logic/gameInstance/slice";
import awardSfx from "../../../static/sounds/winner-trumpet.wav";
import { selectPlayerIdAtTablePos } from "../../../logic/player/slice";
import styles from "./Winnings.module.css";
import { formatMoneyStringWithSign } from "../../../utils/MoneyFormat";
import classNames from "classnames";
import { useSeatContext } from "./SeatContext";
import { SidePotInterface } from "poker-cows-common";

interface WinningsProps {
  isGameWinner?: boolean;
  isBothWinner: boolean;
  isHighWinner: boolean;
  isLowWinner: boolean;
  amount?: number;
  isSidepotWinnings?: boolean;
}

export const LoseAmountItem = ({ amount }: Partial<WinningsProps>) => {
  if (!amount) {
    return <></>;
  }

  return (
    <div className={classNames(styles.winnings, styles.lose)}>
      {formatMoneyStringWithSign(amount > 0 ? -amount : amount)}
    </div>
  );
};

// displays a single winnings amount
const WinAmountItem = ({
  isGameWinner,
  isBothWinner,
  isHighWinner,
  isLowWinner,
  amount,
  isSidepotWinnings,
}: WinningsProps) => {
  return (
    <div
      className={classNames({
        [styles.winnings]: !isSidepotWinnings,
        [styles.sidePotWinnings]: isSidepotWinnings,
        [styles.wonBoth]: isBothWinner,
        [styles.wonHigh]: isHighWinner && !isLowWinner && !isBothWinner,
        [styles.wonLow]:
          (isLowWinner || isGameWinner) && !isHighWinner && !isBothWinner,
      })}
    >
      {formatMoneyStringWithSign(amount)}
    </div>
  );
};

export const Winnings = ({
  isBothWinner,
  isHighWinner,
  isLowWinner,
}: WinningsProps): JSX.Element => {
  const { tablePosition } = useSeatContext();
  const { playerId } = useSelector(selectPlayerIdAtTablePos(tablePosition));
  const { winningsPerPlayer } = useSelector(selectWinningsPerPlayer);
  const amountWon = winningsPerPlayer?.[playerId];

  // check for sidePot winnings
  const { sidePots } = useSelector(selectSidePots);
  const sidePotsWon = sidePots?.filter(
    (sidePot: SidePotInterface) =>
      sidePot.totalAmount > 0 &&
      (sidePot.highHandWinners?.includes(playerId) ||
        sidePot.lowHandWinners?.includes(playerId))
  );
  const hasSidePotWinnings = sidePotsWon.length > 0;

  // helper function - if pot is split
  const getSidePotAmountWon = (pot: SidePotInterface) => {
    const numberOfHighHandWinners = pot.highHandWinners?.length ?? 0;
    const numberOfLowHandWinners = pot.lowHandWinners?.length ?? 0;
    return pot.totalAmount / (numberOfHighHandWinners + numberOfLowHandWinners);
  };

  if (hasSidePotWinnings) {
    return (
      <div className={styles.sidePotWinningsContainer}>
        {sidePotsWon.map((pot: SidePotInterface) => {
          const isSidePotHighWinner =
            pot.highHandWinners?.includes(playerId) ?? false;
          const isSidePotLowWinner =
            pot.lowHandWinners?.includes(playerId) ?? false;
          const sidePotAmountWon = getSidePotAmountWon(pot);
          return (
            <WinAmountItem
              key={pot.playerIds.join("-")}
              isLowWinner={isSidePotLowWinner}
              isHighWinner={isSidePotHighWinner}
              isBothWinner={isSidePotHighWinner && isSidePotLowWinner}
              amount={sidePotAmountWon}
              isSidepotWinnings
            />
          );
        })}
      </div>
    );
  }

  if (amountWon) {
    return (
      <WinAmountItem
        isLowWinner={isLowWinner}
        isHighWinner={isHighWinner}
        isBothWinner={isBothWinner}
        amount={amountWon}
      />
    );
  }

  return <></>;
};
