import HollymoodSquaresImagePlayerTwoCard from "../../static/graphics/rules/Player_Hand_2_Cards.svg";
import HollymoodSquaresImagePlayerFourCard from "../../static/graphics/rules/Player_Hand_4_Cards.svg";
import HollymoodSquaresRulesImagePlayer from "../../static/graphics/rules/Hollymood_Squares_Player_Hand.svg";
import HollymoodSquaresRulesImageCommunity from "../../static/graphics/rules/Hollymood_Squares_Community_Cards.svg";
import HollymoodSquaresRulesImageCommunityNumbered from "../../static/graphics/rules/Hollymood_Squares_Community_Cards_Numbered.svg";
import { GameRulesInterface } from "poker-cows-common";

export const HollywoodSquaresRules: GameRulesInterface = {
  objectives: [
    "Best 5 card hand utilizing any or all of your hole cards and up to 3 community cards up/down, left/right or diagonally.",
  ],
  card: {
    twoCard: ["2 Player Cards"],
    fourCard: ["4 Player Cards"],
  },
  image: {
    player: HollymoodSquaresRulesImagePlayer,
    playerTwoCard: HollymoodSquaresImagePlayerTwoCard,
    playerFourCard: HollymoodSquaresImagePlayerFourCard,
    community: HollymoodSquaresRulesImageCommunity,
    communityNumbered: HollymoodSquaresRulesImageCommunityNumbered,
  },
  actions: [
    "1. After all cards are dealt, a betting round starts left of the dealer. (Opening bet position rotates each betting round.)",
    "2. Next, the community cards in position 1 are turned up, followed by a round of betting. This process is repeated for the remaining community cards 2 through 5.",
    "3. After final betting round, each remaining player will declare “High” or “Low” or “Both” (if High-Low Split game).",
    "4. Cards displayed; winners paid.",
  ],
};
