/**
 * This component is used as a bottom component during game play
 * It gives you options to Bet and Chips, Raise, Call, Check and Reset
 */
import React, { useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { BetAreaChip } from "./BetAreaChip";
import { RaisesCounter } from "../Components/RaisesCounter";
import { BettingProps, useAllInCheck, useBetting } from "./hooks/Betting";
import { selectPrefs } from "../../../../logic/gameInstance/slice";
import {
  formatMoneyString,
  formatMoneyStringPreferInteger,
} from "../../../../utils/MoneyFormat";

import Button from "../../Button";
import { ModalName } from "../../Modal/ModalConfig";
import { useModal } from "../../Modal/hooks/useModal";
import styles from "./ChipBetArea.module.css";
import "./ChipBetArea.css";
import { useSeatContext } from "../../Seat/SeatContext";
import {
  ALLIN_BUTTON,
  CALL_BUTTON,
  CHECK_BUTTON,
  NO_LIMIT_CALL_ALLIN_BUTTON,
  NO_LIMIT_CHIP_BET_AREA,
  RAISE_BUTTON,
  SPREAD_LIMIT_CHIP_BET_AREA,
} from "../../../../test-identifiers";

interface ChipBetAreaProps extends BettingProps {
  showTopButtons?: boolean;
  showSlider?: boolean;

  /** @default false */
  isNoLimit?: boolean;
}

/**
 * TODO: Refactor (Please leave review comments with your thoughts)
 * 1. Abstract <BetButton /> component
 * 2. Abstract <CallButton /> component
 * 3. Abstract <CheckButton /> component
 * 4. Abstract <ActionButton /> component to abstract the logic of choosing which of the above 3 to render.
 */

export function ChipBetArea({ isNoLimit, ...props }: ChipBetAreaProps) {
  const {
    bigBlindValue,
    call,
    callAmount,
    check,
    currentBet,
    hasCall,
    hasCurrentBet,
    hasMoney,
    maxRaise,
    minRaise,
    noLimitMinRaise,
    raiseTo,
    showCall,
    bank,
    bet,
  } = useBetting(props);

  const [currPlayerRaiseAmount, setCurrPlayerRaiseAmount] = useState(0);
  const { prefs } = useSelector(selectPrefs);
  const { showModal } = useModal();

  const lowDenomination = prefs?.chipDenomLow ?? 0;
  const midDenomination = prefs?.chipDenomMid ?? 0;
  const highDenomination = prefs?.chipDenomHigh ?? 0;

  /** BB in this context is "Big Blind" */
  const predefinedBet = {
    BBx4: bigBlindValue * 4,
    BBx16: bigBlindValue * 16,
  };

  const { tablePosition } = useSeatContext();
  const { isAllIn } = useAllInCheck({ tablePosition });

  // The naming of this is tough... I call it potential bet, but it's only potential if the localPlayer isn't going to call.
  const potentialBet = currPlayerRaiseAmount + currentBet;
  const canPlaceMinRaise = bank >= noLimitMinRaise + currentBet;

  const reset = () => setCurrPlayerRaiseAmount(0);

  const increaseRaiseAmount = (raise: number) => {
    if (isNoLimit) {
      setCurrPlayerRaiseAmount((prev) => prev + raise);
      return;
    } else {
      setCurrPlayerRaiseAmount((prev) => Math.min(prev + raise, maxRaise));
      return;
    }
  };

  const getBetLabel = () => {
    if (!hasCurrentBet) {
      return (
        <>
          BET
          <br />
          {formatMoneyString(currPlayerRaiseAmount)}
        </>
      );
    }

    return (
      <>
        RAISE <br />
        {formatMoneyString(currPlayerRaiseAmount)}
        {" TO "}
        {formatMoneyString(potentialBet)}
      </>
    );
  };

  const getActionButton = () => {
    const isRaising = currPlayerRaiseAmount > 0;

    const hasMoneyToRaise = bank >= currPlayerRaiseAmount + bet;

    const canRaise = hasMoney && (!isRaising || (isRaising && hasMoneyToRaise));

    const canCall = bank >= callAmount;

    const isMinimumChipAvailable = maxRaise >= minRaise;

    if (isRaising) {
      return (
        <Button
          data-testid={RAISE_BUTTON}
          className="green actionButton betButton"
          onClick={() => {
            if (!canRaise || !isMinimumChipAvailable || !hasMoneyToRaise) {
              showModal({ name: ModalName.GetMoreChipsModal });
            } else {
              raiseTo(potentialBet);
            }
          }}
        >
          {getBetLabel()}
        </Button>
      );
    } else {
      if (hasCall) {
        if (!isNoLimit) {
          return (
            <Button
              data-testid={CALL_BUTTON}
              className="blue actionButton betButton"
              onClick={() => {
                if (!isNoLimit && (!showCall || !isMinimumChipAvailable)) {
                  showModal({ name: ModalName.GetMoreChipsModal });
                } else {
                  call();
                }
              }}
            >
              CALL
              <br />
              {formatMoneyString(callAmount)}
            </Button>
          );
        } else {
          const buttonText = canCall ? "CALL" : "ALL IN";
          return (
            <Button
              data-testid={NO_LIMIT_CALL_ALLIN_BUTTON}
              className="blue actionButton betButton"
              onClick={() => {
                if (canCall) {
                  call();
                } else {
                  raiseTo(bank);
                }
              }}
            >
              {buttonText}
              <br />
              {formatMoneyString(canCall ? callAmount : bank)}
            </Button>
          );
        }
      }
    }

    return (
      <Button
        data-testid={CHECK_BUTTON}
        className="yellow actionButton betButton"
        onClick={check}
      >
        CHECK
      </Button>
    );
  };

  return (
    <div className="chipBetArea">
      {getActionButton()}

      {isNoLimit ? (
        <div
          className={styles.noLimitChipBetArea}
          data-testid={NO_LIMIT_CHIP_BET_AREA}
        >
          <Button
            className={classNames(styles.noLimitActionButton, "actionButton")}
            onClick={() => setCurrPlayerRaiseAmount(noLimitMinRaise)}
            disabled={
              !canPlaceMinRaise ||
              (hasCurrentBet && currPlayerRaiseAmount !== 0)
            }
          >
            {`MIN ${hasCurrentBet ? "RAISE" : "BET"} `}
            {formatMoneyStringPreferInteger(noLimitMinRaise)}
          </Button>

          <BetAreaChip
            denom="low"
            chipValue={lowDenomination}
            increaseCurrentRaiseAmount={increaseRaiseAmount}
            currentRaiseAmount={currPlayerRaiseAmount}
            {...props}
          />

          <BetAreaChip
            denom="mid"
            chipValue={midDenomination}
            increaseCurrentRaiseAmount={increaseRaiseAmount}
            currentRaiseAmount={currPlayerRaiseAmount}
            {...props}
          />

          <BetAreaChip
            denom="high"
            chipValue={highDenomination}
            increaseCurrentRaiseAmount={increaseRaiseAmount}
            currentRaiseAmount={currPlayerRaiseAmount}
            {...props}
          />

          <Button
            className={classNames(
              styles.noLimitActionButton,
              styles.noLimitResetButton,
              "actionButton"
            )}
            onClick={reset}
            disabled={currPlayerRaiseAmount === 0}
          >
            RESET
          </Button>

          <Button
            className={classNames(styles.noLimitActionButton, "actionButton")}
            onClick={() =>
              setCurrPlayerRaiseAmount((prev) => prev + predefinedBet.BBx4)
            }
            disabled={bank < predefinedBet.BBx4 + potentialBet}
          >
            +<br />
            {formatMoneyStringPreferInteger(predefinedBet.BBx4)}
          </Button>

          <Button
            className={classNames(styles.noLimitActionButton, "actionButton")}
            onClick={() =>
              setCurrPlayerRaiseAmount((prev) => prev + predefinedBet.BBx16)
            }
            disabled={bank < predefinedBet.BBx16 + potentialBet}
          >
            +<br />
            {formatMoneyStringPreferInteger(predefinedBet.BBx16)}
          </Button>

          <Button
            data-testid={ALLIN_BUTTON}
            className={classNames(
              styles.noLimitActionButton,
              styles.allIn,
              "actionButton"
            )}
            onClick={() => setCurrPlayerRaiseAmount(bank - currentBet)}
            disabled={isAllIn}
          >
            ALL <br /> IN
          </Button>
        </div>
      ) : (
        <div className="chipArea" data-testid={SPREAD_LIMIT_CHIP_BET_AREA}>
          <div className="chipAreaRow">
            <Button
              className="actionButton betResetButton"
              onClick={reset}
              disabled={currPlayerRaiseAmount === 0 || maxRaise < minRaise}
            >
              Reset
            </Button>

            <RaisesCounter />
          </div>

          <div className="chipAreaRow chips">
            <BetAreaChip
              denom="low"
              chipValue={lowDenomination}
              increaseCurrentRaiseAmount={increaseRaiseAmount}
              currentRaiseAmount={currPlayerRaiseAmount}
              {...props}
            />

            <BetAreaChip
              denom="mid"
              chipValue={midDenomination}
              increaseCurrentRaiseAmount={increaseRaiseAmount}
              currentRaiseAmount={currPlayerRaiseAmount}
              {...props}
            />

            <BetAreaChip
              denom="high"
              chipValue={highDenomination}
              increaseCurrentRaiseAmount={increaseRaiseAmount}
              currentRaiseAmount={currPlayerRaiseAmount}
              {...props}
            />
          </div>
        </div>
      )}
    </div>
  );
}
