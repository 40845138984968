import { GameRulesInterface } from "poker-cows-common";
import TexasDropemRulesImagePlayer from "../../static/graphics/rules/Texas_Dropem_Player_Hand.svg";

export const TexasDropemRules: GameRulesInterface = {
  objectives: [
    "Lowest point total and highest point total split the pot. If a player drops all of their cards, the game ends and the player wins the entire pot. Aces=1 or 11, Face Cards=10, All others=face value.",
  ],
  card: {
    sevenCard: ["7 player cards dealt down"],
    equalCard: ["Cards dealt equal number to players"],
  },
  image: { player: TexasDropemRulesImagePlayer },
  actions: [
    "1. Each player is dealt 7 down cards.",
    "2. 1 card from the deck is dealt up. Players must drop any matching card.",
    "3. Bet, starting with player left of dealer.",
    "4. Player to left of dealer drops a card(s), players must drop any matching card.",
    "5. Bet, starting always with the player that just dropped.",
    "6. Game continues until all players have initiated the drop/bet, or someone has no cards.",
    "7. Players declare “High” or “Low” or “Both”.",
    "8. Cards displayed; winners paid.",
  ],
};
