import { GameRulesInterface } from "poker-cows-common";
import FollowTheQueenRulesImagePlayer from "../../static/graphics/rules/Follow_The_Queen_Player_Hand.svg";

export const FollowTheQueenRules: GameRulesInterface = {
  objectives: [
    "Best 5 card poker hand. All Queens and Up Queen followers are wild. Wildcard changes with each Up Queen.",
  ],
  card: { default: ["7 player cards - 2 down, 4 up, 1 down"] },
  image: { player: FollowTheQueenRulesImagePlayer },
  title: "Standard 7 Card Stud Deal",
  actions: [
    "1. Each Player is dealt 2 cards down and 1 card up.",
    "2. Bet, always starting with player showing best hand.",
    "3. 3 more rounds of cards dealt up with betting.",
    "4. 1 card dealt down, bet.",
    "5. Players declare “High” or “Low” or “Both” (if a High-Low Split game)",
    "6. Cards displayed; winners paid",
  ],
};
