/** NoLimitSetupTitle.tsx
 *
 * This component displays Title for No Limit Setup and Tooltip
 */
import React from "react";
import { Tooltip } from "../../Tooltip/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { selectNextPrefs } from "../../../../logic/table/slice";
import { changePrefs } from "../../../../logic/prompts/slice";

type NoLimitSetupProps = {
  isRulesPage?: boolean;
};

export const NoLimitSetupTitle = (props: NoLimitSetupProps) => {
  const dispatch = useDispatch();
  const { nextPrefs } = useSelector(selectNextPrefs);
  const onChangeBlindsEditability = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const prefsString = JSON.stringify({
      ...nextPrefs,
      areBlindsEditable: event.target.checked,
    });
    dispatch(changePrefs({ newPrefs: prefsString }));
  };

  const title = (
    <>
      <span className="title">
        <span className="rowCaption">Optional: Blinds</span>&nbsp;
        <Tooltip
          title="No Limit Setup"
          body="Set your blinds here for playing No Limit Texas Hold'em. A complete orbit of the game around the table with rotating dealers must be completed before the deal passes with the option to select a different game."
        />
      </span>
      <span className="description">NO-LIMIT TEXAS HOLD’EM</span>
    </>
  );

  if (props.isRulesPage) {
    return <div className="flexRulesTitle">{title}</div>;
  }

  return (
    <div className="flexRulesTitle">
      <div className="checkbox">
        <input
          type="checkbox"
          checked={nextPrefs?.areBlindsEditable}
          id="blindSetup"
          onChange={onChangeBlindsEditability}
        />
        <label htmlFor="blindSetup" className="blindSetup">
          {title}
        </label>
      </div>
    </div>
  );
};
