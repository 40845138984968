/**
 * AnteArea.tsx
 *
 * This component is used during Ante to pay or skip the hand
 */
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";
import {
  selectAnteAction,
  payAnte,
  skipAnte,
} from "../../../logic/prompts/slice";
import { formatMoneyString } from "../../../utils/MoneyFormat";
import { selectLocalPlayerBank } from "../../../logic/gameInstance/slice";

import { ModalName } from "../Modal/ModalConfig";
import { useModal } from "../Modal/hooks/useModal";

import "./AnteArea.css";
import Button from "../Button";

export const AnteArea = () => {
  const dispatch = useDispatch();
  const { anteAction } = useSelector(selectAnteAction);
  const { localPlayerBank } = useSelector(selectLocalPlayerBank);

  const { showModal } = useModal();

  function onPayAnte() {
    dispatch(payAnte());
  }

  function onSkipAnte() {
    dispatch(skipAnte());
  }

  const canLocalPlayerAffordAnte =
    anteAction?.data?.betAmount &&
    (anteAction?.data?.betAmount ?? 0) <= localPlayerBank;

  return (
    <div className="anteActions">
      <Button
        className={classNames("actionButton", {
          green: canLocalPlayerAffordAnte,
          gray: !canLocalPlayerAffordAnte,
        })}
        onClick={() => {
          if (canLocalPlayerAffordAnte) {
            onPayAnte();
            return;
          }
          showModal({ name: ModalName.GetMoreChipsModal });
        }}
      >
        Pay {formatMoneyString(anteAction?.data?.betAmount ?? 0)} Ante
      </Button>
      <br />
      <Button className="actionButton blue" onClick={onSkipAnte}>
        Skip Hand
      </Button>
    </div>
  );
};
