/*
  This component displays for all players at the end of a round.
  The contents change depending on practice mode and if a player is dealer
*/

import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import Button from "../Button";
import { Game, PlayerStatus } from "poker-cows-common";
import {
  endShowdownPhase,
  selectDelayOfGamePrompt,
} from "../../../logic/prompts/slice";
import {
  selectGameType,
  selectIsBotTable,
  selectCurrentDealer,
} from "../../../logic/table/slice";
import {
  selectPrefs,
  selectPotTotalAmount,
} from "../../../logic/gameInstance/slice";
import { selectIsLocalPlayerHost } from "../../../logic/table/slice";
import { selectIsLocalPlayerDealer } from "../../../logic/table/slice";
import { PracticeHandCompleteButtons } from "./PracticeHandCompleteButtons";
import { useNetBalance } from "../../../logic/gameInstance/hooks";
import {
  CONTINUE_GAME_BUTTON,
  PASS_THE_DECK_BUTTON,
} from "../../../test-identifiers";
import { ModalName } from "../Modal/ModalConfig";
import { useModal } from "../Modal/hooks/useModal";

import "./EndShowdownArea.css";

export const EndShowdownArea = () => {
  const dispatch = useDispatch();
  const { offBalance } = useNetBalance();
  const { currentModalName, showModal } = useModal();
  const { prefs } = useSelector(selectPrefs);
  const practiceMode = prefs?.practiceMode;
  const { isBotTable } = useSelector(selectIsBotTable);
  const { potTotalAmount } = useSelector(selectPotTotalAmount);
  const { delayOfGamePrompt } = useSelector(selectDelayOfGamePrompt);
  const promptPlayer = delayOfGamePrompt?.playerId;
  const { currentDealer } = useSelector(selectCurrentDealer);
  const { isLocalPlayerDealer } = useSelector(selectIsLocalPlayerDealer);
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);
  const { gameType } = useSelector(selectGameType);
  const isGutsGame = gameType === Game.Guts;
  const isTexasHoldemGame = gameType === Game.TexasHoldem;

  const isDealerExited =
    useMemo(
      () =>
        currentDealer?.status === PlayerStatus.KICKED ||
        currentDealer?.status === PlayerStatus.LEFT ||
        currentDealer?.status === PlayerStatus.DECLINED ||
        currentDealer?.status === PlayerStatus.BANNED,
      [currentDealer?.status]
    ) ||
    (isLocalPlayerDealer && !promptPlayer) ||
    (!isLocalPlayerDealer && promptPlayer);

  useEffect(() => {
    if (
      currentModalName !== ModalName.OffBalanceModal &&
      isLocalPlayerHost &&
      offBalance
    ) {
      showModal({ name: ModalName.OffBalanceModal });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocalPlayerHost, offBalance, showModal]);

  const onClickNext = (toContinue = false) => {
    dispatch(endShowdownPhase(toContinue));
  };

  const DealerPromptText = () => {
    if (practiceMode) {
      return <span>CHOOSE HOW TO CONTINUE:</span>;
    }

    let dealer = isDealerExited ? "DEALER'S" : "YOUR";
    if (!isTexasHoldemGame && potTotalAmount) {
      dealer = "THIS";
    }
    const task = potTotalAmount ? "ROUND" : "DEAL";
    return (
      <span>
        {`${dealer} ${task} IS OVER.`}
        <br />
        WHEN READY...
      </span>
    );
  };

  const PassTheDeckButton = () => (
    <Button
      data-testid={PASS_THE_DECK_BUTTON}
      className="actionButton green"
      onClick={() => onClickNext(false)}
    >
      {`Pass the Deck ${isDealerExited ? ` on dealer's behalf` : ""}`}
    </Button>
  );
  const ChangeGameButton = () => (
    <Button
      data-testid={PASS_THE_DECK_BUTTON}
      variant="base"
      className="actionButton"
      onClick={() => onClickNext(false)}
    >
      Change Game
    </Button>
  );
  const EndGutsGameButton = () => (
    <Button
      variant="base"
      className="actionButton"
      onClick={() => onClickNext(false)}
    >
      <div className="descriptionContainer">
        <span>End Guts Game</span>
        <span className="buttonDescription">(Current pot refunded)</span>
      </div>
    </Button>
  );

  const PlayGameAgainButton = () => (
    <Button
      data-testid={CONTINUE_GAME_BUTTON}
      variant="green"
      className="actionButton green"
      onClick={() => onClickNext(true)}
    >
      Play Game Again
    </Button>
  );
  const ContinueGameButton = () => (
    <Button
      data-testid={CONTINUE_GAME_BUTTON}
      variant="green"
      className="actionButton green"
      onClick={() => onClickNext(true)}
    >
      Continue Game
    </Button>
  );

  const ShowdownButton = () => {
    if (practiceMode) {
      return <PracticeHandCompleteButtons />;
    }

    if (potTotalAmount && isGutsGame) {
      return <ContinueGameButton />;
    }

    if (isBotTable) {
      return <PlayGameAgainButton />;
    }

    return <PassTheDeckButton />;
  };

  return (
    <div className="showdownArea">
      <div className="passDeckText">{<DealerPromptText />}</div>
      <div
        className={classNames("botButtons", {
          buttonContainer:
            (isBotTable && !practiceMode) ||
            (!isBotTable && !practiceMode && isGutsGame && !!potTotalAmount),
        })}
      >
        {isBotTable && !practiceMode && <ChangeGameButton />}
        {!isBotTable && !practiceMode && isGutsGame && !!potTotalAmount && (
          <EndGutsGameButton />
        )}
        <ShowdownButton />
      </div>
    </div>
  );
};
