// getBettingStructure.ts
//
// A helper function to get the betting structure type based on the defined prefs of the game

import { BetLimit } from "../BetLimit";
import { BettingStructure } from "./BettingStructure";
import { CapLimit } from "./CapLimit";
import { FixedLimit } from "./FixedLimit";
import { NoLimit } from "./NoLimit";
import { PotLimit } from "./PotLimit";
import { SpreadLimit } from "./SpreadLimit";

// TODO: @typify-prefs
// prefs should have it's own type
export function getBettingStructure(prefs: any): BettingStructure {
  switch (prefs.limitType) {
    case BetLimit.CAP_LIMIT:
      return new CapLimit(prefs);
    case BetLimit.FIXED_LIMIT:
      return new FixedLimit(prefs);
    case BetLimit.NO_LIMIT:
      return new NoLimit(prefs);
    case BetLimit.POT_LIMIT:
      return new PotLimit(prefs);
    case BetLimit.SPREAD_LIMIT:
      return new SpreadLimit(prefs);
    default:
      throw new Error("Unrecognized or unsupported bet limit type.");
  }
}
