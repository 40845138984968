/*

How this works:
Hand.js has a new prop

this.cardsDiscardedInfo = {
   didDiscard: false,
   count: 0,
};

at the beginning of DiscardCardsAction applyTo method, we set this to false and 0,
once the cards are validated and handled, we set to true and cardCount.
this component, DiscardCountIndicator, listens to that discardStatus in a useEffect.
if didDiscard is set to true, and we haven't yet shown the dots,
then we start a timer to show and hide the dots,
and we tell state we've displayed them once so they don't appear again this round,
(like if a player refreshes the page).

Showing and hiding the dots is handled with CSS, not a conditional render,
so that we can have them fade in and out.

*/

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPlayerIdAtTablePos,
  selectPlayerHandAtTablePos,
} from "../../logic/player/slice";
import {
  sawDiscard,
  selectWasPlayerShownDiscardedDotsByPlayerId,
} from "../../logic/localPlayer/slice";
import "./DiscardCountIndicator.css";
import { useSeatContext } from "./Seat/SeatContext";

export const DiscardCountIndicator = () => {
  const [showDots, setShowDots] = useState(false);
  const { tablePosition } = useSeatContext();
  const { playerHand } = useSelector(selectPlayerHandAtTablePos(tablePosition));
  const cardsDiscardedInfo = playerHand?.cardsDiscardedInfo;
  const discardCount = cardsDiscardedInfo?.count ?? 0;
  const didDiscard = Boolean(cardsDiscardedInfo?.didDiscard);
  const { playerId } = useSelector(selectPlayerIdAtTablePos(tablePosition));
  const { wasPlayerShownDiscardedDots } = useSelector(
    selectWasPlayerShownDiscardedDotsByPlayerId(playerId)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let showDotsTimeout: ReturnType<typeof setTimeout>;

    // we haven't discarded, but something has triggered this useEffect,
    // so we must be in a new draw game, so clear sawDiscard, if hasSeenDiscard true
    if (!didDiscard && wasPlayerShownDiscardedDots) {
      dispatch(sawDiscard({ localPlayerId: playerId, seen: false }));
    }

    // we did discard, and localPlayer hasn't seen this remotePlayer's dots yet.
    // so show them, then register in redux
    if (didDiscard && !wasPlayerShownDiscardedDots) {
      setShowDots(true);
      dispatch(sawDiscard({ localPlayerId: playerId, seen: true }));

      // then in three seconds, clear showDots
      showDotsTimeout = setTimeout(() => {
        setShowDots(false);
      }, 3000);
    }

    return () => {
      // if a timeout is set,
      // and we're sure the player has seen the discard
      // clear it, to avoid memory leak
      if (showDotsTimeout && wasPlayerShownDiscardedDots) {
        clearTimeout(showDotsTimeout);
      }
    };
  }, [
    cardsDiscardedInfo,
    wasPlayerShownDiscardedDots,
    didDiscard,
    playerId,
    dispatch,
  ]);

  return (
    <div
      className={
        showDots ? "discardCountIndicator" : "discardCountIndicator hideDots"
      }
    >
      {[...Array(discardCount)].map((empty, index) => {
        return <div className="discardDot discardDotFull" key={index} />;
      })}
      {/* we manually write "3 - count" here because this component is currently used
          in the game 5 card draw, where a player can discard up to 3 cards.
          in the future if used in games where that number may be different,
          we will make this number dynamic.

          I've split this into two array .map() renders for readability
       */}
      {[...Array(3 - discardCount)].map((empty, index) => {
        return <div className="discardDot" key={index} />;
      })}
    </div>
  );
};
