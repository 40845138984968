/**
 * BankingLedgerModal.tsx
 *
 * This is just a wrapper around the BankingLedger
 */
import React from "react";

import { BankingLedger } from "../../Settings/HostControls/BankingLedger/BankingLedger";

import { ModalProps } from "../types/modalTypes";

export const BankingLedgerModal = (props: ModalProps): JSX.Element => {
  return <BankingLedger {...props} />;
};
