/**
 * Welcome.tsx
 * This component used to display welcome message to the player with some instructions
 */
import React from "react";
import styles from "./Welcome.module.css";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ModalProps } from "../Modal/types/modalTypes";
import Button from "../Button";

interface IntructionProps {
  title?: string;
  description: string;
  forBotOnly?: boolean;
}

const instructions: IntructionProps[] = [
  {
    description:
      "Your players play free, but to Host a game, you need a subscription.",
  },
  {
    description: `You can use code <span class="${styles.yellowText}">NEWUSER30</span> during checkout to enjoy 30 days free.  Cancel before 30 days for no charge.`,
  },
  {
    description: `Better codes exist.  Consider asking us for one in our <a target='_blank' class="${styles.linkText}" href='https://discord.com/invite/AnyNXRXBG6'>Discord channel</a>.`,
  },
  {
    description:
      "They're not the smartest bots, but you'll get a feel for all the games and features of PokerCows! AI assisted bots coming soon.",
    forBotOnly: true,
  },
];

export const Welcome = (props: ModalProps) => {
  const proceedToCheckout = () => {
    window.location.href = "payment-plan?period=monthly";
  };

  const Instruction = ({ instruction }: { instruction: IntructionProps }) => {
    if (instruction.forBotOnly && !props.additionalProps?.isBotTable) {
      return null;
    }

    return (
      <div key={instruction.title} className={styles.instruction}>
        <FontAwesomeIcon className={styles.icon} icon={faCheck as IconProp} />
        <div className={styles.instructionContent}>
          {instruction.title && (
            <div className={styles.title}>{instruction.title}</div>
          )}
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: instruction.description }}
          ></div>
        </div>
      </div>
    );
  };

  const Instructions = () => (
    <div className={styles.instructions}>
      {instructions.map((instruction: IntructionProps) => (
        <Instruction instruction={instruction} />
      ))}
    </div>
  );

  return (
    <div className={styles.welcome}>
      <div className={styles.header}>
        <div>Welcome to Pokercows !</div>
      </div>
      <div className={classNames("section", styles.content)}>
        <div className={styles.caption}>Checkout these great features:</div>
        <Instructions />
      </div>
      <Button variant="green" onClick={proceedToCheckout}>
        Proceed to Checkout
      </Button>
    </div>
  );
};
