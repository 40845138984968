import React, { ReactNode, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { selectActivePlayers } from "../../../../../logic/player/slice";
import { hostTransferChips } from "../../../../../logic/prompts/slice";
import { MoneyInput } from "./MoneyInput";
import { PlayerSelect } from "./PlayerSelect";
import Button from "../../../Button";
import { Tooltip } from "../../../Tooltip/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { formatMoneyString } from "../../../../../utils/MoneyFormat";
import { PlayerIdType } from "poker-cows-common";

type TransferInfoType = {
  fromPlayer: PlayerIdType;
  toPlayer: PlayerIdType;
  amount: string;
};

export const ChipTransfer = (props: {
  back: () => void;
  showNotification: (message: ReactNode) => void;
}): JSX.Element => {
  const { activePlayers } = useSelector(selectActivePlayers);
  const disabled = activePlayers.length < 2; // chip transfer disabled if only 1 player
  const [chipTransfer, setChipTransfer] = useState<TransferInfoType>({
    fromPlayer: activePlayers[0].id,
    toPlayer: activePlayers[1]?.id,
    amount: "0",
  });
  const dispatch = useDispatch();
  const [formTouched, setFormTouched] = useState(false);
  const sendingPlayer = activePlayers.find(
    (player) => player.id === chipTransfer.fromPlayer
  );
  const receivingPlayer = activePlayers.find(
    (player) => player.id === chipTransfer.toPlayer
  );
  const sendingPlayerBank = sendingPlayer?.bank ?? 0;
  const chipAmountInNumber = parseFloat(chipTransfer.amount);
  const chipAmountInCents = chipAmountInNumber * 100;
  const illegalAmount = isNaN(chipAmountInNumber) || chipAmountInNumber < 0.01;
  const unsufficientAmount = chipAmountInCents > sendingPlayerBank;
  const illegalTransfer = sendingPlayer?.id === receivingPlayer?.id;
  const isValidForm = illegalAmount || illegalTransfer || unsufficientAmount;
  const updateChipTransfer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const update = { ...chipTransfer, [event.target.name]: event.target.value };
    setChipTransfer(update);
  };
  const executeChipTransfer = () => {
    setFormTouched(true);
    if (isValidForm) {
      return;
    }
    const argString =
      chipAmountInCents.toString() +
      " " +
      chipTransfer.fromPlayer +
      " " +
      chipTransfer.toPlayer +
      " ";

    dispatch(hostTransferChips(argString));
    const message = (
      <>
        <span className="title">COMPLETE!</span>
        <span>
          You transferred {formatMoneyString(chipAmountInCents.toFixed(2))} from{" "}
          {sendingPlayer?.name} to {receivingPlayer?.name}.
        </span>
      </>
    );
    props.showNotification(message);
  };

  let displayMessage = undefined;
  if (unsufficientAmount) {
    displayMessage = `${sendingPlayer?.name} only has ${formatMoneyString(
      sendingPlayerBank
    )}. Please adjust the amount.`;
  } else if (illegalAmount) {
    displayMessage = "Please enter an amount atleast $0.01 to adjust.";
  } else if (illegalTransfer) {
    displayMessage = "Transfer chips is not allowed between same player.";
  }

  const alertMessage = <div className="alert danger">{displayMessage}</div>;
  const closeButton = (
    <span className="close" onClick={props.back}>
      <FontAwesomeIcon
        className="close-icon"
        title="Close"
        icon={faClose as IconProp}
      />
    </span>
  );

  return (
    <div className="chipTransferContainer">
      <div className="chipTransferHeader">
        TRANSFER CHIPS BETWEEN PLAYERS &nbsp;
        <Tooltip
          title="Transfer Chips Between Players"
          body="Used whenever a player needs to give chips from their stack to another player."
        ></Tooltip>
        {closeButton}
      </div>
      <div className="chipTransfer">
        Take:
        <div
          className={classNames("fieldGroup", {
            danger: formTouched && isValidForm,
          })}
        >
          <span className="prefix">$</span>
          <MoneyInput
            name="amount"
            onChange={updateChipTransfer}
            value={chipTransfer.amount}
          />
        </div>
        from
        <div className="fieldGroup">
          <PlayerSelect
            value={chipTransfer.fromPlayer}
            options={activePlayers}
            toOrFrom="fromPlayer"
            onChange={updateChipTransfer}
            disabled={disabled}
          />
        </div>
        and give to
        <div className="fieldGroup">
          <PlayerSelect
            value={chipTransfer.toPlayer}
            options={activePlayers}
            toOrFrom="toPlayer"
            onChange={updateChipTransfer}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="chipBottom">
        {formTouched && isValidForm && alertMessage}
        <div className="chipBottomButton">
          <Button
            disabled={disabled}
            className="actionButton green"
            onClick={executeChipTransfer}
          >
            ADJUST CHIP BALANCES
          </Button>
        </div>
      </div>
    </div>
  );
};
