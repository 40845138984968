/**
 * Modal.tsx
 *
 * This new Modal in-houses the css around the overlay, container and content, along with the custom css passed to the Component
 * This component also handles the mouse events and keyboard events and dispatch actions related to the modal
 * The component itself is wrapped around @reach/dialog
 */
import React from "react";

import classNames from "classnames";
import { DialogOverlay, DialogContent } from "@reach/dialog";

import { ExitIcon } from "./icons/ExitIcon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ModalProps } from "./types/modalTypes";

import "./styles/Modal.css";
import "@reach/dialog/styles.css";
import {
  MODAL_BACK_BUTTON,
  MODAL_CLOSE_BUTTON,
  MODAL_TITLE,
} from "../../../test-identifiers";

export const Modal = ({
  name,
  onBack,
  onClose,
  children,
  visible = false,
  disabled = true,
  hasOverlay = true,
  overlayClass = "",
  containerClass = "",
  hasHeader = true,
  hasBackButton = false,
  backButtonClass = "",
  hasTitle = true,
  title = "",
  titleClass = "",
  hasCloseButton = true,
  closeButtonClass = "",
  contentClass = "",
}: ModalProps): JSX.Element => {
  if (!visible) {
    return <></>;
  }

  const customModalOverlayClass = {
    fullPage: hasOverlay,
    center: !hasOverlay,
    disabled: disabled,
  };
  const customModalTitleClass = {
    isBothButtonsVisible: hasBackButton && hasCloseButton,
    isOneButtonVisible: hasBackButton !== hasCloseButton,
  };
  const customModalContentClass = { noHeader: !hasHeader };

  return (
    <DialogOverlay
      id={`modal-${name}`}
      isOpen={visible}
      allowPinchZoom={true}
      onDismiss={hasCloseButton ? onClose : undefined}
      className={classNames(
        "modalOverlay",
        customModalOverlayClass,
        overlayClass
      )}
    >
      <DialogContent className={classNames("modalContainer", containerClass)}>
        {hasHeader && (
          <div className="modalHeaderWrapper">
            <header className="modalHeader">
              {hasBackButton && (
                <button
                  data-testid={MODAL_BACK_BUTTON}
                  className={classNames("modalBack", backButtonClass)}
                  onClick={() => onBack()}
                >
                  <FontAwesomeIcon icon={faAngleLeft as IconProp} />
                </button>
              )}
              {hasTitle && (
                <h3
                  data-testid={MODAL_TITLE}
                  className={classNames(
                    "modalTitle",
                    customModalTitleClass,
                    titleClass
                  )}
                >
                  {title ?? name}
                </h3>
              )}
              {hasCloseButton && (
                <button
                  data-testid={MODAL_CLOSE_BUTTON}
                  className={classNames("modalClose", closeButtonClass)}
                  onClick={() => onClose()}
                >
                  <ExitIcon />
                </button>
              )}
            </header>
          </div>
        )}

        <div
          className={classNames(
            "modalContent",
            customModalContentClass,
            contentClass
          )}
        >
          {children}
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};
