import React, { useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getName } from "./Dev";
import Loading from "../../components/Loading";
import { useDeveloper } from "../../components/Dev/hooks/useDeveloper";
import { createTable } from "../../../logic/table/thunks";
import { selectTableCode } from "../../../logic/table/slice";
import { SessionKeys, setSession } from "../../../utils/SessionSettings";
import "./Dev.css";

function DevCreate() {
  const dispatch = useDispatch();
  const { tableCode } = useSelector(selectTableCode);
  const { isDeveloper } = useDeveloper();

  useEffect(() => {
    if (isDeveloper) {
      setSession(SessionKeys.isBotTable, "false");
      dispatch(createTable({ name: "Created Table", nickName: getName(0) }));
    }
  }, [dispatch, isDeveloper]);

  if (tableCode) {
    const url = "/dev/" + tableCode;
    return <Redirect to={url} />;
  }

  if (isDeveloper === false) {
    return <Redirect to="/" />;
  }

  return <Loading />;
}

export default withRouter(DevCreate);
