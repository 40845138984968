/**
 * ResetHand.tsx
 *
 * This is a central component of ResetHandModal which gives user option to undo the hand but only after confirming
 */
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { undoHand } from "../../../../logic/prompts/slice";
import { selectTableId } from "../../../../logic/table/slice";
import Button from "../../Button";
import { ModalProps } from "../../Modal/types/modalTypes";

export const ResetHand = (props: ModalProps) => {
  const dispatch = useDispatch();
  const { tableId } = useSelector(selectTableId);

  const undoHandClick = () => {
    const confirmed = window.confirm("Are you sure you want to undo the hand?");
    if (!confirmed) {
      return;
    }
    dispatch(undoHand(tableId.toString()));
    props.onBack();
  };

  return (
    <div className="resetHandModalContainer hostControlsSubpageContainer">
      <div className="resetHandSubtext">
        This will cancel the current hand and return
        <br />
        balances to the end of the previous hand.
      </div>
      <Button
        className="resetHandResetButton actionButton green"
        onClick={undoHandClick}
      >
        YES, RESET THIS HAND
      </Button>
      <Button
        className="resetHandCancelButton actionButton cancelButton"
        onClick={props.onBack}
      >
        NO, CANCEL
      </Button>
    </div>
  );
};
