/** NoLimitSetup.tsx
 *
 * This is a rulesRow component used in Master Startup Modal
 * It displays 2 dropdown select inputs for setting the small blind and big blind
 */
import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { filteredDenominations, PrefInterface } from "poker-cows-common";
import { selectNextPrefs } from "../../../../logic/table/slice";
import { Dropdown } from "../../Dropdown/Dropdown";

interface BettingStructureProps {
  startupPrefs: Partial<PrefInterface>;
  onUpdateInitialSettingsByPref: (
    prefsName: string,
    prefsValue: string
  ) => void;
}

export const arrowStyle = {
  height: 20,
  width: 20,
  color: "black",
}

const NoLimitSetup = (props: BettingStructureProps) => {
  const { nextPrefs } = useSelector(selectNextPrefs);
  return (
    <div className="dropdownContainer noLimitCustomFlex customDropdown">
      <div
        className={classNames("minBet", {
          disabledButton: !nextPrefs?.areBlindsEditable,
        })}
      >
        <div className="dropdownCaption">Small Blind:</div>
        <Dropdown
          value={String(props.startupPrefs.smallBlind)}
          setValue={(newValue) =>
            props.onUpdateInitialSettingsByPref("smallBlind", newValue)
          }
          options={filteredDenominations}
          disabled={!nextPrefs?.areBlindsEditable}
          arrowStyle={arrowStyle}
          isBottomRow={true}
        />
      </div>
      <div
        className={classNames("maxBet", {
          disabledButton: !nextPrefs?.areBlindsEditable,
        })}
      >
        <div className="dropdownCaption">Big Blind:</div>
        <Dropdown
          value={String(props.startupPrefs.bigBlind)}
          setValue={(newValue) =>
            props.onUpdateInitialSettingsByPref("bigBlind", newValue)
          }
          options={filteredDenominations}
          disabled={!nextPrefs?.areBlindsEditable}
          arrowStyle={arrowStyle}
          isBottomRow={true}
        />
      </div>
    </div>
  );
};

export default NoLimitSetup;
