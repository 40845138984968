/**
 * Dedicated to rendering the text that hangs at the top of the seat
 * "avatar" (video element) declaring the user as "WINNER HIGH!", "WINNER LOW!",
 * or "WINNER BOTH!".
 */

import React from "react";
import classNames from "classnames";
import styles from "./SeatWinnerBadge.module.css";
import { useSeatContext } from "./SeatContext";
import { WinnerGlowBox } from "../WinnerGlowBox/WinnerGlowBox";

interface SeatWinnerBadgeProps {
  isDealer: boolean;
  isGameWinner: boolean;
  isBothWinner: boolean;
  isHighWinner: boolean;
  isLowWinner: boolean;
}

export const SeatWinnerBadge = ({
  isDealer,
  isGameWinner,
  isBothWinner,
  isHighWinner,
  isLowWinner,
}: SeatWinnerBadgeProps): JSX.Element => {
  const { isLocalSeat } = useSeatContext();

  let winTypeText: "BOTH" | "HIGH" | "LOW" | "SPADE" = "BOTH";

  if (isGameWinner) {
    winTypeText = "SPADE";
  }

  if (isHighWinner && !isBothWinner) {
    winTypeText = "HIGH";
  }

  if (isLowWinner && !isBothWinner) {
    winTypeText = "LOW";
  }

  return (
    <div
      className={classNames(styles.seatWinnerBadge, {
        [styles.tableSideSeats]: !isLocalSeat,
      })}
    >
      <WinnerGlowBox
        as="span"
        className={styles.seatWinnerBadgeText}
        isGameWinner={isGameWinner}
        isBothWinner={isBothWinner}
        isHighWinner={isHighWinner}
        isLowWinner={isLowWinner}
      >
        WINNER {isDealer && isLocalSeat && <br />}
        {winTypeText}!
      </WinnerGlowBox>
    </div>
  );
};
