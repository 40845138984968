import { faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { selectPlayerIdAtTablePos } from "../../../logic/player/slice";
import {
  selectIsTwilioPlayerMuted,
  selectTwilioSessionMediaState,
} from "../../../logic/twilio/slice";
import { useSeatContext } from "./SeatContext";
import "./MutedIndicator.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const MutedIndicator = () => {
  const { tablePosition, isLocalSeat } = useSeatContext();
  const { playerId } = useSelector(selectPlayerIdAtTablePos(tablePosition));
  const { twilioSessionMediaState: twilioAudioSessionMediaState } = useSelector(
    selectTwilioSessionMediaState("audio")
  );
  const { isTwilioPlayerMuted } = useSelector(
    selectIsTwilioPlayerMuted(playerId)
  );
  const muted = isLocalSeat
    ? !twilioAudioSessionMediaState
    : isTwilioPlayerMuted;

  if (muted) {
    return (
      <div className="mutedIndicator">
        <FontAwesomeIcon icon={faMicrophoneSlash as IconProp} />
      </div>
    );
  } else {
    return <></>;
  }
};
