/**
 * Generic Button Component
 * Button component is used to render generic button in whole application
 * so can manage button from one file
 */
import React, {
  useState,
  DetailedHTMLProps,
  ButtonHTMLAttributes,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";

import {
  selectIsRequestPending,
  selectRequestInitiatedAt,
  setRequestInitiatedAt,
} from "../../logic/connection/slice";

import styles from "./Button.module.css";

interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  "data-testid"?: string;
  variant?:
    | "ghost"
    | "base"
    | "gray"
    | "green"
    | "yellow"
    | "red"
    | "blue"
    | "black";
}

const Button = ({
  children,
  disabled,
  onClick,
  variant,
  ...props
}: ButtonProps) => {
  const dispatch = useDispatch();

  const [buttonRequestInitiatedAt, setButtonRequestInitiatedAt] = useState<
    number | null
  >(null);

  const { requestInitiatedAt } = useSelector(selectRequestInitiatedAt);
  const { isRequestPending } = useSelector(selectIsRequestPending);

  return (
    <button
      {...props}
      disabled={disabled || isRequestPending}
      className={classNames(props.className, {
        [styles.ghost]:
          disabled ||
          (isRequestPending &&
            requestInitiatedAt &&
            requestInitiatedAt === buttonRequestInitiatedAt),
        [styles.base]: !!variant,
        [styles.gray]: variant === "gray",
        [styles.green]: variant === "green",
        [styles.yellow]: variant === "yellow",
        [styles.red]: variant === "red",
        [styles.blue]: variant === "blue",
        [styles.black]: variant === "black",
      })}
      onClick={(e) => {
        const currentDate = Date.now();
        setButtonRequestInitiatedAt(currentDate);
        dispatch(setRequestInitiatedAt(currentDate));
        onClick?.(e);
      }}
    >
      <>{children}</>
    </button>
  );
};

export default Button;
