import { filteredDenominations, TableSettingInterface, PrefInterface } from "poker-cows-common";

/**
 * This function will always return a value showing 2 decimal places for fractional values,
 * but will also never show any decimal places for integer values. So, $1.00 would show as
 * $1, but $1.25 would remain as $1.25.
 */
export const formatMoneyStringPreferInteger = (cents: number) => {
  const value = cents / 100;

  const hasCents = value % 1 !== 0;

  const formatOptions: Intl.NumberFormatOptions = hasCents
    ? { minimumFractionDigits: 2 }
    : { minimumFractionDigits: 0, maximumFractionDigits: 0 };

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    ...formatOptions,
  }).format(value);
};

/* eslint-disable @typescript-eslint/ban-types */
export function formatMoneyString(
  amount?: number | string,
  options?: Omit<Intl.NumberFormatOptions, "style" | "currency">
): string {
  if (typeof amount === "string") {
    amount = +amount;
  }
  if (typeof amount !== "number") {
    return "";
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    ...options,
  }).format(Math.abs(amount / 100));
}

export function formatMoneyStringWithSign(amount?: number | string): string {
  if (typeof amount === "string") {
    amount = +amount;
  }
  if (typeof amount !== "number") {
    return "";
  }

  const formattedString = formatMoneyString(amount);

  if (amount < 0) {
    return `-${formattedString}`;
  }
  if (amount > 0) {
    return `+${formattedString}`;
  }

  return formattedString;
}

// Given an unlimited whole number of red, white, and green chips, what's the closest to `target`
// that can be formed? https://en.wikipedia.org/wiki/Knapsack_problem .  Proceeding via
// pseudo polynomial "dynamic programming" (DP) approach.
export function solveKnapsackProblem(
  target: number,
  red: number,
  white: number,
  green: number
) {
  const chips: number[] = [red, white, green];
  const _default: number[] = [0, 0, 0, 0]; // best value, num(red), num(white), num(green)
  const dp: number[][] = [_default]; // for target = 0
  const lookup = (value: number, chipType: number) => {
    if (value - chips[chipType] < 0) {
      return _default;
    }
    let result = [...dp[value - chips[chipType]]];
    result[0] += chips[chipType];
    result[1 + chipType] += 1;
    return result;
  };

  while (dp.length <= target) {
    const current: number = dp.length;
    let best: number[] = dp[current - 1];
    for (let chipType = 0; chipType < chips.length; chipType += 1) {
      let alt: number[] = lookup(current, chipType);
      if (alt[0] > best[0]) {
        best = alt;
      }
    }
    dp[current] = best;
  }
  return dp[target][0];
}

type PrefsNameType = keyof TableSettingInterface;

// this function is used by table settings and master startup screens
// to make the dropdowns' selected value(s) adjust to always be coherent
export function betSettingsInvariant(
  prefsName: string,
  prefsValue: string,
  newPrefs: Partial<PrefInterface>,
  callBack: (updatedPrefs: Partial<PrefInterface>) => void, // we check values against initial buy in on the init table screen
  isInitTable?: boolean
) {
  const targetName = prefsName;
  const targetValue = parseFloat(prefsValue) ?? Boolean(prefsValue);

  let targetPrefs = {
    initialBuyIn: newPrefs.initialBuyIn,
    ante: newPrefs.ante,
    spreadMinRaise: newPrefs.spreadMinRaise,
    spreadMaxRaise: newPrefs.spreadMaxRaise,
    smallBlind: newPrefs.smallBlind,
    bigBlind: newPrefs.bigBlind,
    chipDenomLow: newPrefs.chipDenomLow,
    chipDenomMid: newPrefs.chipDenomMid,
    chipDenomHigh: newPrefs.chipDenomHigh,
  } as TableSettingInterface;

  // Part 1
  // Inequality Invariants - rules of the form "x <= y"
  // Enforce these completely, first.
  const inequalityRules = [
    // ante <= initialBuyIn
    ["ante", "initialBuyIn"],

    // bigBlind <= initialBuyIn
    ["bigBlind", "initialBuyIn"],

    // minBet == smallBlind == white
    ["chipDenomLow", "smallBlind"],
    ["smallBlind", "chipDenomLow"],
    ["chipDenomLow", "spreadMinRaise"],
    ["spreadMinRaise", "chipDenomLow"],

    // minBet <= maxBet
    ["spreadMinRaise", "spreadMaxRaise"],

    // smallBlind <= bigBlind
    ["smallBlind", "bigBlind"],

    // white <= red <= green
    ["chipDenomLow", "chipDenomMid"],
    ["chipDenomMid", "chipDenomHigh"],
  ] as PrefsNameType[][];
  // minBet <= initialBuyIn - only on init table / master startup, not in host controls
  if (isInitTable) {
    inequalityRules.push(["spreadMinRaise", "initialBuyIn"]);
  }

  // Setup - we changed `target` at least
  let dfsStack: string[] = [];
  const updateValue = (name: string, value = targetValue) => {
    targetPrefs = {
      ...targetPrefs,
      [name]: value,
    };
    dfsStack.push(name);
  };
  updateValue(targetName, targetValue);

  // Treating variables as nodes in a graph, linked by edges which are inequality rules, perform
  // a Depth First Search of all values we need to update.
  while (dfsStack.length > 0) {
    const changedName = dfsStack.pop();
    // eslint-disable-next-line no-loop-func
    inequalityRules.forEach((invariant) => {
      const [aName, bName] = invariant; // aName <= bName

      if (aName === changedName && targetPrefs[bName] < targetPrefs[aName]) {
        updateValue(bName);
      } else if (
        bName === changedName &&
        targetPrefs[aName] > targetPrefs[bName]
      ) {
        updateValue(aName);
      }
    });
  }

  // Part 2 - now that all values have been moved, we need to further move certain values -
  // the "composable values" - as little as possible, so that they represent a number that
  // can be formed ("composed") by some whole number of white, red, and green chips.  If the player
  // changed the white chip value, we change composable values to match; otherwise, we change the
  // white chip to match.  To determine what's composable, and because this is all a bit temporary,
  // we corrupt the problem statement slightly to check composability via knapsack, and simply
  // "relax" a composability issue by "stepping back" the necessary value(s) until it's resolved.
  const composableValues = ["spreadMaxRaise", "bigBlind"] as PrefsNameType[];

  const stepValueBackOne = (value: PrefsNameType) => {
    let currentIndex = filteredDenominations.findIndex(
      (item) => item.value === targetPrefs[value]
    );
    updateValue(value, filteredDenominations[currentIndex - 1].value);
  };

  const relaxCompositionError = (value: PrefsNameType) => {
    if (targetName === "chipDenomLow") {
      stepValueBackOne(value);
    } else {
      stepValueBackOne("chipDenomLow");
    }
  };

  for (const value of composableValues) {
    while (
      solveKnapsackProblem(
        targetPrefs[value],
        targetPrefs["chipDenomLow"],
        targetPrefs["chipDenomMid"],
        targetPrefs["chipDenomHigh"]
      ) !== targetPrefs[value]
    ) {
      relaxCompositionError(value);
    }
  }

  // done
  return callBack({ ...newPrefs, ...targetPrefs });
}
