import React from "react";
import classNames from "classnames";

import { filteredDenominations } from "poker-cows-common";

// a reusable chip component used throughout application

interface ChipProps {
  value: number; // ie. 0.25, 0.05, 1, 5, 100
  denom: string; // denomination category - low, mid, or high
  disabled?: boolean; // dims the chip and disables onClick
  raise?: boolean; // raise will add a plus sign above the denomination for betting phase
  onClick?: () => void;
}

export const Chip = (props: ChipProps) => {
  const chipLabel = filteredDenominations.find(
    (item) => item.value === props.value
  )?.label;

  if (!chipLabel) {
    return <></>;
  }

  return (
    <div
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      className={classNames("chipContainer", `${props.denom}Chip`, {
        raise: props.raise,
        disabled: props.disabled,
      })}
    >
      {props.raise && <div className="raisePlus">+</div>}
      <div
        className={classNames("chipTitle", {
          chipTitleSmall: chipLabel.length === 4,
          chipTitleMedium: chipLabel.length === 3,
          chipTitleLarge: chipLabel.length === 2,
        })}
      >
        {chipLabel}
      </div>
    </div>
  );
};
