/*
  localStorageSettings.ts
  This in-houses the the constants used(directly, prefix, suffix) and the different functions that is set,get,remove for Local Storage
*/

export enum LocalKeys {
  hasAgreedMobileDisclaimer = "pokercows-has-agreed-mobile-disclaimer",
  isDeveloper = "pokercows-is-developer",
}

export const setLocal = (key: string, value: string): void => {
  return localStorage.setItem(key, value);
};

export const getLocal = (key: string): string | null => {
  const value = localStorage.getItem(key);

  return value;
};

export const removeLocal = (key: string): void => {
  return localStorage.removeItem(key);
};
