// CapLimit.ts
//
// Implementation of the Cap limit betting structure. Limits the max amount money
// a player can put in the pot

import { BetInfo } from "./BetInfo";
import { BettingStructure } from "./BettingStructure";
import { RoundBetInfo } from "./RoundBetInfo";

/**
 * Cap Limit
 *
 * Check for the cap on how much money a player can bet during the game (the total buy in of the player)
 */
export class CapLimit extends BettingStructure {
  private capLimit: number;

  constructor(prefs: any) {
    super(prefs);
    this.validatePositiveNumber(prefs.capLimitAmount, "capLimitAmount");
    this.capLimit = prefs.capLimitAmount;
  }

  validateBet(bet: BetInfo, round: RoundBetInfo) {
    this.validatePositiveNumber(bet.amount, "bet amount");

    if (bet.amount + bet.personalInPot > this.capLimit) {
      throw new Error(
        `Current bet amount of ${bet.amount} will exceed exceed the CAP_LIMIT of ${this.capLimit}`
      );
    }

    this.validateRaises(bet, round);
    this.validateBetSize(bet, round);
  }
}
