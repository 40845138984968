/**
 * ActForPlayerModal.tsx
 *
 * This is just a wrapper around the ActForPlayer
 */
import React from "react";

import { ActForPlayer } from "../../Settings/HostControls/ActForPlayer";

import { ModalProps } from "../types/modalTypes";

export const ActForPlayerModal = (props: ModalProps): JSX.Element => {
  return <ActForPlayer {...props} />;
};
