/**
 * useCardSelection.tsx
 *
 * A react hook for selecting cards.
 * Anywhere you need to select cards, or get info about selected cards, this hook should likely be used.
 */
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CardInterface } from "poker-cows-common";
import {
  discardCards,
  selectAnyCardSelectionPrompt,
  foldDuringSelection,
  selectAnyDealPrompt,
} from "../../../../logic/prompts/slice";
import { selectPlayerCardsAtTablePos } from "../../../../logic/player/slice";
import {
  clearSelectedCards,
  selectSelectedCards,
  setCardSelected,
} from "../../../../logic/cardSelection/slice";
import { useSeatContext } from "../../Seat/SeatContext";

/**
 * 12/10/2022
 * 🚧 This is a hack!
 * We use the negative value of the card's orderDealt value so that we can highlight selected cards easily and ignore
 * their in-hand, opaque counter-part.
 */
const getInverseOrderDealt = (card: Partial<CardInterface>) => {
  return card.orderDealt ? -card.orderDealt : 0;
};

export const useCardSelection = () => {
  const dispatch = useDispatch();
  const { tablePosition } = useSeatContext();
  const { selectedCards } = useSelector(selectSelectedCards);
  const { anyCardSelectionPrompt } = useSelector(selectAnyCardSelectionPrompt);
  const selectSameRank = anyCardSelectionPrompt?.data?.selectSameRank;
  const selectingString = anyCardSelectionPrompt?.data?.selectingString;
  const selectedString = anyCardSelectionPrompt?.data?.selectedString;
  const maxSelectAmount = anyCardSelectionPrompt?.data?.maxNumberOfCards;
  const minSelectAmount =
    anyCardSelectionPrompt?.data?.minNumberOfCards ?? maxSelectAmount;
  const { anyDealPrompt } = useSelector(selectAnyDealPrompt);
  const isSelecting = !!anyCardSelectionPrompt && !anyDealPrompt;

  const { playerCards } = useSelector(
    selectPlayerCardsAtTablePos(tablePosition)
  );

  const onCardClicked = useCallback(
    (card: Partial<CardInterface>, isCardSelected: boolean) => {
      const cards: Partial<CardInterface>[] = [];

      if (selectSameRank) {
        for (let i = 0; i < playerCards.length; i++) {
          if (playerCards[i].rank === card.rank) {
            const otherCard = playerCards[i];
            cards.push({
              selectSameRank,
              rank: otherCard.rank,
              suit: otherCard.suit,
              selected: !isCardSelected,
              orderDealt: getInverseOrderDealt(otherCard),
            });
          }
        }
      } else {
        cards.push({
          selectSameRank,
          rank: card.rank,
          suit: card.suit,
          selected: !isCardSelected,
          orderDealt: getInverseOrderDealt(card),
        });
      }

      dispatch(setCardSelected(cards as CardInterface[]));
    },
    [playerCards, dispatch, selectSameRank]
  );

  const fold = () => {
    dispatch(foldDuringSelection());
  };

  useEffect(() => {
    dispatch(clearSelectedCards());
    return () => {
      dispatch(clearSelectedCards());
    };
    // adding selectedString to this useEffect dependency ensures selected cards are cleared
    // in back to back discard -> roll phases
  }, [dispatch, selectedString]);

  const submitSelectedCards = () => {
    // TODO: update once DiscardCardAction and its subclasses support spaces in JSON array argument!
    const cardString = JSON.stringify(selectedCards).replace(" ", "").trim();

    dispatch(discardCards(cardString));
  };

  return {
    toDiscard: selectedCards,
    onCardClicked,
    isSelecting,
    selectingString,
    selectedString,
    maxSelectAmount,
    minSelectAmount,
    submitSelectedCards,
    fold,
  };
};
