// this file renders the localPlayer's twilio video,
// a single connection to twilio, and a single instance of TwilioClient.
// on mount we connect to twilio, and on unmount we disconnect.
// for rendering the twilio video and audio of OTHER players at the table - ("remote" players),
// see RemoteTwilioMedia.
// And for more information on our Twilio setup, see TwilioClient.ts

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTableId } from "../../../../logic/table/slice";
import {
  selectLocalPlayerTwilioKey,
  selectLocalPlayerId,
} from "../../../../logic/localPlayer/slice";
import { TwilioClient } from "../../../../logic/twilio/TwilioClient";
import { PauseOverlay } from "./TwilioMedia";
import { SeatInfo } from "../SeatInfo";
import { useTwilioMedia } from "../../../../logic/twilio/hooks";
import {
  selectAnyTwilioError,
  selectIsTwilioConnected,
  selectIsTwilioConnecting,
  selectTwilioSessionMediaState,
  selectTwilioError,
} from "../../../../logic/twilio/slice";
import { TwilioErrorType } from "poker-cows-common";
import { TwilioErrorMessage } from "./TwilioErrorMessage";
import { VideoRoomMonitor } from "@twilio/video-room-monitor";
import { selectAnyDelayPrompt } from "../../../../logic/prompts/slice";

export const LocalTwilioMedia = () => {
  const { tableId } = useSelector(selectTableId);
  const { localPlayerId } = useSelector(selectLocalPlayerId);
  const dispatch = useDispatch();
  const { localPlayerTwilioKey } = useSelector(selectLocalPlayerTwilioKey);
  const { isTwilioConnecting } = useSelector(selectIsTwilioConnecting);
  const { isTwilioConnected } = useSelector(selectIsTwilioConnected);
  const { twilioSessionMediaState: twilioVideoSessionMediaState } = useSelector(
    selectTwilioSessionMediaState("video")
  );
  const { twilioError: anyTwilioError } = useSelector(selectAnyTwilioError);
  const { twilioError } = useSelector(selectTwilioError);

  const { anyDelayPrompt } = useSelector(selectAnyDelayPrompt);

  // ?twilio=debug in the url will enable the room monitor.
  // if you close it you can open it back up by clicking your
  // own video stream
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const twilioDebug = params.get("twilio") === "debug";
  const twilioMediaClick = () => {
    if (twilioDebug) {
      VideoRoomMonitor.openMonitor();
    }
  };

  // TwilioClient uses a class factory pattern to manage instances of itself.
  // one instance is created for each table the localPlayer joins, so we
  // keep track of the instances by tableId within the class
  const twilioClient =
    TwilioClient.get(tableId.toString(), localPlayerId) ??
    TwilioClient.create(tableId.toString(), localPlayerId, dispatch);

  // pass in TwilioClient instance for hooks to access attach / detach functions
  // second arg is "local", as in, is this hook for localPlayer or remote
  // useTwilioMedia also returns an audioRef, which you will see accessed in RemoteTwilioMedia - however
  // it's not necessary to play back to the localPlayer their own audio stream, so we don't need it here
  const { videoRef } = useTwilioMedia(twilioClient, true);

  const showPauseOverlay = !twilioVideoSessionMediaState;

  // make sure we have all dependencies before connecting,
  // and that we aren't already connecting or connected
  useEffect(() => {
    if (
      !tableId ||
      !localPlayerId ||
      !localPlayerTwilioKey ||
      isTwilioConnecting ||
      isTwilioConnected ||
      twilioError.type !== TwilioErrorType.NONE
    ) {
      return;
    }
    twilioClient.connect(localPlayerTwilioKey, localPlayerId, twilioDebug);

    // disconnect on unmount
    return () => {
      if (isTwilioConnected) {
        twilioClient.disconnect();
      }
    };
  }, [
    localPlayerTwilioKey,
    tableId,
    localPlayerId,
    twilioClient,
    isTwilioConnecting,
    isTwilioConnected,
    twilioError.type,
    twilioDebug,
  ]);

  return (
    <div className={"twilio-media"} onClick={twilioMediaClick}>
      {anyTwilioError && !anyDelayPrompt && <TwilioErrorMessage />}
      {showPauseOverlay && <PauseOverlay />}
      <video ref={videoRef} autoPlay={true} />
      <SeatInfo />
    </div>
  );
};
