/**
 * Game Flavor.tsx
 * This file used to render game flavor/versions
 */

import React from "react";
import classNames from "classnames";

export const GameFlavor = (props: {
  value: number;
  title: string;
  isFlavorSelected: boolean;
  changeGameFlavor: (flavour: number) => void;
}) => {
  return (
    <div
      className={classNames("flavor", {
        selected: props.isFlavorSelected,
      })}
    >
      <label
        htmlFor="flavor"
        className="radio"
        onClick={() => {
          props.changeGameFlavor(props.value);
        }}
      >
        <input
          readOnly
          type="radio"
          id={`flavor_${props.value}`}
          name="flavor"
          className="radioInput"
          checked={props.isFlavorSelected}
        />
        <div className="customRadio"></div>
        <div className="radioOptionText">{props.title}</div>
      </label>
    </div>
  );
};
