import React, { CSSProperties } from "react";
import { useSelector } from "react-redux";

import { CardInterface } from "poker-cows-common";
import { selectTableCards } from "../../logic/gameInstance/slice";
import Card from "./Card";

import "./TableCards.css";
import "./GridTableCards.css";
import { TABLE_CARDS_CONTAINER } from "../../test-identifiers";

function objectFlip(obj: any) {
  const ret = {} as any;
  Object.keys(obj).forEach((key) => {
    ret[obj[key]] = key;
  });
  return ret;
}

export interface GridTableCardsProps {
  gridLayoutMap: any; // maps location (as "x / y"), to tableCards index
}

export const GridTableCards = (props: GridTableCardsProps) => {
  const gridLayout = objectFlip(props.gridLayoutMap);

  const mapCard = (card: CardInterface, style: CSSProperties) => (
    // for community table cards, currently showdownOverride is always false (i.e. not applicable)
    <Card
      tableCards={true}
      style={style}
      dealNumber={card.orderDealt}
      rank={card.rank}
      suit={card.suit}
      up={card.up}
      showdownOverride={false}
    />
  );
  const { tableCards } = useSelector(selectTableCards);

  const mapCards = () => {
    const cards: JSX.Element[] = [];
    if (!tableCards) {
      return null;
    }
    for (let i = 0; i < tableCards.length; i++) {
      const style = { gridArea: gridLayout[i] };
      const card = mapCard(tableCards[i], style);
      cards.push(card);
    }
    return cards;
  };
  const cards = mapCards();
  return (
    <div
      data-testid={TABLE_CARDS_CONTAINER}
      className="table-cards-container cross-container"
    >
      {cards}
    </div>
  );
};
