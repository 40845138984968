/*
    This component displays to the left of the local user's seat,
    where the fold button displays during betting phases,
    during showdown if the player has not folded

    The button reveals the players hand to others
*/

import React from "react";
import { useDispatch } from "react-redux";
import { showHand } from "../../../../logic/prompts/slice";
import Button from "../../Button";
import { SHOW_CARDS_BUTTON } from "../../../../test-identifiers";

export interface ShowCardsButtonProps {
  disabled: boolean;
}

export const ShowCardsButton = ({ disabled }: ShowCardsButtonProps) => {
  const dispatch = useDispatch();

  const showHandClick = () => {
    dispatch(showHand());
  };

  return (
    <Button
      data-testid={SHOW_CARDS_BUTTON}
      className="actionButton blue showCardsButton"
      onClick={showHandClick}
      disabled={disabled}
    >
      Show Cards
    </Button>
  );
};
