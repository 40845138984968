/*
 * SingleGameRulesPage.tsx
 *
 * This file is a react component that displays a games rules.
 * It's used in the "Current Game" tab in the rules tab and also reused when clicking on various games within the "All Games" tab.
 * When rendered as the "Current Game" tab it should display the current games rules, whereas when it's rendered in the "All Games" tab
 * it should list the rules and available card options (i.e. 2 or 3 card)
 */
import React, { useState } from "react";

import {
  getCardImages,
  getCardRules,
  getGameOptions,
  getGameRules,
  getPrettyGameType,
} from "../../../../utils/GameInfo";
import { RulesPage } from "../Rules/RulesPage";
import { OptionsPage } from "../Options/OptionsPage";
import { ModalProps } from "../../Modal/types/modalTypes";
import { useSelector } from "react-redux";
import { selectGameType } from "../../../../logic/table/slice";
import { Game } from "poker-cows-common";
import { GameVersionInterface } from "poker-cows-common";
import { selectPrefs } from "../../../../logic/gameInstance/slice";
import { GameFlavor } from "./GameFlavor";

export const SingleGameRulesPage = (props: ModalProps): JSX.Element => {
  const { gameType } = useSelector(selectGameType);
  const selectedGame = props.additionalProps?.selectedGame;
  const selectedGameFlavor = props.additionalProps?.selectedGameFlavor;
  const { prefs } = useSelector(selectPrefs);
  const isCurrentGame = gameType === selectedGame;
  const gameTitle = getPrettyGameType(selectedGame);
  const gameRules = getGameRules(selectedGame);
  const gameOptions = getGameOptions(selectedGame);
  const gameVersions = gameOptions?.versions ?? [];
  const [flavor, setFlavour] = useState(
    selectedGameFlavor ?? gameVersions[0]?.flavor
  );
  const [limitType, setLimitType] = useState(
    prefs?.limitType ?? gameVersions[0]?.betLimit
  );
  const playerHandImage = getCardImages(gameRules, flavor);

  const GameImage = () => {
    if (playerHandImage) {
      return <img alt="" src={playerHandImage} />;
    }
    return <img alt="" src={gameRules?.image?.player} />;
  };

  const getGameVersion = () => {
    const selectedVersionIndex =
      gameVersions?.findIndex(
        (version: GameVersionInterface) =>
          version.flavor === flavor && version.betLimit === limitType
      ) ?? -1;

    if (selectedVersionIndex > -1 && gameVersions.length > 1) {
      return gameVersions.map(
        (version: GameVersionInterface, index: number) => (
          <GameFlavor
            key={index}
            isFlavorSelected={selectedVersionIndex === index}
            value={index}
            title={
              getCardRules(gameRules, version.flavor)?.join(" | ") ??
              version.description
            }
            changeGameFlavor={changeGameFlavor}
          />
        )
      );
    }

    return (
      <span className="cardRule">
        {getCardRules(gameRules, flavor)?.join(" | ")}
      </span>
    );
  };

  const GameNameAndCardInfo = () => (
    <>
      {isCurrentGame && <div className="currentGameText">CURRENT GAME</div>}
      <div>{gameTitle}</div>
      <div className="cardInfo">{getGameVersion()}</div>
    </>
  );

  const changeGameFlavor = (index: number) => {
    const selectedVersion = gameVersions?.[index];
    setFlavour(selectedVersion.flavor);
    setLimitType(selectedVersion.betLimit);
  };

  /**
   * TODO: Refactor the component to be reused with Settings.tsx
   */
  return (
    <div className="gameSelector">
      <div className="gameSelectorHeader">
        <div className="gameSelectBackButtonWrapper" />
        <div className="gameSelectorTitle">
          <GameNameAndCardInfo />
        </div>
        <div className="gameSelectorImage">
          <GameImage />
        </div>
      </div>
      <div className="gameSetting">
        <div className="gameRules">
          <RulesPage gameRules={gameRules} playerHandImage={playerHandImage} />
        </div>
        {selectedGame !== Game.TexasHoldem && (
          <div className="gameOptions">
            <OptionsPage
              isRulePage={true}
              isCurrentGameOption={isCurrentGame}
              gameRules={gameRules}
              gameOptions={gameOptions}
              playerHandImage={playerHandImage}
            />
          </div>
        )}
      </div>
    </div>
  );
};
