/**
 * HouseRulesModal.tsx
 *
 * This is just a wrapper around the Rules
 */
import React from "react";

import { RulesHeader } from "../../Settings/Rules";
import { HouseRules } from "../../Settings/RulesPages/HouseRules/HouseRules";

import { ModalProps } from "../types/modalTypes";

export const HouseRulesModal = (props: ModalProps): JSX.Element => {
  return (
    <>
      <RulesHeader {...props} />
      <div className="rulesModalContentContainer rules">
        <HouseRules {...props} />
      </div>
    </>
  );
};
