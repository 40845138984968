/**
 * AuthClientHook.ts
 * This hook used to obtain instance of Auth0Client in component from auth0-spa-js package
 */
import { AppState } from "@auth0/auth0-react";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { useHistory } from "react-router-dom";

export function useAuth0Client() {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  if (!domain || !clientId || !audience) {
    throw new Error(
      "Missing Auth0 env settings\n" +
        `REACT_APP_AUTH0_DOMAIN=${process.env.REACT_APP_AUTH0_DOMAIN}\n` +
        `REACT_APP_AUTH0_CLIENT_ID=${process.env.REACT_APP_AUTH0_CLIENT_ID}\n` +
        `REACT_APP_AUTH0_AUDIENCE=${process.env.REACT_APP_AUTH0_AUDIENCE}`
    );
  }

  const history = useHistory();
  const onRedirectCallback = (appState?: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  const auth0Client = new Auth0Client({
    domain,
    client_id: clientId,
    audience,
    cacheLocation: "localstorage",
    redirectUri: window.location.pathname,
    onRedirectCallback: onRedirectCallback,
  });

  return {
    auth0Client,
  };
}
