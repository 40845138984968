/**
 * LowChipModal.tsx
 *
 * This is just a wrapper around the low chip component
 */
import React from "react";

import { ModalProps } from "../types/modalTypes";
import { LowChip } from "../../ActionArea/LowChip";

export const LowChipModal = (props: ModalProps): JSX.Element => {
  return <LowChip {...props} />;
};
