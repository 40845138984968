/**
 * LedgerRow.tsx
 *
 * This is a stateless component used by BankingLedger to show banking information around a player
 */
import React from "react";

import { PlayerInterface } from "poker-cows-common";
import {
  formatMoneyString,
  formatMoneyStringWithSign,
} from "../../../../../utils/MoneyFormat";

interface LedgerRowProps {
  playerName?: string;
  bankBalance?: number;
  chipBalance?: number;
  netBalance?: number;
  isEndRow?: boolean;
  isHeader?: boolean;
  systemNetBalance?: number;
  isStatic?: boolean; // if true, player name not clickable

  navigate?: (p?: PlayerInterface) => void;
  player?: PlayerInterface;
}

export const LedgerRow = (props: LedgerRowProps) => {
  const onPlayerNameClick = () => {
    if (props.isStatic !== true) {
      props.navigate?.(props.player);
    }
  };

  if (props.isHeader) {
    return (
      <div className="ledgerRow">
        <div>Player</div>
        <div>Buy-Ins Balance</div>
        <div>Chip Balance</div>
        <div>Net Balance</div>
      </div>
    );
  }

  return (
    <>
      <div className="ledgerRow">
        <div
          className={props.isStatic ? "currentPotText" : "ledgerPlayerName"}
          onClick={onPlayerNameClick}
        >
          {props.playerName}
        </div>
        <div>{formatMoneyString(props.bankBalance)}</div>
        <div>{formatMoneyString(props.chipBalance)}</div>
        <div>{formatMoneyStringWithSign(props.netBalance)}</div>
      </div>

      {props.isEndRow && (
        <div className="ledgerRow">
          <div className="ledgerMergedRow">
            <span className="ledgerSubtext">This should always equal zero</span>
          </div>
          <div>{formatMoneyString(props.systemNetBalance)}</div>
        </div>
      )}
    </>
  );
};
