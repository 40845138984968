import { GameRulesInterface } from "poker-cows-common";
import LowChicagoRulesImagePlayer from "../../static/graphics/rules/Follow_The_Queen_Player_Hand.svg";

export const LowChicagoRules: GameRulesInterface = {
  objectives: [
    "Best 5 card poker hand. Low spade in the hole splits the pot with the high hand. 2 is lowest Spade.",
  ],
  card: { default: ["7 player cards - 2 down, 4 up, 1 down"] },
  image: { player: LowChicagoRulesImagePlayer },
  title: "Standard 7 Card Stud Deal",
  actions: [
    "1. Each Player is dealt 2 cards down and 1 card up.",
    "2. Bet, always starting with player showing best hand.",
    "3. 3 more rounds of cards dealt up with betting.",
    "4. 1 card dealt down, bet.",
    "5. Cards displayed; winners paid.",
  ],
};
