/*
  for handling persisting session media settings.
  (session = tab by tab, as opposed to local = global browser)
  the sessionStorage entries are under
    pokercows:audio = boolean | pokercows:video = boolean
*/

import { SessionKeys, getSession, setSession } from "./SessionSettings";

export const setSessionMediaIsActive = (
  twilioClientId: string,
  mediaType: "audio" | "video",
  active: boolean
): void => {
  setSession(
    `${SessionKeys.twilioMedia}-${twilioClientId}-${mediaType}`,
    active.toString()
  );
};

export const getSessionMediaIsActive = (
  twilioClientId: string,
  mediaType: "audio" | "video"
): boolean => {
  const sessionStorageEntry = getSession(
    `${SessionKeys.twilioMedia}-${twilioClientId}-${mediaType}`
  );

  if (sessionStorageEntry) {
    return eval(sessionStorageEntry);
  } else {
    // assume active if unset
    return true;
  }
};
