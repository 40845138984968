/**
 * AccountEmailModal.tsx
 *
 * This is just a wrapper around the Account Email
 */
import React from "react";

import { ModalProps } from "../types/modalTypes";
import { Email } from "../../../views/account/Email";

export const AccountEmailModal = (props: ModalProps): JSX.Element => {
  return <Email {...props} />;
};
