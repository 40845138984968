/**
 * TableUpdater.tsx
 * React hook for updating redux table state
 */
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateTable } from "../../shared";
import { selectUpdateNumber, selectUpdateTime } from "../../connection/slice";

export function useTableUpdater() {
  const dispatch = useDispatch();
  const { updateNumber } = useSelector(selectUpdateNumber);
  const { updateTime } = useSelector(selectUpdateTime);

  const updateTableFromJson = useCallback(
    (jsonData: any) => {
      try {
        let data = {} as any;
        if (typeof jsonData === "string") {
          data = JSON.parse(jsonData);
        } else {
          data = jsonData;
        }

        // If we are receiving an update that is older than the latest processed update,
        // ignore it.

        if (data.updateTime < updateTime || data.updateNumber < updateNumber) {
          return;
        }

        dispatch(updateTable({ ...data, receivedAt: Date.now() }));
      } catch (e: any) {
        console.groupCollapsed("Error parsing json jsonData:");
        console.log(jsonData);
        console.groupEnd();
        console.error(e.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  return updateTableFromJson;
}
