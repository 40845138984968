import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectNextPrefs } from "../../../../logic/table/slice";
import { dealerChangePrefs } from "../../../../logic/prompts/slice";
import { getGameOptions } from "../../../../utils/GameInfo";
import { Tooltip } from "../../Tooltip/Tooltip";
import classNames from "classnames";
import { GameWinnerInterface, HighLowSetting } from "poker-cows-common";

interface WinnersSectionProps {
  isRulePage: boolean;
  isCurrentGameOption: boolean;
  gameOptions: ReturnType<typeof getGameOptions>;
}

type WinnerOptionProps = {
  isRulePage: boolean;
  isCurrentGameOption: boolean;
  option: GameWinnerInterface;
  setHighLowSetting: (option: any) => void;
  currentWinnerSetting?: HighLowSetting;
};

/**
 * Winner options
 * Component used to render winner option along with selected
 */
const WinnerOption = (props: WinnerOptionProps) => {
  const { option } = props;
  const isSelected = props.currentWinnerSetting === option.value;

  if (props.isRulePage) {
    return (
      <div className="winnersRuleOption" key={option.description}>
        <div
          className={classNames("optionDescription", {
            selected: props.isCurrentGameOption && isSelected,
          })}
        >
          {option.description}
        </div>
      </div>
    );
  }

  return (
    <div
      key={option.description}
      data-testid={`WinnerSection-${option.description}`}
      className={classNames("winnersOption", {
        selected: isSelected,
      })}
    >
      <label
        htmlFor="winners"
        className="radio"
        onClick={() => props.setHighLowSetting(option.value)}
      >
        <input
          readOnly
          type="radio"
          id={`winners_${option.value}`}
          name="winners"
          className="radioInput"
          checked={isSelected}
        />
        <div className="customRadio"></div>
        <div className="radioOptionText">{option.description}</div>
        {option.tooltip && <Tooltip body={option.tooltip} />}
      </label>
    </div>
  );
};

// This is the winners section in the OptionsPage when picking a game or looking at "Rules" within the game
export const WinnersSection = (props: WinnersSectionProps) => {
  const { nextPrefs } = useSelector(selectNextPrefs);
  const dispatch = useDispatch();

  const setHighLowSetting = (value: string) => {
    const prefsString = JSON.stringify({ highLowSetting: value });
    dispatch(dealerChangePrefs(prefsString));
  };

  return (
    <div className="section">
      <div className="optionsSubSection optionTitle">
        {/* If this label changes, check out rule for `.optionTitle > span` in Settings.css */}
        <span>Winner(s):</span>
        <div className="optionSelectionContainer">
          {props.gameOptions?.winners?.map((option) => (
            <WinnerOption
              key={option.description}
              option={option}
              setHighLowSetting={setHighLowSetting}
              currentWinnerSetting={nextPrefs?.highLowSetting}
              isCurrentGameOption={props.isCurrentGameOption}
              isRulePage={props.isRulePage}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
