/**
 * InvalidTableCode.tsx
 * 
 * This Component is reached when user enters invalid table code
 */
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { ExtraSession } from "../ExtraSession";
import "../ExtraSession.css";

export const InvalidTableCode = () => {
  const [redirect, setRedirect] = useState(false);
  const handleClick = () => {
    setRedirect(true);
  };
  const title = "INVALID TABLE CODE";
  const contents = (
    <>
      <div className="extraSessionSubtext">
        The table code you entered is invalid. Try again.
      </div>
    </>
  );

  return (
    <>
      {redirect && <Redirect to={"/"} />}
      <ExtraSession
        title={title}
        contents={contents}
        actionButtonOnClick={handleClick}
        actionButtonText={"Continue"}
      />
    </>
  );
};
