// this is the overlay that shows in the video from of a player while they are in the process of joining. Host has
// options for admitting and declining players' entry to the game.
import React from "react";
import {
  selectPlayerAtTablePos,
  selectIsSeatTakenAtTablePos,
} from "../../../logic/player/slice";
import "./AdmitOverlay.css";
import { useSeatContext } from "./SeatContext";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLocalPlayerSeated } from "../../../logic/localPlayer/slice";
import {
  declinePlayerAdmission,
  grantPlayerAdmission,
} from "../../../logic/prompts/slice";
import Button from "../Button";
import { useModal } from "../Modal/hooks/useModal";
import { ModalName } from "../Modal/ModalConfig";
import useDevice from "../../../hooks/useDevice";

export const AdmitOverlay = () => {
  const dispatch = useDispatch();
  const { currentModalName } = useModal();
  const { isMobile } = useDevice();
  const { tablePosition } = useSeatContext();
  const { player } = useSelector(selectPlayerAtTablePos(tablePosition));
  const {
    id: joiningPlayerId,
    name: joiningPlayerName,
    consecutiveRemoveCount: joiningPlayerConsecutiveRemoveCount,
  } = player ?? { id: "", name: "", consecutiveRemoveCount: 0 };
  const { isSeatTaken } = useSelector(
    selectIsSeatTakenAtTablePos(tablePosition)
  );
  const { isLocalPlayerSeated } = useSelector(selectIsLocalPlayerSeated);

  const declineAdmission = () => {
    dispatch(declinePlayerAdmission(joiningPlayerId));
  };

  const grantAdmission = () => {
    dispatch(grantPlayerAdmission(joiningPlayerId));
  };

  if (!isSeatTaken) {
    return isLocalPlayerSeated ? null : (
      <div className="seatSpot button-glow open-seat">Click To Sit</div>
    );
  }
  const declineText =
    joiningPlayerConsecutiveRemoveCount >= 2 ? "Ban" : "Decline";

  if (isMobile && currentModalName === ModalName.InviteFriendsModal) {
    return (
      <div className="admit-overlay-text mobile">
        <span>Seat for</span>
        <span className="admit-player-name">{joiningPlayerName}</span>
      </div>
    );
  }

  return (
    <div className="admit-overlay">
      <div className="admit-overlay-text">
        <span>Admit</span>
        <span className="admit-player-name">{`${joiningPlayerName}?`}</span>
      </div>
      <div className="admit-buttons-container">
        <div className="admit-overlay-buttons">
          <Button
            onClick={declineAdmission}
            data-testid={`decline-${joiningPlayerName}`}
            className="actionButton red admit-decline-button"
          >
            <span>{declineText}</span>
          </Button>
          <Button
            onClick={grantAdmission}
            data-testid={`admit-${joiningPlayerName}`}
            className="actionButton green admit-decline-button"
          >
            <span>Admit</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
