import { BetLimit } from "../bets/BetLimit";
import { ForcedBet } from "../bets/ForcedBet";
import { BuyLastCardUpSetting } from "../enums/BuyLastCardUpSetting";
import { Game, GameOptionsInterface } from "../interfaces";
import { FiveCardDrawOptions } from "./FiveCardDrawOptions";
import { FiveCardStudOptions } from "./FiveCardStudOptions";
import { FollowTheQueenOptions } from "./FollowTheQueenOptions";
import { GutsOptions } from "./GutsOptions";
import { HighChicagoOptions } from "./HighChicagoOptions";
import { HollywoodSquaresOptions } from "./HollywoodSquaresOptions";
import { IronCrossOptions } from "./IronCrossOptions";
import { LowChicagoOptions } from "./LowChicagoOptions";
import { OmahaOptions } from "./OmahaOptions";
import { PassTheTrashOptions } from "./PassTheTrashOptions";
import { RollYourOwnOptions } from "./RollYourOwnOptions";
import { SevenCardStudOptions } from "./SevenCardStudOptions";
import { SevenTwentySevenOptions } from "./SevenTwentySevenOptions";
import { TexasDropemOptions } from "./TexasDropemOptions";
import { TexasHoldemOptions } from "./TexasHoldemOptions";
import { TheSlutOptions } from "./TheSlutOptions";

export const gameNameOptions: Record<Game, GameOptionsInterface> = {
  [Game.FiveCardDraw]: FiveCardDrawOptions,
  [Game.FiveCardStud]: FiveCardStudOptions,
  [Game.SevenCardStud]: SevenCardStudOptions,
  [Game.TexasHoldem]: TexasHoldemOptions,
  [Game.FollowTheQueen]: FollowTheQueenOptions,
  [Game.LowChicago]: LowChicagoOptions,
  [Game.HighChicago]: HighChicagoOptions,
  [Game.RollYourOwn]: RollYourOwnOptions,
  [Game.IronCross]: IronCrossOptions,
  [Game.HollywoodSquares]: HollywoodSquaresOptions,
  [Game.TheSlut]: TheSlutOptions,
  [Game.Omaha]: OmahaOptions,
  [Game.SevenTwentySeven]: SevenTwentySevenOptions,
  [Game.Texas]: TexasDropemOptions,
  [Game.PassTheTrash]: PassTheTrashOptions,
  [Game.Guts]: GutsOptions,
};

export function getDefaultSettings(game: Game | null | undefined) {
  if (!game || !gameNameOptions[game]) {
    return null;
  }

  const gameOption = gameNameOptions[game];

  const defaultVersion = gameOption.versions.find((option) => !!option.default);
  if (!defaultVersion) {
    console.log(`Undefined default version for game: ${game}`);
    throw new Error(`Undefined default version for game: ${game}`);
  }

  // Find the default options for each section
  const defaultWinner = gameOption.winners.find((option) => !!option.default);
  const defaultOption = gameOption.options.find((option) => !!option.default);
  const defaultWildcard = gameOption.wildcards.find(
    (option) => !!option.default
  );

  // defaults to Spread Limit
  let betting = {
    limitType: BetLimit.SPREAD_LIMIT,
    forcedBetType: ForcedBet.ANTES,
    allowAllIn: false,
  };
  if (defaultVersion.betLimit === BetLimit.NO_LIMIT) {
    betting = {
      limitType: BetLimit.NO_LIMIT,
      forcedBetType: ForcedBet.BLINDS,
      allowAllIn: true,
    };
  }

  return {
    highLowSetting: defaultWinner?.value,
    rollYourOwnSetting: defaultOption?.value,
    buyLastCardUpSetting:
      defaultOption?.description === "Buy Last Card Up"
        ? BuyLastCardUpSetting.ENABLED
        : BuyLastCardUpSetting.DISABLED,
    wildcardSetting: defaultWildcard?.value,
    gameFlavor: defaultVersion.flavor,
    ...betting,
  };
}
