/**
 * StartMenuModal.tsx
 *
 * This is just a wrapper around the StartMenu
 */
import React from "react";

import { StartMenu } from "../../ActionArea/StartMenu";

import { ModalProps } from "../types/modalTypes";

export const StartMenuModal = (props: ModalProps): JSX.Element => {
  return <StartMenu {...props} />;
};
