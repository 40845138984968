/**
 * localPlayer.ts
 * This thunk used to call async api from localPlayer and update in localPlayer redux store
 */
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Requests from "./api";
import { PlayerState } from "../logicTypes";

export const getLocalPlayer = createAsyncThunk("localPlayer/get", async () =>
  Requests.getLocalPlayerDetails()
);

export const updateLocalPlayer = createAsyncThunk(
  "localPlayer/update",
  async (player: PlayerState) => Requests.updateLocalPlayerDetails(player)
);

export const linkAccount = createAsyncThunk(
  "localPlayer/linkAccount",
  async (secondaryAuthId: string, thunkApi) => {
    await Requests.linkAccount(secondaryAuthId);
    thunkApi.dispatch(getLocalPlayer());
  }
);

export const updateEmail = createAsyncThunk(
  "localPlayer/updateEmail",
  async (email: string) => Requests.updateEmail(email)
);

export const resetPassword = createAsyncThunk(
  "localPlayer/resetPassword",
  async () => Requests.resetPassword()
);

export const checkEmail = createAsyncThunk(
  "localPlayer/checkEmail",
  async (email: string) => Requests.checkEmail(email)
);
