/**
 * HandRankingsRulesModal.tsx
 *
 * This is just a wrapper around the Rules
 */
import React from "react";

import { RulesHeader } from "../../Settings/Rules";
import { HandRankings } from "../../Settings/RulesPages/HandRankings/HandRankings";

import { ModalProps } from "../types/modalTypes";

export const HandRankingsRulesModal = (props: ModalProps): JSX.Element => (
  <>
    <RulesHeader {...props} />
    <div className="rulesModalContentContainer rules">
      <div className="rulesSection">
        <HandRankings {...props} />
      </div>
    </div>
  </>
);
