/* eslint-disable @typescript-eslint/ban-types */
import React from "react";

import { initialBuyInDenominations, PrefInterface } from "poker-cows-common";
import { Dropdown } from "../../Dropdown/Dropdown";
import { arrowStyle } from "../../ActionArea/MasterStartupComponents/NoLimitSetup";
// this is a rulesRow component used in the masterStartup modal

interface InitialBuyInContentProps {
  startupPrefs: Partial<PrefInterface>;
  onUpdateInitialSettingsByPref: (
    prefsName: string,
    prefsValue: string
  ) => void;
}

export const InitialBuyInContent = (props: InitialBuyInContentProps) => {
  const onChangeInitialBuyIn = (newValue: string) => {
    props.onUpdateInitialSettingsByPref("initialBuyIn", newValue);
  };

  return (
    <>
      <div className="dropdownContainer initialBuyInCustomFlex customDropdown">
        <div className="ante">
          <Dropdown
            value={String(props.startupPrefs.initialBuyIn)}
            setValue={onChangeInitialBuyIn}
            options={initialBuyInDenominations}
            arrowStyle={arrowStyle}
          />
        </div>
      </div>
      per Player
    </>
  );
};
