/**
 * TableSettings.tsx
 *
 * This is a central component of TableSettingsModal which gives host the option to update the prefs
 */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrefInterface } from "poker-cows-common";
import { RulesRow } from "../RulesPages/RulesRow";
import { changePrefs } from "../../../../logic/prompts/slice";
import SpreadLimitSetup from "../../ActionArea/MasterStartupComponents/SpreadLimitSetup";
import { selectNextPrefs } from "../../../../logic/table/slice";
import { ChipDenomSetupContent } from "../../ActionArea/MasterStartupComponents/ChipDenomSetupContent";
import {
  betSettingsInvariant,
  formatMoneyString,
} from "../../../../utils/MoneyFormat";
import { NoLimitSetupTitle } from "../../ActionArea/MasterStartupComponents/NoLimitSetupTitle";
import { SpreadLimitSetupTitle } from "../../ActionArea/MasterStartupComponents/SpreadLimitSetupTitle";
import NoLimitSetup from "../../ActionArea/MasterStartupComponents/NoLimitSetup";
import { ChipDenomTitle } from "../../ActionArea/MasterStartupComponents/ChipDenomTitle";
import { ModalProps } from "../../Modal/types/modalTypes";
import Button from "../../Button";
import { InitialBuyInTitle } from "../../ActionArea/MasterStartupComponents/InitialBuyInTitle";
import { START_GAME_BUTTON } from "../../../../test-identifiers";
import { LowMethodSetupTitle } from "../../ActionArea/MasterStartupComponents/LowMethodSetupTitle";
import LowMethodSetup from "../../ActionArea/MasterStartupComponents/LowMethodSetup";

export const TableSettings = (props: ModalProps) => {
  const { nextPrefs } = useSelector(selectNextPrefs);
  const [newPrefs, setNewPrefs] = useState<Partial<PrefInterface>>(
    nextPrefs ?? {}
  );

  const dispatch = useDispatch();

  // set newPrefs, and tableInitialized to true so we know the masterStartup process has been triggered and dont show this screen again.
  useEffect(() => {
    setNewPrefs({
      ...newPrefs,
      areBlindsEditable: nextPrefs?.areBlindsEditable, // need to make sure these stay in sync, since it is changed in NoLimitSetupTitle
      tableInitialized: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(nextPrefs)]);

  // this runs when one of the dropdowns is changed and sets the new preferences
  function onUpdateInitialSettingsByPref(
    prefsName: string,
    prefsValue: string
  ) {
    betSettingsInvariant(prefsName, prefsValue, newPrefs, (updatedPrefs) => {
      setNewPrefs(updatedPrefs);
    });
  }

  const onUpdatePrefs = (prefsName: string, prefsValue: string) => {
    setNewPrefs({
      ...newPrefs,
      [prefsName]: prefsValue,
    });
  };

  // this runs when the "start game" button is clicked, it's when the prefs are officially changed in state
  function onSavePrefsClick() {
    const prefsString = JSON.stringify(newPrefs).replace(" ", "");
    // bypassPromptFidelity is here to ensure that host can always submit, even when they are not the dealer
    dispatch(
      changePrefs({ newPrefs: prefsString, bypassPromptFidelity: true })
    );
    props.onBack();
  }

  return (
    <>
      <div className="hostControlsSubpageContainer tableSetting">
        <div className="table">
          <div className="tableRow">
            <RulesRow
              className="section"
              title={<InitialBuyInTitle />}
              content={
                <div>
                  {formatMoneyString(nextPrefs?.initialBuyIn)}&nbsp; per Player{" "}
                </div>
              }
              isTitleBold={true}
              isContentBigger={true}
            />
            <RulesRow
              className="section"
              title={<SpreadLimitSetupTitle />}
              content={
                <SpreadLimitSetup
                  startupPrefs={newPrefs}
                  onUpdateInitialSettingsByPref={onUpdateInitialSettingsByPref}
                />
              }
              isTitleBold={true}
              isContentBigger={true}
            />
            <RulesRow
              className="chipDenomination section"
              title={<ChipDenomTitle />}
              content={
                <ChipDenomSetupContent
                  startupPrefs={newPrefs}
                  onUpdateInitialSettingsByPref={onUpdateInitialSettingsByPref}
                />
              }
              isTitleBold={true}
              isContentBigger={true}
            />
            <RulesRow
              className="section"
              title={<LowMethodSetupTitle />}
              content={
                <LowMethodSetup
                  startupPrefs={newPrefs}
                  onUpdateInitialSettingsByPref={onUpdatePrefs}
                />
              }
              isTitleBold={true}
              isContentBigger={true}
            />
            <RulesRow
              className="section"
              title={<NoLimitSetupTitle />}
              content={
                <NoLimitSetup
                  startupPrefs={newPrefs}
                  onUpdateInitialSettingsByPref={onUpdateInitialSettingsByPref}
                />
              }
              isTitleBold={true}
              isContentBigger={true}
            />
          </div>

          <footer className="modalFooter">
            <Button
              data-testid={START_GAME_BUTTON}
              onClick={onSavePrefsClick}
              className={"modalButtonPrimary"}
            >
              SAVE TABLE SETTINGS
            </Button>
          </footer>
        </div>
      </div>
    </>
  );
};
