/**
 * PlayerInfo.ts
 *
 * This utils checks the player status and gets session info related to player
 */
import { PlayerStatus } from "poker-cows-common";
import { SessionKeys, getSession } from "./SessionSettings";

export function isAdmitted(playerStatus: string) {
  return (
    playerStatus === PlayerStatus.ADMITTED ||
    playerStatus === PlayerStatus.HOST ||
    playerStatus === PlayerStatus.WAITING
  );
}

/* we need to persist if the local user has seen a remote user's discard dots.
  it's stored as a JSON string in sessionStorage. it's format is:
  {
    "remoteUserId": bool
  }
*/
export function getSessionHasSeenDiscardRecord() {
  const hasSeenRecordString = getSession(SessionKeys.hasSeenDiscardInfo);

  if (hasSeenRecordString) {
    return JSON.parse(hasSeenRecordString);
  } else {
    return {};
  }
}
