/**
 * StartAGameModal.tsx
 *
 * This is just a wrapper around the StartAreaContents
 */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  selectAnyBetPrompt,
  selectAnyDealPrompt,
  selectChooseGamePrompt,
} from "../../../../logic/prompts/slice";
import { StartAreaContents } from "../../ActionArea/StartArea";

import { useModal } from "../hooks/useModal";
import { ModalName } from "../ModalConfig";
import { ModalProps } from "../types/modalTypes";

export const StartAGameModal = ({
  onClose,
  ...props
}: ModalProps): JSX.Element => {
  const { showModal } = useModal();

  const { anyBetPrompt } = useSelector(selectAnyBetPrompt);
  const { anyDealPrompt } = useSelector(selectAnyDealPrompt);
  const { chooseGamePrompt } = useSelector(selectChooseGamePrompt);

  useEffect(() => {
    if (chooseGamePrompt) {
      showModal({ name: ModalName.GameTypeListModal });
    }
  }, [chooseGamePrompt, showModal]);

  useEffect(() => {
    if (anyDealPrompt || anyBetPrompt) {
      onClose();
    }
  }, [anyDealPrompt, anyBetPrompt, onClose]);

  return <StartAreaContents onClose={onClose} {...props} />;
};
