/**
 * Dashboard.tsx
 * We display the summarize data of played games with the selected options
 * Also displaying the amount win/loss by the players
 */
import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import {
  PlayedGameFilterInterface,
  PlayedGameInterface,
  PlayedGamePlayerInterface,
  downloadPlayedGames,
  getGamesInfo,
} from "../../../logic/dashboard/api";
import { GamesInfo } from "../../../utils/GameInfo";
import { formatMoneyStringWithSign } from "../../../utils/MoneyFormat";
import Loading from "../../components/Loading";
import ReactPaginate from "react-paginate";
import Button from "../../components/Button";

const formatDate = (dateInString: string) => {
  return new Date(dateInString).toLocaleString();
};

const Dashboard = () => {
  const [playedGameInfo, setPlayedGameInfo] = useState({ games: [], count: 0 });
  const [fetching, setFetching] = useState(true);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [playedGameFilter, setPlayedGameFilter] =
    useState<PlayedGameFilterInterface>({
      searchText: "",
      take: 50,
      skip: 0,
    });
  const currentPage = playedGameFilter.skip / playedGameFilter.take;
  const totalPage = Math.ceil(playedGameInfo.count / playedGameFilter.take);

  useEffect(() => {
    setFetching(true);

    (async () => {
      const { data: games, count } = await getGamesInfo(playedGameFilter);
      if (games) {
        setPlayedGameInfo({ games, count });
        setFetching(false);
      }
    })();
  }, [playedGameFilter]);

  if (fetching || !playedGameInfo) {
    return <Loading />;
  }

  const renderGamePlayer = (
    gamePlayer: PlayedGamePlayerInterface,
    index: number
  ) => {
    return (
      <div key={index}>
        {gamePlayer.playerName} ({formatMoneyStringWithSign(gamePlayer.amount)})
      </div>
    );
  };

  const renderPlayedGame = (playedGame: PlayedGameInterface, index: number) => {
    const game = GamesInfo[playedGame.gameType];
    return (
      <tr key={index}>
        <td>{index + playedGameFilter.skip + 1}</td>
        <td>{playedGame.table.code}</td>
        <td>{game.title}</td>
        <td className="host-column" title={playedGame.host.displayName}>
          {playedGame.host.displayName}
        </td>
        <td>{formatDate(playedGame.startTime)}</td>
        <td>{playedGame.stopTime && formatDate(playedGame.stopTime)}</td>
        <td>{formatMoneyStringWithSign(playedGame.gameOption.initialBuyIn)}</td>
        <td>{formatMoneyStringWithSign(playedGame.gameOption.ante)}</td>
        <td>
          {formatMoneyStringWithSign(playedGame.gameOption.spreadMinRaise)}
        </td>
        <td>
          {formatMoneyStringWithSign(playedGame.gameOption.spreadMaxRaise)}
        </td>
        <td>{playedGame.gameOption.lowestHand}</td>
        <td>{playedGame.tablePlayedGamePlayers.map(renderGamePlayer)}</td>
      </tr>
    );
  };

  const handlePageClick = (event: { selected: number }) => {
    setPlayedGameFilter({
      ...playedGameFilter,
      skip: event.selected * playedGameFilter.take,
    });
  };

  const showingEntries = () => {
    if (playedGameInfo.games.length < playedGameFilter.take) {
      return playedGameInfo.games.length + playedGameFilter.skip;
    }
    return playedGameFilter.skip + playedGameFilter.take;
  };

  // fetch data from game server in blob format
  const onClickDownload = async () => {
    setDownloading(true);
    try {
      const data = await downloadPlayedGames();

      const currentDateTimeString = new Date()
        .toLocaleString()
        .replace(", ", "-")
        .replace(/:/g, "")
        .replace(/\//g, "-");
      const filename = `pokercows-games-played-${currentDateTimeString}.csv`;

      const a = document.createElement("a");
      a.href = URL.createObjectURL(data);
      a.setAttribute("download", filename);
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.error(`Error! while downloading the data - ${error}`);
    }

    setDownloading(false);
  };

  return (
    <div className="dashboard-container">
      <div className="options">
        <Button
          className="download-button"
          variant="green"
          onClick={onClickDownload}
          disabled={downloading}
        >
          {downloading ? "Downloading..." : "Download the complete data"}
        </Button>
      </div>
      <div className="section played-games">
        <table>
          <thead>
            <tr>
              <th className="caption">Id</th>
              <th className="caption">Code</th>
              <th className="caption">Game</th>
              <th className="caption host-column">Host</th>
              <th className="caption">StartTime</th>
              <th className="caption">EndTime</th>
              <th className="caption">Buy-In</th>
              <th className="caption">Ante</th>
              <th className="caption">Min Bet</th>
              <th className="caption">Max Bet</th>
              <th className="caption">Lowest Hand</th>
              <th className="caption">Players</th>
            </tr>
          </thead>
          <tbody>{playedGameInfo.games.map(renderPlayedGame)}</tbody>
        </table>
        <div className="page-detail">
          <div className="page-info">
            Showing {playedGameFilter.skip + 1} to {showingEntries()} of{" "}
            {playedGameInfo.count} entries
          </div>
          <div className="pagination-area">
            <ReactPaginate
              className="pagination"
              activeClassName="activePage"
              pageLinkClassName="pageLink"
              nextLinkClassName="pageLink"
              previousLinkClassName="pageLink"
              forcePage={currentPage}
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalPage}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
