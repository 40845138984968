/*
 * PlayerBet.tsx
 * This file is the PlayerBet component. It's the oval-ish indicator next to the players frame
 * that displays "checked" or the amount they bet. It is rendered by the ActionArea.tsx component
 * for the localPlayer, and rendered in Seat.tsx for all other players.
 */

import "./PlayerBet.css";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import {
  selectPlayerBetAtTablePos,
  selectHasPlayerCheckedAtTablePos,
  selectPotTotalBetPerPlayer,
  selectHasPlayerFoldedAtTablePos,
} from "../../logic/gameInstance/slice";
import { formatMoneyString } from "../../utils/MoneyFormat";
import { useAllInCheck } from "./ActionArea/BetArea/hooks/Betting";
import {
  selectPlayerIdAtTablePos,
  selectPlayerCardsAtTablePos,
} from "../../logic/player/slice";
import { selectLocalPlayerId } from "../../logic/localPlayer/slice";
import { selectAnyDelayPrompt } from "../../logic/prompts/slice";

interface PlayerBetProps {
  tablePosition: number;
}

export const PlayerBet = ({ tablePosition }: PlayerBetProps) => {
  let { playerBet } = useSelector(selectPlayerBetAtTablePos(tablePosition));
  const { hasPlayerChecked } = useSelector(
    selectHasPlayerCheckedAtTablePos(tablePosition)
  );
  const { isAllIn } = useAllInCheck({ tablePosition });
  const { playerId } = useSelector(selectPlayerIdAtTablePos(tablePosition));
  const { potTotalBetPerPlayer } = useSelector(selectPotTotalBetPerPlayer);
  const { hasPlayerFolded } = useSelector(
    selectHasPlayerFoldedAtTablePos(tablePosition)
  );
  const { playerCards } = useSelector(
    selectPlayerCardsAtTablePos(tablePosition)
  );
  const { localPlayerId } = useSelector(selectLocalPlayerId);
  const { anyDelayPrompt } = useSelector(selectAnyDelayPrompt);

  if (isAllIn && potTotalBetPerPlayer?.[playerId]) {
    playerBet = potTotalBetPerPlayer[playerId];
  }

  const toDisplay = useCallback(() => {
    return hasPlayerChecked ? "Check" : formatMoneyString(playerBet);
  }, [hasPlayerChecked, playerBet])();

  if ((anyDelayPrompt && !isAllIn) || !playerCards.length) {
    return <></>;
  }

  return (
    <div
      className={classNames("player-bet", {
        top: localPlayerId === playerId && !playerCards.length,
        checked: hasPlayerChecked,
        hidden: !playerBet && !hasPlayerChecked,
        allIn: isAllIn,
        folded: hasPlayerFolded,
      })}
    >
      <p>{toDisplay}</p>
    </div>
  );
};
