/**
 * Pot.tsx
 * this component displays the pot in the middle, under game info header
 */

import classNames from "classnames";
import { SidePotInterface } from "poker-cows-common";
import React from "react";
import { useSelector } from "react-redux";
import { selectPrefs, selectSidePots } from "../../logic/gameInstance/slice";
import { formatMoneyStringPreferInteger } from "../../utils/MoneyFormat";
import styles from "./Pot.module.css";
import { SidePotsTotals } from "./SidePotsTotals";
import { selectIsBotTable } from "../../logic/table/slice";

interface PotProps {
  amount: number;
}

const Pot = ({ amount }: PotProps) => {
  const { prefs } = useSelector(selectPrefs);
  const practiceMode = prefs?.practiceMode;

  const { isBotTable } = useSelector(selectIsBotTable);
  const formattedTotal = formatMoneyStringPreferInteger(amount);
  const { sidePots } = useSelector(selectSidePots);

  const hasSidePots = sidePots?.length > 1;
  const sidePotsWithValue = sidePots?.filter(
    (sidePot: SidePotInterface) => sidePot.totalAmount > 0
  );
  const shouldUseSidePotInterface =
    hasSidePots && sidePotsWithValue?.length > 1;

  if (shouldUseSidePotInterface) {
    return (
      <SidePotsTotals
        overallTotal={formattedTotal}
        sidePots={sidePotsWithValue}
      />
    );
  }

  return (
    <div className={styles.potContainer}>
      <div
        className={classNames({
          [styles.practiceModeContainer]: practiceMode,
          [styles.botModeContainer]: isBotTable,
        })}
      >
        {practiceMode && (
          <div className={styles.practiceModeText}>PRACTICE MODE</div>
        )}
        {isBotTable && (
          <div className={styles.practiceModeText}>PLAYING WITH BOTS</div>
        )}
        <div className={styles.potAmount}>{amount && formattedTotal}</div>
      </div>
    </div>
  );
};

export default Pot;
