import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import * as Sentry from "@sentry/react";

export function initLogRocket() {
  LogRocket.init("lj1dzo/pokercows");
  setupLogRocketReact(LogRocket);

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
}
