/** FiveCardDrawRules.tsx
 *
 * In-Game Rules for FiveCardDraw, shown when you click on "Rules" in the browser.
 */
import { GameRulesInterface } from "poker-cows-common";
import FiveCardDrawRulesImagePlayer from "../../static/graphics/rules/Five_Card_Draw_Player_Hand.svg";

export const FiveCardDrawRules: GameRulesInterface = {
  objectives: ["Best 5 card poker hand"],
  card: { default: ["5 player cards dealt down"] },
  image: { player: FiveCardDrawRulesImagePlayer },
  actions: [
    "1. Each Player is dealt 5 cards face down.",
    "2. Bet, starting left of dealer.",
    "3. Players replace 0 to 3 cards, starting left of dealer.",
    "4. Bet, starting left of dealer.",
    "5. Players declare “High” or “Low” or “Both” (if a High-Low Split game).",
    "6. Cards displayed; winners paid.",
  ],
};
