/**
 * BottomLeft.tsx
 *
 * This component gives localPlayer the buttons when they get inside a table
 * For Host: Host controls and Rules
 * For Player: Help and Rules
 */
import React from "react";
import { useSelector } from "react-redux";

import { GamePrefsInfo } from "./GamePrefsInfo";
import { RulesButton } from "../Settings/Rules";
import { HostControlsButton } from "../Settings/HostControls";
import {
  selectNextPrefs,
  selectIsLocalPlayerHost,
} from "../../../logic/table/slice";
import { HelpButton } from "../Settings/HelpPage";

export const BottomLeft = () => {
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);
  const { nextPrefs } = useSelector(selectNextPrefs);

  return (
    <>
      <div className="buttonsBottomLeft">
        <div className="buttonsBottomRow">
          {isLocalPlayerHost ? <HostControlsButton /> : <HelpButton />}
          <RulesButton />
        </div>
        {nextPrefs?.tableInitialized && (
          <div className="buttonsBottomRow buttonsBottomRowCentered">
            <GamePrefsInfo />
          </div>
        )}
      </div>
    </>
  );
};
