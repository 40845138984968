/**
 * This is an abstraction of the Chip component.
 * It is meant only to be used within ChipBetArea.
 */
import React from "react";
import { useSelector } from "react-redux";
import { selectIsBetNoLimit } from "../../../../logic/gameInstance/slice";
import { Chip } from "../../Chip";
import { useModal } from "../../Modal/hooks/useModal";
import { ModalName } from "../../Modal/ModalConfig";
import { useSeatContext } from "../../Seat/SeatContext";
import { BettingProps, useAllInCheck, useBetting } from "./hooks/Betting";

export interface BetAreaChipProps extends BettingProps {
  denom: string;
  chipValue: number;
  currentRaiseAmount: number;
  increaseCurrentRaiseAmount: (increaseAmount: number) => void;
}

export const BetAreaChip = ({
  denom,
  chipValue,
  currentRaiseAmount,
  increaseCurrentRaiseAmount,
  maxRaise,
  minRaise,
}: BetAreaChipProps) => {
  const { showModal } = useModal();
  const { tablePosition } = useSeatContext();
  const { isAllIn } = useAllInCheck({ tablePosition });

  const {
    callAmount,
    currentBet,
    maxRaises,
    noLimitMinRaise,
    numberOfRaises,
    bank,
  } = useBetting({ maxRaise, minRaise });
  const { isBetNoLimit } = useSelector(selectIsBetNoLimit);

  const willExceedAllIn = bank < currentRaiseAmount + currentBet + chipValue;

  const hasEnoughMoneyToUseChip = bank >= chipValue;
  const hasEnoughMoneyToBet = bank >= currentBet + chipValue;
  const hasEnoughMoneyToRaise =
    bank >= currentBet + currentRaiseAmount + chipValue;

  const canMeetMinBet = currentRaiseAmount + chipValue >= noLimitMinRaise;
  const hasPassedMaxRaise = currentRaiseAmount >= maxRaise;
  const hasRaisedTooMuch = numberOfRaises >= maxRaises;

  /**
   * In a no-limit setting, the chip can be used if all of the following are true:
   *  1. The localPlayer has enough money to use it.
   *  2. With or without the addition of one chip, the localPlayer will meet the minimum required bet/raise.
   *  3. LocalPlayer isn't all-in.
   * 4. The addition of the chip won't cause the localPlayer to go PAST the all-in amount.
   *
   * In other settings, the chip can be used if all of the following are true:
   *  1. The current raise amount is less than the max raise allowed.
   *  2. There are still raises left.
   */
  const isDisabled = isBetNoLimit
    ? isAllIn || !hasEnoughMoneyToUseChip || !canMeetMinBet || willExceedAllIn
    : hasPassedMaxRaise || hasRaisedTooMuch;

  const isMinimumChipAvailable = maxRaise >= minRaise;

  const isPlusIconShowing = callAmount > 0;

  return (
    <Chip
      denom={denom}
      value={chipValue}
      raise={isPlusIconShowing}
      disabled={isDisabled}
      onClick={() => {
        if (
          (!isMinimumChipAvailable ||
            !hasEnoughMoneyToUseChip ||
            !hasEnoughMoneyToBet ||
            (isPlusIconShowing && !hasEnoughMoneyToRaise)) &&
          !isBetNoLimit
        ) {
          showModal({ name: ModalName.GetMoreChipsModal });
          return;
        }
        if (!isDisabled) {
          increaseCurrentRaiseAmount(chipValue);
        }
      }}
    />
  );
};
