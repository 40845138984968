import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./AuthButton.css";
import userImage from "../../../static/graphics/User.svg";
import { SIGN_IN_BUTTON } from "../../../test-identifiers";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div
      data-testid={SIGN_IN_BUTTON}
      className="SignInOutButton"
      onClick={loginWithRedirect}
    >
      <img
        src={userImage as unknown as string}
        alt="user"
        className="authButtonIcon"
      />
      Sign In
    </div>
  );
};

export default LoginButton;
