/**
 * BankingLedger.tsx
 *
 * This is the central component BankingLedgerModal which gives you the banks information of the players and actions that you can take around the bank
 */
import React from "react";
import { useSelector } from "react-redux";

import { PlayerInterface } from "poker-cows-common";
import { useNetBalance } from "../../../../../logic/gameInstance/hooks";
import { selectPotTotalAmount } from "../../../../../logic/gameInstance/slice";
import { selectPlayersInvolvedInLedger } from "../../../../../logic/player/slice";
import { LedgerRow } from "./LedgerRow";

import { useModal } from "../../../Modal/hooks/useModal";
import { ModalName } from "../../../Modal/ModalConfig";
import { ModalProps } from "../../../Modal/types/modalTypes";
import { ChipMovementContainer } from "./ChipsMovementContainer";

export const BankingLedger = (props: ModalProps) => {
  const { showModal } = useModal();

  const playerClick = (player: PlayerInterface) => {
    showModal({
      name: ModalName.BankingLedgerForCertainPlayerModal,
      additionalProps: { selectedPlayerId: player?.id },
    });
  };

  return (
    <>
      <div className="hostControlsSubpageContainer bankingLedgerModalContainer">
        <BankingLedgerTable navigate={playerClick} />
        <footer className="modalFooter bankingLedgerModalFooter">
          <ChipMovementContainer />
        </footer>
      </div>
    </>
  );
};

export const BankingLedgerTable = (props: {
  static?: boolean;
  navigate: (p: any) => void;
}) => {
  const { playersInvolvedInLedger } = useSelector(
    selectPlayersInvolvedInLedger
  );
  const { potTotalAmount } = useSelector(selectPotTotalAmount);
  const { netBalance } = useNetBalance();

  const PotLedgerRow = () => {
    return (
      <LedgerRow
        playerName="Current Pot Amount"
        bankBalance={0}
        chipBalance={potTotalAmount}
        netBalance={potTotalAmount}
        isStatic={true}
        systemNetBalance={netBalance}
        isEndRow={true}
      />
    );
  };

  return (
    <>
      <div
        className={
          props.static ? "ledgerTable staticLedgerTable" : "ledgerTable"
        }
      >
        <LedgerRow isHeader={true} />
        {playersInvolvedInLedger.map((player) => {
          const playerNetBalance = player.bank - player.deposit;
          return (
            <LedgerRow
              key={player.name + playerNetBalance}
              isStatic={props.static}
              playerName={player.name}
              bankBalance={player.deposit}
              chipBalance={player.bank}
              netBalance={playerNetBalance}
              navigate={props.navigate}
              player={player}
            />
          );
        })}
        <PotLedgerRow />
      </div>
    </>
  );
};
