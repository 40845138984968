// this functional component is used to display the CONTENTS of
// the modal that any player (including host) sees WHEN
// the host is contemplating shutdown
// ie. Host Controls -> Cash Out (CashOut.tsx)
// and
// PreShutDownArea.tsx (for non-hosts)
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TableStatus } from "poker-cows-common";
import {
  selectIsBotTable,
  selectIsLocalPlayerHost,
  selectIsTableInPreShutdown,
  selectWhenToArchiveTable,
} from "../../logic/table/slice";
import {
  selectTableId,
  selectHostId,
  selectPokerSessionId,
  selectTableStatus,
} from "../../logic/table/slice";
import {
  beginShutdown,
  leaveGame,
  selectPreShutdownTime,
  shutdownNow,
  togglePreShutdown,
} from "../../logic/prompts/slice";
import { BankingLedgerTable } from "./Settings/HostControls/BankingLedger/BankingLedger";
import { Timer } from "./Timer";
import { ModalProps } from "./Modal/types/modalTypes";
import { redirectToSurvey } from "../../utils/RedirectToSurvey";
import Button from "./Button";

import "./PreShutdownContents.css";
import {
  CASH_ME_OUT_AND_LEAVE_BUTTON,
  END_GAME_SESSION_BY_HOST_BUTTON,
  SHUTDOWN_GAME_BUTTON,
  SHUTDOWN_TIMER_AREA,
} from "../../test-identifiers";

export const PreShutDownContents = (props: ModalProps) => {
  const dispatch = useDispatch();
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);
  const { archiveTableTime } = useSelector(selectWhenToArchiveTable);
  const { preShutdownTime } = useSelector(selectPreShutdownTime);
  const { tableStatus } = useSelector(selectTableStatus);
  const isPostGame = tableStatus === TableStatus.POST_GAME;

  const [endGameSession, setEndGameSession] = useState(false);

  useEffect(() => {
    if (isPostGame) {
      setEndGameSession(true);
    }
  }, [isPostGame]);

  // redirecting to survey may be re-implemented in the future, commenting out for now according to SoW
  /* const { hostId } = useSelector(selectHostId);
  const { pokerSessionId } = useSelector(selectPokerSessionId);
  const { tableId } = useSelector(selectTableId); */
  const { isBotTable } = useSelector(selectIsBotTable);
  const { isTableInPreShutdown } = useSelector(selectIsTableInPreShutdown);

  const handleBeginShutdown = () => {
    dispatch(beginShutdown());
    // TODO: cash players out
    // TODO: generate game report
    setEndGameSession(true);
    // redirecting to survey may be re-implemented in the future, commenting out for now according to SoW
    /* redirectToSurvey({ tableId, isBotTable, hostId, pokerSessionId }); */
  };

  const handleCancel = () => {
    if (isLocalPlayerHost && isTableInPreShutdown) {
      dispatch(togglePreShutdown());
    }
    props.onBack();
  };

  const handleShutdownNow = () => {
    dispatch(shutdownNow());
  };

  const leaveTableClick = () => {
    dispatch(leaveGame());
   // redirecting to survey may be re-implemented in the future, commenting out for now according to SoW
  };

  return (
    <>
      <div className="hostControlsSubpageContainer hostControlsCashoutContainer">
        {!isLocalPlayerHost && !isPostGame && (
          <div className="endSessionNonHostSubHeader">
            The host has started the cash out & <br />
            end poker session process
          </div>
        )}

        <BankingLedgerTable static={true} navigate={props.onBack} />

        {/* body contents before clicking end game */}
        <footer className="modalFooter blackBackground">
          <div className="hostControlsCashoutFooter">
            {isLocalPlayerHost && !endGameSession && (
              <>
                {
                  <div className="hostControlsCashoutHeader">
                    {/* This is removed for the alpha launch */}
                    <p className="preShutdownHeader">
                      This cashes out all players and ends the game session.
                    </p>
                  </div>
                }
                <div>
                  <Button
                    data-testid={END_GAME_SESSION_BY_HOST_BUTTON}
                    className="resetHandResetButton actionButton green"
                    onClick={() => handleBeginShutdown()}
                  >
                    YES, END THE GAME SESSION *
                  </Button>
                </div>
                {!isBotTable && (
                  <p className="resetButtonSubtext">
                    *Free video chat will remain active for 10 minutes
                  </p>
                )}
                <Button
                  className="resetHandCancelButton actionButton cancelButton"
                  onClick={handleCancel}
                >
                  NO, CANCEL
                </Button>
              </>
            )}

            {/* body contents if clicked end game */}
            {isPostGame && (
              <>
                {(archiveTableTime || preShutdownTime) && (
                  <div className="verticalTimer" data-testid={SHUTDOWN_TIMER_AREA}>
                    Video chat will remain active for:
                    <Timer deadline={archiveTableTime ?? preShutdownTime} />
                    <div className="minutesText"> MINUTES </div>
                  </div>
                )}
                {<Button
                  data-testid={SHUTDOWN_GAME_BUTTON}
                  className="endVideoButton actionButton"
                  onClick={isLocalPlayerHost ? handleShutdownNow : leaveTableClick}
                >
                  {isLocalPlayerHost && (
                    <>
                      END VIDEO CHAT NOW & <br />
                      DISCONNECT FROM POKERCOWS
                    </>
                  )}
                  {!isLocalPlayerHost && <>LEAVE TABLE</>}
                </Button>}
              </>
            )}
          </div>
        </footer>
      </div>
    </>
  );
};
