import { Rank } from "../enums/Rank";
import { Suit } from "../enums/Suit";

/**
 * This class handles tracking which cards are considered wild.
 * You can set entire ranks, suits, or specific cards as wild.
 * Wild constraints are labeled with a string so that you can track multiple "types"
 * of wild cards and easily change one without effecting others.
 */
export class Wildcards {
  /**
   * Store wild card constraints as a key/value mapping.
   * key - string label, often the "reason" for a wild card (ex: lowHoleCard)
   * value - a wild card constraint.
   *
   * Wild card constraints are an object with one or both of the [rank, suit] properties.
   * If a property is not present, that property is not considered when determining if a card is wild.
   *
   * Ex: "lowHoleCard": { rank: 4 }
   *  - Any card with a rank of 4 is considered wild.
   * Ex: "centerCard": { rank: 4, suit: 6 }
   *  - The card with rank 4 and suit 6 is considered wild.
   */
  _wildcards: { [key: string]: WildcardConstraint } = {};

  constructor(wildcards?) {
    if (wildcards) {
      this._wildcards = wildcards;
    }
  }

  isWild(card: WildcardConstraint): boolean {
    const wildcards = Object.values(this._wildcards);
    return wildcards.some((wildcard) => {
      const validSuit =
        wildcard.suit === card.suit || wildcard.suit === undefined;
      const validRank =
        wildcard.rank === card.rank || wildcard.rank === undefined;
      return validSuit && validRank;
    });
  }

  unsetWild(label: string) {
    delete this._wildcards[label];
  }

  setRankWild(label: string, rank: number) {
    if (rank === undefined) {
      throw new Error("Wildcard rank must be defined.");
    } else if (rank >= 0 && rank <= 12) {
      this._wildcards[label] = {
        rank: rank,
      };
    } else {
      throw new Error(`card rank is not in range 0 - 12, rank: ${rank}`);
    }
  }

  setSuitWild(label: string, suit: number) {
    if (suit === undefined) {
      throw new Error("Wildcard suit must be defined.");
    } else if (suit >= 0 && suit <= 3) {
      this._wildcards[label] = {
        suit: suit,
      };
    } else {
      throw new Error(`card suit is not in range 0 - 3, suit: ${suit}`);
    }
  }

  setWild(label: string, wildConstraint: WildcardConstraint) {
    this._wildcards[label] = wildConstraint;
  }
}

/**
 * A wild card constraint is an object with one or both of the [rank, suit] properties.
 */
export type WildcardConstraint = {
  rank?: Rank;
  suit?: Suit;
} & ({ rank: Rank } | { suit: Suit });
