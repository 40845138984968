/** LowChicagoOptions.tsx
 *
 * Game Options for LowChicago.
 */
import { BetLimit } from "../bets/BetLimit";
import { CardVersionSetting } from "../enums/CardVersionSetting";
import { GameOptionsInterface } from "../interfaces";
import {
  optionNoneDefault,
  optionRollYourOwn,
  wildcardDeuces,
  wildcardLowHoleWithBuy,
  wildcardNoneDefault,
  wildcardOneEyedJacks,
  winnerHighOnlyDefault,
} from "./defaultOptions";

export const LowChicagoOptions: GameOptionsInterface = {
  versions: [
    {
      title: "7 Cards",
      description: "",
      value: CardVersionSetting.SEVEN_CARD,
      default: true,
      flavor: 7,
      betLimit: BetLimit.SPREAD_LIMIT,
    },
  ],
  winners: [
    {
      ...winnerHighOnlyDefault,
      description: "High hand splits with low spade in the hole",
    },
  ],
  options: [
    optionNoneDefault,
    { ...optionRollYourOwn, description: "Roll Your Own (First card)" },
  ],
  wildcards: [
    wildcardNoneDefault,
    wildcardLowHoleWithBuy,
    wildcardDeuces,
    wildcardOneEyedJacks,
  ],
};
