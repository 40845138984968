/** TexasHoldemOptions.tsx
 *
 * Game Options for TexasHold'em.
 */
import { BetLimit } from "../bets/BetLimit";
import { CardVersionSetting } from "../enums/CardVersionSetting";
import { GameOptionsInterface } from "../interfaces";
import {
  optionNoneDefault,
  wildcardDeuces,
  wildcardLowHole,
  wildcardNoneDefault,
  wildcardOneEyedJacks,
  winnerHighOnlyDefault,
} from "./defaultOptions";

export const TexasHoldemOptions: GameOptionsInterface = {
  versions: [
    {
      title: "No-Limit",
      description: "No-Limit w/ Blinds",
      value: CardVersionSetting.SEVEN_CARD,
      default: true,
      flavor: 7,
      betLimit: BetLimit.NO_LIMIT,
    },
    {
      title: "Spread-Limit",
      description: "Spread-Limit w/ Antes",
      value: CardVersionSetting.SEVEN_CARD,
      flavor: 7,
      betLimit: BetLimit.SPREAD_LIMIT,
    },
  ],
  winners: [winnerHighOnlyDefault],
  options: [optionNoneDefault],
  wildcards: [
    wildcardNoneDefault,
    wildcardLowHole,
    wildcardDeuces,
    wildcardOneEyedJacks,
  ],
};
