/**
 * PlayerInfo.tsx
 * This component used to display player name and current balance in seat
 */
import classNames from "classnames";
import React from "react";

interface Props {
  name: string;
  balance: string;
}

const PlayerInfo = ({ name, balance }: Props) => {
  return (
    <>
      <div
        className={classNames("playerInfoLeft", {
          scrollText: name.length > 10,
        })}
      >
        <span title={name}>{name}</span>
      </div>
      <div>
        <span className="balance">{balance}</span>
      </div>
    </>
  );
};

export default PlayerInfo;
