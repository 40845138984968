// UsersAdminView.tsx
//
// Admin panel that lists all the users and allows admins to grant a 30 day free trial to users

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllUsers } from "../../../logic/admin/user/slice";
import {
  extendUserPremium,
  getAllUsers,
} from "../../../logic/admin/user/thunks";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import styles from "./UsersAdminView.module.css";

/**  Admin panel that lists all the users and allows admins to grant a 30 day free trial to any of them */
export const UsersAdminView = () => {
  const dispatch = useDispatch();
  const { users } = useSelector(selectAllUsers);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  if (!users) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <table>
        <thead>
          <tr>
            <th className={styles.header}>Email</th>
            <th className={styles.header}>AuthId</th>
            <th className={styles.header}>Paying</th>
            <th className={styles.header}>Premium Expires</th>
            <th className={styles.header}>Extend Premium</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={index}>
              <td>{user.email}</td>
              <td>{user.authId}</td>
              <td>{user.paying ? "YES" : "no"}</td>
              <td>{new Date(user.premiumExpiresAt).toLocaleDateString()}</td>
              <td>
                <Button
                  onClick={() =>
                    dispatch(extendUserPremium({ targetUserId: user.id }))
                  }
                >
                  Extend 30 days
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
