import React from "react";
import { SeatInfoContents } from "./SeatInfoContents";

export const SeatInfo = () => {
  return (
    <div className={"seatSpot seat"}>
      <SeatInfoContents />
    </div>
  );
};
