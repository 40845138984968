/**
 * HostShutdown.tsx
 * 
 * This Component is reached when host confirms cash out and ends session
 */
import React from "react";
import { ExtraSession } from "../ExtraSession";
import "../ExtraSession.css";

export const HostShutdown = () => {
  const handleClick = () => {
    window.location.href = "https://pokercows.com";
  };
  const title = "POKER GAME SESSION ENDED";
  const contents = (
    // TODO
    // <div className="extraSessionSubtext">You have ended your poker game session. A final report containing all player balances will be emailed to you.</div>
    <>
      <div className="extraSessionSubtext">
        You have ended your poker game session.
      </div>
      <div className="extraSessionSubtext">
        Thank you for playing on PokerCows!
      </div>
    </>
  );
  return (
    <ExtraSession
      title={title}
      contents={contents}
      actionButtonOnClick={handleClick}
      actionButtonText={"Exit PokerCows"}
    />
  );
};
