/**
 * BuyLastCardUpArea.tsx
 *
 * This component is as bottom component to buy up or decline the value
 */
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import { useSeatContext } from "../Seat/SeatContext";
import { selectPrefs } from "../../../logic/gameInstance/slice";
import { formatMoneyString } from "../../../utils/MoneyFormat";
import { selectPlayerBankAtTablePos } from "../../../logic/player/slice";
import {
  buyLastCardUp,
  declineBuyLastCardUp,
} from "../../../logic/prompts/slice";

import { ModalName } from "../Modal/ModalConfig";
import { useModal } from "../Modal/hooks/useModal";
import Button from "../Button";

import "./DeclareArea.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { BUY_LAST_CARD_UP_ACCEPT_BUTTON } from "../../../test-identifiers";

export const BuyLastCardUpArea = () => {
  const dispatch = useDispatch();
  const { tablePosition } = useSeatContext();

  const { showModal } = useModal();

  const { prefs } = useSelector(selectPrefs);
  const { playerBank } = useSelector(selectPlayerBankAtTablePos(tablePosition));

  const doBuyLastCardUp = () => dispatch(buyLastCardUp());
  const dontBuyLastCardUp = () => dispatch(declineBuyLastCardUp());

  // The buyUp value is the actual price for BuyCardUpPrompt in BuyCardUpController
  // The value is double as in the current spreadMinRaise is added with the minimum value the user has to input ie. spreadMinRaise + spreadMinRaise
  const buyUpValue = !prefs?.spreadMinRaise ? 0 : prefs?.spreadMinRaise * 2;

  return (
    <div className="declareActions">
      <div className="declareTitleText">BUY LAST CARD UP?</div>
      <div className="declareButtonsContainer">
        <Button className="actionButton red" onClick={dontBuyLastCardUp}>
          <div className="declareIcon">
            <FontAwesomeIcon icon={faTimes as IconProp} />
          </div>
          Decline
        </Button>
        <Button
          data-testid={BUY_LAST_CARD_UP_ACCEPT_BUTTON}
          className="actionButton green long"
          onClick={() => {
            if (buyUpValue < playerBank) {
              doBuyLastCardUp();
            } else {
              showModal({ name: ModalName.GetMoreChipsModal });
            }
          }}
        >
          <div className="declareIcon">
            <FontAwesomeIcon icon={faCheck as IconProp} />
          </div>
          {`Buy up ${buyUpValue ? formatMoneyString(buyUpValue) : ""}`}
        </Button>
      </div>
    </div>
  );
};
