/**
 * PreShutdownModal.tsx
 *
 * This is just a wrapper around the PreShutDownContents
 */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { TableStatus } from "poker-cows-common";
import {
  selectIsTableInPreShutdown,
  selectTableStatus,
} from "../../../../logic/table/slice";
import { PreShutDownContents } from "../../PreShutdownContents";

import { useModal } from "../hooks/useModal";
import { ModalName } from "../ModalConfig";
import { ModalProps } from "../types/modalTypes";

export const PreShutdownModal = ({
  onClose,
  ...props
}: ModalProps): JSX.Element => {
  const { showModal } = useModal();

  const { isTableInPreShutdown } = useSelector(selectIsTableInPreShutdown);
  const { tableStatus } = useSelector(selectTableStatus);

  useEffect(() => {
    if (tableStatus === TableStatus.POST_GAME) {
      showModal({
        name: ModalName.PreShutdownModal,
        title: "Final Balance Report",
      });
    }
  }, [tableStatus, showModal]);

  useEffect(() => {
    if (!isTableInPreShutdown && tableStatus !== TableStatus.POST_GAME) {
      onClose();
    }
  }, [isTableInPreShutdown, tableStatus, onClose]);

  return <PreShutDownContents onClose={onClose} {...props} />;
};
