import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { Game } from "poker-cows-common";
import { selectGameType, selectNextPrefs } from "../../../logic/table/slice";
import {
  getCardImages,
  getGameOptions,
  getGameRules,
} from "../../../utils/GameInfo";
import { OptionsPage } from "./Options/OptionsPage";
import { RulesPage } from "./Rules/RulesPage";

import "./Settings.css";

interface SettingsProps {
  rulesFirst: boolean;
}

export const Settings = (props: SettingsProps) => {
  const { gameType } = useSelector(selectGameType);
  const gameRules = useMemo(() => getGameRules(gameType), [gameType]);
  const gameOptions = useMemo(() => getGameOptions(gameType), [gameType]);
  const { nextPrefs } = useSelector(selectNextPrefs);
  const gameFlavor = nextPrefs?.gameFlavor;

  const playerHandImage = getCardImages(gameRules, gameFlavor);

  return (
    <div className="gameSetting">
      <div className="gameRules">
        <RulesPage gameRules={gameRules} playerHandImage={playerHandImage} />
      </div>

      {/* TODO: Temporary! Remove this when we have both spread-limit and no-limit Texas Hold'em. */}
      {gameType !== Game.TexasHoldem && (
        <div className="gameOptions">
          <OptionsPage
            isRulePage={false}
            isCurrentGameOption={false}
            gameRules={gameRules}
            gameOptions={gameOptions}
            playerHandImage={playerHandImage}
          />
        </div>
      )}
    </div>
  );
};
