/**
 * There are many elements that are rendered in Pokercows with an animated border and box-shadow when representing a
 * winner and important pieces of UI to make note of during a "win state". This polymorphic component is used to act as
 * a source of truth for those styles so that when a "win state" UI is adjusted, it can be adjusted (hopefully) in one
 * place.
 */

import React, { ElementType, PropsWithChildren } from "react";

import classNames from "classnames";
import styles from "./WinnerGlowBox.module.css";
import { Box, BoxProps } from "../Elements/Box/Box";

type WinnerGlowBoxProps<E extends ElementType> = PropsWithChildren<{
  isGameWinner: boolean;
  isBothWinner: boolean;
  isHighWinner: boolean;
  isLowWinner: boolean;
}> &
  BoxProps<E>;

const DEFAULT_ELEMENT = "div" as const;

export const WinnerGlowBox = <E extends ElementType = typeof DEFAULT_ELEMENT>({
  isGameWinner,
  isLowWinner,
  isHighWinner,
  isBothWinner,
  ...props
}: WinnerGlowBoxProps<E>): JSX.Element => {
  return (
    <Box
      {...props}
      className={classNames(props.className, styles.WinnerGlowBox, {
        [styles.bothWin]: isBothWinner || (isHighWinner && isLowWinner),
        [styles.highWin]: isHighWinner && !isLowWinner && !isBothWinner,
        [styles.lowWin]:
          (isLowWinner || isGameWinner) && !isHighWinner && !isBothWinner,
      })}
    />
  );
};
