import { createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { LocalPlayerState, PlayerState } from "../logicTypes";
import { chooseSeat, createTable, joinTable } from "../table/thunks";
import { SessionKeys, setSession } from "../../utils/SessionSettings";
import { getSessionHasSeenDiscardRecord } from "../../utils/PlayerInfo";
import {
  getLocalPlayer,
  updateEmail,
  updateLocalPlayer,
  checkEmail,
} from "./thunks";

// =============================== //
//              Slice              //
// ================================//
export const initialState = {
  // an array of remote localPlayer id's the local localPlayer has seen discard dots from in current game
  shownDiscard: getSessionHasSeenDiscardRecord(),
} as LocalPlayerState & PlayerState;

// =============================== //
//              Slice              //
// ================================//
export const slice = createSlice({
  name: "localPlayer",
  initialState: initialState,
  reducers: {
    setLocalPlayerId: (state, action) => {
      state.id = action.payload;
      state.seatPosition = 1;
    },
    sawDiscard: (state, action) => {
      if (!state.shownDiscard) {
        return;
      }
      state.shownDiscard[action.payload.localPlayerId] = action.payload.seen;
      setSession(
        SessionKeys.hasSeenDiscardInfo,
        JSON.stringify(state.shownDiscard)
      );
    },
    resetEmailField: (state) => {
      state.isFetching = false;
      state.isEmailInUse = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(joinTable.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });
    builder.addCase(joinTable.rejected, (state, action: any) => {
      return {
        ...state,
        error: {
          type: action.payload.errorType,
          message: action.payload.message,
        },
      };
    });
    builder.addCase(createTable.fulfilled, (state, action) => {
      // storing on session because for bot table, when we reload the page on jon table
      const localPlayerId = action.payload?.host?.id;
      if (localPlayerId) {
        setSession(SessionKeys.localPlayerId, localPlayerId);
      }

      return { ...state, ...action.payload.host };
    });
    builder.addCase(chooseSeat.fulfilled, (state, action: any) => {
      return { ...state, ...action.payload };
    });
    builder.addCase(getLocalPlayer.pending, (state, action: any) => {
      return { ...state, isFetching: true };
    });
    builder.addCase(getLocalPlayer.fulfilled, (state, action: any) => {
      return { ...state, ...action.payload.user, isFetching: false };
    });
    builder.addCase(updateLocalPlayer.fulfilled, (state, action: any) => {
      return { ...state, ...action.meta.arg };
    });
    builder.addCase(updateEmail.fulfilled, (state, action: any) => {
      return { ...state, email: action.meta.arg };
    });
    builder.addCase(checkEmail.pending, (state) => {
      return { ...state, isFetching: true };
    });
    builder.addCase(checkEmail.fulfilled, (state, action) => {
      return {
        ...state,
        isFetching: false,
        isEmailInUse: action.payload.exist,
      };
    });
  },
});

// =============================== //
//            Actions              //
// ================================//
export const { setLocalPlayerId, sawDiscard, resetEmailField } = slice.actions;

// =============================== //
//            Reducers             //
// ================================//
export default slice.reducer;

// =============================== //
//            Selectors            //
// ================================//
const selectLocalPlayer = (state: RootState) => state.localPlayer;
export const selectLocalPlayerId = createSelector(
  selectLocalPlayer,
  ({ id }) => ({
    localPlayerId: id ?? null,
  })
);
export const selectLocalPlayerName = createSelector(
  selectLocalPlayer,
  ({ name }) => ({
    localPlayerName: name ?? "",
  })
);
export const selectLocalPlayerEmail = createSelector(
  selectLocalPlayer,
  ({ email }) => ({
    localPlayerEmail: email ?? "",
  })
);
export const selectLocalPlayerHasGoogle = createSelector(
  selectLocalPlayer,
  ({ hasGoogle }) => ({
    localPlayerHasGoogle: hasGoogle ?? false,
  })
);
export const selectLocalPlayerHasFacebook = createSelector(
  selectLocalPlayer,
  ({ hasFacebook }) => ({
    localPlayerHasFacebook: hasFacebook ?? false,
  })
);
export const selectLocalPlayerIsSocial = createSelector(
  selectLocalPlayer,
  ({ isSocial }) => ({
    localPlayerIsSocial: isSocial ?? false,
  })
);
export const selectLocalPlayerPhone = createSelector(
  selectLocalPlayer,
  ({ phone }) => ({
    localPlayerPhone: phone ?? "",
  })
);
export const selectLocalPlayerFreeTrial = createSelector(
  selectLocalPlayer,
  ({ freeTrial }) => ({
    freeTrial,
  })
);
export const selectIsLocalPlayerSeated = createSelector(
  selectLocalPlayer,
  ({ seatPosition }) => ({
    isLocalPlayerSeated: seatPosition ? seatPosition > 0 : false,
  })
);
export const selectIsLocalPlayerDetailFetching = createSelector(
  selectLocalPlayer,
  ({ isFetching }) => ({
    isFetching: isFetching,
  })
);
export const selectIsEmailInUse = createSelector(
  selectLocalPlayer,
  ({ isEmailInUse }) => ({
    isEmailInUse: isEmailInUse ?? false,
  })
);
export const selectLocalPlayerSeatNumber = createSelector(
  selectLocalPlayer,
  ({ seatPosition }) => {
    return { localPlayerSeatNumber: seatPosition - 1 };
  }
);
export const selectLocalPlayerSeatNumberOffSet = createSelector(
  selectLocalPlayerSeatNumber,
  ({ localPlayerSeatNumber }) => ({
    localPlayerSeatNumberOffset: 7 - localPlayerSeatNumber,
  })
);
export const selectLocalPlayerScreenToTablePosition = (screenPosition = -1) =>
  createSelector(
    selectLocalPlayerSeatNumberOffSet,
    ({ localPlayerSeatNumberOffset }) => ({
      localPlayerScreenToTablePosition:
        (screenPosition - localPlayerSeatNumberOffset + 7) % 7,
    })
  );
export const selectWasPlayerShownDiscardedDotsByPlayerId = (playerId = "") =>
  createSelector(selectLocalPlayer, ({ shownDiscard }) => ({
    wasPlayerShownDiscardedDots: shownDiscard?.[playerId] ?? false,
  }));
export const selectLocalPlayerTwilioKey = createSelector(
  selectLocalPlayer,
  ({ twilioKey }) => ({
    localPlayerTwilioKey: twilioKey,
  })
);
