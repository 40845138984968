import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  PrefInterface,
  WildcardOption,
  BuyLastCardUpSetting,
  GameWildcardInterface,
} from "poker-cows-common";
import { getGameOptions } from "../../../../utils/GameInfo";
import { selectNextPrefs } from "../../../../logic/table/slice";
import { dealerChangePrefs } from "../../../../logic/prompts/slice";
import classNames from "classnames";
import { SpadeIcon } from "./icons/SpadeIcon";
import { HeartIcon } from "./icons/HeartIcon";
import { Tooltip } from "../../Tooltip/Tooltip";

type WildcardSectionProps = {
  isRulePage: boolean;
  isCurrentGameOption: boolean;
  gameOptions: ReturnType<typeof getGameOptions>;
};

type WildCardOptionProps = {
  isRulePage: boolean;
  isCurrentGameOption: boolean;
  option: GameWildcardInterface;
  setWildcardSetting: (option: any) => void;
  currentWildcardSetting?: WildcardOption;
};

/**
 * Wildcard options
 * Component used to render wildcard option along with selected
 */
const WildcardOptions = (props: WildCardOptionProps) => {
  const { option } = props;
  const isSelected = props.currentWildcardSetting === option.value;

  if (props.isRulePage) {
    return (
      <div className="winnersRuleOption" key={option.description}>
        <div
          className={classNames("optionDescription", {
            selected: props.isCurrentGameOption && isSelected,
          })}
        >
          {option.description}
        </div>
      </div>
    );
  }

  return (
    <div
      key={option.description}
      data-testid={`WildCardSection-${option.description}`}
      className={classNames("winnersOption", {
        selected: isSelected,
      })}
    >
      <label
        htmlFor="wildcard"
        className="radio"
        onClick={() => {
          props.setWildcardSetting(option.value);
        }}
      >
        <input
          readOnly
          type="radio"
          id={`wildcard_${option.value}`}
          name="wildcard"
          className="radioInput"
          checked={isSelected}
        />
        <div className="customRadio"></div>
        <div className="radioOptionText">
          {option.description}
          {option.value === WildcardOption.ONE_EYED_JACKS && (
            <span className="wildCardOptionIcon">
              <SpadeIcon />
              <HeartIcon />
            </span>
          )}
        </div>
        {option.tooltip && <Tooltip body={option.tooltip} />}
      </label>
    </div>
  );
};

// This is the Wildcard section in the OptionsPage when picking a game or looking at "Rules" within the game,
// it determines whether 2's jacks, kings, low hole etc. is the wild card
// low hole wild cards show up as blue, and face up cards affected by low hole in your hand should not be visible to other players
// public wild cards likes deuces, jacks, or kings will show gold and will show gold for other players when face up
export const WildcardSection = (props: WildcardSectionProps) => {
  const { nextPrefs } = useSelector(selectNextPrefs);
  const dispatch = useDispatch();

  const wildcardHasLowHoleWithBuy =
    props.gameOptions?.options?.find(
      ({ description }) => description !== "Buy Last Card Up"
    ) &&
    props.gameOptions?.wildcards?.find(
      ({ value }) => value === WildcardOption.LOW_HOLE_WITH_BUY
    );

  const setWildcardSetting = (value: WildcardOption) => {
    const settings: Partial<PrefInterface> = { wildcardSetting: value };

    if (wildcardHasLowHoleWithBuy) {
      if (value === WildcardOption.LOW_HOLE_WITH_BUY) {
        settings["buyLastCardUpSetting"] = BuyLastCardUpSetting.ENABLED;
      } else {
        settings["buyLastCardUpSetting"] = BuyLastCardUpSetting.DISABLED;
      }
    }

    dispatch(dealerChangePrefs(JSON.stringify(settings)));
  };

  return (
    <div className="section">
      <div className="optionsSubSection optionTitle">
        {/* If this label changes, check out rule for `.optionTitle > span` in Settings.css */}
        <span>Wild Card:</span>
        <div className="optionSelectionContainer wildCardOptions">
          {props.gameOptions?.wildcards?.map((option) => (
            <WildcardOptions
              key={option.description}
              option={option}
              setWildcardSetting={setWildcardSetting}
              currentWildcardSetting={nextPrefs?.wildcardSetting}
              isCurrentGameOption={props.isCurrentGameOption}
              isRulePage={props.isRulePage}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
