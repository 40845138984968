/**
 * Sound.ts
 * This utils used to play the sound on action
 */

import { Sound } from "poker-cows-common";
import checkSfx from "../static/sounds/check.wav";
import foldSfx from "../static/sounds/fold.wav";
import callSfx from "../static/sounds/chips.wav";
import awardSfx from "../static/sounds/winner-trumpet.wav";
import turnSfx from "../static/sounds/your-turn.wav";

export const playSound = (soundId: Sound) => {
  let soundSfx;
  if (soundId === Sound.FOLD) {
    soundSfx = foldSfx;
  } else if (soundId === Sound.CALL) {
    soundSfx = callSfx;
  } else if (soundId === Sound.BET) {
    soundSfx = callSfx;
  } else if (soundId === Sound.CHECK) {
    soundSfx = checkSfx;
  } else if (soundId === Sound.WINNING) {
    soundSfx = awardSfx;
  } else if (soundId === Sound.YOUR_TURN) {
    soundSfx = turnSfx;
  }

  if (soundSfx) {
    const sound = new Audio(soundSfx);
    sound.play();
  }
};
