/*
  WaitingNextGame.tsx
  This component displays for all players at the end of a round.
*/

import React from "react";
import { useSelector } from "react-redux";
import { Game, PlayerStatus } from "poker-cows-common";
import {
  selectHost,
  selectGameType,
  selectCurrentDealer,
} from "../../../logic/table/slice";
import {
  selectPrefs,
  selectPotTotalAmount,
} from "../../../logic/gameInstance/slice";

import "./WaitingNextGame.css";

export const WaitingNextGame = () => {
  const { prefs } = useSelector(selectPrefs);
  const practiceMode = prefs?.practiceMode;
  const { potTotalAmount } = useSelector(selectPotTotalAmount);
  const { currentDealer } = useSelector(selectCurrentDealer);
  const hostName = useSelector(selectHost).host?.name;
  const { gameType } = useSelector(selectGameType);
  const isGutsGame = gameType === Game.Guts;

  const isDealerExited =
    currentDealer?.status === PlayerStatus.KICKED ||
    currentDealer?.status === PlayerStatus.LEFT ||
    currentDealer?.status === PlayerStatus.DECLINED ||
    currentDealer?.status === PlayerStatus.BANNED;

  const WaitingText = () => {
    const dealerName = isDealerExited ? hostName : currentDealer?.name;

    let nextActionName = "Pass the Deck";
    if (potTotalAmount && isGutsGame) {
      nextActionName = "Deal the Cards";
    } else if (practiceMode) {
      nextActionName = "End Round";
    }

    return <span>{`Waiting for ${dealerName} to ${nextActionName}`}</span>;
  };

  return (
    <div className="waitingNextGame">
      <div className="waitingText">
        <WaitingText />
      </div>
    </div>
  );
};
