import TexasHoldemRulesImagePlayer from "../../static/graphics/rules/Texas_Holdem_Player_Hand.svg";
import TexasHoldemRulesImageCommunity from "../../static/graphics/rules/Texas_Holdem_Community_Cards.svg";
import { GameRulesInterface } from "poker-cows-common";

export const TexasHoldemRules: GameRulesInterface = {
  objectives: [
    "Best 5 card poker hand using any combination of player and community cards.",
  ],
  card: {
    nolimit: ["No-Limit w/ Blinds"],
    spreadlimit: ["Spread-Limit w/ Antes"],
  },
  image: {
    player: TexasHoldemRulesImagePlayer,
    community: TexasHoldemRulesImageCommunity,
  },
  actions: [
    "1. <b>No-Limit Only:</b> The first player left of the dealer places the small blind bet. The player to their left places the big blind bet.",
    "2. Each player is dealt 2 cards down.",
    "3. Bet, starting this round with the player left of the big blind (No-Limit) or the player left of the dealer (Spread-Limit).",
    "4. 3 community cards flipped (flop), bet, starting left of the dealer.",
    "5. 1 community card flipped (turn), bet, starting left of the dealer.",
    "6. Final community card flipped (river), bet, starting left of the dealer.",
    "7. Cards displayed; high hand winner(s) paid.",
  ],
  noteTitle: "No-Limit Only",
  notes: [
    "The minimum bet is the value of the big blind.",
    "All raises must be equal to or greater than the latest bet or raise in the round.",
  ],

  // TODO: Adjust GameRulesInterface and GameOptionsInterface to prepare for the shape of No Limits Texas Hold'em.
  // (maybe it's mean to be a flavor?)
  // actions: [
  //   "1. Each player is dealt 2 cards down with 5 community cards.",
  //   "2. Bet, starting with the player left of the dealer. Bet rotates each round",
  //   "3. 3 community cards flipped, bet.",
  //   "4. 1 community card flipped, bet.",
  //   "5. Final community card flipped, bet.",
  //   "6. Players declare “High” or “Low” (if a high-low split game)",
  //   "7. Cards displayed; winners paid",
  // ],
};
