/** TheSlutOptions.tsx
 *
 * Game Options for TheSlut.
 */
import { BetLimit } from "../bets/BetLimit";
import { CardVersionSetting } from "../enums/CardVersionSetting";
import { GameOptionsInterface } from "../interfaces";
import {
  optionNoneDefault,
  wildcardDeuces,
  wildcardNoneDefault,
  wildcardOneEyedJacks,
  winnerHighLowSplit,
  winnerHighOnlyDefault,
  winnerLowOnly,
} from "./defaultOptions";

export const TheSlutOptions: GameOptionsInterface = {
  versions: [
    {
      title: "9 Card",
      description:
        "(7 cards after players discard 2) | 4 Player Cards, MUST USE 2. | 5 Comm. Cards, MUST USE 3.",
      value: CardVersionSetting.NINE_CARD,
      flavor: 9,
      default: true,
      betLimit: BetLimit.SPREAD_LIMIT,
    },
    // {
    //     title: "7 Card",
    //     description: "2 Player Cards, MUST USE BOTH. | 5 Comm. Cards, MUST USE 3.",
    //     value: CardVersionSetting.SEVEN_CARD,
    //     default: true
    // }
  ],
  winners: [winnerHighOnlyDefault, winnerLowOnly, winnerHighLowSplit],
  options: [optionNoneDefault],
  wildcards: [wildcardNoneDefault, wildcardDeuces, wildcardOneEyedJacks],
};
