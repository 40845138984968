/*

  This is a popup styled like our tooltips. it displays
  if there's a twilio error, next to local user's twilio media stream div.
  This can be expanded in the future to be used on remote user's seats if there are
  errors subscribing to their streams, etc.

  We may eventually want to combine this with the tooltip component,
  if we make the tooltip component more generic.

*/

import { TwilioErrorType } from "poker-cows-common";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectTwilioError,
  selectTwilioPermissionsError,
} from "../../../../logic/twilio/slice";
import styles from "./TwilioErrorMessage.module.css";

export const TwilioErrorMessage = () => {
  const { twilioError } = useSelector(selectTwilioError);
  const { twilioPermissionsError } = useSelector(selectTwilioPermissionsError);
  const [errorMessage, setErrorMessage] = useState("");
  const [dismissed, setDismissed] = useState(false);

  const dismissPopup = () => setDismissed(true);

  useEffect(() => {
    if (twilioPermissionsError.audio || twilioPermissionsError.video) {
      const microphone = twilioPermissionsError.audio;
      const camera = twilioPermissionsError.video;
      let device = "";

      if (camera && microphone) {
        device = "camera and microphone";
      } else if (camera) {
        device = "camera";
      } else if (microphone) {
        device = "microphone";
      }

      setErrorMessage(
        "We are having trouble accessing your " +
          device +
          ". Check your browser permissions and refresh."
      );
    }
    // 53205 is "duplicate identity",
    // which currently occurs when user refreshes page,
    // but twilio automatically handles it, so no need to show error
    if (
      twilioError.type === TwilioErrorType.CONNECT &&
      twilioError.code !== 53205
    ) {
      setErrorMessage(
        "We were unable to connect you to the video server. Refresh your browser to try again."
      );
    }
  }, [twilioPermissionsError, twilioError]);

  if (errorMessage === "" || dismissed) {
    return <></>;
  }
  return (
    <div className={styles.errorMessage}>
      <button onClick={dismissPopup} className={styles.popupClose}>
        x
      </button>
      {errorMessage}
    </div>
  );
};
