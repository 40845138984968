import { getEndpoint, postEndpoint } from "../api";

export async function createTable(body: {
  name: string;
  nickName?: string;
  seed?: string;
  isBotTable?: boolean;
  isTestTable?: boolean;
  numberOfPlayers?: number;
  localPlayerId: string;
}) {
  return postEndpoint("api/table/create", "", {
    name: body.name,
    nickName: body.nickName ?? "",
    numberOfPlayers: body.numberOfPlayers ?? 7,
    seed: body.seed ?? "",
    isBotTable: body.isBotTable ?? false,
    isTestTable: body.isTestTable ?? false,
    localPlayerId: body.localPlayerId,
  });
}

export async function joinTable(tableInfo = {} as any) {
  return postEndpoint(
    `api/table/${tableInfo.tableId}/join`,
    tableInfo.tableGroup,
    tableInfo
  );
}

export async function getTable(code: string) {
  // tableGroup deliberately here is left blank so that
  // it can hit the orchestrator server and not the game server
  return getEndpoint(`api/table/${code}/get`, "");
}

export async function getTablePlayersNames(
  tableId: string,
  tableGroup: string
) {
  return getEndpoint(`api/table/${tableId}/player-names`, tableGroup);
}

// ================================================================================
//           NOT USED, to be removed
// TODO: @clean-unused-fe-endpoints
// ================================================================================
export function chooseSeat(payload = {} as any) {
  const endpoint = "/tables/" + payload?.tableId + "/players/";
  const result = postEndpoint(endpoint, "", payload);

  if (result) {
    result.then((body: any) => body.player);
  }
}

export function resetGame(tableId: string, localPlayerId: string) {
  return postEndpoint("/tables/" + tableId + "/game-actions", "", {
    actionType: "resetGame",
    localPlayerId,
  });
}

export function refresh(tableId: string, localPlayerId: string) {
  return postEndpoint(`/tables/${tableId}/game-actions`, "", {
    actionType: "refreshTable",
    localPlayerId,
  });
}
