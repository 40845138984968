/**
 * BotImage.ts
 *
 * This files handles matching the bot player name with their respective static image to be shown as overlay on bot's respective seat
 */
import BotImage1 from "../static/graphics/bots/Bot1.png";
import BotImage2 from "../static/graphics/bots/Bot2.png";
import BotImage3 from "../static/graphics/bots/Bot3.png";
import BotImage4 from "../static/graphics/bots/Bot4.png";
import { SessionKeys, getSession } from "./SessionSettings";

export const getBotImage = (botName: string) => {
  const filteredName = botName.toLowerCase();

  if (filteredName.includes("bot-1")) {
    return BotImage1;
  }
  if (filteredName.includes("bot-2")) {
    return BotImage2;
  }
  if (filteredName.includes("bot-3")) {
    return BotImage3;
  }
  if (filteredName.includes("bot-4")) {
    return BotImage4;
  }

  return "";
};

// check a bot playerId exist so it's a bot table
export const checkIsBotTable = () => {
  return getSession(SessionKeys.isBotTable) === "true";
};
