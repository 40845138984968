/**
 * This component is used to render the totals of all the active
 * sidebets and the overall total amount earnable at the end of
 * game.
 */

import classNames from "classnames";
import { PlayerInterface, SidePotInterface } from "poker-cows-common";
import React from "react";
import { useSelector } from "react-redux";
import { selectAllPlayers } from "../../logic/player/slice";
import { formatMoneyStringPreferInteger } from "../../utils/MoneyFormat";
import { Tooltip } from "./Tooltip/Tooltip";
import styles from "./SidePotsTotals.module.css";

interface SidePotsTotalsProps {
  sidePots: SidePotInterface[];
  overallTotal: string;
}

interface SidePotItemProps {
  sidePot: SidePotInterface;
  players: PlayerInterface[];
  potId?: string;
}

const getPlayersInvolvedInSidePot = (
  gamePlayers: PlayerInterface[],
  sidePot: SidePotInterface
) => {
  return gamePlayers.filter((player) => sidePot.playerIds.includes(player.id));
};

const SidePotItemTooltip = ({ sidePot, players, potId }: SidePotItemProps) => {
  const isWinner = (playerId: string, sidePot: SidePotInterface) => {
    return (
      sidePot.highHandWinners?.includes(playerId) ||
      sidePot.lowHandWinners?.includes(playerId)
    );
  };

  const sidePotTooltipContent = (
    <ul className={styles.playerList}>
      {players.map((player) => (
        <li
          key={`player-name-${player.name}-${potId}`}
          className={classNames({
            [styles.winner]: isWinner(player.id, sidePot),
          })}
        >
          {player.name}
        </li>
      ))}
    </ul>
  );

  return (
    <Tooltip
      body={sidePotTooltipContent}
      buttonClassNames={styles.sidepotItemTooltipButton}
    />
  );
};

const SidePotItem = ({ sidePot, players }: SidePotItemProps) => {
  // Long key here because sidepot doesn't contain anything that's 100% gauranteed to be unique
  const potId = `${sidePot.totalAmount}-${sidePot.playerIds[0]}-${sidePot.limitPerPlayer}-sidepot`;
  const involvedPlayers = getPlayersInvolvedInSidePot(players, sidePot);

  return (
    <div className={styles.sidepotItem} key={potId}>
      {formatMoneyStringPreferInteger(sidePot.totalAmount)}
      <SidePotItemTooltip
        players={involvedPlayers}
        sidePot={sidePot}
        potId={potId}
      />
    </div>
  );
};

export const SidePotsTotals = ({
  sidePots,
  overallTotal,
}: SidePotsTotalsProps) => {
  const { allPlayers } = useSelector(selectAllPlayers);

  return (
    <div className={styles.sidepotsContainer}>
      <div className={styles.sidepots}>
        {sidePots.map((sidePot, index) => (
          <SidePotItem sidePot={sidePot} players={allPlayers} />
        ))}
      </div>

      <p className={styles.totalInSidepots}>
        {`Total Amount - `}
        <span className={styles.totalInSidepotsAmount}>{overallTotal}</span>
      </p>
    </div>
  );
};
