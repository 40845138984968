/**
 * Widgets.tsx
 *
 * This component shows different widgets used prior to join a table and the dev only buttons
 */
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import HostGameWidget from "./HostGameWidget";
import JoinGameWidget from "./JoinGameWidget";
import { CreateDevTable } from "./Dev/CreateDevTable";
import { useDeveloper } from "./Dev/hooks/useDeveloper";
import { ShowTemplateModal } from "./Dev/ShowTemplateModal";
import "./Widgets.css";
import Loading from "./Loading";

const Widgets = () => {
  const developer = useDeveloper();
  const isNotProduction = process.env.NODE_ENV !== "production";
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="widgetContainer">
      <div className="widgetCenter">
        <HostGameWidget />
        <JoinGameWidget />
        {developer.isDeveloper && isNotProduction && <CreateDevTable />}
        {developer.isDeveloper && isNotProduction && <ShowTemplateModal />}
      </div>
    </div>
  );
};
export default Widgets;
