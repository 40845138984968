/**
 * GameSelectHeader.tsx
 *
 * This component is used by as header for StartAGameModal to show the passed headerImage
 */
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { SELECTED_GAME_IMAGE } from "../../../test-identifiers";

import "./GameSelectHeader.css";

interface GameSelectHeaderProps {
  header: JSX.Element;
  headerImage?: string;
  onBack?: React.MouseEventHandler;
}

export function GameSelectHeader(props: GameSelectHeaderProps) {
  // Reason for introducing state
  // We had flaky VRTs as the image being passed to header was not loaded before the screenshot
  // So, as a work around, we added a visibility test before passing the data-testid
  const [isImageVisible, setIsImageVisible] = useState(false);

  return (
    <div className="gameSelectorHeader">
      {props.onBack && (
        <div className="gameSelectBackButtonWrapper">
          <button onClick={props.onBack} className="gameSelectBackButton">
            <FontAwesomeIcon icon={faAngleLeft as IconProp} />
          </button>
        </div>
      )}
      <div className="gameSelectorTitle">{props.header}</div>
      {props.headerImage && (
        <div className="gameSelectorImage">
          <img
            data-testid={isImageVisible ? SELECTED_GAME_IMAGE : ""}
            src={props.headerImage}
            alt=""
            onLoad={() => {
              if (!isImageVisible) {
                setIsImageVisible(true);
              }
            }}
          />
        </div>
      )}
    </div>
  );
}
