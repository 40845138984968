// Toggle.ts
//
// Toggle enum that list all the available toggles for the project. Note that each toggle could be
// enabled in one deployment environment but disabled in another. When configuring the toggles in
// the .env file or the deployment/.ebextensions/03.env.config you can specify the list of
// deployments for which a certain toggle is enabled (e.g. TOGGLE_DEV_TABLE=LOCAL|DEV => enabled in
// local and dev deployments).
// When the list of values for a toggle contains "OFF", then the toggle is off in all deployments

export enum Toggle {
  /* enabling playing on a dev table */
  DEV_TABLE = <any>"dev_table",
  /* enabling playing on a test table (ie. with passed seed) */
  TEST_TABLE = <any>"test_table",
  /* key name for enabling mailchimp service */
  MAILCHIMP = <any>"mailchimp",
}

export function convertStringToToggle(name: string): Toggle | undefined {
  for (const key of Object.keys(Toggle)) {
    if (Toggle[key] === name) {
      return Toggle[key];
    }
  }
  return undefined;
}
