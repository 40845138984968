// thunk.ts
//
// All the thunks for the users interactions part of the admin functionality

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getEndpoint, postEndpoint } from "../../api";

/** Fetches all users from the server */
export const getAllUsers = createAsyncThunk("admin/users/all", async () =>
  getEndpoint(`api/user/all`, "")
);

export const extendUserPremium = createAsyncThunk(
  "admin/user/premium",
  async (body: { targetUserId: string }) =>
    postEndpoint(`api/user/premium`, "", body)
);
