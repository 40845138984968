/**
 * AuthAPIHook.tsx
 *
 * Adds an Axios hook that adds the Auth0 bearer token to all requests made to Express API.
 * This should wrap everything that needs to access the secure Express API,
 * and itself should be eventually wrapped by a Auth0Provider (because it uses useAuth0()).
 *
 */

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const AuthAPIHook = ({ children }: { children: JSX.Element }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  // Request interceptor for API calls
  axios.interceptors.request.use(
    async (config) => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });

        if (config.headers) {
          config.headers.authorization = `Bearer ${token}`;
        }
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // TODO: Response interceptor for API calls that fail authentication check,
  //       and redirects to login screen if required

  // axios.interceptors.response.use(
  //     response => response.data,
  //     async function(error) {
  //         if (error.response?.status === 401 || error?.error === "login_required") {
  //             history.push("TOOD-authentication-URL");
  //         }
  //         return Promise.reject(error);
  //     }
  // );

  return children;
};

export default AuthAPIHook;
