/** LowMethodSetupTitle.tsx
 *
 * This component displays Title for Low Method Setup and Tooltip
 */
import React from "react";
import { Tooltip } from "../../Tooltip/Tooltip";

export const LowMethodSetupTitle = () => {
  return (
    <div className="flexRulesTitle">
      <span className="title">
        <span className="rowCaption">Low Method</span>&nbsp;
        <Tooltip
          title="Low Method"
          body="Select the low hand evaluation method here. The default method is “The Wheel.” If your group prefers the 7-5 or 6-4 method, you can select that here. Any game specific rules on low evaluation will take priority over this setting."
        />
      </span>
      <span className="description">ALL GAMES</span>
    </div>
  );
};
