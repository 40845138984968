import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLocalPlayerHost } from "../../../logic/table/slice";
import {
  selectPlayerIdAtTablePos,
  selectPlayerNameAtTablePos,
} from "../../../logic/player/slice";
import { kickPlayer } from "../../../logic/prompts/slice";

interface Props {
  position: number;
}

export const KickButton = ({ position }: Props) => {
  const dispatch = useDispatch();
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);

  const { playerId } = useSelector(selectPlayerIdAtTablePos(position));
  const { playerName } = useSelector(selectPlayerNameAtTablePos(position));

  const className = isLocalPlayerHost
    ? "kickButton bankButton"
    : "kickButton bankButton hidden";

  const click = () => {
    prompt(`Kick ${playerName}?`);
    dispatch(kickPlayer(playerId));
  };

  return (
    <div className={className} onClick={click}>
      Kick
    </div>
  );
};
