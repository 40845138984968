/** GutsOptions.ts
 *
 * Game Options for Guts.
 */
import { BetLimit } from "../bets/BetLimit";
import { CardVersionSetting } from "../enums/CardVersionSetting";
import { GameOptionsInterface } from "../interfaces";
import {
  optionNoneDefault,
  wildcardNoneDefault,
  winnerHighOnlyDefault,
} from "./defaultOptions";

export const GutsOptions: GameOptionsInterface = {
  versions: [
    {
      title: "2 Card",
      description: "2 Player Cards",
      value: CardVersionSetting.TWO_CARD,
      default: true,
      flavor: 2,
      betLimit: BetLimit.SPREAD_LIMIT,
    },
    {
      title: "3 Card",
      description: "3 Player Cards",
      value: CardVersionSetting.THREE_CARD,
      flavor: 3,
      betLimit: BetLimit.SPREAD_LIMIT,
    },
  ],
  winners: [winnerHighOnlyDefault],
  options: [optionNoneDefault],
  wildcards: [wildcardNoneDefault],
};
