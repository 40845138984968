/**
 * TemplateModal.tsx
 *
 * This is just a placeholder Template for devs to use when creating a new modal
 * It explains how:
 *  - props can be used
 *  - a new modal can be called
 *  - custom header and footer
 *  - central component
 */
import React from "react";

import { ModalName } from "../ModalConfig";
import { useModal } from "../hooks/useModal";

import { ModalProps } from "../types/modalTypes";

export const TemplateModal = (props: ModalProps): JSX.Element => {
  const { showModal } = useModal();

  return (
    <>
      {/* 
          NOTE: check props.hasHeader to see if this modal requires custom header instead of the default one
      */}

      {/* Wrap around modalOverflow class to automatically handle overflow */}
      <div className="modalOverflow">
        <h1>Template Modal h1</h1>
        <h2>Template Modal h2</h2>
        <h3>Template Modal h3</h3>
        <p>Template Modal p</p>
        <br />
        <span>Template Modal span</span>
        <br />
        <div>Template Modal div</div>
        <br />
        <button>Template Modal button</button>
        <br />
        {/* <Component that needs to be shown inside the modal {...props}/> */}

        {/* 
          NOTE: there is no default footer component and if you require footer then you may/can write as follows:
        */}
        <footer className="modalFooter greenBackground">
          <button className="modalButtonSecondary" onClick={props.onClose}>
            Secondary
          </button>
          <button
            className="modalButtonPrimary"
            onClick={() =>
              showModal({
                name: ModalName.TemplateModal,
                hasCloseButton: false,
              })
            }
          >
            Primary
          </button>
        </footer>
      </div>
    </>
  );
};
