/**
 * TableSetting.ts
 * declares default settings used by master startup screen,
 * and the default test settings used by toybox tests/utils
 */

import { TableSettingInterface } from "../interfaces";

export const defaultTableSettings: TableSettingInterface = {
  initialBuyIn: 2000,
  ante: 2000 / 20 / 4,
  spreadMinRaise: 2000 / 20 / 4,
  spreadMaxRaise: 2000 / 20,
  smallBlind: 2000 / 20 / 4,
  bigBlind: 2000 / 20,
  chipDenomLow: 2000 / 20 / 4,
  chipDenomMid: 2000 / 20 / 2,
  chipDenomHigh: 2000 / 20,
};

export const testTableSettings: TableSettingInterface = {
  initialBuyIn: 500,
  ante: 1,
  smallBlind: 1,
  bigBlind: 2,
  spreadMinRaise: 1,
  spreadMaxRaise: 10,
  chipDenomLow: 1,
  chipDenomMid: 5,
  chipDenomHigh: 10,
};
