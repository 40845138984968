/**
 * AccountPhoneModal.tsx
 *
 * This is just a wrapper around the Account Phone 
 */
import React from "react";

import { ModalProps } from "../types/modalTypes";
import { Phone } from "../../../views/account/Phone";

export const AccountPhoneModal = (props: ModalProps): JSX.Element => {
  return <Phone {...props} />;
};
