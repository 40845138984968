/*
  An extrasession page for displaying and selecting payment options.
  Currently unused, until free trial period is over,
  and currently set to send you to free trial for any payment choice
*/
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";

import classNames from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "@auth0/auth0-react";

import { PaymentOptionInterface } from "poker-cows-common";
import { ExtraSession } from "../ExtraSession";
import { getOptions, checkout } from "../../../../logic/payment/api";
import { formatMoneyStringPreferInteger } from "../../../../utils/MoneyFormat";

import styles from "./paymentPlanSelect.module.css";
import { ACTIVE_SUBSCRIPTION_PLAN_CONTAINER } from "../../../../test-identifiers";
import Button from "../../../components/Button";

const usePaymentOptions = () => {
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [activeStripePriceId, setActiveStripePriceId] = useState("");

  useEffect(() => {
    getOptions().then((options) => {
      setPaymentOptions(options.options);
      setActiveStripePriceId(options.activeStripePriceId);
    });
  }, []);

  return {
    paymentOptions,
    activeStripePriceId,
  };
};

const PaymentOption = (props: {
  index: number;
  activeStripePriceId: string;
  option: PaymentOptionInterface;
  optionSelect: () => void;
}) => {
  const { index, activeStripePriceId, option, optionSelect } = props;

  const makeOptionPoint = (point: string) => {
    const pointString = point.replace(
      /\d+% ?/g,
      (match) => `<span style="color: #f0c105">${match} </span>`
    );

    return (
      <li className={styles.optionPoint} key={point}>
        <div className={styles.optionPointCheck}>
          <FontAwesomeIcon icon={faCheck as IconProp} />
        </div>
        <div
          className={styles.optionPointText}
          dangerouslySetInnerHTML={{ __html: pointString }}
        ></div>
      </li>
    );
  };
  const disabledStyle =
    option.stripePriceId === activeStripePriceId
      ? {
          pointerEvents: "none" as React.CSSProperties["pointerEvents"],
          opacity: "0.4",
        }
      : undefined;

  return (
    <div
      data-testid={disabledStyle && ACTIVE_SUBSCRIPTION_PLAN_CONTAINER}
      className={styles.paymentOption}
      style={disabledStyle}
    >
      <div
        className={classNames(styles.optionBanner, {
          [styles.blueBg]: option.name === "Monthly",
          [styles.magentaBg]: option.name === "Yearly",
        })}
      >
        {option.banner}
      </div>

      <div className={styles.optionContent}>
        <div className={styles.optionHeader}>
          <div className={styles.optionName}>{option.name}</div>
          <div className={styles.optionType}>
            {option.type}
            <br />
            {option.description}
          </div>
        </div>

        <ul className={styles.optionPoints}>
          {option.points.map(makeOptionPoint)}
        </ul>

        <div className={styles.optionPrice}>
          <span className={styles.optionPriceAmount}>
            {formatMoneyStringPreferInteger(option.price)}
          </span>
          <span className={styles.optionPricePeriod}>
            / {option.pricePeriod}
          </span>
          <div
            className={classNames(styles.optionBillingPeriod, {
              hidden: option.billingPeriod === "once",
            })}
          >
            Billed {option.billingPeriod}
          </div>
        </div>

        <Button
          data-testid={`${index}_SUBSCRIPTION_PLAN_BUTTON`}
          variant="green"
          className={classNames(styles.optionButton, "actionButton")}
          title={disabledStyle ? "Active" : "Choose"}
          onClick={optionSelect}
        >
          {disabledStyle ? "Active" : "Select"}
        </Button>
      </div>
    </div>
  );
};

const PaymentExpired = (props: {
  handleCloseClick: () => void;
  paymentOptions: Array<PaymentOptionInterface>;
  activeStripePriceId: string;
  optionSelect: (option: PaymentOptionInterface) => void;
  hasTrialPeriodExpired: boolean;
}) => {
  const contents = (
    <>
      <button
        className={classNames("exitModalButton", styles.exitModalButton)}
        onClick={props.handleCloseClick}
      />
      {props.hasTrialPeriodExpired && (
        <div className={styles.modalSubheader}>
          SELECT AN OPTION BELOW TO CONTINUE
        </div>
      )}
      <div className={styles.paymentOptionsContainer}>
        {props.paymentOptions?.map((option, index) => (
          <PaymentOption
            index={index}
            key={option.name}
            activeStripePriceId={props.activeStripePriceId}
            option={option}
            optionSelect={() => props.optionSelect(option)}
          />
        ))}
      </div>
      <ul className={styles.paymentOptionsFootnote}>
        <li>
          * A session is defined as a single table continuous poker gathering of
          up to 7 hours.
        </li>
      </ul>
    </>
  );

  return (
    <ExtraSession
      title={
        props.activeStripePriceId
          ? "SELECT PAYMENT PLAN!"
          : "YOUR FREE TRIAL HAS EXPIRED!"
      }
      contents={contents}
      modalClass="paymentPlanSelectModal"
    />
  );
};

const PaymentError = (props: { handleCloseClick: () => void }) => {
  const contents = (
    <>
      <button
        className={classNames("exitModalButton", styles.exitModalButton)}
        onClick={props.handleCloseClick}
      />
      <div className={styles.modalSubheader}>
        We are unable to process your request at this time. Please retry after a
        few minutes.
      </div>
    </>
  );

  return (
    <ExtraSession
      title={"Error!"}
      contents={contents}
      modalClass="paymentPlanSelectModal"
    />
  );
};

// this screen will show if free trial is over.
// see utils/FreeTrial.ts
// if you click Choose, it brings you to another modal that says "your free trial is extended",
// and you click continue, and it continues the usual routing.
export const PaymentPlanSelect = () => {
  const { user } = useAuth0();
  const { paymentOptions, activeStripePriceId } = usePaymentOptions();

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const hasTrialPeriodExpired = !!searchParams.get("hasTrialPeriodExpired");
  const selectedPeriod = searchParams.get("period");
  const [redirectToBilling, setRedirectToBilling] = useState(false);
  const [redirectToCheckout, setRedirectToCheckout] = useState("");
  // const [showFreeTrialExtended, setShowFreeTrialExtended] = useState(false);

  useEffect(() => {
    if (paymentOptions) {
      const selectedPaymentOption = paymentOptions.find(
        (option: PaymentOptionInterface) =>
          option.billingPeriod === selectedPeriod
      );
      if (selectedPaymentOption) {
        optionSelect(selectedPaymentOption);
      }
    }
  }, [selectedPeriod, paymentOptions]);

  const handleCloseClick = () => {
    setRedirectToBilling(true);
  };

  const optionSelect = async (option: PaymentOptionInterface) => {
    let impact_click_id = "";
    await window.ire("generateClickId", (id: string) => (impact_click_id = id));

    checkout({
      priceId: option.stripePriceId,
      email: user?.email ?? "",
      impact_click_id,
    }).then((url) => {
      setRedirectToCheckout(url);
    });
  };

  if (redirectToBilling) {
    return <Redirect to="/account/billing" />;
  }

  if (redirectToCheckout) {
    window.location.href = redirectToCheckout;
    return <></>;
  }

  if (paymentOptions === null || redirectToCheckout === null) {
    return <PaymentError handleCloseClick={handleCloseClick} />;
  }

  if (paymentOptions.length === 0) {
    return <></>;
  }

  if (selectedPeriod) {
    return <></>;
  }

  return (
    <PaymentExpired
      handleCloseClick={handleCloseClick}
      paymentOptions={paymentOptions}
      activeStripePriceId={activeStripePriceId}
      optionSelect={optionSelect}
      hasTrialPeriodExpired={hasTrialPeriodExpired}
    />
  );
};
