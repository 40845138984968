import React from "react";
import { useSelector } from "react-redux";
import { selectPrefs } from "../../../../logic/gameInstance/slice";
import { ChipBetArea } from "./ChipBetArea";

export const SpreadBetArea = () => {
  const { prefs } = useSelector(selectPrefs);

  return (
    <ChipBetArea
      maxRaise={prefs?.spreadMaxRaise ?? 0}
      minRaise={prefs?.spreadMinRaise ?? 0}
    />
  );
};
