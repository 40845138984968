/**
 * ChipDropdown.tsx
 *
 * This new Dropdown is used for the chips in the game setup screen and in-houses the basic composed dropdown which defines the span and the dropdown list around it
 * This component has no styling around it and is dependent upon the classes passed to it
 * The component itself is wrapped around @reach/dialog
 */
import React from "react";

import {
  ListboxList,
  ListboxInput,
  ListboxButton,
  ListboxPopover,
  ListboxOption,
} from "@reach/listbox";
import classNames from "classnames";

import { DownIcon } from "./icons/DownIcon";

import "@reach/listbox/styles.css";
import { DownIconLarge } from "./icons/DownIconLarge";

export const ChipDropdown = ({
  chip,
  value,
  setValue,
  options,
  defaultLabel,
  wrapperClass = "",
  buttonClass = "",
  popoverClass = "",
}: {
  chip?: JSX.Element;
  defaultLabel?: string;
  value: string | number;
  setValue: (value: string) => void;
  options: { id?: string | number; value: string | number; label: string }[];
  wrapperClass?: classNames.Argument;
  buttonClass?: classNames.Argument;
  popoverClass?: classNames.Argument;
}) => {
  if (!defaultLabel) {
    defaultLabel = options.find(
      (option) => value.toString() === option.value.toString()
    )?.label;
  }

  return (
    <ListboxInput
      value={value.toString()}
      onChange={setValue}
      className={classNames(wrapperClass)}
    >
    <ListboxButton arrow={<DownIconLarge width={28} height={28} className={classNames(buttonClass)}/>}>
        <div>
        {chip}
        </div>
    </ListboxButton>
    <ListboxPopover portal={false} className={classNames(popoverClass)}>
    <ListboxList>
        {options.map((option) => (
        <ListboxOption
            key={option.value.toString()}
            value={option.value.toString()}
        >
            {option.label}
        </ListboxOption>
        ))}
    </ListboxList>
    </ListboxPopover>
    </ListboxInput>
  );
};
