/*

  OffBalanceNotification.tsx
  This is a popup component that informs the host if the net balance is off balance 
  (total all deposits !== total all player bank + pot).
  It is rendered by EndShowdownArea,
  So clicking "Continue" here will dismiss it once,
  but it will appear at the end of every game.
*/
import React from "react";

import classNames from "classnames";

import Button from "../Button";
import { ModalProps } from "../Modal/types/modalTypes";

import styles from "./GetMoreChips.module.css";

export const OffBalanceContents = (props: ModalProps) => {
  return (
    <div className={classNames(styles.getMoreChipsModalSection)}>
      <Button
        className="chipRequestButton actionButton green"
        onClick={props.onClose}
      >
        Continue
      </Button>
    </div>
  );
};
