import React from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useSeatContext } from "../../Seat/SeatContext";
import Button from "../../Button";
import {
  selectHasPlayerFoldedAtTablePos,
  selectBetRoundID,
} from "../../../../logic/gameInstance/slice";
import {
  useExperimentalFeature,
  EXPERIMENTAL_FEATURES,
} from "../../Feature/FeatureContext";
import {
  selectFoldInTurn,
  setTargetBetRound,
  setFoldInTurn,
} from "../../../../logic/betting/slice";
import styles from "./FoldInTurnButton.module.css";

// this component displays and handles that button that lets you fold in turn
// used in <FoldArea />

export const FoldInTurnButton = () => {
  const dispatch = useDispatch();
  const { tablePosition } = useSeatContext();
  const { hasPlayerFolded } = useSelector(
    selectHasPlayerFoldedAtTablePos(tablePosition)
  );
  // TODO: how do i enable this for just no limit games?
  const checkCallInTurnEnabled = useExperimentalFeature(
    EXPERIMENTAL_FEATURES.CHECK_CALL_IN_TURN
  ).isEnabled;
  const { foldInTurn } = useSelector(selectFoldInTurn);
  const { betRoundID } = useSelector(selectBetRoundID);

  const foldClick = () => {
    dispatch(setTargetBetRound(betRoundID));
    dispatch(setFoldInTurn(!foldInTurn));
  };

  return (
    <>
      {!hasPlayerFolded && checkCallInTurnEnabled && (
        <Button
          className={classNames(
            styles.foldInTurnButton,
            "actionButton",
            "foldInTurnButton",
            {
              selected: foldInTurn,
              red: !foldInTurn,
            }
          )}
          onClick={foldClick}
        >
          {foldInTurn ? (
            <>
              CANCEL
              <br />
              FOLD
              <br />
              IN
              <br />
              TURN
            </>
          ) : (
            <>
              FOLD
              <br />
              IN
              <br />
              TURN
            </>
          )}
        </Button>
      )}
    </>
  );
};
