import { GameRulesInterface } from "poker-cows-common";
import FiveCardStudRulesImagePlayer from "../../static/graphics/rules/Five_Card_Stud_Player_Hand.svg";

export const FiveCardStudRules: GameRulesInterface = {
  objectives: ["Best 5 card poker hand"],
  card: { default: ["5 cards - 1 down and 4 up"] },
  image: { player: FiveCardStudRulesImagePlayer },
  actions: [
    "1. Each Player is dealt 1 card down and 1 card up.",
    "2. Bet, always starting with player showing best hand.",
    "3. 3 more rounds of cards dealt up with betting.",
    "4. Players declare “High” or “Low” or “Both” (if a High-Low Split game).",
    "5. Cards displayed; winners paid.",
  ],
};
