import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import "./AuthButton.css";

const redirectUrl = "https://www.pokercows.com/";
const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <div
      className="SignInOutButton"
      onClick={() => logout({ returnTo: redirectUrl })}
    >
      Log Out
    </div>
  );
};

export default withAuthenticationRequired(LogoutButton);
