/**
 * RulesPage.tsx
 *
 * This component gives user the rules around the selected game before starting a game
 */
import React from "react";

import { getCardImages, getGameRules } from "../../../../utils/GameInfo";

export const RulesPage = (props: {
  gameRules: ReturnType<typeof getGameRules>;
  playerHandImage?: string;
}) => {
  const communityImage = getCardImages(props.gameRules, null, true);

  return (
    <>
      <div className="section">
        <div className="rulePage">
          <div className="sectionContent">
            <div className="sectionTextContent">
              <div className="objective">
                <div className="sectionTextTitle">Object:</div>
                <span className="objectiveText">
                  {props.gameRules?.objectives?.map((objective) => (
                    <div key={objective}>{objective}</div>
                  ))}
                </span>
              </div>
            </div>
            <br />
            <div className="sectionTextContent">
              <div className="sectionTextTitle">The Deal:</div>
              {props.gameRules?.title && (
                <div className="dealTitle">{props.gameRules?.title}</div>
              )}
              <div className="actionText">
                {props.gameRules?.actions?.map((item) => (
                  <div key={item}>
                    <span dangerouslySetInnerHTML={{ __html: item }}></span>
                  </div>
                ))}
              </div>
              {props.gameRules?.noteTitle && (
                <div className="ruleNoteTitle">
                  {props.gameRules?.noteTitle}
                </div>
              )}
              {props.gameRules?.notes && (
                <div className="ruleNotes">
                  <ul>
                    {props.gameRules?.notes.map((note) => (
                      <li key={note}>{note}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="sectionImages">
            {communityImage ? <img src={communityImage} alt=""></img> : <></>}
          </div>
        </div>
      </div>
    </>
  );
};
