/**
 * PlayAutomaticallyButton.tsx
 *
 * Button for the host to progress the game by randomly choosing an action for the current player.
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLocalPlayerHost } from "../../../logic/table/slice";
import { playAutomatically } from "../../../logic/prompts/slice";
import { useAuth0 } from "@auth0/auth0-react";

export const PlayAutomaticallyButton = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);

  const className = isLocalPlayerHost
    ? "kickButton bankButton"
    : "kickButton bankButton hidden";

  const click = async () => {
    await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    dispatch(playAutomatically());
  };

  return (
    <div className={className} onClick={click}>
      Play Automatically
    </div>
  );
};
