/**
 * CashedOut.tsx
 * 
 * This Component is reached when player clicks cash out
 */
import React from "react";
import { useRedirect } from "../../../../hooks/useRedirect";
import { ExtraSession } from "../ExtraSession";

export const CashedOut = () => {
  const goToHomePage = useRedirect("/");

  const title = "CASHED OUT";
  const contents = (
    <div className="extraSessionSubtext">
      You have cashed out and left the table.
    </div>
  );

  return (
    <ExtraSession
      title={title}
      contents={contents}
      actionButtonOnClick={goToHomePage}
      actionButtonText={"Continue"}
    />
  );
};
