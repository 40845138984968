/**
 * Timer.tsx
 * This component accepts deadline (number of milliseconds elapsed since epoch) and return a basic countdown to the deadline
 */
import React, { useEffect, useState } from "react";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

export const Timer = ({ deadline = Date.now() }) => {
  const [time, setTime] = useState(deadline - Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(deadline - Date.now()), 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parts = Object.entries({
    hours: (time / HOUR) % 24,
    minutes: (time / MINUTE) % 60,
    seconds: (time / SECOND) % 60,
  });
  // remove the hours if it is 0
  if (parts[0][1] <= 1) {
    parts.shift();
  }

  return (
    <div className="timerNumbers">
      {parts.map(([label, value], index) => (
        <span key={label}>
          {`${Math.floor(value)}`.padStart(2, "0")}
          {index === parts.length - 1 ? "" : ":"}
        </span>
      ))}
    </div>
  );
};
