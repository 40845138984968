/**
 * OffBalanceModal.tsx
 *
 * This is just a wrapper around the OffBalanceContents
 */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { OffBalanceContents } from "../../ActionArea/OffBalance";
import { useNetBalance } from "../../../../logic/gameInstance/hooks";
import { selectIsLocalPlayerHost } from "../../../../logic/table/slice";

import { ModalProps } from "../types/modalTypes";

export const OffBalanceModal = ({
  onClose,
  ...props
}: ModalProps): JSX.Element => {
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);
  const { offBalance } = useNetBalance();

  useEffect(() => {
    if (isLocalPlayerHost && !offBalance) {
      onClose();
    }
  }, [isLocalPlayerHost, offBalance, onClose]);

  return <OffBalanceContents onClose={onClose} {...props} />;
};
