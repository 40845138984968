/**
 * This component renders the "tab content" for the profile tab of the account page.
 */

import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import styles from "./AccountPageProfileTab.module.css";
import { ModalName } from "../../components/Modal/ModalConfig";
import { useModal } from "../../components/Modal/hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocalPlayer,
  linkAccount,
  resetPassword,
  updateLocalPlayer,
} from "../../../logic/localPlayer/thunks";
import {
  selectLocalPlayerEmail,
  selectLocalPlayerHasFacebook,
  selectLocalPlayerHasGoogle,
  selectLocalPlayerIsSocial,
  selectLocalPlayerPhone,
} from "../../../logic/localPlayer/slice";
import { useAuth0Client } from "../../components/Auth/AuthClientHook";
import { PopupLoginOptions } from "@auth0/auth0-react";
import { useAccountInfo } from "./AccountPageBillingTab";

const ONE_SECOND = 1000;

export const AccountPageProfileTab = () => {
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const { accountInfo } = useAccountInfo();
  const [showMailSentText, setShowMailSentText] = useState(false);
  const { localPlayerEmail } = useSelector(selectLocalPlayerEmail);
  const { localPlayerPhone } = useSelector(selectLocalPlayerPhone);
  const { localPlayerHasFacebook } = useSelector(selectLocalPlayerHasFacebook);
  const { localPlayerHasGoogle } = useSelector(selectLocalPlayerHasGoogle);
  const { localPlayerIsSocial } = useSelector(selectLocalPlayerIsSocial);
  const { auth0Client } = useAuth0Client();
  const hasSocial = localPlayerHasGoogle || localPlayerHasFacebook;
  const hasSubscription = Boolean(accountInfo?.subscription);

  // open login auth0 popup
  // after login start linking account to primary
  // Reference: https://github.com/auth0-samples/auth0-link-accounts-sample/blob/master/SPA/public/js/app.js
  const startAuth0Linking = async (
    options: PopupLoginOptions = {
      max_age: 0,
      scope: "openid",
    }
  ) => {
    await auth0Client.loginWithPopup(options);
    const user = await auth0Client.getUser();
    if (user?.sub) {
      dispatch(linkAccount(user.sub));
    }
  };

  // open auth0 popup to signup
  // to create `Username-Password Authentiaction` account
  const createPassword = async () => {
    startAuth0Linking({
      max_age: 0,
      scope: "openid",
      login_hint: localPlayerEmail,
      screen_hint: "signup",
    });
  };

  const updatePassword = () => {
    dispatch(resetPassword());
    setShowMailSentText(true);
    setTimeout(() => setShowMailSentText(false), 10 * ONE_SECOND);
  };

  const removePhoneNumber = () => {
    dispatch(updateLocalPlayer({ phone: null }));
  };

  const phoneLabel = localPlayerPhone
    ? "Update Phone Number"
    : "Add Phone Number";

  const connectAccount = () => {
    startAuth0Linking();
  };

  const PasswordButton = () =>
    localPlayerIsSocial ? (
      <Button variant="green" onClick={createPassword}>
        Create Password
      </Button>
    ) : (
      <Button variant="green" onClick={updatePassword}>
        Update Password
      </Button>
    );

  const AddOrManageAccountButton = () =>
    hasSocial ? (
      <Button variant="green">Manage Accounts</Button>
    ) : (
      <Button variant="green" onClick={connectAccount}>
        Add Accounts
      </Button>
    );

  const connectedAccount = () => {
    let connectedAccountText = "None";

    if (hasSocial) {
      if (localPlayerHasGoogle) {
        connectedAccountText = "Google";
      } else {
        connectedAccountText = "Facebook";
      }
    }

    return connectedAccountText;
  };

  return (
    <section className={styles.tabContent}>
      <ul>
        <li>
          <div className={styles.labelAndValue}>
            <h6>Email Address</h6>
            <span>{localPlayerEmail}</span>
          </div>

          <Button
            variant="green"
            disabled={!!localPlayerIsSocial || hasSubscription}
            onClick={() => {
              if (!localPlayerIsSocial) {
                showModal({
                  name: ModalName.AccountEmailModal,
                  title: "Update Email",
                  requiresBackendVerification: false,
                });
              }
            }}
          >
            Update Email
          </Button>
        </li>

        <li>
          <div className={styles.labelAndValue}>
            <h6>Password</h6>
            <span>{localPlayerIsSocial ? "None" : "******"}</span>
          </div>

          <div className={styles.multiButtonContainer}>
            {showMailSentText && (
              <span className={styles.mailSendText}>
                We've sent an email with instructions to reset your password.
              </span>
            )}

            <PasswordButton />
          </div>
        </li>

        <li>
          <div className={styles.labelAndValue}>
            <h6>Phone Number</h6>
            <span>{localPlayerPhone ? localPlayerPhone : "None"}</span>
          </div>

          <div className={styles.multiButtonContainer}>
            {localPlayerPhone && (
              <Button variant="base" onClick={removePhoneNumber}>
                Remove <span className="sr-only">Phone Number</span>
              </Button>
            )}

            <Button
              variant="green"
              onClick={() =>
                showModal({
                  name: ModalName.AccountPhoneModal,
                  title: phoneLabel,
                  requiresBackendVerification: false,
                })
              }
            >
              <span className={styles.ellipsize}>{phoneLabel}</span>
            </Button>
          </div>
        </li>

        <li>
          <div className={styles.labelAndValue}>
            <h6>Connected Accounts</h6>
            <span>{connectedAccount()}</span>
          </div>

          <AddOrManageAccountButton />
        </li>
      </ul>
    </section>
  );
};
