/**
 * App.tsx
 *
 * This the the first component rendered which defined all the active routes
 */
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import React from "react";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import ProtectedRoute from "./ProtectedRoute";
import { ErrorBoundary } from "./error_handling/ErrorBoundary";
import { ErrorPage } from "./error_handling/ErrorPage";
import tableStore from "./logic/store";
import Auth0ProviderWithHistory from "./ui/components/Auth/Auth0ProviderWithHistory";
import AuthAPIHook from "./ui/components/Auth/AuthAPIHook";
import { FeaturesProvider } from "./ui/components/Feature/FeatureContext";
import { ModalWrapper } from "./ui/components/Modal/ModalWrapper";
import PaymentAPIHook from "./ui/components/Payment/PaymentAPIHook";
import {
  Account,
  billingPagePath,
  profilePagePath,
} from "./ui/views/account/Account";
import { UsersAdminView } from "./ui/views/admin/UsersAdminView";
import Dashboard from "./ui/views/dashboard/Dashboard";
import Dev from "./ui/views/dev/Dev";
import DevCreate from "./ui/views/dev/DevCreate";
import { CashedOut } from "./ui/views/extraSession/screens/CashedOut";
import { HostShutdown } from "./ui/views/extraSession/screens/HostShutdown";
import { InvalidTableCode } from "./ui/views/extraSession/screens/InvalidTableCode";
import { JoinRequestDeclined } from "./ui/views/extraSession/screens/JoinRequestDeclined";
import { NonHostShutdown } from "./ui/views/extraSession/screens/NonHostShutdown";
import { PaymentPlanSelect } from "./ui/views/extraSession/screens/PaymentPlanSelect";
import { RemovedByHost } from "./ui/views/extraSession/screens/RemovedByHost";
import Home from "./ui/views/home/Home";
import { StartNewTable } from "./ui/views/home/StartNewTable";
import { StartBotTable } from "./ui/views/table/StartBotTable";
import { StartBotTestTable } from "./ui/views/table/StartBotTestTable";
import { StartTestTable } from "./ui/views/table/StartTestTable";
import Table from "./ui/views/table/Table";
import { GamesAdminView } from "./ui/views/admin/GamesAdminView";

console.log("Poker Cows Version: ", process.env.REACT_APP_POKER_COWS_VERSION);

const RouteSwitch = () => (
  <Switch>
    <ProtectedRoute path="/begin-session" component={StartNewTable} />

    <Route path="/begin-test/:seed?" component={StartTestTable} />
    <Route path="/play-with-bots" component={StartBotTable} />
    <Route path="/test-with-bots/:seed?" component={StartBotTestTable} />

    <ProtectedRoute path="/payment-plan" component={PaymentPlanSelect} />

    <ProtectedRoute path="/invalid-table" component={InvalidTableCode} />

    <ProtectedRoute path="/end-session" component={HostShutdown} />
    <ProtectedRoute
      path="/end-session-for-player"
      component={NonHostShutdown}
    />
    <ProtectedRoute path="/cashed-out" component={CashedOut} />
    <ProtectedRoute
      path="/join-request-declined"
      component={JoinRequestDeclined}
    />
    <ProtectedRoute path="/removed-by-host" component={RemovedByHost} />

    <ProtectedRoute path="/table/:code" component={Table} />
    <Route path="/bot/table/:code" component={Table} />
    <ProtectedRoute path="/dev/:code" component={Dev} />
    <ProtectedRoute path="/dev" component={DevCreate} />
    <ProtectedRoute path={profilePagePath} component={Account} />
    <ProtectedRoute path={billingPagePath} component={Account} />
    <ProtectedRoute path="/dashboard" component={Dashboard} />

    {/* admin routes  */}
    <ProtectedRoute path="/admin/users" component={UsersAdminView} />
    <ProtectedRoute path="/admin/games" component={GamesAdminView} />

    {/* This should be last since `exact` is false and _all_ paths are similar to "/" */}
    <Route path="/" component={Home} />
  </Switch>
);

function App() {
  return (
    <ErrorBoundary renderTemplate={ErrorPage}>
      <ErrorBoundary>
        <FeaturesProvider>
          <Provider store={tableStore}>
            <TooltipPrimitive.Provider delayDuration={0}>
              <div className="App">
                <Router>
                  <Auth0ProviderWithHistory>
                    <AuthAPIHook>
                      <PaymentAPIHook>
                        <>
                          <RouteSwitch />
                          <ModalWrapper />
                        </>
                      </PaymentAPIHook>
                    </AuthAPIHook>
                  </Auth0ProviderWithHistory>
                </Router>
              </div>
            </TooltipPrimitive.Provider>
          </Provider>
        </FeaturesProvider>
      </ErrorBoundary>
    </ErrorBoundary>
  );
}

export default App;
