// slice.ts
//
// All the game info needed for the admin functionality

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { getAllPremiumGames, toggleGamePremium } from "./thunks";

// =============================== //
//          Initial State          //
// ================================//
const initialState: { games: string[] | undefined } = { games: undefined };

// =============================== //
//              Slice              //
// ================================//
export const slice = createSlice({
  name: "admin/games",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllPremiumGames.fulfilled, (state, action) => {
      return { ...state, games: action.payload ?? [] };
    });

    builder.addCase(getAllPremiumGames.rejected, (state, action) => {
      return { ...state, games: undefined };
    });

    builder.addCase(toggleGamePremium.fulfilled, (state, action: any) => {
      if (!state.games) {
        return { ...state };
      }

      const gameName = action.payload.gameName;
      if (!state.games.includes(gameName)) {
        return { ...state, games: [...state.games, gameName] };
      }

      return {
        ...state,
        games: state.games.filter((name) => name !== gameName),
      };
    });
  },
});

// =============================== //
//            Reducers             //
// ================================//
export default slice.reducer;

// =============================== //
//            Selectors            //
// ================================//
export const selectAllPremiumGames = (state: RootState) => state.games?.games ?? [];
