/**
 * GameTypeListModal.tsx
 *
 * This is just a wrapper around the GameTypeListContents
 */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectStartPrompt } from "../../../../logic/prompts/slice";
import { GameTypeListContents } from "../../SelectGame/GameTypeList";

import { useModal } from "../hooks/useModal";
import { ModalName } from "../ModalConfig";
import { ModalProps } from "../types/modalTypes";

export const GameTypeListModal = (props: ModalProps): JSX.Element => {
  const { showModal } = useModal();

  const { startPrompt } = useSelector(selectStartPrompt);

  useEffect(() => {
    if (startPrompt) {
      showModal({ name: ModalName.StartGameModal });
    }
  }, [showModal, startPrompt]);

  return <GameTypeListContents {...props} />;
};
