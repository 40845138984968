/**
 * ChipPurchasedModal.tsx
 *
 * This is just a wrapper around the Chip Purchased component
 */
import React from "react";

import { ModalProps } from "../types/modalTypes";
import { ChipPurchased } from "../../ActionArea/ChipPurchased";

export const ChipPurchasedModal = (props: ModalProps): JSX.Element => {
  return <ChipPurchased {...props} />;
};
