/**
 * Hand.tsx
 *
 * A react component for showing a collection of cards.
 */
import React from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";

import {
  CardInterface,
  Game,
  HighLowSetting,
  Rank,
  Suit,
} from "poker-cows-common";
import Card from "./Card";
import {
  selectPlayerCardsAtTablePos,
  selectPlayerHandAtTablePos,
  selectPlayerIdAtTablePos,
  selectIsSeatTakenAtTablePos,
} from "../../logic/player/slice";
import {
  selectIsGameRunning,
  selectDeclarationForPlayerById,
  selectHasPlayerFoldedAtTablePos,
} from "../../logic/gameInstance/slice";
import { selectLocalPlayerId } from "../../logic/localPlayer/slice";
import { selectGameType } from "../../logic/table/slice";
import { HandValue } from "./ActionArea/HandValue";
import { PlayerDeclarationIndicator } from "./PlayerDeclarationIndicator";
import { FoldIndicator } from "./Seat/FoldIndicator";
import { useCardSelection } from "./ActionArea/CardSelection/useCardSelection";
import { useSeatContext } from "./Seat/SeatContext";
import { DiscardCountIndicator } from "./DiscardCountIndicator";
import { getCardId } from "../../logic/cardSelection/slice";
import { SELECTED_CARDS_CONTAINER } from "../../test-identifiers";

import "./Hand.css";
import { selectAnyDelayPrompt } from "../../logic/prompts/slice";

interface Props {
  cardStack: CardInterface[];
  show?: boolean;
  className?: string;
  tableCards?: boolean;
  folded?: boolean;
  label?: JSX.Element;
  declarationInd?: JSX.Element;
  selecting?: boolean;
  discardCountIndicator?: JSX.Element;
  discardCardDescription?: string | null;
  showdownOverride?: boolean;
  cardClickCallback?: (
    card: Partial<CardInterface>,
    isSelected: boolean
  ) => void;
}

const FoldedHand = () => {
  return (
    <>
      <Card
        dealNumber={0}
        up={false}
        showdownOverride={false}
        cardClickCallback={() => {}}
      />
      <FoldIndicator />
    </>
  );
};

const DroppedCardIndicator = (props: { description: string }) => {
  const { gameType } = useSelector(selectGameType);
  if (gameType !== Game.Texas) {
    return <></>;
  }

  return <div className="droppedCardIndicator">{props.description}</div>;
};

/**
 * A component for rendering any collection of cards.
 */
const Hand = ({
  cardStack,
  show,
  className = "",
  tableCards,
  label,
  declarationInd,
  folded,
  cardClickCallback,
  selecting,
  discardCountIndicator,
  discardCardDescription,
  showdownOverride,
}: Props) => {
  const { screenPosition } = useSeatContext();
  const getCards = (cards: CardInterface[]) => {
    return cards.map((card) => (
      <Card
        dealNumber={card.orderDealt}
        tableCards={tableCards}
        rank={card.rank}
        suit={card.suit}
        up={card.up}
        showdownOverride={!!showdownOverride}
        show={show}
        key={getCardId(card)}
        metadata={card.metadata}
        cardClickCallback={cardClickCallback}
      />
    ));
  };

  const mainPile = cardStack.filter(
    (card) => !card?.metadata?.selected && !card?.metadata?.discarded
  );
  const secondaryPile = cardStack
    .filter((card) => !!card?.metadata?.selected || !!card?.metadata?.discarded)
    .sort(
      (cardA, cardB) =>
        (cardA.metadata?.discardedTime ?? 0) -
        (cardB.metadata?.discardedTime ?? 0)
    );

  return (
    <div
      className={classNames("cards", {
        [className]: !!className,
        overlapped: !tableCards && !folded,
        selectingDiscard: selecting && show,
      })}
    >
      {mainPile.length !== 0 && !folded && getCards(mainPile)}
      {label}
      {discardCountIndicator}
      {folded && <FoldedHand />}
      {secondaryPile.length !== 0 && (
        <>
          {discardCardDescription && (
            <DroppedCardIndicator description={discardCardDescription} />
          )}
          <div
            className={classNames("selected-group", {
              leftPlayers: [1, 2, 3, 7].includes(screenPosition),
              rightPlayers: [4, 5, 6].includes(screenPosition),
            })}
          >
            {getCards(secondaryPile)}
          </div>
        </>
      )}
      {declarationInd}
    </div>
  );
};

/**
 * A react component for showing the cards of a specific player.
 */
export const PlayerHand = () => {
  const { tablePosition, isLocalSeat } = useSeatContext();
  const { onCardClicked, isSelecting } = useCardSelection();
  const { anyDelayPrompt } = useSelector(selectAnyDelayPrompt);
  const { isSeatTaken } = useSelector(
    selectIsSeatTakenAtTablePos(tablePosition)
  );
  const { playerId } = useSelector(selectPlayerIdAtTablePos(tablePosition));
  const { playerHand } = useSelector(selectPlayerHandAtTablePos(tablePosition));
  let { playerCards } = useSelector(selectPlayerCardsAtTablePos(tablePosition));
  const { hasPlayerFolded } = useSelector(
    selectHasPlayerFoldedAtTablePos(tablePosition)
  );

  const { localPlayerId } = useSelector(selectLocalPlayerId);
  const { isGameRunning } = useSelector(selectIsGameRunning);
  const { gameType } = useSelector(selectGameType);
  const { playerDeclaration } = useSelector(
    selectDeclarationForPlayerById(playerId)
  );

  const showdownOverride = playerHand?.showdownOverride;
  const declarationInd =
    anyDelayPrompt || isLocalSeat ? (
      <PlayerDeclarationIndicator
        high={playerDeclaration === HighLowSetting.HIGH}
        low={playerDeclaration === HighLowSetting.LOW}
        both={playerDeclaration === HighLowSetting.HI_LO}
      />
    ) : (
      <></>
    );

  const cardsDiscardedInfo = playerHand?.cardsDiscardedInfo;
  const discardCount =
    !isLocalSeat && cardsDiscardedInfo && gameType === Game.FiveCardDraw ? (
      <DiscardCountIndicator />
    ) : (
      <></>
    );

  // Deal cards in order for Local Player if Game is of type Texas Drop'em
  if (gameType === Game.Texas && localPlayerId === playerId) {
    const aces = [Rank.ACE, Rank.HIGH_ACE];
    playerCards = [...playerCards].sort((cardA, cardB) => {
      const cardARank = cardA.rank ?? Rank.ACE;
      const cardASuit = cardA.suit ?? Suit.SPADE;
      const cardBRank = cardB.rank ?? Rank.ACE;
      const cardBSuit = cardB.suit ?? Suit.SPADE;

      let sortByRank = cardARank - cardBRank;
      const sortBySuit = cardASuit - cardBSuit;

      // for shift ACE or HIGH_ACE to extreme right to other cards
      if (aces.includes(cardARank) || aces.includes(cardBRank)) {
        sortByRank = cardBRank - cardARank;
      }
      return sortByRank || sortBySuit;
    });
  }

  const label = showdownOverride ? <HandValue /> : <></>;
  const showFolded =
    isGameRunning &&
    isSeatTaken &&
    hasPlayerFolded &&
    !(playerCards.length > 0);
  const handContent = (
    <Hand
      cardClickCallback={onCardClicked}
      folded={!!showFolded}
      cardStack={playerCards}
      className={classNames({ folded: !!showFolded }, "outside")}
      show={isLocalSeat}
      label={label}
      declarationInd={declarationInd}
      selecting={isSelecting}
      discardCountIndicator={discardCount}
      discardCardDescription={cardsDiscardedInfo?.description}
      showdownOverride={showdownOverride}
    />
  );

  if (isSelecting && isLocalSeat) {
    return (
      <span
        data-testid={SELECTED_CARDS_CONTAINER}
        className="wrapper selecting"
      >
        {handContent}
      </span>
    );
  }

  return handContent;
};

export default Hand;
