/**
 * TableRulesModal.tsx
 *
 * This is just a wrapper around the Rules
 */
import React from "react";

import { RulesHeader } from "../../Settings/Rules";
import { TableRules } from "../../Settings/RulesPages/TableRules/TableRules";

import { ModalProps } from "../types/modalTypes";

export const TableRulesModal = (props: ModalProps): JSX.Element => {
  return (
    <>
      <RulesHeader {...props} />
      <div className="rulesModalContentContainer">
        <TableRules {...props} />
      </div>
    </>
  );
};
