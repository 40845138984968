// payment/api.ts
//
// All API endpoints related to payments.

import LogRocket from "logrocket";

import { getEndpoint, postEndpoint } from "../api";

export async function getOptions() {
  try {
    const options = await getEndpoint("api/payment/options", "");
    return options;
  } catch (e: any) {
    LogRocket.captureException(e);
    return null;
  }
}

export async function checkout(checkoutInfo: {
  priceId: string;
  email: string;
  impact_click_id: string;
}) {
  try {
    const session = await postEndpoint(
      "api/payment/create-checkout-session",
      "",
      checkoutInfo
    );
    return session.url;
  } catch (e: any) {
    LogRocket.captureException(e);
    return null;
  }
}

export async function getAccountInfo() {
  try {
    const response = await getEndpoint("api/payment/account-info", "");
    return response.accountInfo;
  } catch (e: any) {
    LogRocket.captureException(e);
    return null;
  }
}
