/**
 * GetMoreChips.tsx
 * A custom component which when "shown" lets localPlayer know that they have run out of chips to play
 * and are asked to get chips from bank that hides GetMoreChipsModal and triggers TableLedgerModal
 * PS. GetMoreChipsModal Modal uses custom styles defined in sibling module css
 */

import React from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";

import Button from "../Button";
import { selectLocalPlayerName } from "../../../logic/localPlayer/slice";

import { ModalName } from "../Modal/ModalConfig";
import { useModal } from "../Modal/hooks/useModal";
import { ModalProps } from "../Modal/types/modalTypes";

import styles from "./GetMoreChips.module.css";

export const GetMoreChips = (props: ModalProps) => {
  const { localPlayerName } = useSelector(selectLocalPlayerName);

  const { showModal } = useModal();

  return (
    <div className={classNames(styles.getMoreChipsModalSection)}>
      <Button
        className="chipRequestButton actionButton green"
        onClick={() => {
          showModal({
            name: ModalName.LedgerModal,
            title: localPlayerName ? `${localPlayerName}'s Bank` : `My Bank`,
          });
        }}
      >
        REQUEST CHIPS FROM BANK
      </Button>
    </div>
  );
};
