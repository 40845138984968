import React from "react";
import useDevice from "../../../../../hooks/useDevice";
interface MoneyInputProps {
  value?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function preventNegativeClipboardEvent(
  event: React.ClipboardEvent<HTMLInputElement>
) {
  const value = parseFloat(event.clipboardData.getData("text"));
  if (Math.abs(value) === 0 || value < 0) {
    event.preventDefault();
  }
}

function preventNegativeKeyboardEvent(
  event: React.KeyboardEvent<HTMLInputElement>
) {
  if (["e", "E", "+", "-"].includes(event.key)) {
    event.preventDefault();
  }
}

export const MoneyInput = (props: MoneyInputProps) => {
  const { isMobile } = useDevice();

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    // Early exit in case of:
    // 1. value does not exist for in mobile device
    // 2. value is not of form (begin with digit(0 to 9) between 0 & 10 of preceding token, with optional "." followed by digit(0 to 9) between 0 & 2 of preceding token)
    const allowEmptyValue = isMobile && !event.target.value;
    if (
      allowEmptyValue ||
      !event.target.value.match(/^\d{0,10}(\.\d{0,2})?$/)
    ) {
      return;
    }
    props.onChange(event);
  }

  return (
    <input
      className="field"
      type="number"
      value={props.value}
      min={0}
      name={props.name}
      onChange={onChange}
      onKeyDown={preventNegativeKeyboardEvent}
      onPaste={preventNegativeClipboardEvent}
    />
  );
};
