/**
 * HostControlsModal.tsx
 *
 * This is just a wrapper around the HostControls
 */
import React from "react";

import { HostControls } from "../../Settings/HostControls";

import { ModalProps } from "../types/modalTypes";

export const HostControlsModal = (props: ModalProps): JSX.Element => {
  return <HostControls {...props} />;
};
