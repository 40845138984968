import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";

import { UsernameLength } from "poker-cows-common";
import silentSfx from "../../../static/sounds/silent.wav";
import { ExtraSession } from "../extraSession/ExtraSession";
import { selectIsLocalPlayerHost } from "../../../logic/table/slice";
import {
  SessionKeys,
  getSession,
  setSession,
  removeSession,
} from "../../../utils/SessionSettings";
import {
  selectIsNameTaken,
  selectIsSeatAvailable,
  selectIsOnlyOnePlayerAllowed,
} from "../../../logic/connection/slice";
import { ENTER_PLAYER_NAME_INPUT } from "../../../test-identifiers";

import "./EnterName.css";

const nameErrors = {
  empty: (name: string) => "You entered empty username!",
  maxLength: (name: string) => `The username you entered is too long!`,
  alreadyTaken: (name: string) =>
    `A player with the name '${name}' is already seated at this table. Please choose a different name.`,
  noSeatsAvailable: (name: string) =>
    `The table is full. Comeback later to check if the table has an empty seat.`,
  botTable: (name: string) =>
    `The table allows seat for single player only, and all other seats are reserved for bots.`,
};

interface EnterNameParams {
  defaultName: string;
  setName: (name: string) => void;
  takenNames?: string[];
}

const EnterName = (params: EnterNameParams) => {
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);
  const { isNameTaken } = useSelector(selectIsNameTaken);
  const { isSeatAvailable } = useSelector(selectIsSeatAvailable);
  const { isOnlyOnePlayerAllowed } = useSelector(selectIsOnlyOnePlayerAllowed);

  const [name, setName] = useState(params.defaultName);
  const [nameError, setNameError] = useState<string | null>(
    isNameTaken ? nameErrors.alreadyTaken(name) : null
  );

  useEffect(() => {
    if (!isSeatAvailable) {
      setNameError(nameErrors.noSeatsAvailable(name));
    }
  }, [isSeatAvailable, name]);

  useEffect(() => {
    if (isOnlyOnePlayerAllowed) {
      setNameError(nameErrors.botTable(name));
    }
  }, [isOnlyOnePlayerAllowed, name]);

  useEffect(() => {
    if (isNameTaken) {
      setNameError(nameErrors.alreadyTaken(name));
    }
  }, [isNameTaken, name]);

  // TODO: @fe-routes-redux-state
  // HACK: remove the line below (see StartNewTable.tsx @fe-routes-redux-state)
  const localPlayerIsHost =
    isLocalPlayerHost || getSession(SessionKeys.isLocalPlayerHost) === "true";

  function playSound(sfx: string) {
    const sound = new Audio(sfx);
    sound.volume = 0;
    sound.play();
  }

  function onNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (nameError) {
      setNameError(null);
    }

    setName(event.target.value ?? "");
  }

  function onCreatingOrJoiningTheTable() {
    if (!name) {
      setNameError(nameErrors.empty(name));
      return;
    }

    if (name.length > UsernameLength) {
      setNameError(nameErrors.maxLength(name));
      return;
    }

    const trimmedName = name.trim();
    if (
      (params.takenNames && params.takenNames.includes(trimmedName)) ||
      (isNameTaken && trimmedName === params.defaultName)
    ) {
      setNameError(nameErrors.alreadyTaken(trimmedName));
      return;
    }

    playSound(silentSfx);
    setSession(SessionKeys.username, trimmedName);
    params.setName(trimmedName);

    // TODO: @fe-routes-redux-state
    // HACK: remove the line below (see StartNewTable.tsx @fe-routes-redux-state)
    removeSession(SessionKeys.isLocalPlayerHost);
  }

  const contents = (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onCreatingOrJoiningTheTable();
        }}
        noValidate
        id="login-name"
      >
        <input
          data-testid={ENTER_PLAYER_NAME_INPUT}
          maxLength={UsernameLength}
          autoFocus
          placeholder="Player Name"
          className={classNames("nameInput", {
            error: nameError,
          })}
          type="text"
          name="name"
          value={name}
          onChange={onNameChange}
        />
        {nameError && <span className="nameError">{nameError}</span>}
      </form>
    </>
  );

  return (
    <div className="enterName">
      <ExtraSession
        actionButtonOnClick={onCreatingOrJoiningTheTable}
        actionButtonText={localPlayerIsHost ? "Create Table" : "Join Table"}
        contents={contents}
        title="Enter Your Player Name"
      />
    </div>
  );
};

export default EnterName;
