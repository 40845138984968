/**
 * FeaturePreviewModal.tsx
 *
 * This is just a wrapper around the FeatureList
 */
import React from "react";

import { FeatureList } from "../../../views/dev/Dev";

import { ModalProps } from "../types/modalTypes";

export const FeaturePreviewModal = (props: ModalProps): JSX.Element => {
  return <FeatureList {...props} />;
};
