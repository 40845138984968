/**
 * This const stores all the default values that are to be shared by FE and BE
 */

// The length of Table Code that can be entered from FE and generated by BE
export const TableCodeLength = 4;

// The length of username that can be entered from FE
export const UsernameLength = 15;

// Maximum number of bots that can join a Bot Table
export const TotalBotPlayers = 4;
