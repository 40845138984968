/*
 * Seat.tsx
 *
 * This file renders the seat component for every player. It includes the gray frame that
 * contains players video and the information in bottom row (by way of the PlayerRow component).
 * Additionally, the logic for all indicators such as how much the player bet or their check/bet status,
 * single and multiplayer turn indicator arrows, and high/low declarations are determined in this component.
 */
import "./Seat.css";
import classNames from "classnames";
import { selectIsLocalPlayerSeated } from "../../../logic/localPlayer/slice";
import {
  selectLocalPlayerStatus,
  selectHasPlayerFoldedAtTablePos,
  selectIsBetNoLimit,
} from "../../../logic/gameInstance/slice";
import { useSelector } from "react-redux";
import React, { useMemo } from "react";
import {
  selectIsPlayerOnBreakAtTablePos,
  selectIsSeatTakenAtTablePos,
  selectPlayerAtTablePos,
} from "../../../logic/player/slice";
import { PlayerBet } from "../PlayerBet";
import {
  useCurrentPlayer,
  useWinners,
} from "../../../logic/gameInstance/hooks";
import { PlayerRow } from "./PlayerRow";
import {
  selectCurrentDealer,
  selectIsLocalPlayerHost,
  selectTableStatus,
} from "../../../logic/table/slice";
import { TakeBreakIndicator } from "./TakeBreakIndicator";
import { DealerIndicator } from "./DealerIndicator";
import { LoseAmountItem, Winnings } from "./Winnings";
import { TurnIndicator } from "./TurnIndicator";
import { useSeatContext } from "./SeatContext";
import { KickButton } from "./KickButton";
import {
  EXPERIMENTAL_FEATURES,
  useExperimentalFeature,
} from "../Feature/FeatureContext";
import { PlayAutomaticallyButton } from "./PlayAutomaticallyButton";
import { PlayerStatus, TableStatus } from "poker-cows-common";
import { MutedIndicator } from "./MutedIndicator";
import { SeatWinnerBadge } from "./SeatWinnerBadge";
import {
  selectAnyDealPrompt,
  selectAnyDelayPrompt,
  selectPlayerShowdownPrompt,
  selectMultiplayerPromptForPlayer,
} from "../../../logic/prompts/slice";

export const Seat = () => {
  const { tablePosition, isLocalSeat } = useSeatContext();
  const {
    isWinner,
    isGameWinner,
    isBothWinner,
    isHighWinner,
    isLowWinner,
    playerLostBy,
  } = useWinners({
    tablePosition,
  });
  const isChicagoBothWinner = isGameWinner && isHighWinner;
  const { player } = useSelector(selectPlayerAtTablePos(tablePosition));
  const { isCurrentPlayer } = useCurrentPlayer(tablePosition);
  const { isSeatTaken } = useSelector(
    selectIsSeatTakenAtTablePos(tablePosition)
  );
  const { isPlayerTakingBreak } = useSelector(
    selectIsPlayerOnBreakAtTablePos(tablePosition)
  );
  const { hasPlayerFolded } = useSelector(
    selectHasPlayerFoldedAtTablePos(tablePosition)
  );

  const { currentDealer } = useSelector(selectCurrentDealer);
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);
  const { isLocalPlayerSeated } = useSelector(selectIsLocalPlayerSeated);
  const { isBetNoLimit } = useSelector(selectIsBetNoLimit);

  const { anyDealPrompt } = useSelector(selectAnyDealPrompt);
  const { anyDelayPrompt } = useSelector(selectAnyDelayPrompt);
  const { playerShowdownPrompt } = useSelector(selectPlayerShowdownPrompt);
  const { multiplayerPromptForPlayer } = useSelector(
    selectMultiplayerPromptForPlayer(player?.id)
  );

  const kickFeature = useExperimentalFeature(
    EXPERIMENTAL_FEATURES.KICK_PLAYERS
  );
  const botFeature = useExperimentalFeature(
    EXPERIMENTAL_FEATURES.PLAY_AUTOMATICALLY
  );

  // determine if the player is the dealer
  const isDealer = useMemo(
    () => currentDealer?.seatNumber === player?.seatNumber,
    [currentDealer, player]
  );

  // get the players status
  const { localPlayerStatus } = useSelector(selectLocalPlayerStatus);

  const { tableStatus } = useSelector(selectTableStatus);

  const showNothing =
    (!isLocalSeat &&
      (localPlayerStatus === PlayerStatus.JOINING ||
        (isLocalPlayerSeated && !isSeatTaken))) ||
    tableStatus === TableStatus.UNINITIALIZED; // show nothing if table not initialized

  // determine if turn indicator should be shown and which version be shown (single vs multi)
  // multi is only shown when single arrow indicator is not visible
  let turnIndicator = <></>;

  if (
    !playerShowdownPrompt &&
    !hasPlayerFolded &&
    (isCurrentPlayer || multiplayerPromptForPlayer)
  ) {
    turnIndicator = (
      <TurnIndicator
        isMultiplayerIndicator={
          !isCurrentPlayer && !!multiplayerPromptForPlayer
        }
      />
    );
  }

  // determine if the kick button should be shown
  const kickButton = kickFeature.isEnabled && isLocalPlayerHost && (
    <KickButton position={tablePosition} />
  );

  // determine if the "Play Automatically" button should be shown
  const autoPlayButton = isLocalSeat &&
    botFeature.isEnabled &&
    isLocalPlayerHost && <PlayAutomaticallyButton />;

  const seatContainerClass = classNames("seatContainer", {
    active: isCurrentPlayer && !anyDealPrompt,
    winner: isWinner,
    declaredBoth: isBothWinner || isChicagoBothWinner,
    declaredHigh: isHighWinner && !isLowWinner && !isBothWinner,
    declaredLow:
      (isLowWinner || isGameWinner) && !isHighWinner && !isBothWinner,
    open: showNothing,
    overlapHandler: !showNothing && !isCurrentPlayer,
  });

  if (showNothing) {
    return (
      // seat with no details
      <div className={seatContainerClass}>
        {kickButton}
        {autoPlayButton}
        <PlayerRow />
      </div>
    );
  }

  return (
    // seat with details
    <div className={seatContainerClass}>
      {kickButton}
      {isWinner && (
        <Winnings
          isGameWinner={isGameWinner}
          isBothWinner={isBothWinner || isChicagoBothWinner}
          isHighWinner={isHighWinner}
          isLowWinner={isLowWinner}
        />
      )}
      {anyDelayPrompt && !isBetNoLimit && !!playerLostBy && (
        <LoseAmountItem amount={playerLostBy} />
      )}
      {turnIndicator}
      {autoPlayButton}
      {isDealer && <DealerIndicator />}
      {!player?.isBot && <MutedIndicator />}
      {isPlayerTakingBreak && <TakeBreakIndicator />}
      <PlayerBet tablePosition={tablePosition} />
      <PlayerRow />
      {isWinner && (
        <SeatWinnerBadge
          isDealer={isDealer}
          isGameWinner={isGameWinner && !isChicagoBothWinner}
          isBothWinner={isBothWinner || isChicagoBothWinner}
          isHighWinner={isHighWinner}
          isLowWinner={isLowWinner}
        />
      )}
    </div>
  );
};

export default Seat;
