/** InitialBuyInTitle.tsx
 *
 * This component displays Title for Initial But-In and Tooltip
 */
import React from "react";
import { Tooltip } from "../../Tooltip/Tooltip";

export const InitialBuyInTitle = () => {
  return (
    <>
      <span className="rowCaption">Initial Buy-In</span>
      <Tooltip
        title="Initial Buy In"
        body="As a player joins the table, this initial buy in will be added to their stack and bank ledger."       
      />
    </>
  );
};
