/** HollywoodSquaresOptions.tsx
 *
 * Game Options for HollywoodSquares.
 */
import { BetLimit } from "../bets/BetLimit";
import { CardVersionSetting } from "../enums/CardVersionSetting";
import { GameOptionsInterface } from "../interfaces";
import {
  optionNoneDefault,
  wildcardCenterCard,
  wildcardDeuces,
  wildcardNoneDefault,
  wildcardOneEyedJacks,
  winnerHighLowSplit,
  winnerHighOnlyDefault,
  winnerLowOnly,
} from "./defaultOptions";

export const HollywoodSquaresOptions: GameOptionsInterface = {
  versions: [
    {
      title: "7 Card",
      description: "4 Player Cards, | 3 Community Cards",
      value: CardVersionSetting.SEVEN_CARD,
      flavor: 4,
      default: true,
      betLimit: BetLimit.SPREAD_LIMIT,
    },
    {
      title: "5 Card",
      description: "2 Player Cards, | 3 Community Cards",
      flavor: 2,
      value: CardVersionSetting.FIVE_CARD,
      betLimit: BetLimit.SPREAD_LIMIT,
    },
  ],
  winners: [winnerHighOnlyDefault, winnerLowOnly, winnerHighLowSplit],
  options: [optionNoneDefault],
  wildcards: [
    wildcardNoneDefault,
    wildcardCenterCard,
    wildcardDeuces,
    wildcardOneEyedJacks,
  ],
};
