/**
 * HostControls.tsx
 *
 * This react components returns button to open Host Controls Modal and well as returns the contents of the Modal
 */
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";

import { TableStatus } from "poker-cows-common";
import Button from "../Button";
import {
  selectTableStatus,
  selectIsNoLimitFullRound,
  selectTableCode,
  selectIsTableInPreShutdown,
  selectIsLocalPlayerHost,
  selectIsBotTable,
} from "../../../logic/table/slice";

import { ModalName } from "../Modal/ModalConfig";
import { useModal } from "../Modal/hooks/useModal";
import { ModalProps } from "../Modal/types/modalTypes";

import "./HostControls.css";
import { HOST_CONTROLS_BUTTON } from "../../../test-identifiers";
import { togglePreShutdown } from "../../../logic/prompts/slice";

const HostButton = ({
  className = "",
  handle,
  navigate,
  title,
  isButtonDisabled = false,
}: {
  className?: string;
  handle: ModalName;
  navigate: (pageHandle: ModalName) => void;
  title: string;
  isButtonDisabled?: boolean;
}) => (
  <div
    key={handle}
    data-testid={handle}
    className={classNames("hostControlsButton", {
      [className]: !!className,
      disabledButton: isButtonDisabled,
    })}
    onClick={() => !isButtonDisabled && navigate(handle)}
  >
    <span className="buttonText">{title}</span>
  </div>
);

// this is the modal that pops up to display host controls, for host only
// it appears any time you're a host on pokercows.com
// from here you can adjust table settings, reset hand, act for player,
// view banking ledger, and end the session
export const HostControls = (props: ModalProps) => {
  const { showModal } = useModal();

  const dispatch = useDispatch();
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);
  const { isTableInPreShutdown } = useSelector(selectIsTableInPreShutdown);
  const { isBotTable } = useSelector(selectIsBotTable);
  const { tableCode } = useSelector(selectTableCode);
  const { isNoLimitFullRound } = useSelector(selectIsNoLimitFullRound);

  const buttons = [
    { handle: ModalName.TableSettingsModal, title: "Table Settings" },
    {
      handle: ModalName.ActForPlayerModal,
      title: "Act for Player",
      isButtonDisabled: isBotTable,
    },
    {
      handle: ModalName.ResetHandModal,
      title: "Cancel Current Hand",
      isButtonDisabled: isNoLimitFullRound || isBotTable,
    },
    {
      handle: ModalName.BankingLedgerModal,
      title: "Chip Sales, Transfers & Ledgers",
    },
    {
      handle: ModalName.CashOutModal,
      className: "lightDanger",
      title: isBotTable
        ? "Begin cash out and \nexit \nbot play"
        : "Begin cash out",
    },
  ];

  function onClickOptionButton(name: ModalName) {
    if (
      name === ModalName.CashOutModal &&
      isLocalPlayerHost &&
      !isTableInPreShutdown
    ) {
      dispatch(togglePreShutdown());
    }
    showModal({
      name,
      hasCloseButton: false,
    });
  }

  const showHelpModal = () => {
    showModal({
      name: ModalName.HelpModal,
      title: "Help",
    });
  };

  return (
    <div className="hostControlsTableWrapper">
      <div className="hostControlsTableCode">
        <div className="tableCodeText">TABLE:</div>
        <div className="tableCode">{tableCode}</div>
      </div>
      <div className="hostControlsHeaderOptions">
        <div className="help" onClick={showHelpModal} title="help">
          <span className="help-icon"></span>
        </div>
      </div>
      <div className="hostControlsNavigationPage">
        <div className="hostControlsButtonsContainer">
          {buttons.map((value, i) => (
            <HostButton key={i} navigate={onClickOptionButton} {...value} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const HostControlsButton = () => {
  const { tableStatus } = useSelector(selectTableStatus);

  const { currentModalName, showModal } = useModal();

  return (
    <div className="hostControls">
      <Button
        data-testid={HOST_CONTROLS_BUTTON}
        className={classNames("actionButton", {
          activeRulesButton: currentModalName === ModalName.HostControlsModal,
          disabled: tableStatus === TableStatus.UNINITIALIZED,
        })}
        onClick={() =>
          showModal({
            name: ModalName.HostControlsModal,
          })
        }
      >
        HOST CONTROLS
      </Button>
    </div>
  );
};
