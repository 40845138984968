/**
 * Table.tsx
 *
 * This is the default component for `/table/:id` route to check the user login, table verification and then only show TableArea
 */
import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LogRocket from "logrocket";
import { TableErrorType, TableStatus } from "poker-cows-common";
import EnterName from "../login/EnterName";
import { joinTable } from "../../../logic/table/thunks";
import { TableArea } from "../../components/Table/TableArea";
import {
  selectTableGroup,
  selectIsLocalPlayerHost,
  selectTableId,
  selectTableStatus,
  setTableGroup,
  setTableId,
} from "../../../logic/table/slice";
import InvalidTable from "./InvalidTable";
import { useRedirect } from "../../../hooks/useRedirect";
import {
  SessionKeys,
  getSession,
  setSession,
} from "../../../utils/SessionSettings";
import { getTable, getTablePlayersNames } from "../../../logic/table/api";
import Loading from "../../components/Loading";
import {
  selectIsNameTaken,
  selectIsTableValid,
  selectIsSeatAvailable,
  selectIsOnlyOnePlayerAllowed,
} from "../../../logic/connection/slice";
import { checkIsBotTable } from "../../../utils/BotImage";

const emptyFunc = () => undefined;

interface TableProps {
  code: string;
  username?: string;
  delay?: number;
  eventHandler?: (event: string) => any;
}

export const TableContext = React.createContext({
  eventCallback: (event: string) => undefined,
});

export const Table = (props: TableProps) => {
  const code = props.code;
  const dispatch = useDispatch();
  const { tableId } = useSelector(selectTableId);
  const { tableStatus } = useSelector(selectTableStatus);
  const { isTableValid } = useSelector(selectIsTableValid);
  const { isNameTaken } = useSelector(selectIsNameTaken);
  const { isSeatAvailable } = useSelector(selectIsSeatAvailable);
  const { isOnlyOnePlayerAllowed } = useSelector(selectIsOnlyOnePlayerAllowed);
  const { tableGroup } = useSelector(selectTableGroup);
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);
  const [takenDisplayNames, setTakenDisplayNames] = useState<string[]>([]);
  const [displayName, setDisplayName] = useState(
    props.username ?? getSession(SessionKeys.username) ?? ""
  );
  const [isTableChecked, setIsTableChecked] = useState<
    "unchecked" | "checked" | "errored"
  >("unchecked");

  const gotoEndSession = useRedirect(
    isLocalPlayerHost ? "/end-session" : "/end-session-for-player"
  );

  // check and set isTableChecked flag based on existence of table
  useEffect(() => {
    if (!code) {
      setIsTableChecked("errored");
    }

    getTable(code)
      .then((res) => {
        if (res?.response?.data?.errorType === TableErrorType.TABLE_NOT_FOUND) {
          setIsTableChecked("errored");
        } else {
          dispatch(setTableGroup(res.tableGroup));
          dispatch(setTableId(res.id));
          setIsTableChecked("checked");
        }
      })
      .catch((error) => {
        setIsTableChecked("errored");
      });
  }, [code, dispatch]);

  // If the players game/tableStatus is ARCHIVED, redirect to the appropriate "Session Ended" screen
  useEffect(() => {
    if (tableStatus === TableStatus.ARCHIVED) {
      gotoEndSession();
    }
  }, [tableStatus, gotoEndSession]);

  // check if displayName does not exist to get previous display names from api
  // join table if valid display name
  useEffect(() => {
    const isBotTable = checkIsBotTable();
    if (!displayName && !isBotTable) {
      getTablePlayersNames(tableId.toString(), tableGroup).then((res) => {
        if (res?.previousDisplayName) {
          setSession(SessionKeys.username, res.previousDisplayName);
          setDisplayName(res.previousDisplayName);
        }
        if (res.takenDisplayNames?.length !== 0) {
          setTakenDisplayNames(res.takenDisplayNames);
        }
      });
    }

    if (displayName.trim().length === 0) {
      return;
    }
    if (isTableChecked !== "checked") {
      return;
    }

    LogRocket.identify(tableId + "." + displayName, {
      tableId: tableId,
      nickname: displayName,
    });

    dispatch(
      joinTable({
        tableId: tableId.toString(),
        tableGroup,
        nickName: displayName,
      })
    );
  }, [tableId, displayName, isTableChecked, tableGroup, dispatch]);

  if (isTableChecked === "unchecked") {
    return <Loading />;
  }

  if (isTableChecked === "errored" || !isTableValid) {
    return <InvalidTable code={code} />;
  }

  // If the name is not present or already taken, ask the user to enter one
  if (
    !displayName ||
    isNameTaken ||
    !isSeatAvailable ||
    isOnlyOnePlayerAllowed
  ) {
    return (
      <EnterName
        defaultName={displayName}
        setName={setDisplayName}
        takenNames={takenDisplayNames}
      />
    );
  }

  return (
    <TableContext.Provider
      value={{ eventCallback: props.eventHandler ?? emptyFunc }}
    >
      <TableArea id={tableId.toString()} />
    </TableContext.Provider>
  );
};

const RoutedTable = () => {
  const { code }: { code: string } = useParams();
  return <Table code={code} />;
};

export default withRouter(RoutedTable);
