/**
 * ActForPlayer.tsx
 *
 * This is a central component around ActForPlayerModal using Host Controls to give the list of player over which host can take actions
 */
import React from "react";
import { useSelector } from "react-redux";

import { PlayerInterface } from "poker-cows-common";
import { selectAdmittedPlayers } from "../../../../logic/player/slice";
import { ModalName } from "../../Modal/ModalConfig";
import { useModal } from "../../Modal/hooks/useModal";
import { ModalProps } from "../../Modal/types/modalTypes";

export const ActForPlayer = (props: ModalProps) => {
  const { admittedPlayers } = useSelector(selectAdmittedPlayers);

  const { showModal } = useModal();

  const playerClick = (player: PlayerInterface) => {
    showModal({
      name: ModalName.ActForCertainPlayerModal,
      additionalProps: { selectedPlayerId: player?.id },
    });
  };

  return (
    <div className="hostControlsSubpageContainer actForPlayerButtonsContainer">
      {admittedPlayers?.map((player, i: number) => {
        return (
          <div
            key={i}
            data-testid={`act-${player.name}`}
            className="hostControlsButton"
            onClick={() => playerClick(player)}
          >
            <span className="buttonText">{player.name}</span>
          </div>
        );
      })}
    </div>
  );
};
