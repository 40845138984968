import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../Button";
import { useModal } from "../Modal/hooks/useModal";
import { alertOkay, selectAlertPrompt } from "../../../logic/prompts/slice";
import { ModalName } from "../Modal/ModalConfig";

export const AlertArea = () => {
  const { currentModalName, closeAllModals } = useModal();

  const dispatch = useDispatch();
  const { alertPrompt } = useSelector(selectAlertPrompt);

  useEffect(() => {
    if (alertPrompt && currentModalName === ModalName.StartGameModal) {
      closeAllModals();
    }
  }, [alertPrompt, currentModalName, closeAllModals]);

  const click = () => {
    dispatch(alertOkay());
  };

  if (!alertPrompt) {
    return null;
  }

  return (
    <div className="centeredContainer">
      {alertPrompt.actionsAvailable[0].name}
      <br />
      <Button variant="base" onClick={click}>
        Retry
      </Button>
    </div>
  );
};
