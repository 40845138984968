/**
 * Home.tsx
 *
 * This is the default component for `/` route to add the ModalWrapper on top and the widgets around it
 */
import "./Home.css";
import React from "react";
import Widgets from "../../components/Widgets";
import AuthWidget from "../../components/Auth/AuthWidget";

const Home = () => {
  return (
    <div className="container">
      <div className="auth0ImageAsset" />
      <div className="logo" />
      <div className="topbar">
        <AuthWidget />
      </div>
      <Widgets />
    </div>
  );
};

export default Home;
