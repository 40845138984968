/**
 * BetLimit.ts
 *
 * Enumeration of all the possible bet limits that a game can have.
 */
export enum BetLimit {
  /** any player can bet all the money they have (no restrictions) */
  NO_LIMIT = 1,

  /** the size of the pot is the limit */
  POT_LIMIT = 2,

  /** predefined amount up to which the players can bet each turn */
  FIXED_LIMIT = 3,

  /** the maximum amount of money a single player can be bet per game */
  CAP_LIMIT = 4,

  /** provides a range from min to max money a player can bet */
  SPREAD_LIMIT = 5,
}
