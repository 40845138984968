/**
 * BottomRight.tsx
 *
 * This component gives localPlayer the buttons when they get inside a table
 * It mostly covers: around Deal Me in/Out, Pause Button and Chips & Banking
 */
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";

import { TableStatus } from "poker-cows-common";
import {
  selectIsPlayerOnBreakAtTablePos,
  selectIsPlayerScheduledToTakeBreakAtTablePos,
} from "../../../logic/player/slice";
import { beginShutdown, toggleBreak } from "../../../logic/prompts/slice";
import {
  selectLocalPlayerName,
  selectLocalPlayerSeatNumber,
} from "../../../logic/localPlayer/slice";
import {
  selectIsNoLimitFullRound,
  selectIsBotTable,
  selectTableStatus,
} from "../../../logic/table/slice";
import MediaControls from "../MediaControls";
import Button from "../Button";

import { ModalName } from "../Modal/ModalConfig";
import { useModal } from "../Modal/hooks/useModal";
import { BREAK_BUTTON, LEDGER_BUTTON } from "../../../test-identifiers";

export const LedgerButton = () => {
  const { localPlayerName } = useSelector(selectLocalPlayerName);
  const { tableStatus } = useSelector(selectTableStatus);

  const { currentModalName, showModal } = useModal();

  const ledgerButtonClicked = () => {
    showModal({
      name: ModalName.LedgerModal,
      title: localPlayerName ? `${localPlayerName}'s Bank` : `My Bank`,
    });
  };

  return (
    <Button
      data-testid={LEDGER_BUTTON}
      className={classNames("actionButton breakButton", {
        activeRulesButton: currentModalName === ModalName.LedgerModal,
        disabled:
          tableStatus === TableStatus.UNINITIALIZED ||
          tableStatus === TableStatus.POST_GAME,
      })}
      onClick={ledgerButtonClicked}
    >
      REQUEST CHIPS & BANKING
    </Button>
  );
};

export const BottomRight = () => {
  const dispatch = useDispatch();
  const { localPlayerSeatNumber } = useSelector(selectLocalPlayerSeatNumber);
  const { isPlayerTakingBreak } = useSelector(
    selectIsPlayerOnBreakAtTablePos(localPlayerSeatNumber)
  );
  const { isPlayerScheduledToTakeBreak } = useSelector(
    selectIsPlayerScheduledToTakeBreakAtTablePos(localPlayerSeatNumber)
  );
  const { tableStatus } = useSelector(selectTableStatus);
  const { isBotTable } = useSelector(selectIsBotTable);
  const { isNoLimitFullRound } = useSelector(selectIsNoLimitFullRound);

  function onTakeBreak() {
    dispatch(toggleBreak());
  }

  function onExitBotPlay() {
    dispatch(beginShutdown());
    window.location.href = "https://www.pokercows.com";
  }

  const getSitOutButtonLabel = () => {
    let label = "";
    if (isPlayerTakingBreak || isPlayerScheduledToTakeBreak) {
      if (isNoLimitFullRound) {
        label = "DEAL ME IN AFTER CURRENT ORBIT";
      } else if (isPlayerScheduledToTakeBreak) {
        label = "DEAL ME IN AFTER CURRENT HAND";
      } else {
        label = "DEAL ME IN";
      }
    } else {
      if (isNoLimitFullRound) {
        label = "SIT OUT CURRENT ORBIT";
      } else {
        label = "SIT OUT NEXT HAND";
      }
    }

    return label;
  };

  return (
    <>
      <LedgerButton />
      {!isBotTable && (
        <Button
          data-testid={BREAK_BUTTON}
          className={classNames("actionButton break", {
            disabled:
              tableStatus === TableStatus.UNINITIALIZED ||
              tableStatus === TableStatus.POST_GAME,
          })}
          onClick={onTakeBreak}
        >
          {getSitOutButtonLabel()}
        </Button>
      )}
      {isBotTable && (
        <Button
          data-testid={BREAK_BUTTON}
          variant="red"
          className="actionButton"
          onClick={onExitBotPlay}
        >
          EXIT DEMO
        </Button>
      )}
      <MediaControls />
    </>
  );
};
