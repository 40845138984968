import React from "react";
import { useSelector } from "react-redux";

import { BetLimit } from "poker-cows-common";
import { formatMoneyString } from "../../../utils/MoneyFormat";
import { selectNextPrefs } from "../../../logic/table/slice";

export const GamePrefsInfo = () => {
  const { nextPrefs } = useSelector(selectNextPrefs);

  const isNoLimit = nextPrefs?.limitType === BetLimit.NO_LIMIT;

  if (isNoLimit) {
    return (
      <>
        <div className="gamePrefsInfo">
          <div className="gamePrefsItem">{`Small Blind ${formatMoneyString(
            nextPrefs?.smallBlind
          )}`}</div>
          <div className="gamePrefsItem">{`Big Blind ${formatMoneyString(
            nextPrefs?.bigBlind
          )}`}</div>
        </div>
      </>
    );
  }

  const anteValue = formatMoneyString(nextPrefs?.ante);
  const minValue = formatMoneyString(
    nextPrefs?.limitType === BetLimit.FIXED_LIMIT
      ? nextPrefs?.fixedLimitAmount
      : nextPrefs?.spreadMinRaise
  );
  const maxValue = formatMoneyString(
    nextPrefs?.limitType === BetLimit.FIXED_LIMIT
      ? nextPrefs?.fixedLimitAmount
      : nextPrefs?.spreadMaxRaise
  );

  let {lowestHand} = nextPrefs;
  // Format the lowestHand from nextPrefs to display correctly in gameinfo.
  switch (lowestHand) {
    case "wheel":
      lowestHand = "The Wheel";
      break;
    case "7-5":
      lowestHand = "7-5 Low";
      break;
    case "6-4":
      lowestHand = "6-4 Low";
  };

  return (
    <div className="gamePrefsInfoWrapper">
      <div className="gamePrefsInfo">
        <div className="gamePrefsItem">{`Ante ${anteValue}`}</div>
        <div className="gamePrefsItem">{`Min ${minValue}`}</div>
        <div className="gamePrefsItem">{`Max ${maxValue}`}</div>
      </div>
      <div className="gamePrefsItem">
        Low Method: {lowestHand}
      </div>
    </div>
  );
};
