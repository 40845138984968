/**
 * ModalWrapper.tsx
 *
 * This in-houses the redux wrapper to give store actions such as showModal, popModal, closeAllModals in the store
 * The wrapper handles the array of modals and passes the respective Component as children to modal along with the customProps passed to be accessible in the Component
 * The wrapper has to be defined in each route
 */
import React, { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DevPlayerNameContext } from "../../views/dev/Dev";

import { selectAllPlayers } from "../../../logic/player/slice";
import { selectLocalPlayerId } from "../../../logic/localPlayer/slice";
import { selectLocalPlayerActiveModalName } from "../../../logic/gameInstance/slice";
import { show } from "../../../logic/modal/slice";
import { getLocal, LocalKeys } from "../../../utils/LocalSettings";

import { Modal } from "./Modal";
import { useModal } from "./hooks/useModal";
import { ModalEntity, ModalName } from "./ModalConfig";
import { ModalState } from "../../../logic/logicTypes";
import useDevice from "../../../hooks/useDevice";

export const ModalWrapper: React.FC = () => {
  const { modals, currentModalName, popModal, closeAllModals, showModal } =
    useModal();
  const { isMobile } = useDevice();

  const devPlayerName = useContext(DevPlayerNameContext);

  const dispatch = useDispatch();
  const { allPlayers } = useSelector(selectAllPlayers);
  const { localPlayerId } = useSelector(selectLocalPlayerId);
  const { localPlayerActiveModalName } = useSelector(
    selectLocalPlayerActiveModalName
  );

  // We look at local-storage to see if player has agreed to disclaimer that is only visible for mobile, and if not, we show them the Disclaimer modal
  const hasAgreedMobileDisclaimer = !!(
    getLocal(LocalKeys.hasAgreedMobileDisclaimer) ?? false
  );
  useEffect(() => {
    if (isMobile && !hasAgreedMobileDisclaimer) {
      showModal({
        name: ModalName.MobileDisclaimerModal,
        requiresBackendVerification: false,
      });
    }
  }, [isMobile, hasAgreedMobileDisclaimer, dispatch]);

  // for modal visibility conditions
  // - if modal does not require verification from backend, pass isCurrentModalName with truthy isActiveModalName
  // - if dev mode is active, match the dev player and pass isCurrentModalName for that dev player with isActiveModalName
  // - else, pass isCurrentModalName and isActiveModalName
  const isModalVisible = useCallback(
    (modal: ModalState) => {
      const isCurrentModalName = modal.name === currentModalName;
      const isActiveModalName = modal.name === localPlayerActiveModalName;

      if (!modal.props.requiresBackendVerification) {
        return { isCurrentModalName, isActiveModalName: true };
      }

      if (devPlayerName) {
        const developerId = allPlayers.find(
          ({ name }) => name === devPlayerName
        )?.id;
        return {
          isCurrentModalName:
            localPlayerId === developerId && isCurrentModalName,
          isActiveModalName,
        };
      }

      return { isCurrentModalName, isActiveModalName };
    },
    [
      currentModalName,
      localPlayerActiveModalName,
      devPlayerName,
      allPlayers,
      localPlayerId,
    ]
  );

  if (modals.length <= 0) {
    return <></>;
  }

  return (
    <div>
      {modals.map((modal) => {
        const currentModal = ModalEntity[modal.name];

        if (!currentModal) {
          return <></>;
        }

        const { isCurrentModalName, isActiveModalName } = isModalVisible(modal);
        const Component = currentModal.component;
        const props = {
          title: currentModal.title,
          hasBackButton: modals.length > 1,
          ...modal.props,
          visible: isCurrentModalName,
          disabled: !isActiveModalName,
          onBack: () => popModal(),
          onClose: () => closeAllModals(),
        };

        return (
          <Modal key={props.name} {...props}>
            <Component {...props} />
          </Modal>
        );
      })}
    </div>
  );
};
