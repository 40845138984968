import { createSelector, createSlice } from "@reduxjs/toolkit";

import {
  TableInterface,
  PlayerInterface,
  PlayerStatus,
} from "poker-cows-common";
import { RootState } from "../store";
import { updateTable } from "../shared";

// =============================== //
//          Initial State          //
// ================================//
export const initialState: { players: PlayerInterface[] } = { players: [] };

// =============================== //
//              Slice              //
// ================================//
export const slice = createSlice({
  name: "player",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      updateTable,
      (state, action: { type: string; payload: { table: TableInterface } }) => {
        const table = action.payload.table;

        if (table && table.players && Array.isArray(table.players)) {
          return { ...state, players: table.players };
        }

        return initialState;
      }
    );
  },
});

// =============================== //
//            Reducers             //
// ================================//
export default slice.reducer;

// =============================== //
//            Selectors            //
// ================================//
const selectPlayers = (state: RootState) => state.player.players ?? [];
export const selectAllPlayers = createSelector(selectPlayers, (players) => ({
  allPlayers: players,
}));
export const selectValidPlayers = createSelector(selectPlayers, (players) => ({
  validPlayers: players.filter(
    (player) =>
      player.status === PlayerStatus.HOST ||
      player.status === PlayerStatus.JOINING ||
      player.status === PlayerStatus.WAITING ||
      player.status === PlayerStatus.ADMITTED
  ),
}));
export const selectActivePlayers = createSelector(selectPlayers, (players) => ({
  activePlayers: players.filter(
    (player) =>
      player.status === PlayerStatus.HOST ||
      player.status === PlayerStatus.ADMITTED
  ),
}));
export const selectJoiningPlayers = createSelector(
  selectPlayers,
  (players) => ({
    joiningPlayers: players.filter(
      (player) => player.status === PlayerStatus.JOINING
    ),
  })
);
export const selectAdmittedPlayers = createSelector(
  selectPlayers,
  (players) => ({
    admittedPlayers: players.filter(
      (player) => player.status === PlayerStatus.ADMITTED
    ),
  })
);
export const selectPlayersWithActiveModal = (modalName: string) =>
  createSelector(selectValidPlayers, ({ validPlayers }) => ({
    playersWithActiveModal: validPlayers.filter((player) => {
      return player.activeModalName === modalName;
    }),
  }));
export const selectAreAnyPlayersBanking = createSelector(
  selectPlayersWithActiveModal("ledger-modal"),
  ({ playersWithActiveModal }) => ({
    areAnyPlayersBanking: playersWithActiveModal.length > 0,
  })
);
export const selectPlayersInvolvedInLedger = createSelector(
  selectPlayers,
  (players) => ({
    playersInvolvedInLedger: players.filter((player) => player.everBoughtIn),
  })
);
export const selectPlayerById = (playerId = "") =>
  createSelector(selectPlayers, (players) => ({
    player: players.find((player) => player.id === playerId),
  }));
export const selectPlayerStatusById = (playerId = "") =>
  createSelector(selectPlayers, (players) => ({
    playerStatus:
      players.find((player) => player.id === playerId)?.status ?? "",
  }));
export const selectIsPlayerTakingBreakById = (playerId = "") =>
  createSelector(selectPlayerById(playerId), ({ player }) => ({
    isPlayerTakingBreak: player?.takingBreak ?? false,
  }));
export const selectIsRemainingSessionTimeVisibleForPlayerById = (
  playerId = ""
) =>
  createSelector(selectPlayerById(playerId), ({ player }) => ({
    isRemainingSessionTimeVisibleForPlayer:
      player?.showRemainingSessionTime ?? false,
  }));
export const selectIsSeatTakenAtTablePos = (tablePosition = -1) =>
  createSelector(selectPlayerAtTablePos(tablePosition), ({ player }) => ({
    isSeatTaken: !!player?.name,
  }));
export const selectPlayerAtTablePos = (tablePosition = -1) =>
  createSelector(selectValidPlayers, ({ validPlayers }) => ({
    player: validPlayers.find((player) => player.seatNumber === tablePosition),
  }));
export const selectPlayerIdAtTablePos = (tablePosition = -1) =>
  createSelector(selectPlayerAtTablePos(tablePosition), ({ player }) => ({
    playerId: player?.id ?? "",
  }));
export const selectPlayerBankAtTablePos = (tablePosition = -1) =>
  createSelector(selectPlayerAtTablePos(tablePosition), ({ player }) => ({
    playerBank: player?.bank ?? 0,
  }));
export const selectPlayerStatusAtTablePos = (tablePosition = -1) =>
  createSelector(selectValidPlayers, ({ validPlayers }) => ({
    playerStatus: (validPlayers.find(
      (player) => player.seatNumber === tablePosition
    )?.status ?? "") as PlayerStatus,
  }));
export const selectPlayerNameAtTablePos = (tablePosition = -1) =>
  createSelector(selectPlayerAtTablePos(tablePosition), ({ player }) => ({
    playerName: player?.name ?? "",
  }));
export const selectIsPlayerBotAtTablePos = (tablePosition = -1) =>
  createSelector(selectPlayerAtTablePos(tablePosition), ({ player }) => ({
    isPlayerBot: player?.isBot ?? false,
  }));
export const selectPlayerActiveModalNameAtTablePos = (tablePosition = -1) =>
  createSelector(selectPlayerAtTablePos(tablePosition), ({ player }) => ({
    playerActiveModalName: player?.activeModalName,
  }));
export const selectIsPlayerOnBreakAtTablePos = (tablePosition = -1) =>
  createSelector(selectPlayerAtTablePos(tablePosition), ({ player }) => ({
    isPlayerTakingBreak: player?.takingBreak ?? false,
  }));
export const selectIsPlayerScheduledToTakeBreakAtTablePos = (
  tablePosition = -1
) =>
  createSelector(selectPlayerAtTablePos(tablePosition), ({ player }) => ({
    isPlayerScheduledToTakeBreak: player?.scheduledToTakeBreak ?? false,
  }));
export const selectPlayerHandAtTablePos = (tablePosition = -1) =>
  createSelector(selectPlayerAtTablePos(tablePosition), ({ player }) => ({
    playerHand: player?.hand,
  }));
export const selectPlayerCardsAtTablePos = (tablePosition = -1) =>
  createSelector(
    selectPlayerHandAtTablePos(tablePosition),
    ({ playerHand }) => ({ playerCards: playerHand?.cards ?? [] })
  );
export const selectIsCardsDealtForAnyPlayer = (state: RootState) => {
  const players = selectPlayers(state);
  const areCardsDealtForAnyPlayer = players.some((player) => {
    const relevantPlayer = selectPlayerCardsAtTablePos(player.seatNumber)(
      state
    );
    return relevantPlayer?.playerCards.length > 0;
  });

  return { areCardsDealtForAnyPlayer };
};
