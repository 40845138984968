/**
 * TableError.ts
 *
 * Table error which can be thrown with a code specifying the type of
 * error, as well as an HTTP status code.  Useful for throwing errors from
 * server API or backend, through to the frontend for display and handling.
 */
import { TableErrorType } from "../enums/TableErrorType";

export class TableError {
  readonly message: string;
  readonly errorType: TableErrorType;
  readonly statusCode: number;

  constructor(
    message: string,
    errorType = TableErrorType.INTERNAL_ERROR,
    statusCode = 500
  ) {
    this.message = message;
    this.errorType = errorType;
    this.statusCode = statusCode;
  }
}
