// OmahaRules.tsx
//
// Description of the Omaha game rules

import { GameRulesInterface } from "poker-cows-common";
import OmahaRulesImagePlayer from "../../static/graphics/rules/Player_Hand_4_Cards.svg";
import OmahaRulesImageCommunity from "../../static/graphics/rules/Texas_Holdem_Community_Cards.svg";

export const OmahaRules: GameRulesInterface = {
  objectives: [
    "Best 5 card poker hand utilizing EXACTLY 2 of the player’s hole cards and up to 3 community cards.",
  ],
  card: { default: ["4 player cards", "5 community cards"] },
  image: { player: OmahaRulesImagePlayer, community: OmahaRulesImageCommunity },
  actions: [
    "1. Each player is dealt 4 cards down.",
    "2. Bet, always starting with the player left of the dealer.",
    "3. 3 community cards dealt (flop), bet.",
    "4. 1 community card dealt (turn), bet.",
    "5. Final community card dealt (river), bet.",
    "6. Players declare “High” or “Low” (if a High-Low Split game)",
    "7. Cards displayed; winners paid.",
  ],
};
