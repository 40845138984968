import "./Loading.css";
import { ExtraSession } from "../views/extraSession/ExtraSession";
import React from "react";

const Loading = () => {
  return (
    <div className="loading">
      <ExtraSession title="Loading..." contents={<></>} />
    </div>
  );
};

export default Loading;
