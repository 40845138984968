import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { initSentry } from "./error_handling/Sentry";
import { initLogRocket } from "./error_handling/LogRocket";

if (process.env.NODE_ENV === "production") {
  initSentry();
  initLogRocket();
}

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
