/** TexasDropemOptions.tsx
 *
 * Game Options for TexasDropem.
 */
import { BetLimit } from "../bets/BetLimit";
import { CardVersionSetting } from "../enums/CardVersionSetting";
import { GameOptionsInterface } from "../interfaces";
import {
  optionNoneDefault,
  wildcardNoneDefault,
  winnerHighLowSplitDefault,
} from "./defaultOptions";

export const TexasDropemOptions: GameOptionsInterface = {
  versions: [
    {
      title: "7 Card",
      description: "",
      value: CardVersionSetting.SEVEN_CARD,
      default: true,
      flavor: 7,
      betLimit: BetLimit.SPREAD_LIMIT,
    },
    {
      title: "Cards Dealt Equal Number of Players",
      description: "",
      value: CardVersionSetting.EQUAL_TO_PLAYERS,
      flavor: -1,
      betLimit: BetLimit.SPREAD_LIMIT,
    },
  ],
  winners: [winnerHighLowSplitDefault],
  options: [optionNoneDefault],
  wildcards: [wildcardNoneDefault],
};
