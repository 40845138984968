/**
 * StartBotTestTable.tsx
 *
 * Start a deterministic test table with bots (a table whose deck is always the same given the seed
 * parameter is the same and starts 4 bots to play game with). The route takes a seed param that can be used to control the
 * test scenarios if needed to be different.
 */
import React, { useEffect } from "react";
import Loading from "../../components/Loading";
import { useTester } from "./StartTestTable";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { createTable } from "../../../logic/table/thunks";
import { selectTableCode } from "../../../logic/table/slice";
import { SessionKeys, setSession } from "../../../utils/SessionSettings";

const DEFAULT_TEST_SEED = "42";

export const StartBotTestTable = () => {
  const dispatch = useDispatch();
  const { isTester } = useTester();
  const { tableCode } = useSelector(selectTableCode);
  const { seed } = useParams<{ seed?: string }>();

  useEffect(() => {
    if (isTester) {
      setSession(SessionKeys.isBotTable, "true");

      dispatch(
        createTable({
          name: "Bot Test Table",
          isBotTable: true,
          isTestTable: true,
          seed: seed ?? DEFAULT_TEST_SEED,
        })
      );
    }
  }, [seed, isTester, dispatch]);

  if (tableCode) {
    const url = `/bot/table/${tableCode}`;
    return <Redirect to={url} />;
  }

  if (isTester === false) {
    return <Redirect to="/" />;
  }

  return <Loading />;
};
