/**
 * PreShutdownArea.tsx
 *
 * This is a component which is visible when host starts to shutdown the game and after post game
 */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TableStatus } from "poker-cows-common";
import {
  selectIsBotTable,
  selectIsLocalPlayerHost,
} from "../../../logic/table/slice";
import {
  selectHostId,
  selectPokerSessionId,
  selectTableId,
  selectTableStatus,
} from "../../../logic/table/slice";
import { ModalName } from "../Modal/ModalConfig";
import { useModal } from "../Modal/hooks/useModal";
import { leaveGame, shutdownNow } from "../../../logic/prompts/slice";
import Button from "../Button";
import { redirectToSurvey } from "../../../utils/RedirectToSurvey";

export const PreShutdownArea = () => {
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);
  const { tableStatus } = useSelector(selectTableStatus);
  const isPostGame = tableStatus === TableStatus.POST_GAME;

  const { hostId } = useSelector(selectHostId);
  const { pokerSessionId } = useSelector(selectPokerSessionId);
  const { tableId } = useSelector(selectTableId);
  const { isBotTable } = useSelector(selectIsBotTable);

  const { currentModalName, showModal } = useModal();

  const dispatch = useDispatch();

  const handleShutdownNow = () => {
    redirectToSurvey({ tableId, isBotTable, hostId, pokerSessionId });
    dispatch(shutdownNow());
  };

  const leaveTableClick = () => {
    redirectToSurvey({ tableId, isBotTable, hostId, pokerSessionId });
    dispatch(leaveGame());
  };

  const isLeaveButtonVisible =
    !currentModalName ||
    ![ModalName.CashOutModal, ModalName.PreShutdownModal].includes(
      currentModalName
    );

  let defaultText: string | JSX.Element = "";
  if (isPostGame) {
    defaultText = (
      <div className="endSessionNonHostSubHeader">
        YOUR GAME SESSION HAS ENDED
        {isLeaveButtonVisible && (
          <div className="buttonContainer">
            <Button
              className="resetHandCancelButton actionButton cancelButton"
              onClick={isLocalPlayerHost ? handleShutdownNow : leaveTableClick}
            >
              Exit PokerCows
            </Button>
          </div>
        )}
      </div>
    );
  } else if (!isLocalPlayerHost) {
    defaultText = "Waiting for Host";
  }

  useEffect(() => {
    if (currentModalName !== ModalName.CashOutModal) {
      showModal({ name: ModalName.PreShutdownModal });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return <>{defaultText}</>;
};
