import { GameRulesInterface } from "poker-cows-common";
import GutsRulesImagePlayer from "../../static/graphics/rules/Guts_Player_Hand.svg";
import GutsRulesImagePlayerThreeCard from "../../static/graphics/rules/Guts_Player_Hand_3_Cards.svg";

export const GutsRules: GameRulesInterface = {
  objectives: [
    "• 2 Card Guts: Best 2 Card Poker Hand. Highest pair wins. Any pair beats non pairs. High card if no pairs. 2nd card if high cards match.",
    "• 3 Card Guts: Best 3 Card Poker Hand. The ranking of hands in three card Guts is (from high to low): straight flush, three of a kind, straight, flush, pair, high card, 2nd card if high cards match, 3rd card if both high cards and 2nd cards match.",
  ],
  card: {
    twoCard: ["2 Player Cards"],
    threeCard: ["3 Player Cards"],
  },
  image: {
    player: GutsRulesImagePlayer,
    playerTwoCard: GutsRulesImagePlayer,
    playerThreeCard: GutsRulesImagePlayerThreeCard,
  },
  actions: [
    "1. Players ante, and each player is dealt 2 or 3 cards down depending on game version.",
    "2. Players simultaneously declare “In” or “Out”. Players “Out” can not win the pot.",
    "3. Players “In” show their cards, and the best hand takes the pot.",
    "4. Any player that was “In“ and lost must match the pot and play continues.",
    "5. If no players declared “In,” then all players re-ante and play continues.",
    "6. Play continues with another round of 2 or 3 cards depending on game version.",
    "7. The game ends when only one person stays “In” and wins the pot, or 2+ players stay “In” and split the pot and no matching required.",
  ],
};
