/**
 * ActForPlayerSubpage.tsx
 *
 * This is a central component around ActForPlayerSubpageModal using Host Controls to give actions that can be taken over certain player
 */
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import classNames from "classnames";

import {
  checkForPlayer,
  callForPlayer,
  kickPlayer,
  foldAndTakeBreakForPlayer,
  selectBetPromptForPlayer,
  selectAnyDeclarePrompt,
} from "../../../../logic/prompts/slice";
import {
  selectRemainingPlayers,
  selectAllWinners,
  selectPlayerCallAmountAtTablePos,
  selectDeclarationForPlayerById,
} from "../../../../logic/gameInstance/slice";
import {
  selectPlayerBankAtTablePos,
  selectIsPlayerTakingBreakById,
  selectPlayerById,
} from "../../../../logic/player/slice";
import { ModalProps } from "../../Modal/types/modalTypes";
import Button from "../../Button";

export const ActForPlayerSubpage = (props: ModalProps) => {
  const dispatch = useDispatch();

  const { player } = useSelector(
    selectPlayerById(props?.additionalProps?.selectedPlayerId)
  );
  const selectedPlayerId = player?.id ?? "";
  const selectedPlayerSeatNumber = player?.seatNumber ?? 0;
  const { betPromptForPlayer: isCheckForPlayerActive } = useSelector(
    selectBetPromptForPlayer(selectedPlayerId)
  );
  const { playerCallAmount } = useSelector(
    selectPlayerCallAmountAtTablePos(selectedPlayerSeatNumber)
  );
  const { playerBank } = useSelector(
    selectPlayerBankAtTablePos(selectedPlayerSeatNumber)
  );
  const { anyDeclarePrompt } = useSelector(selectAnyDeclarePrompt);
  const { playerDeclaration } = useSelector(
    selectDeclarationForPlayerById(selectedPlayerId)
  );
  const isPlayerDeclared = !!(playerDeclaration && anyDeclarePrompt);
  const playerCanAffordCall = playerBank - playerCallAmount > 0;

  // to determine if a player can be folded, make sure there's a game,
  // and the player is in it,
  // and the game is active (there aren't winners)
  const { remainingPlayers } = useSelector(selectRemainingPlayers);
  const { winners } = useSelector(selectAllWinners);
  const foldActive =
    remainingPlayers?.[selectedPlayerId] && winners.length === 0;

  // see if player is already on break
  const { isPlayerTakingBreak } = useSelector(
    selectIsPlayerTakingBreakById(selectedPlayerId)
  );

  // the deal out button is concerned with both foldability and
  // take-a-break-ability for the player
  const dealOutActive = foldActive || !isPlayerTakingBreak;

  const checkCallForPlayer = () => {
    if (playerCallAmount === 0) {
      dispatch(checkForPlayer(selectedPlayerId));
    } else {
      dispatch(
        callForPlayer({
          playerId: selectedPlayerId,
          amount: playerCallAmount,
        })
      );
    }
    props.onBack();
  };
  const dealOutClick = () => {
    if (dealOutActive) {
      dispatch(foldAndTakeBreakForPlayer(selectedPlayerId));
      props.onBack();
    }
  };
  const removePlayer = () => {
    // During a game kick player will also perform a fold for the player. Removing a player
    // during a game without a fold doesn't make sense as it will leave the game in blocked
    // state (e.g. no one can make a move because the removed player needs to make a move
    // but they are not able due to being removed from the table). Thus kickPlayer will do
    // a fold and then kick the player out of the game.
    // When the player is removed outside of play (e.g. when selecting a game or showing cards)
    // no fold is necessary (the toybox will do the right thing in both cases so all we need to
    // do here is issue the HostKickPlayerAction).
    dispatch(kickPlayer(selectedPlayerId));
    // close player subpage
    props.onBack();
  };

  const dealOutButtonText = `${foldActive ? "Fold & " : ""}Sit Out for ${
    player?.name
  }`;
  const removeButtonText = `${foldActive ? "Fold & " : ""}Remove ${
    player?.name
  } from Table`;

  if (!player) {
    return <></>;
  }

  return (
    <div className="hostControlsSubpageContainer actForPlayerSubpage">
      <div className="actForPlayerSubpageButtonsContainer">
        <Button
          className={classNames("hostControlsButton", {
            inactive: !isCheckForPlayerActive,
          })}
          onClick={() => {
            if (isCheckForPlayerActive) {
              checkCallForPlayer();
            }
          }}
        >
          <span className="buttonText">Check/Call for {player?.name}</span>
          {!playerCanAffordCall && (
            <span className="buttonDetailText">
              Player does not have enough chips to call.
            </span>
          )}
        </Button>
        <Button
          className={classNames("hostControlsButton", {
            disabledButton: !dealOutActive || isPlayerDeclared,
          })}
          disabled={!dealOutActive || isPlayerDeclared}
          onClick={dealOutClick}
        >
          <span className="buttonText">{dealOutButtonText}</span>
        </Button>
        <Button
          className={classNames("hostControlsButton", {
            disabledButton: isPlayerDeclared,
          })}
          disabled={isPlayerDeclared}
          onClick={removePlayer}
        >
          <span className="buttonText">{removeButtonText}</span>
        </Button>
      </div>
    </div>
  );
};
