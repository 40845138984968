import React from "react";
import { useSelector } from "react-redux";

import { PlayerHand } from "../Hand";
import { TwilioMedia } from "./TwilioMedia/TwilioMedia";
import { ErrorBoundary } from "../../../error_handling/ErrorBoundary";
import { useSeatContext } from "./SeatContext";
import { selectGameType } from "../../../logic/table/slice";
import { PassesIndicator } from "./PassesIndicator";
import { selectLocalPlayerStatus } from "../../../logic/gameInstance/slice";
import { Game, PlayerStatus } from "poker-cows-common";
import {
  selectAnyChooseGamePrompt,
  selectStartPrompt,
} from "../../../logic/prompts/slice";

export const PlayerRow = () => {
  const { isLocalSeat, isThisSeatTaken } = useSeatContext();

  const { localPlayerStatus } = useSelector(selectLocalPlayerStatus);
  const isPlayerJoining = localPlayerStatus === PlayerStatus.JOINING;

  const { gameType } = useSelector(selectGameType);
  const is_727 = gameType === Game.SevenTwentySeven;

  const { anyChooseGamePrompt } = useSelector(selectAnyChooseGamePrompt);
  const { startPrompt } = useSelector(selectStartPrompt);
  const isGameBeingSelected = anyChooseGamePrompt || startPrompt;

  return (
    <div className="playerRow">
      <>
        {is_727 &&
          !isPlayerJoining &&
          isThisSeatTaken &&
          !isGameBeingSelected && <PassesIndicator />}
        {!isPlayerJoining && !isLocalSeat && !isGameBeingSelected && (
          <ErrorBoundary>
            <PlayerHand />
          </ErrorBoundary>
        )}
        <TwilioMedia />
      </>
    </div>
  );
};
