/*
  ChipPurchased.tsx
  This component used to notify to a player that he has purchased chips from system (auto)
*/
import React from "react";
import Button from "../Button";
import { ModalProps } from "../Modal/types/modalTypes";
import { useSeatContext } from "../Seat/SeatContext";
import { useSelector } from "react-redux";
import { selectPlayerAtTablePos } from "../../../logic/player/slice";

import "./ChipPurchased.css";
import { formatMoneyString } from "../../../utils/MoneyFormat";

export const ChipPurchased = (props: ModalProps) => {
  const { tablePosition } = useSeatContext();
  const { player } = useSelector(selectPlayerAtTablePos(tablePosition));

  if (!player) {
    return <></>;
  }

  const chipPurchased =
    player.bankingHistory[player.bankingHistory.length - 1].amount;

  return (
    <div className="chipPurchased section">
      <div className="chipPurchasedText">
        Chips were automatically purchased
        <br /> to match your pot obligation.
      </div>
      <div className="chipPurchasedAmount">
        {formatMoneyString(chipPurchased)}
      </div>
      <div className="chipPurchasedText">Updated on your ledger.</div>
      <Button
        className="actionButton green chipPurchasedButton"
        onClick={props.onClose}
      >
        CONTINUE
      </Button>
    </div>
  );
};
