import React from "react";
import { useSelector } from "react-redux";

import { BettingProps, useBetting } from "../BetArea/hooks/Betting";
import {
  selectPrefs,
  selectFixedLimitAmount,
  selectIsBetSpreadLimit,
} from "../../../../logic/gameInstance/slice";

// this component displays those three little dots in the user actionArea
// to tell you how many raises have happened in the round

export const RaisesCounter = () => {
  const { prefs } = useSelector(selectPrefs);
  const { fixedLimitAmount } = useSelector(selectFixedLimitAmount);
  const { isBetSpreadLimit } = useSelector(selectIsBetSpreadLimit);

  let bettingProps = {
    minRaise: fixedLimitAmount,
    maxRaise: fixedLimitAmount,
  } as BettingProps;

  if (prefs && isBetSpreadLimit) {
    bettingProps = {
      minRaise: prefs?.spreadMinRaise,
      maxRaise: prefs?.spreadMaxRaise,
    };
  }
  const betting = useBetting(bettingProps);

  function getRaiseDot(index: number) {
    const classes = ["raiseDot"];
    if (index < betting.numberOfRaises) {
      classes.push("raiseDotFilled");
    }
    return <div key={index} className={classes.join(" ")} />;
  }
  function getRaiseDots() {
    const dots: JSX.Element[] = [];

    for (let i = 0; i < betting.maxRaises; i++) {
      dots.push(getRaiseDot(i));
    }

    return dots;
  }
  return (
    <div className="raisesCounter">
      Raises <div className="dotsGroup">{getRaiseDots()}</div>
    </div>
  );
};
