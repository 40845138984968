import "./UserInfo.css";
import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

const UserInfo = () => {
  const { user } = useAuth0();

  return (
    <div className="profileInfo">
      <div className="profilepic">
        <img
          className="profileavatar"
          src={user?.picture}
          alt={user?.name}
          width="64px"
        />
      </div>
      <div className="usertext">
        <Link
          to="/account/profile"
          className="userTextLink"
          title="Goto Profile Page"
        >
          <p className="profileusername">{user?.name}</p>
          <p>{user?.email}</p>
        </Link>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(UserInfo);
