// PotLimit.ts
//
// Checks whether the current bet is bigger than the pot size

import { BetInfo } from "./BetInfo";
import { BettingStructure } from "./BettingStructure";
import { RoundBetInfo } from "./RoundBetInfo";

/**
 * Pot Limit
 *
 * The limit each player can put is the amount in the pot
 */
export class PotLimit extends BettingStructure {
  validateBet(bet: BetInfo, round: RoundBetInfo) {
    this.validatePositiveNumber(bet.amount, "bet amount");
    const currentPot = round.currentPot ?? 0;

    if (bet.amount > currentPot) {
      throw new Error(
        `Current bet amount of ${bet.amount} will exceed exceed the current pot of ${round.currentPot}`
      );
    }

    this.validateRaises(bet, round);
  }
}
