/**
 * useDevice hook
 * This hook returns the current screen is the mobile / desktop
 * We can use single bind listener window event from this file
 */
import { useEffect, useState } from "react";

interface DeviceProps {
  resize?: (...args: any) => any
}

/**
 * This util handled returning if the device that is currently playing the game is from mobile device of desktop device
 * We might introduce more functions in the near future to handle tablet and touch events
 * In future, we need to change the function to just variables
 */
const mobileAgentRegex = /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|samsungbrowser.*mobile|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;
export const isMobileAgent = () => mobileAgentRegex.test(window.navigator.userAgent) || window.innerWidth <= 768 ;  

export default function useDevice(value: DeviceProps = {}) {
  const [listener, setListener] = useState(value);


  const [isMobile, setMobile] = useState(isMobileAgent());
  const [isDesktop, setDesktop] = useState(!isMobileAgent());

  const onResize: EventListener = (...args) => {
    
    setMobile(isMobileAgent());
    setDesktop(!isMobileAgent());

    // resize listener received from component;
    listener.resize?.(...args);
  }

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [listener]);

  return { 

    // is mobile
    isMobile,

    // is desktop
    isDesktop,

    // return listener
    setListener
  };
}