/**
 * GetMoreChipsModal.tsx
 *
 * This is just a wrapper around the GetMoreChips
 */
import React from "react";

import { GetMoreChips } from "../../ActionArea/GetMoreChips";

import { ModalProps } from "../types/modalTypes";

export const GetMoreChipsModal = (props: ModalProps): JSX.Element => {
  return <GetMoreChips {...props} />;
};
