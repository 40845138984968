import React from "react";
import styles from "./TwilioMedia.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause } from "@fortawesome/free-solid-svg-icons";
import { useSeatContext } from "../SeatContext";
import { LocalTwilioMedia } from "./LocalTwilioMedia";
import { RemoteTwilioMedia } from "./RemoteTwilioMedia";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const TwilioMedia = () => {
  const { isLocalSeat } = useSeatContext();

  // if local user's seat
  if (isLocalSeat) {
    return <LocalTwilioMedia />;
  } else {
    // remote players' seats
    return <RemoteTwilioMedia />;
  }
};

export const PauseOverlay = () => (
  <div className={styles.pauseOverlay}>
    <div className={styles.pauseContent}>
      <FontAwesomeIcon icon={faPause as IconProp} />
      <br />
      <div className={"pause-text"}>Video Paused</div>
    </div>
  </div>
);

export const JoiningPlaceholder = () => (
  <div className={styles.joiningPlaceholder}>
    <div className={styles.joiningPlaceholderText}>Someone is joining...</div>
  </div>
);
