/**
 * Dashboard api.ts
 * Api request methods defined for dashboard
 */

import { getBlobEndpoint, getEndpoint } from "../api";
import { Game } from "poker-cows-common";

export interface PlayedGamePlayerInterface {
  amount: number;
  playerName: string;
}

export interface PlayedGameInterface {
  gameType: Game;
  host: {
    displayName: string;
  };
  practiceMode: boolean;
  startTime: string;
  stopTime: string;
  table: {
    code: string;
  };
  gameOption: Record<string, any>;
  tablePlayedGamePlayers: PlayedGamePlayerInterface[];
}

export interface PlayedGameFilterInterface {
  searchText: string;
  take: number;
  skip: number;
}

export async function getGamesInfo(
  playedGameFilter: PlayedGameFilterInterface
) {
  const queryString: string = new URLSearchParams({
    take: playedGameFilter.take.toString(),
    skip: playedGameFilter.skip.toString(),
    searchText: playedGameFilter.searchText,
  }).toString();
  return getEndpoint(`api/dashboard/get?${queryString}`, "");
}

export async function downloadPlayedGames() {
  return getBlobEndpoint(`api/dashboard/download-played-games`, "");
}
