/**
 * MobileDisclaimerModal.tsx
 *
 * This is the Modal shown for Disclaimer when players visit from mobile devices
 */
import React from "react";
import Button from "../../Button";

import { LocalKeys, setLocal } from "../../../../utils/LocalSettings";

import { ModalProps } from "../types/modalTypes";

export const MobileDisclaimerModal = (props: ModalProps): JSX.Element => {
  const hideDisclaimer = () => {
    setLocal(LocalKeys.hasAgreedMobileDisclaimer, "true");
    window.location.reload();
  };

  return (
    <span className="disclaimer">
      <h2>PokerCows is not designed for mobile right now!</h2>
      <h3>What this means for you:</h3>
      You may experience inconsistencies in the look and feel of the game when
      accessing it from a mobile device. For the best experience, we recommend
      using PokerCows on a desktop by visiting <b>https://pokercows.com</b>.
      <br />
      <br />
      We value your feedback, and it can significantly contribute to the
      improvement of the game in the future. Therefore, if you encounter any
      issues or have suggestions for improvement, please reach out to us at{" "}
      <b>support@pokercows.com</b>.
      <br />
      <br />
      <footer className="modalFooter greenBackground">
        <Button onClick={hideDisclaimer} className="modalButtonPrimary">
          I understand, let's play!
        </Button>
      </footer>
    </span>
  );
};
