/**
 * ForcedBet.ts
 *
 * Enum for all the supported forced bets (a bet you have to make to play in a game before the
 * cards are even dealt). Current support is for Ante (everyone pays the same amount) and Blinds
 * a couple of players pay a smallBlind and bigBlind and the rest of the players decide if they
 * want to be in the game or not.
 */
//
export enum ForcedBet {
  BLINDS = 1,
  ANTES = 2,
}
