/*
 * LowChip.tsx
 * This low chip component used to notify to player that he is getting low chips to play next game
 */
import React, { useEffect } from "react";
import Button from "../Button";
import { ModalProps } from "../Modal/types/modalTypes";
import { useModal } from "../Modal/hooks/useModal";
import { ModalName } from "../Modal/ModalConfig";
import { useDispatch, useSelector } from "react-redux";
import { submitLowChipPrompt } from "../../../logic/prompts/slice";
import { selectLocalPlayerName } from "../../../logic/localPlayer/slice";
import "./LowChip.css";

export const LowChip = (props: ModalProps) => {
  const dispatch = useDispatch();
  const { showModal, popModal } = useModal();
  const { localPlayerName } = useSelector(selectLocalPlayerName);

  const requestChipButton = () => {
    showModal({
      name: ModalName.LedgerModal,
      title: localPlayerName ? `${localPlayerName}'s Bank` : `My Bank`,
      hasCloseButton: false,
    });
  };

  const back = () => {
    dispatch(submitLowChipPrompt());
    popModal();
  };

  return (
    <div className="lowChipContent section">
      <div className="lowChipButtons">
        <Button
          variant="green"
          className="requestChipButton"
          onClick={requestChipButton}
        >
          REQUEST CHIPS FROM BANK
        </Button>
        <Button variant="black" className="lowChipBackButton" onClick={back}>
          NO THANKS
        </Button>
      </div>
    </div>
  );
};
