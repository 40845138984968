/**
 * MasterStartupModal.tsx
 *
 * This is just a wrapper around the MasterStartupContents
 */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectStartNormalGamePlayPrompt } from "../../../../logic/prompts/slice";
import { MasterStartupContents } from "../../ActionArea/MasterStartup";

import { useModal } from "../hooks/useModal";
import { ModalName } from "../ModalConfig";
import { ModalProps } from "../types/modalTypes";

export const MasterStartupModal = (props: ModalProps): JSX.Element => {
  const { showModal } = useModal();
  const { startNormalGamePlayPrompt } = useSelector(
    selectStartNormalGamePlayPrompt
  );

  useEffect(() => {
    if (startNormalGamePlayPrompt) {
      showModal({ name: ModalName.InviteFriendsModal });
    }
  }, [startNormalGamePlayPrompt, showModal]);

  return <MasterStartupContents {...props} />;
};
