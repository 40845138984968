/**
 * Loader.tsx
 * This component used to display loading...
 */
import React from "react";
import styles from "./Loader.module.css";

export default function Loader() {
  return <div className={styles.loader}></div>;
}
