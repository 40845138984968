import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  selectPotTotalAmount,
  selectCanLocalPlayerCheck,
} from "../../../../logic/gameInstance/slice";
import { formatMoneyString } from "../../../../utils/MoneyFormat";
import "./GenericBetArea.css";
import { useBetting } from "./hooks/Betting";
import Button from "../../Button";
import { CHECK_BUTTON } from "../../../../test-identifiers";

interface GenericBetAreaProps {
  showTopButtons: boolean;
  showSlider: boolean;
  minRaise: number;
  maxRaise: number;
}

export const GenericBetArea = (props: GenericBetAreaProps) => {
  const {
    showCall,
    callAmount,
    maxRaises,
    currentBet,
    bank,
    maxRaise,
    minRaise,
    numberOfRaises,
    ...betting
  } = useBetting(props);
  const { potTotalAmount } = useSelector(selectPotTotalAmount);
  const { canLocalPlayerCheck } = useSelector(selectCanLocalPlayerCheck);
  const showRaise = callAmount + props.minRaise <= bank;
  const showSlider = maxRaise - minRaise > 0;
  const [sliderValue, setSliderValue] = useState(minRaise);
  const formattedSlider = formatMoneyString(sliderValue);

  const raiseByToTotalBet = useCallback(
    (amount: number) => amount + currentBet,
    [currentBet]
  );

  const canRaise = maxRaises <= 0 || numberOfRaises < maxRaises;

  useEffect(() => {
    setSliderValue(raiseByToTotalBet(minRaise));
  }, [setSliderValue, minRaise, raiseByToTotalBet]);

  const checkClick = () => betting.check();
  const callClick = () => betting.call();
  const raiseClick = () => betting.raiseTo(sliderValue);

  const onSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSliderValue(parseInt(event.target.value));
  };

  const setPresetBetAmounts = (fractionOfPot: number) => () => {
    let amount = Math.round(fractionOfPot * potTotalAmount);
    amount = Math.min(props.maxRaise, amount);
    amount = Math.max(minRaise, amount);
    setSliderValue(amount);
  };

  const changeSliderBy = (changeAmount: number) => () => {
    changeAmount += sliderValue;
    changeAmount = Math.min(raiseByToTotalBet(maxRaise), changeAmount);
    changeAmount = Math.max(raiseByToTotalBet(minRaise), changeAmount);
    if (changeAmount !== sliderValue) {
      setSliderValue(changeAmount);
    }
  };

  const checkClass = canLocalPlayerCheck
    ? "actionButton yellow"
    : "actionButton hidden";
  const callClass = showCall ? "actionButton green" : "actionButton hidden";
  const raiseClass =
    showRaise && canRaise ? "actionButton green" : "actionButton hidden";
  const sliderClass =
    !showRaise || !showSlider || minRaise === maxRaise || !canRaise
      ? "sliderRowContainer hidden"
      : "sliderRowContainer";
  const amountButtonsClass = showRaise
    ? "topRowBetButtons"
    : "topRowBetButtons hidden";
  const decDisabled = sliderValue <= raiseByToTotalBet(minRaise);
  const incDisabled = sliderValue >= raiseByToTotalBet(maxRaise);

  const raiseText = currentBet === 0 ? "BET" : "RAISE to";

  const getPresetButton = (potFraction: number, label: string) => (
    <div
      className="actionButton"
      onClick={() => setPresetBetAmounts(potFraction)}
    >
      {label}
    </div>
  );

  return (
    <div className="betArea">
      {props.showTopButtons && (
        <div className={amountButtonsClass}>
          {getPresetButton(0, "MIN RAISE")}
          {getPresetButton(0.5, "1/2 POT")}
          {getPresetButton(0.75, "3/4 POT")}
          {getPresetButton(1, "POT")}
        </div>
      )}
      <div className={sliderClass}>
        {decDisabled && <div className={"betDec disabled"}>-</div>}
        {!decDisabled && (
          <div className={"betDec"} onClick={() => changeSliderBy(-1)}>
            -
          </div>
        )}
        <div className={"middleRowBetSlider"}>
          <div className="currentSliderAmount">${sliderValue}</div>
          <div className="inputDiv">
            <input
              type="range"
              value={sliderValue}
              onChange={onSliderChange}
              min={raiseByToTotalBet(minRaise)}
              max={raiseByToTotalBet(maxRaise)}
            />
          </div>
        </div>
        {incDisabled && <div className={"betInc disabled"}>+</div>}
        {!incDisabled && (
          <div className={"betInc"} onClick={() => changeSliderBy(1)}>
            +
          </div>
        )}
      </div>
      <div className="bottomRowBetButtons">
        <Button
          data-testid={CHECK_BUTTON}
          className={checkClass}
          onClick={checkClick}
        >
          CHECK
        </Button>
        <Button className={callClass} onClick={callClick}>
          CALL {formatMoneyString(callAmount)}
        </Button>
        <Button className={raiseClass} onClick={raiseClick}>
          {raiseText} {formattedSlider}
        </Button>
      </div>
    </div>
  );
};
