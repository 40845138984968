/**
 * allMoneyDenominations.ts
 *
 * This const returns UI friendly display name for denominations in dropdown
 * The Denomination enum is used as value and respective title is set
 */
import { Denomination } from "../enums/Denomination";

export const allMoneyDenominations = [
  { label: "1¢", value: Denomination.ONE_CENT },
  { label: "2¢", value: Denomination.TWO_CENT },
  { label: "5¢", value: Denomination.FIVE_CENT },
  { label: "10¢", value: Denomination.TEN_CENT },
  {
    label: "25¢",
    value: Denomination.TWENTY_FIVE_CENT,
  },
  { label: "50¢", value: Denomination.FIFTY_CENT },
  { label: "$1", value: Denomination.ONE_DOLLAR },
  { label: "$2", value: Denomination.TWO_DOLLAR },
  { label: "$3", value: Denomination.THREE_DOLLAR },
  { label: "$4", value: Denomination.FOUR_DOLLAR },
  { label: "$5", value: Denomination.FIVE_DOLLAR },
  { label: "$10", value: Denomination.TEN_DOLLAR },
  {
    label: "$20",
    value: Denomination.TWENTY_DOLLAR,
  },
  {
    label: "$25",
    value: Denomination.TWENTY_FIVE_DOLLAR,
  },
  {
    label: "$40",
    value: Denomination.FORTY_DOLLAR,
  },
  {
    label: "$50",
    value: Denomination.FIFTY_DOLLAR,
  },
  {
    label: "$100",
    value: Denomination.HUNDRED_DOLLAR,
  },
  {
    label: "$200",
    value: Denomination.TWO_HUNDRED_DOLLAR,
  },
  {
    label: "$300",
    value: Denomination.THREE_HUNDRED_DOLLAR,
  },
  {
    label: "$500",
    value: Denomination.FIVE_HUNDRED_DOLLAR,
  },
];

export const initialBuyInDenominations = allMoneyDenominations.filter(
  (denomination) =>
    ![
      Denomination.ONE_CENT,
      Denomination.TWO_CENT,
      Denomination.FIVE_CENT,
      Denomination.TEN_CENT,
      Denomination.TWENTY_FIVE_CENT,
      Denomination.FIFTY_CENT,
    ].includes(denomination.value)
);

export const filteredDenominations = allMoneyDenominations.filter(
  (denomination) =>
    ![
      Denomination.TWENTY_DOLLAR,
      Denomination.FORTY_DOLLAR,
      Denomination.TWO_HUNDRED_DOLLAR,
      Denomination.THREE_HUNDRED_DOLLAR,
      Denomination.FIVE_HUNDRED_DOLLAR,
    ].includes(denomination.value)
);
