/**
 * MyAccountModal.tsx
 *
 * This is a central component of MyAccountModal which just gives you coming soon as information
 */
import React from "react";
import { ModalProps } from "../../Modal/types/modalTypes";

export const MyAccount = (props: ModalProps) => {
  return (
    <div className="section myAccountPlaceholder">
      <h2>Coming Soon</h2>
    </div>
  );
};
