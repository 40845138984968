/**
 * TableErrorType.ts
 *
 * Types of error that can occur in the express-server and are returned via
 * response to the frontend for handling or display to the user.
 */
export enum TableErrorType {
  NONE,
  BAD_REQUEST,
  UNAUTHORIZED,
  INTERNAL_ERROR,
  TABLE_NOT_FOUND,
  PLAYER_NAME_TAKEN,
  PLAYER_ALREADY_EXISTS,
  NO_SEATS_AVAILABLE,
  ONLY_ONE_PLAYER_ALLOWED,
  // the request contains action not supported by the server
  INVALID_ACTION,
}
