import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCheckCallInTurn,
  selectFoldInTurn,
  selectTargetBetRound,
  setCheckCallInTurn,
  setFoldInTurn,
} from "../../../../../logic/betting/slice";
import { BettingProps, useBetting } from "./Betting";
import {
  selectPrefs,
  selectFixedLimitAmount,
  selectIsBetSpreadLimit,
} from "../../../../../logic/gameInstance/slice";

export function useAutomatedBet() {
  const dispatch = useDispatch();
  const { checkCallInTurn } = useSelector(selectCheckCallInTurn);
  const { foldInTurn } = useSelector(selectFoldInTurn);
  const { fixedLimitAmount } = useSelector(selectFixedLimitAmount);
  const { targetBetRound } = useSelector(selectTargetBetRound);
  const { isBetSpreadLimit } = useSelector(selectIsBetSpreadLimit);
  const { prefs } = useSelector(selectPrefs);

  let bettingProps = {
    minRaise: fixedLimitAmount,
    maxRaise: fixedLimitAmount,
  } as BettingProps;

  if (prefs && isBetSpreadLimit) {
    bettingProps = {
      minRaise: prefs.spreadMinRaise,
      maxRaise: prefs.spreadMaxRaise,
    };
  }
  const betting = useBetting(bettingProps);

  useEffect(() => {
    if (!betting.isTurnToBet) {
      return;
    }
    if (targetBetRound !== betting.betRoundId) {
      dispatch(setCheckCallInTurn(false));
      dispatch(setFoldInTurn(false));
      return;
    }
    if (checkCallInTurn) {
      dispatch(setCheckCallInTurn(false));
      if (betting.canCheck) {
        return betting.check();
      } else {
        return betting.call();
      }
    }
    if (foldInTurn) {
      dispatch(setFoldInTurn(false));
      return betting.fold();
    }
    return;
  }, [
    betting.isTurnToBet,
    betting,
    targetBetRound,
    checkCallInTurn,
    foldInTurn,
    dispatch,
  ]);
}
