/**
 * TableSocket.tsx
 * React hook for creating a websocket connection to server and receiving table update events
 */

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { io } from "socket.io-client";

import { getWebsocket } from "../../api";
import { useTableUpdater } from "./TableUpdater";
import { setConnected, setConnectionAttempted } from "../../connection/slice";

export function useTableSocket(
  id: string,
  tableGroup: string,
  localPlayerId: string
) {
  const [socket, setSocket] = useState<ReturnType<typeof io>>();
  const updateTable = useTableUpdater();
  const dispatch = useDispatch();

  useEffect(() => {
    // only open socket connection when the localPlayerId is set (aka join-table call has succeeded)
    if (!id || !localPlayerId) {
      return;
    }

    const newSocket = getWebsocket(id, tableGroup, localPlayerId);
    setSocket(newSocket);

    const setConnectedStatus = (state: boolean) => {
      dispatch(setConnected(state));
      dispatch(setConnectionAttempted(true));
    };

    newSocket.on("connect", () => setConnectedStatus(true));
    newSocket.on("disconnect", () => setConnectedStatus(false));
    newSocket.on("update_table", updateTable);
    return () => {
      newSocket.off("update_table", updateTable);
      newSocket.close();
    };
  }, [id, localPlayerId, tableGroup, updateTable, dispatch]);
}
