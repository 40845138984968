/**
 * useModal.ts
 *
 * This file in-houses all the actions and selectors related to modal
 * The primary reason to build this hook rather than direct action dispatch was so that we have ability to pass custom actions on each showModal, popModal & closeAllModals
 * Just use the hook and you get access to all the modal function, the array and current modal
 */
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectActiveModal } from "../../../../logic/prompts/slice";
import {
  selectCurrentModalName,
  selectModals,
  show,
  pop,
  closeAll,
  selectPreviousModalName,
  popUntil,
} from "../../../../logic/modal/slice";
import { ActionlessModalProps } from "../types/modalTypes";

export function useModal() {
  const dispatch = useDispatch();

  const { modals } = useSelector(selectModals);
  const { previousModalName } = useSelector(selectPreviousModalName);
  const { currentModalName } = useSelector(selectCurrentModalName);

  // `requiresBackendVerification` used to control the modal from the toybox state, so it should true and default is always true
  // if you need to show modal without control from backend, pass false
  const showModal = useCallback(
    (props: ActionlessModalProps) => {
      dispatch(selectActiveModal(props.name));
      dispatch(show({ ...props, requiresBackendVerification: props.requiresBackendVerification ?? true }));
    },
    [dispatch]
  );

  const popModal = useCallback(() => {
    dispatch(selectActiveModal(previousModalName));
    dispatch(pop());
  }, [previousModalName, dispatch]);

  const closeAllModals = useCallback(() => {
    const nonCloseableModals = modals.filter(
      ({ props: { hasBackButton, hasCloseButton, hasOverlay } }) =>
        hasBackButton === false &&
        hasCloseButton === false &&
        hasOverlay === false
    );

    const currentModalName = modals[modals.length - 1].name;
    const isCurrentModalNonCloseable = nonCloseableModals.find(
      (modal) => modal.name === currentModalName
    );

    // if the current modal is closeable and there are non-closeable modals in stack, pop the modals on stack till we reach the modal that is not closeable (if it exists)
    if (!isCurrentModalNonCloseable && nonCloseableModals.length > 0) {
      const lastNonCloseableModalName =
        nonCloseableModals[nonCloseableModals.length - 1].name;
      dispatch(selectActiveModal(lastNonCloseableModalName));
      dispatch(popUntil(lastNonCloseableModalName));
      return;
    }

    // reset to initial state
    dispatch(selectActiveModal(null));
    dispatch(closeAll());
  }, [modals, dispatch]);

  return {
    modals,
    currentModalName,
    previousModalName,
    /**
     * The function is used to show a new modal, or update a modal in stack
     */
    showModal,
    /**
     * The function is used to remove the currentModalName from stack
     */
    popModal,
    /**
     * The function is used to close all modals
     *
     * Case 1: If currentModalName is closeable modal and there is non-closeable modal in stack, it pops till that modal is currentModalName
     *
     * Case 2: Else, clear the stack
     */
    closeAllModals,
  };
}
