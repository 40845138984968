/**
 * Sound.ts
 * Enumerate for play the sound
 */

export enum Sound {
  CHECK = 1,
  CALL = 2,
  BET = 3,
  FOLD = 4,
  WINNING = 5,
  YOUR_TURN = 6,
}
