// toggle/api.ts
//
// Getting the state of a toggle from the server. Toggles are governed by the server
// thus we need to ask the server if a certain toggle is on.

import { Toggle } from "poker-cows-common";
import { getEndpoint } from "../api";

export async function isToggleOn(toggle: Toggle) {
  try {
    const res = await getEndpoint(`api/toggle/${toggle}`, "");
    return !!res[toggle];
  } catch (e) {
    return false;
  }
}
