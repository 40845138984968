import { createSelector, createSlice } from "@reduxjs/toolkit";

import {
  BetLimit,
  Wildcards,
  PlayerStatus,
  TableInterface,
  WildcardConstraint,
  GameInstanceInterface,
} from "poker-cows-common";
import { RootState } from "../store";
import { updateTable } from "../shared";
import {
  selectLocalPlayerId,
  selectLocalPlayerSeatNumber,
} from "../localPlayer/slice";
import {
  selectPlayerAtTablePos,
  selectPlayerIdAtTablePos,
  selectPlayerBankAtTablePos,
  selectPlayerStatusAtTablePos,
  selectPlayerActiveModalNameAtTablePos,
} from "../player/slice";

// =============================== //
//          Initial State          //
// ================================//
export const initialState = {} as GameInstanceInterface;

// =============================== //
//              Slice              //
// ================================//
export const slice = createSlice({
  name: "gameInstance",
  initialState: initialState,
  reducers: {
    updateGameData: (
      state,
      action: { type: string; payload: GameInstanceInterface }
    ) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      updateTable,
      (state, action: { type: string; payload: { table: TableInterface } }) => {
        const table = action.payload.table;

        if (table && table.currentGameInstance) {
          return { ...state, ...table.currentGameInstance };
        }

        return initialState;
      }
    );
  },
});

// =============================== //
//            Actions              //
// ================================//
export const { updateGameData } = slice.actions;

// =============================== //
//            Reducers             //
// ================================//
export default slice.reducer;

// =============================== //
//            Selectors            //
// ================================//
const selectGameInstance = (state: RootState) => state.gameInstance;
const selectGamePlayers = (state: RootState) => state.player.players ?? [];
export const selectBetRoundNumber = createSelector(
  selectGameInstance,
  ({ betRoundNumber }) => ({ betRoundNumber: betRoundNumber ?? 0 })
);
export const selectGameUUID = createSelector(
  selectGameInstance,
  ({ uuid }) => ({ gameUUID: uuid ?? "" })
);
export const selectBetRoundID = createSelector(
  [selectGameUUID, selectBetRoundNumber],
  (uuid, roundNumber) => ({ betRoundID: `${uuid.gameUUID}::${roundNumber.betRoundNumber}` })
);
export const selectCurrentBet = createSelector(
  selectGameInstance,
  ({ currentBet }) => ({ currentBet: currentBet ?? 0 })
);
export const selectPlayersBets = createSelector(
  selectGameInstance,
  ({ currentBettingRoundTotals }) => ({
    playersBets: currentBettingRoundTotals,
  })
);
export const selectPlayersChecks = createSelector(
  selectGameInstance,
  ({ currentBettingRoundChecks }) => ({
    playersChecks: currentBettingRoundChecks,
  })
);
export const selectPot = createSelector(selectGameInstance, ({ pot }) => ({
  pot,
}));
export const selectSidePots = createSelector(selectPot, ({ pot }) => ({
  sidePots: pot?.sidePots ?? [],
}));
export const selectPotTotalAmount = createSelector(selectPot, ({ pot }) => ({
  potTotalAmount: pot?.totalAmount ?? 0,
}));
export const selectPotTotalBetPerPlayer = createSelector(
  selectPot,
  ({ pot }) => ({
    potTotalBetPerPlayer: pot?.totalBetPerPlayer,
  })
);
export const selectIsGameRunning = createSelector(
  selectGameInstance,
  (gameInstance) => ({
    isGameRunning: !!gameInstance || Object.keys(gameInstance).length > 0,
  })
);
export const selectRemainingPlayers = createSelector(
  selectGameInstance,
  ({ remainingPlayers }) => ({ remainingPlayers })
);
export const selectLocalPlayerStatus = (state: RootState) => {
  const { localPlayerSeatNumber } = selectLocalPlayerSeatNumber(state);
  return {
    localPlayerStatus: selectPlayerStatusAtTablePos(localPlayerSeatNumber)(
      state
    ).playerStatus,
  };
};
export const selectLocalPlayerActiveModalName = (state: RootState) => {
  const { localPlayerSeatNumber } = selectLocalPlayerSeatNumber(state);
  return {
    localPlayerActiveModalName: selectPlayerActiveModalNameAtTablePos(
      localPlayerSeatNumber
    )(state).playerActiveModalName,
  };
};
export const selectLocalPlayerCallAmount = (state: RootState) => {
  const { localPlayerSeatNumber } = selectLocalPlayerSeatNumber(state);
  return {
    localPlayerCallAmount: selectPlayerCallAmountAtTablePos(
      localPlayerSeatNumber
    )(state).playerCallAmount,
  };
};
export const selectCanLocalPlayerCheck = createSelector(
  selectLocalPlayerCallAmount,
  ({ localPlayerCallAmount }) => ({
    canLocalPlayerCheck: localPlayerCallAmount === 0,
  })
);
export const selectLocalPlayerBet = (state: RootState) => {
  const { localPlayerSeatNumber } = selectLocalPlayerSeatNumber(state);
  return {
    localPlayerBet: selectPlayerBetAtTablePos(localPlayerSeatNumber)(state)
      .playerBet,
  };
};
export const selectLocalPlayerBank = (state: RootState) => {
  const { localPlayerSeatNumber } = selectLocalPlayerSeatNumber(state);
  return {
    localPlayerBank: selectPlayerBankAtTablePos(localPlayerSeatNumber)(state)
      .playerBank,
  };
};
/**
 * TODO: Consider rename. This is really "call is required and is possible"
 * The logic to actually SHOW the call button is just that callAmount > 0;
 * The localPlayer can click on it regardless of if they have the funds, but they'll
 * be prompted to get more chips/funds.
 */
export const selectCanLocalPlayerCall = createSelector(
  [selectLocalPlayerCallAmount, selectLocalPlayerBank],
  ({ localPlayerCallAmount }, { localPlayerBank }) => {
    let canLocalPlayerCall = false;
    if (localPlayerCallAmount > 0) {
      canLocalPlayerCall = localPlayerBank >= localPlayerCallAmount;
    }
    return { canLocalPlayerCall };
  }
);
export const selectCurrentRaise = createSelector(
  selectGameInstance,
  ({ currentRaise }) => ({ currentRaise: currentRaise ?? 0 })
);
export const selectRaises = createSelector(
  selectGameInstance,
  ({ currentBettingRoundRaises }) => ({
    currentRaises: currentBettingRoundRaises ?? 0,
  })
);
export const selectRemainingPassesForPlayerById = (playerId = "") =>
  createSelector(selectGameInstance, ({ data }) => ({
    remainingPasses: data?.passesRemaining?.[playerId] ?? 3,
  }));
export const selectPrefs = createSelector(selectGameInstance, ({ prefs }) => ({
  prefs,
}));
export const selectIsBetNoLimit = createSelector(selectPrefs, ({ prefs }) => ({
  isBetNoLimit: prefs?.limitType === BetLimit.NO_LIMIT,
}));
export const selectIsBetFixedLimit = createSelector(
  selectPrefs,
  ({ prefs }) => ({
    isBetFixedLimit: prefs?.limitType === BetLimit.FIXED_LIMIT,
  })
);
export const selectIsBetSpreadLimit = createSelector(
  selectPrefs,
  ({ prefs }) => ({
    isBetSpreadLimit: prefs?.limitType === BetLimit.SPREAD_LIMIT,
  })
);
export const selectFixedLimitAmount = createSelector(
  selectPrefs,
  ({ prefs }) => ({ fixedLimitAmount: prefs?.fixedLimitAmount ?? 0 })
);
export const selectBigBlind = createSelector(selectPrefs, ({ prefs }) => ({
  bigBlind: prefs?.bigBlind ?? 0,
}));
// TODO bet settings are currently hardcoded but should come from the backend
export const selectCardDelay = createSelector(selectPrefs, ({ prefs }) => ({
  cardDelay: prefs?.cardDelay ?? 0,
}));
export const selectTableCards = createSelector(
  selectGameInstance,
  ({ communityCards }) => ({
    tableCards: communityCards?.hand?.cards ?? [],
  })
);
export const selectIsCardWild = (cardData: WildcardConstraint) =>
  createSelector(selectGameInstance, ({ wildcards }) => {
    const wilds = new Wildcards(wildcards);
    return { isCardWild: wilds.isWild(cardData) };
  });
export const selectIsCardWildPrivate = (
  cardData: WildcardConstraint,
  tablePosition: number
) =>
  createSelector(selectPlayerAtTablePos(tablePosition), ({ player }) => {
    const wilds = new Wildcards(player?.hand?.wildcards);
    return { isCardWildPrivate: wilds.isWild(cardData) };
  });
export const selectCurrentPlayerId = createSelector(
  selectGameInstance,
  ({ currentPlayerId }) => ({ currentPlayerId })
);
export const selectCurrentPlayer = createSelector(
  [selectCurrentPlayerId, selectGamePlayers],
  ({ currentPlayerId }, players) => {
    const currentPlayer = players.find(
      (player) =>
        player.id === currentPlayerId &&
        (player.status === PlayerStatus.HOST ||
          player.status === PlayerStatus.ADMITTED)
    );
    return { currentPlayer };
  }
);
export const selectIsLocalPlayerCurrentPlayer = createSelector(
  [selectLocalPlayerId, selectCurrentPlayerId],
  ({ localPlayerId }, { currentPlayerId }) => ({
    isLocalPlayerCurrentPlayer:
      localPlayerId && currentPlayerId && localPlayerId === currentPlayerId,
  })
);
export const selectPlayerBetAtTablePos = (tablePosition = -1) =>
  createSelector(
    [selectPlayerIdAtTablePos(tablePosition), selectPlayersBets],
    ({ playerId }, { playersBets }) => ({
      playerBet: playersBets?.[playerId] ?? 0,
    })
  );
export const selectPlayerCallAmountAtTablePos = (playerSeatPosition = -1) =>
  createSelector(
    [selectCurrentBet, selectPlayerBetAtTablePos(playerSeatPosition)],
    ({ currentBet }, { playerBet }) => ({
      playerCallAmount: currentBet - playerBet,
    })
  );
export const selectHasPlayerCheckedAtTablePos = (tablePosition = -1) =>
  createSelector(
    [
      selectHasPlayerFoldedAtTablePos(tablePosition),
      selectPlayerBetAtTablePos(tablePosition),
      selectPlayerIdAtTablePos(tablePosition),
      selectPlayersChecks,
    ],
    ({ hasPlayerFolded }, { playerBet }, { playerId }, { playersChecks }) => ({
      hasPlayerChecked:
        !hasPlayerFolded && playerBet <= 0 && playersChecks?.[playerId],
    })
  );
export const selectHasPlayerFoldedAtTablePos = (tablePosition = -1) =>
  createSelector(
    [
      selectPlayerIdAtTablePos(tablePosition),
      selectPlayerStatusAtTablePos(tablePosition),
      selectRemainingPlayers
    ],
    ({ playerId }, { playerStatus }, { remainingPlayers }) => {
      let hasPlayerFolded = false;
      // if a player has left the game mid-play and is rejoining during a game,
      // then their id will be in remainingPlayers, but they are not folded
      // so we check for that as well
      if (
        remainingPlayers &&
        playerStatus !== PlayerStatus.WAITING &&
        playerStatus !== PlayerStatus.JOINING &&
        Object.keys(remainingPlayers).includes(playerId)
      ) {
        // player in game, return inverse value
        hasPlayerFolded = !remainingPlayers[playerId];
      }
      // player not in game, so not folded
      return { hasPlayerFolded };
    }
  );
export const selectDeclarations = createSelector(
  selectGameInstance,
  ({ declarations }) => ({ declarations })
);
export const selectDeclarationForPlayerById = (playerId = "") =>
  createSelector(selectDeclarations, ({ declarations }) => ({
    playerDeclaration: declarations?.[playerId],
  }));
export const selectWinningsPerPlayer = createSelector(
  selectGameInstance,
  ({ accounting }) => ({ winningsPerPlayer: accounting?.totalWonPerPlayer })
);
export const selectGameWinners = createSelector(
  selectGameInstance,
  ({ gameWinners }) => ({ gameWinners: gameWinners ?? [] })
);
export const selectHighWinners = createSelector(
  selectGameInstance,
  ({ highHandWinners }) => ({ highHandWinners: highHandWinners ?? [] })
);
export const selectLowWinners = createSelector(
  selectGameInstance,
  ({ lowHandWinners }) => ({ lowHandWinners: lowHandWinners ?? [] })
);
export const selectAllWinners = createSelector(
  [selectGameWinners, selectHighWinners, selectLowWinners],
  ({ gameWinners }, { highHandWinners }, { lowHandWinners }) => {
    const winners = [];
    if (gameWinners?.length > 0) {
      winners.push(...gameWinners);
    }
    if (highHandWinners.length > 0) {
      winners.push(...highHandWinners);
    }
    if (lowHandWinners.length > 0) {
      winners.push(...lowHandWinners);
    }
    return { winners };
  }
);
