// SpreadLimit.ts
//
// Spread limit implementation for enforcing that  the bet is within a given range

import { BetInfo } from "./BetInfo";
import { BettingStructure } from "./BettingStructure";
import { RoundBetInfo } from "./RoundBetInfo";

/**
 * Spread Limit
 *
 * A player can bet between the min and max amount of the spread
 */
export class SpreadLimit extends BettingStructure {
  private minRaise: number;
  private maxRaise: number;

  constructor(prefs: any) {
    super(prefs);
    const min = prefs.spreadMinRaise;
    const max = prefs.spreadMaxRaise;
    this.validatePositiveNumber(min, "spreadMinRaise");
    this.validatePositiveNumber(max, "spreadMaxRaise");
    if (max < min) {
      throw new Error(`Max spread: ${max} is smaller than min spread: ${min}`);
    }
    this.minRaise = min;
    this.maxRaise = max;
  }

  validateBet(bet: BetInfo, round: RoundBetInfo) {
    this.validatePositiveNumber(bet.amount, "bet amount");

    const outOfRange = bet.raise > this.maxRaise || bet.raise < this.minRaise;
    if (bet.raise > 0 && outOfRange) {
      throw new Error(
        `Spread limit: Bet (${bet.amount}) must be between ${this.minRaise} and ${this.maxRaise}`
      );
    }

    this.validateRaises(bet, round);
    this.validateBetSize(bet, round);
  }
}
