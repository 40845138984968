/**
 * SeatContext.tsx
 *
 * When dealing with seats, there are 2 types of position.  The position on the screen and the position at the table.
 * Each localPlayer appears at the bottom of the screen, in the 7th or 0th screen position.
 *
 * React contexts are best used when dealing with infrequently changing values, because if a value changes,
 * all components in the Component tree inside that context will re-render.
 *
 * A Seat's screen position will never change, so we pass this value with a react context.
 * We wrap this context with the useSeatContext hook to add the relevant tablePosition and a boolean indicating if
 * the seat is the current localPlayer's seat.
 */

import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectIsSeatTakenAtTablePos } from "../../../logic/player/slice";
import { selectLocalPlayerScreenToTablePosition } from "../../../logic/localPlayer/slice";

export const SeatContext = React.createContext(0);

export function useSeatContext() {
  const screenPosition = useContext(SeatContext);
  const { localPlayerScreenToTablePosition } = useSelector(
    selectLocalPlayerScreenToTablePosition(screenPosition)
  );
  const isLocalSeat = useMemo(() => screenPosition === 7, [screenPosition]);
  const { isSeatTaken } = useSelector(
    selectIsSeatTakenAtTablePos(localPlayerScreenToTablePosition)
  );

  return {
    /**
     * This is the seat that the localPlayer is currently sitting in. It may also be known as the "middle" seat.
     */
    isLocalSeat,
    isThisSeatTaken: isSeatTaken,
    screenPosition,
    tablePosition: localPlayerScreenToTablePosition,
  };
}
