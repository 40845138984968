/**
 * AllGamesRulesModal.tsx
 *
 * This is just a wrapper around the Rules
 */
import React from "react";

import { RulesHeader } from "../../Settings/Rules";
import { AllGames } from "../../Settings/RulesPages/AllGames/AllGames";

import { ModalProps } from "../types/modalTypes";

export const AllGamesRulesModal = (props: ModalProps): JSX.Element => {
  return (
    <>
      <RulesHeader {...props} />
      <div className="rulesModalContentContainer rules3">
        <AllGames {...props} />
      </div>
    </>
  );
};
