// UsersAdminView.tsx
//
// Admin panel that lists all the users and allows admins to grant a 30 day free trial to users

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllPremiumGames } from "../../../logic/admin/game/slice";
import {
  getAllPremiumGames,
  toggleGamePremium,
} from "../../../logic/admin/game/thunks";
import { GamesInfo, getPrettyGameType } from "../../../utils/GameInfo";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import styles from "./GamesAdminView.module.css";
import { Game } from "poker-cows-common";

const allGames = Object.keys(GamesInfo);

/**  Admin panel that lists all the users and allows admins to grant a 30 day free trial to any of them */
export const GamesAdminView = () => {
  const dispatch = useDispatch();
  const premiumGames = useSelector(selectAllPremiumGames);

  useEffect(() => {
    dispatch(getAllPremiumGames());
  }, [dispatch]);

  if (!premiumGames) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <table>
        <thead>
          <tr>
            <th className={styles.header}>Game</th>
            <th className={styles.header}>Is Premium</th>
            <th className={styles.header}>Make Premium</th>
          </tr>
        </thead>
        <tbody>
          {allGames.map((game, index) => (
            <tr key={index}>
              <td>{getPrettyGameType(game as Game)}</td>
              <td>{premiumGames.includes(game) ? "Premium" : "-"} </td>
              <td>
                <Button
                  onClick={() =>
                    dispatch(toggleGamePremium({ gameName: game }))
                  }
                >
                  Toggle Premium
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
