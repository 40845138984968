/**
 * FeaturesList.tsx
 *
 * UI for managing the state of experimental features.
 */

import React from "react";
import { useExperimentalFeatures } from "./FeatureContext";
import "./FeaturesList.css";

export function FeaturesList() {
  const features = useExperimentalFeatures();
  return (
    <>
      <p className="experimentalModalSubtitle">
        WARNING: These features are <b>EXPERIMENTAL</b> and can very likely
        result in a table breaking completely
      </p>
      <br />
      <div className="section experimentalModalContent">
        {features.getFeaturesList().map((feature) => (
          <span className="contentWrapper" key={feature}>
            <input
              className="pointer"
              type="checkbox"
              checked={features.isFeatureEnabled(feature)}
              onChange={() => features.toggleFeature(feature)}
            />
            <span
              className="selectDescription"
              onClick={() => features.toggleFeature(feature)}
            >
              {feature}
            </span>
            <br />
            <br />
          </span>
        ))}
      </div>
    </>
  );
}
