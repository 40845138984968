/**
 * ModalConfig.tsx
 *
 * The most important part of the new modal system where we describe:
 *  - the modal name ie. key
 *  - the component of the respective key and default title
 */
import { TemplateModal } from "./components/TemplateModal";
import { GetMoreChipsModal } from "./components/GetMoreChipsModal";
import { HelpModal } from "./components/HelpModal";
import { MobileDisclaimerModal } from "./components/MobileDisclaimerModal";
import { HostControlsModal } from "./components/HostControlsModal";
import { LedgerModal } from "./components/LedgerModal";
import { FeaturePreviewModal } from "./components/FeaturePreviewModal";
import { TableSettingsModal } from "./components/TableSettingsModal";
import { MyAccountModal } from "./components/MyAccountModal";
import { ResetHandModal } from "./components/ResetHandModal";
import { ActForPlayerModal } from "./components/ActForPlayerModal";
import { ActForCertainPlayerModal } from "./components/ActForCertainPlayerModal";
import { BankingLedgerModal } from "./components/BankingLedgerModal";
import { BankingLedgerForCertainPlayerModal } from "./components/BankingLedgerForCertainPlayerModal";
import { CashOutModal } from "./components/CashOutModal";
import { CashMeOutModal } from "./components/CashMeOutModal";
import { MasterStartupModal } from "./components/MasterStartupModal";
import { InviteFriendsModal } from "./components/InviteFriendsModal";
import { StartMenuModal } from "./components/StartMenuModal";
import { GameTypeListModal } from "./components/GameTypeListModal";
import { StartAGameModal } from "./components/StartAGameModal";
import { PreShutdownModal } from "./components/PreShutdownModal";
import { OffBalanceModal } from "./components/OffBalanceModal";
import { HouseRulesModal } from "./components/HouseRulesModal";
import { TableRulesModal } from "./components/TableRulesModal";
import { AllGamesRulesModal } from "./components/AllGamesRulesModal";
import { CurrentGameRulesModal } from "./components/CurrentGameRulesModal";
import { HandRankingsRulesModal } from "./components/HandRankingsRulesModal";
import { NoLimitHeadsUpModal } from "./components/NoLimitHeadsUpModal";
import { ModalEntityType } from "./types/modalTypes";
import styles from "./styles/ModalConfig.module.css";
import { AccountPhoneModal } from "./components/AccountPhoneModal";
import { AccountEmailModal } from "./components/AccountEmailModal";
import { WelcomeModal } from "./components/WelcomeModal";
import { ChipPurchasedModal } from "./components/ChipPurchasedModal";
import { LowChipModal } from "./components/LowChipModal";

export enum ModalName {
  TemplateModal = "template-modal",
  // NOTE: When creating new Modal, add the key value pair below this line and then the respective component

  MenuModal = "menu-modal",
  MobileDisclaimerModal = "mobile-disclaimer-modal",

  FeaturePreviewModal = "feature-preview-modal",

  WelcomeModal = "welcome-modal",
  HelpModal = "help-modal",
  GetMoreChipsModal = "get-more-chips-modal",
  OffBalanceModal = "off-balance-modal",
  WaitingToJoinModal = "waiting-to-join-modal",

  CashMeOutModal = "cash-me-out-modal",
  LedgerModal = "ledger-modal",
  ChipPurchasedModal = "chip-purchased-modal",

  PreShutdownModal = "pre-shutdown-modal",

  CashOutModal = "cash-out-modal",
  BankingLedgerForCertainPlayerModal = "banking-ledger-for-certain-player-modal",
  BankingLedgerModal = "banking-ledger-modal",
  ActForCertainPlayerModal = "act-for-certain-player-modal",
  ActForPlayerModal = "act-for-player-modal",
  ResetHandModal = "reset-hand-modal",
  MyAccountModal = "my-account-modal",
  TableSettingsModal = "table-settings-modal",
  HostControlsModal = "host-controls-modal",

  HouseRulesModal = "house-rules-modal",
  TableRulesModal = "table-rules-modal",
  AllGamesRulesModal = "all-games-rules-modal",
  CurrentGameRulesModal = "current-game-rules-modal",
  HandRankingsRulesModal = "hand-rankings-rules-modal",

  StartGameModal = "start-game-modal",
  GameTypeListModal = "game-type-list-modal",
  StartMenuModal = "start-menu-modal",
  InviteFriendsModal = "invite-friends-modal",
  MasterStartupModal = "master-startup-modal",
  NoLimitHeadsUpModal = "no-limit-heads-up-modal",
  AccountPhoneModal = "account-phone-modal",
  AccountEmailModal = "account-email-modal",

  LowChipModal = "low-chip-modal",
}

export const ModalEntity: ModalEntityType = {
  [ModalName.TemplateModal]: {
    title: "Template Modal",
    component: TemplateModal,
  },
  // NOTE: When creating new Modal, start by cloning the template above and pasting it below this line

  [ModalName.MobileDisclaimerModal]: {
    title: "Disclaimer",
    component: MobileDisclaimerModal,
    props: { hasCloseButton: false },
  },

  [ModalName.FeaturePreviewModal]: {
    title: "Experimental Features",
    component: FeaturePreviewModal,
  },

  [ModalName.HelpModal]: {
    title: "Help",
    component: HelpModal,
    props: {
      containerClass: styles.fixedHeightContainer,
    },
  },
  [ModalName.GetMoreChipsModal]: {
    title: "You need more chips!",
    component: GetMoreChipsModal,
    props: {
      containerClass: styles.tinyWidthContainer,
    },
  },
  [ModalName.OffBalanceModal]: {
    title: "Bank totals are out of sync!",
    component: OffBalanceModal,
  },

  [ModalName.CashMeOutModal]: {
    title: "Cash Me Out?",
    component: CashMeOutModal,
    props: {
      hasOverlay: false,
      hasBackButton: false,
      hasCloseButton: false,
      containerClass: styles.fixedHeightContainer,
    },
  },
  [ModalName.LedgerModal]: {
    title: "My Bank",
    component: LedgerModal,
    props: {
      hasBackButton: false,
      containerClass: styles.fixedHeightContainer,
    },
  },

  [ModalName.PreShutdownModal]: {
    title: "Done Playing?",
    component: PreShutdownModal,
    props: {
      hasOverlay: false,
      hasBackButton: false,
      hasCloseButton: false,
      containerClass: styles.fixedHeightContainer,
    },
  },

  [ModalName.CashOutModal]: {
    title: "Done Playing?",
    component: CashOutModal,
    props: {
      hasOverlay: false,
      hasBackButton: false,
      hasCloseButton: false,
      containerClass: styles.fixedHeightContainer,
    },
  },
  [ModalName.BankingLedgerForCertainPlayerModal]: {
    title: "Banking Ledger",
    component: BankingLedgerForCertainPlayerModal,
    props: { containerClass: styles.fixedHeightContainer },
  },
  [ModalName.BankingLedgerModal]: {
    title: "Banking Ledger",
    component: BankingLedgerModal,
    props: { containerClass: styles.fixedHeightContainer },
  },
  [ModalName.ActForCertainPlayerModal]: {
    title: "Act for Player",
    component: ActForCertainPlayerModal,
    props: { containerClass: styles.fixedHeightContainer },
  },
  [ModalName.ActForPlayerModal]: {
    title: "Act for Player",
    component: ActForPlayerModal,
    props: { containerClass: styles.fixedHeightContainer },
  },
  [ModalName.ResetHandModal]: {
    title: "Reset this hand?",
    component: ResetHandModal,
    props: { containerClass: styles.fixedHeightContainer },
  },
  [ModalName.MyAccountModal]: {
    title: "My Account",
    component: MyAccountModal,
    props: { containerClass: styles.fixedHeightContainer },
  },
  [ModalName.TableSettingsModal]: {
    title: "Table Settings",
    component: TableSettingsModal,
    props: { containerClass: styles.fixedHeightContainer },
  },
  [ModalName.HostControlsModal]: {
    title: "Host Controls",
    component: HostControlsModal,
    props: {
      hasBackButton: false,
      containerClass: styles.fixedHeightContainer,
    },
  },

  [ModalName.HouseRulesModal]: {
    title: "House Rules",
    component: HouseRulesModal,
    props: {
      hasHeader: false,
      containerClass: styles.fixedHeightContainer,
    },
  },
  [ModalName.TableRulesModal]: {
    title: "Table Rules Rules",
    component: TableRulesModal,
    props: {
      hasHeader: false,
      containerClass: styles.fixedHeightContainer,
    },
  },
  [ModalName.AllGamesRulesModal]: {
    title: "All Games Rules",
    component: AllGamesRulesModal,
    props: {
      hasHeader: false,
      containerClass: styles.fixedHeightContainer,
    },
  },
  [ModalName.CurrentGameRulesModal]: {
    title: "Current Game Rules",
    component: CurrentGameRulesModal,
    props: {
      hasHeader: false,
      containerClass: styles.fixedHeightContainer,
    },
  },
  [ModalName.HandRankingsRulesModal]: {
    title: "Hand Rankings Rules",
    component: HandRankingsRulesModal,
    props: {
      hasHeader: false,
      containerClass: styles.fixedHeightContainer,
    },
  },

  [ModalName.StartGameModal]: {
    title: "Start a Game!",
    component: StartAGameModal,
    props: {
      hasOverlay: false,
      hasHeader: false,
      hasBackButton: false,
      hasCloseButton: false,
      containerClass: styles.fixedHeightContainer,
    },
  },
  [ModalName.GameTypeListModal]: {
    title: "It's your deal!",
    component: GameTypeListModal,
    props: {
      hasOverlay: false,
      hasBackButton: false,
      hasCloseButton: false,
      containerClass: styles.fixedHeightContainer,
    },
  },
  [ModalName.StartMenuModal]: {
    title: "Let's Get Started!",
    component: StartMenuModal,
    props: {
      hasOverlay: false,
      hasBackButton: false,
      hasCloseButton: false,
      containerClass: styles.smallWidthContainer,
    },
  },
  [ModalName.InviteFriendsModal]: {
    title: "Invite Your Friends to Play Now",
    component: InviteFriendsModal,
    props: {
      hasOverlay: false,
      hasBackButton: false,
      hasCloseButton: false,
      containerClass: styles.smallWidthContainer,
    },
  },
  [ModalName.MasterStartupModal]: {
    title: "Set Up Table to Play Now",
    component: MasterStartupModal,
    props: {
      hasOverlay: false,
      hasBackButton: false,
      hasCloseButton: false,
      containerClass: styles.fixedHeightContainer,
    },
  },
  [ModalName.NoLimitHeadsUpModal]: {
    title: "HEADS UP!",
    component: NoLimitHeadsUpModal,
    props: {
      hasOverlay: false,
      hasCloseButton: false,
      hasBackButton: false,
      containerClass: styles.tinyWidthContainer,
    },
  },
  [ModalName.AccountPhoneModal]: {
    title: "Phone Number",
    component: AccountPhoneModal,
    props: {
      overlayClass: "overlayTableBoard",
      titleClass: styles.tinyTitle,
      closeButtonClass: styles.tinyCloseButton,
    },
  },
  [ModalName.AccountEmailModal]: {
    title: "Email",
    component: AccountEmailModal,
    props: {
      overlayClass: "overlayTableBoard",
      titleClass: styles.tinyTitle,
      closeButtonClass: styles.tinyCloseButton,
    },
  },
  [ModalName.WelcomeModal]: {
    title: "Welcome to Pokercows!",
    component: WelcomeModal,
    props: {
      hasTitle: false,
      hasHeader: false,
      hasOverlay: false,
      hasBackButton: false,
      hasCloseButton: false,
      containerClass: styles.smallWidthContainer,
    },
  },
  [ModalName.ChipPurchasedModal]: {
    title: "CHIPS AUTO PURCHASED",
    component: ChipPurchasedModal,
    props: {
      hasBackButton: false,
      hasCloseButton: false,
      containerClass: styles.tinyWidthContainer,
    },
  },
  [ModalName.LowChipModal]: {
    title: "YOU'RE LOW ON CHIPS!",
    component: LowChipModal,
    props: {
      hasBackButton: false,
      hasCloseButton: false,
      containerClass: styles.tinyWidthContainer,
    },
  },
};
