/**
 * SpadeIcon.tsx
 *
 * The icon is used in to show Spade Icon with predefined white stroke width
 */
import React from "react";

export const SpadeIcon = ({
  width = 26,
  height = 28,
  color = "#2C2C2C",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  return (
    <svg
      width={`${width / 13}em`}
      height={`${height / 15}em`}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.7067 22.1416L6.8571 22.6623V26.5198H18.1432V22.6623L17.2936 22.1416C19.1047 21.95 20.7304 21.0165 21.7901 19.5835C22.7793 18.2457 23.2393 16.5065 22.8775 14.6864C22.5162 12.8687 21.3772 11.1573 19.5127 9.73087C16.1365 7.1475 15.0483 5.16513 14.2939 3.63525L12.5014 0L10.7069 3.63425C9.95073 5.16551 8.86334 7.14786 5.48755 9.73092C3.62301 11.1574 2.48405 12.8686 2.12276 14.6864C1.76099 16.5065 2.22102 18.2457 3.21022 19.5835C4.26985 21.0165 5.89559 21.95 7.7067 22.1416Z"
        fill="white"
      />
      <path
        d="M18.2973 11.3193C14.6251 8.5095 13.3585 6.26089 12.5 4.51975C11.6402 6.26089 10.3749 8.5095 6.70271 11.3193C0.440315 16.1103 6.33476 22.7961 11.5176 19.1163C11.18 21.3999 10.0276 23.0648 8.85696 23.7823V24.5198H16.143V23.7823C14.9724 23.0648 13.82 21.3999 13.4824 19.1163C18.6652 22.7961 24.5597 16.1103 18.2973 11.3193Z"
        fill={color}
      />
    </svg>
  );
};
