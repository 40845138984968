/* Lobby.tsx
 * This file is a react component that render the page a player sees when first starting a table
 * that provides the option to click to copy the table code/link to clipboard. Once
 * a player joins and is accepted, it automatically moves onto the next screen where
 * the host gets to choose which game is played first.
 * Startup flow: MasterStartup.tsx (table settings) > Lobby.tsx (copy table code) > GameTypeList.tsx (view/select games) > GameSelectPop.tsx (select game options/click start) > Game starts
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";

import Button from "../Button";
import { selectIsBotTable, selectTableCode } from "../../../logic/table/slice";
import {
  selectAdmittedPlayers,
  selectJoiningPlayers,
} from "../../../logic/player/slice";
import {
  declinePlayerAdmission,
  grantPlayerAdmission,
  submitStartNormalGamePlay,
} from "../../../logic/prompts/slice";
import { ModalName } from "../Modal/ModalConfig";
import { useModal } from "../Modal/hooks/useModal";
import { ModalProps } from "../Modal/types/modalTypes";
import { SHARE_TABLE_CODE_BUTTON } from "../../../test-identifiers";
import useDevice from "../../../hooks/useDevice";

export const InviteFriends = (props: ModalProps) => {
  const { isDesktop, isMobile } = useDevice();

  const dispatch = useDispatch();
  const { tableCode } = useSelector(selectTableCode);
  const { joiningPlayers } = useSelector(selectJoiningPlayers);
  const { admittedPlayers } = useSelector(selectAdmittedPlayers);
  const { isBotTable } = useSelector(selectIsBotTable);
  const isBotOnMobile = isMobile && isBotTable;
  const [clipboardInfo, setClipboardInfo] = useState<"code" | "link" | null>(
    null
  );

  const declineAdmission = (playerId: string) => {
    dispatch(declinePlayerAdmission(playerId));
  };

  const grantAdmission = (playerId: string) => {
    dispatch(grantPlayerAdmission(playerId));
  };

  const startGame = () => {
    dispatch(submitStartNormalGamePlay(false /* isPracticeMode */));
  };

  const onCopyButtonClick = (info: "code" | "link") => {
    const value = info === "link" ? window.location.href : tableCode.toString();
    navigator.clipboard.writeText(value);

    setTimeout(() => setClipboardInfo(info));
    setTimeout(() => setClipboardInfo(null), 3500);
  };

  const CopyNotification = () => {
    return (
      <Button
        className={classNames("capitalize", {
          modalButtonWarning: clipboardInfo === "link",
          modalButtonSecondary: clipboardInfo === "code",
        })}
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
        }}
      >
        {`${clipboardInfo} copied to clipboard`}
      </Button>
    );
  };

  return (
    <div className="lobbyContainer">
      <div className="lobbyMiddleContainer borderColored">
        <span className="lobbyText colored">
          Tell your friends to enter Table Code
        </span>
        {clipboardInfo === "code" && <CopyNotification />}
        {clipboardInfo !== "code" && (
          <Button
            className="lobbyCode"
            disabled={clipboardInfo === "link"}
            onClick={() => onCopyButtonClick("code")}
          >
            {tableCode}
          </Button>
        )}
        <span className="lobbyText">
          at
          <br />
          <span className="biggerText">pokercows.com</span>
        </span>
      </div>

      <span className="lobbyText">OR</span>

      <div className="lobbyMiddleContainer">
        <span className="lobbyText colored">
          Copy the table link and send to your friends.
        </span>
        {clipboardInfo === "link" && <CopyNotification />}
        {clipboardInfo !== "link" && (
          <Button
            className="modalButtonDark"
            disabled={clipboardInfo === "code"}
            data-testid={SHARE_TABLE_CODE_BUTTON}
            onClick={() => onCopyButtonClick("link")}
          >
            COPY TABLE LINK
          </Button>
        )}
      </div>
      {isDesktop && (
        <span className="lobbyText">
          The game will begin when your first friend joins.
        </span>
      )}
      {isMobile && joiningPlayers.length !== 0 && (
        <div className="lobbyMiddleContainer">
          <span className="lobbyText">Admit Players:</span>
          {joiningPlayers.map((player) => (
            <div className="lobbyMobileAdmitContainer" key={player.name}>
              <span className="lobbyMobileComponent">{player.name}</span>
              <div className="lobbyMobileAdmitPlayerContainer">
                <Button
                  onClick={() => declineAdmission(player.id)}
                  className="actionButton red lobbyMobileComponent"
                >
                  {player.consecutiveRemoveCount >= 2 ? "Ban" : "Decline"}
                </Button>
                <Button
                  onClick={() => grantAdmission(player.id)}
                  className="actionButton green lobbyMobileComponent"
                >
                  <span>Admit</span>
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
      {isMobile && admittedPlayers.length !== 0 && !isBotOnMobile && (
        <footer className="modalFooter">
          <Button onClick={startGame} className="modalButtonPrimary">
            <span>Start Game</span>
          </Button>
        </footer>
      )}
    </div>
  );
};

export const Lobby = () => {
  const { currentModalName, showModal } = useModal();

  useEffect(() => {
    if (!currentModalName) {
      showModal({
        name: ModalName.InviteFriendsModal,
      });
    }
  }, [currentModalName, showModal]);

  return (
    <div className="actionAreaCallout">
      SHARE THE TABLE CODE WITH
      <br />
      YOUR FRIENDS TO GET STARTED
    </div>
  );
};
