/**
 * AllGames.tsx
 *
 * This is a central component around AllGamesRulesModal to give user the option to select a game and see the rules around it
 * PS. has internal navigation
 */
import React from "react";

import { ModalProps } from "../../../Modal/types/modalTypes";
import { GameTypeListContents } from "../../../SelectGame/GameTypeList";

export const AllGames = (props: ModalProps) => {
  return <GameTypeListContents {...props} />;
};
