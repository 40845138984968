import { createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { BettingState } from "../logicTypes";

// =============================== //
//          Initial State          //
// ================================//
const initialState: BettingState = {
  checkCallInTurn: false,
  foldInTurn: false,
  targetBetRound: "", // The bet round actions are automated for, to prevent performing actions in the wrong round
};

// =============================== //
//              Slice              //
// ================================//
export const slice = createSlice({
  name: "betting",
  initialState: initialState,
  reducers: {
    setCheckCallInTurn(state, action) {
      state.checkCallInTurn = action.payload;
      state.foldInTurn = false;
    },
    setFoldInTurn(state, action) {
      state.foldInTurn = action.payload;
      state.checkCallInTurn = false;
    },
    setTargetBetRound(state, action) {
      state.targetBetRound = action.payload;
    },
  },
});

// =============================== //
//            Actions              //
// ================================//
export const { setCheckCallInTurn, setFoldInTurn, setTargetBetRound } =
  slice.actions;

// =============================== //
//            Reducers             //
// ================================//
export default slice.reducer;

// =============================== //
//            Selectors            //
// ================================//
const selectBetting = (state: RootState) => state.betting;
export const selectCheckCallInTurn = createSelector(
  selectBetting,
  ({ checkCallInTurn }) => ({ checkCallInTurn })
);
export const selectFoldInTurn = createSelector(
  selectBetting,
  ({ foldInTurn }) => ({ foldInTurn })
);
export const selectTargetBetRound = createSelector(
  selectBetting,
  ({ targetBetRound }) => ({ targetBetRound })
);
