import classNames from "classnames";
import React from "react";
import "./ExtraSession.css";

interface ExtraSessionProps {
  title: string | JSX.Element;
  contents: JSX.Element;
  actionButtonOnClick?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void; // optional - if not supplied, no render
  actionButtonText?: string; // optional - if not supplied, no render
  modalClass?: string; // extra class to supply to modal for styling
}

export const ExtraSession = (props: ExtraSessionProps) => {
  return (
    <div className="extraSessionBackground">
      <div className="mainLogo" />
      <div className={classNames("extraSessionModal", props.modalClass)}>
        <div className="extraSessionHeadline">{props.title}</div>
        {props.contents}
        {props.actionButtonOnClick && props.actionButtonText && (
          <div
            className="extraSessionContinueButton actionButton green"
            onClick={props.actionButtonOnClick}
          >
            {props.actionButtonText}
          </div>
        )}
      </div>
    </div>
  );
};
