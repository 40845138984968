import "./TableCards.css";
import React from "react";
import { useSelector } from "react-redux";
import { selectTableCards } from "../../logic/gameInstance/slice";
import Hand from "./Hand";
import { GridTableCards } from "./GridTableCards";
import { Game, HollywoodSquaresMap, IronCrossMap } from "poker-cows-common";
import { selectGameType } from "../../logic/table/slice";

export const TableCards = () => {
  const { tableCards } = useSelector(selectTableCards);
  const { gameType } = useSelector(selectGameType);

  if (gameType && gameType.includes(Game.IronCross)) {
    return <GridTableCards gridLayoutMap={IronCrossMap} />;
  }

  if (gameType && gameType.includes(Game.HollywoodSquares)) {
    return <GridTableCards gridLayoutMap={HollywoodSquaresMap} />;
  }

  return (
    <div className="table-cards-container">
      <Hand tableCards={true} cardStack={tableCards} />
    </div>
  );
};
