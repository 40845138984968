import { GameRulesInterface } from "poker-cows-common";
import RollYourOwnRulesImagePlayer from "../../static/graphics/rules/Follow_The_Queen_Player_Hand.svg";

export const RollYourOwnRules: GameRulesInterface = {
  objectives: ["Best 5 card poker hand"],
  card: {
    default: [
      "7 player cards - 3 down & roll, 1 down & roll, 1 down & roll, 1 down & roll, 1 down",
    ],
  },
  image: { player: RollYourOwnRulesImagePlayer },
  title: "Standard 7 Card Stud Deal",
  actions: [
    "1. Each player is dealt 3 cards down.",
    "2. Players roll 1 card up (simultaneously) and bet, always starting with the player showing the best hand.",
    "3. 3 rounds of players receiving 1 down card, rolling and betting.",
    "4. 1 final round with card dealt down, bet.",
    "5. Players declare “High” or “Low” or “Both” (if a High-Low Split game).",
    "6. Cards displayed; winners paid.",
  ],
};
