// FixedLimit.ts
//
// Implementation for the Fix Limit. Every player is allowed to bet only the specified amount

import { BetInfo } from "./BetInfo";
import { BettingStructure } from "./BettingStructure";
import { RoundBetInfo } from "./RoundBetInfo";

/**
 * Fixed Limit
 *
 * Every player should bet/raise predetermine fixed amount
 */
export class FixedLimit extends BettingStructure {
  private limitAmount: number;

  constructor(prefs: any) {
    super(prefs);
    this.validatePositiveNumber(prefs.fixedLimitAmount, "fixedLimitAmount");
    this.limitAmount = prefs.fixedLimitAmount;
  }

  validateBet(bet: BetInfo, round: RoundBetInfo) {
    this.validatePositiveNumber(bet.amount, "bet amount");

    if (bet.raise > 0 && bet.amount !== this.limitAmount) {
      throw new Error(
        `Illegal amount: ${bet.amount} for fixed limit: ${this.limitAmount}`
      );
    }

    this.validateRaises(bet, round);
    this.validateBetSize(bet, round);
  }
}
