/**
 * This modal renders after the user selects "No-Limit Texas Hold'em" from the game list. It is meant to serve as a
 * warning to users that all players must act as the dealer once before the game type can be changed.
 */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectStartPrompt } from "../../../../logic/prompts/slice";
import { NoLimitHeadsUpModalContent } from "../../SelectGame/NoLimitHeadsUpModalContent";

import { useModal } from "../hooks/useModal";
import { ModalName } from "../ModalConfig";
import { ModalProps } from "../types/modalTypes";

export const NoLimitHeadsUpModal = (props: ModalProps): JSX.Element => {
  const { showModal } = useModal();

  const { startPrompt } = useSelector(selectStartPrompt);

  useEffect(() => {
    if (startPrompt) {
      showModal({ name: ModalName.StartGameModal });
    }
  }, [showModal, startPrompt]);

  return <NoLimitHeadsUpModalContent {...props} />;
};
