import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { HOST_GAME_BUTTON } from "../../test-identifiers";
import { freeTrialIsOver } from "../../utils/FreeTrial";

import "./HostGameWidget.css";

const HostGameWidget = () => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const handleCreateTable = () => {
    setShouldRedirect(true);
  };

  if (shouldRedirect) {
    const url = freeTrialIsOver()
      ? "/payment-plan?host=true"
      : "/begin-session";
    return <Redirect to={url} />;
  }

  return (
    <div className="hostGameWidget">
      <div className="hostGameWidgetText">
        READY TO HOST A <br /> GAME WITH FRIENDS?
      </div>
      <div
        data-testid={HOST_GAME_BUTTON}
        className="actionButton green"
        onClick={handleCreateTable}
      >
        HOST A GAME
      </div>
    </div>
  );
};
export default HostGameWidget;
