// Cards.ts
//
// Helpers for mapping Ranks and Suits to Strings and back to enums.

import { Rank } from "../enums/Rank";
import { Suit } from "../enums/Suit";

//
//   Ranks
//

const cardRankToString = new Map<Rank, { short: string; text: string }>([
  [Rank.ACE, { short: "A", text: "Ace" }],
  [Rank.TWO, { short: "2", text: "Two" }],
  [Rank.THREE, { short: "3", text: "Three" }],
  [Rank.FOUR, { short: "4", text: "Four" }],
  [Rank.FIVE, { short: "5", text: "Five" }],
  [Rank.SIX, { short: "6", text: "Six" }],
  [Rank.SEVEN, { short: "7", text: "Seven" }],
  [Rank.EIGHT, { short: "8", text: "Eight" }],
  [Rank.NINE, { short: "9", text: "Nine" }],
  [Rank.TEN, { short: "10", text: "Ten" }],
  [Rank.JACK, { short: "J", text: "Jack" }],
  [Rank.QUEEN, { short: "Q", text: "Queen" }],
  [Rank.KING, { short: "K", text: "King" }],
  [Rank.HIGH_ACE, { short: "A", text: "Ace" }],
]);

export function getCardRankToShortString(rank: Rank): string {
  const rankString = cardRankToString.get(rank);
  if (rankString) {
    return rankString.short;
  }
  return "";
}

export function getCardRankToString(rank: Rank): string {
  const rankString = cardRankToString.get(rank);
  if (rankString) {
    return rankString.text;
  }
  return "";
}

export function getCardRankFromString(rankString: string): Rank | null {
  // needed only for our tests, every rank is a single letter and 10 as digit is 2 letters that's why
  // it is represented as T
  if (rankString === "T") {
    return Rank.TEN;
  }

  for (const [rank, text] of cardRankToString) {
    if (text.short === rankString || text.text === rankString) {
      return rank;
    }
  }
  return null;
}

//
//   Suits
//

const cardSuitToString = new Map<Suit, { short: string; text: string }>([
  [Suit.CLUB, { short: "c", text: "Clubs" }],
  [Suit.DIAMOND, { short: "d", text: "Diamonds" }],
  [Suit.HEART, { short: "h", text: "Hearts" }],
  [Suit.SPADE, { short: "s", text: "Spades" }],
]);

export function getCardSuitToShortString(suit: Suit): string {
  const suitString = cardSuitToString.get(suit);
  if (suitString) {
    return suitString.short;
  }
  return "";
}

export function getCardSuitToString(suit: Suit): string {
  const suitString = cardSuitToString.get(suit);
  if (suitString) {
    return suitString.short;
  }
  return "";
}

export function getCardSuitFromString(suitString: string): Suit | null {
  for (const [suit, text] of cardSuitToString) {
    if (text.short === suitString || text.text === suitString) {
      return suit;
    }
  }
  return null;
}
