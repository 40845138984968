/**
 * localPlayer/Api.ts
 * Api request methods defined for local player
 */
import { getEndpoint, postEndpoint } from "../api";

export async function getLocalPlayerDetails() {
  return getEndpoint(`api/user/get`, "");
}

export async function updateLocalPlayerDetails(
  body: {
    phone?: string | null;
    email?: string | null;
    hasFacebook?: boolean | null;
    hasGoogle?: boolean | null;
  } = {}
) {
  return postEndpoint(`api/user/update`, "", body);
}

export async function linkAccount(secondaryAuthId: string) {
  return postEndpoint(`api/user/link`, "", { secondaryAuthId });
}

export async function updateEmail(email: string) {
  return postEndpoint(`api/user/updateEmail`, "", { email });
}

export async function resetPassword() {
  return postEndpoint(`api/user/resetPassword`, "", {});
}

export async function checkEmail(email: string) {
  return postEndpoint(`api/user/checkEmail`, "", { email });
}
