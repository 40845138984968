/*

This file handles the twilio state.
This state drives all updating of the twilio video streams, local and remote.
this state should ONLY be changed via the TwilioClient, never by any other component or code.
For more information on our Twilio setup, see TwilioClient.ts.
*/
import { createSelector, createSlice } from "@reduxjs/toolkit";

import { TwilioErrorType } from "poker-cows-common";
import { RootState } from "../store";
import { TwilioState } from "../logicTypes";
import { setSessionMediaIsActive } from "../../utils/SessionMediaSettings";

enum STATE {
  DISCONNECTED,
  CONNECTING,
  RECONNECTING,
  CONNECTED,
}

// =============================== //
//          Initial State          //
// ================================//
export const initialState: TwilioState = {
  players: {},
  twilioClientId: "",
  connected: false,
  connecting: false,
  // mirrors sessionStorage
  //   true = unmuted / on
  //   false = muted / off
  sessionMediaActive: {
    audio: true,
    video: true,
  },
  // primarily what is used for UI logic is error.type -
  // currently though code, name, and message are not used,
  // I include them here for their usefulness in debugging
  // using the redux state, and for future possibility of
  // using codes for more specific UI logic
  error: {
    type: TwilioErrorType.NONE,
    code: -1, // -1 for no error
    name: "", // empty for no error
    message: "", // empty for no error
  },
  permissionsError: {
    audio: false,
    video: false,
  },
};

// =============================== //
//              Slice              //
// ================================//
export const slice = createSlice({
  name: "twilio",
  initialState: initialState,
  reducers: {
    twilioClientId: (state, action) => {
      state.twilioClientId = action.payload;
    },
    sessionMediaActive: (state, action) => {
      const { mediaType, active } = action.payload;
      state.sessionMediaActive[mediaType as "audio" | "video"] = active;
      setSessionMediaIsActive(state.twilioClientId, mediaType, active);
    },
    error: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = {
        type: TwilioErrorType.NONE,
        code: -1,
        name: "",
        message: "",
      };
    },
    permissionsError: (state, action) => {
      state.permissionsError[action.payload.type as "audio" | "video"] = true;
    },
    clearPermissionsError: (state, action) => {
      state.permissionsError[action.payload.type as "audio" | "video"] = false;
    },
    connected: (state: typeof initialState) => {
      state.connecting = false;
      state.connected = true;
    },
    connecting: (state: typeof initialState) => {
      state.connected = false;
      state.connecting = true;
    },
    disconnected: (state: typeof initialState) => {
      state.connected = false;
    },
    reconnecting: (state: typeof initialState) => {
      state.connecting = true;
      state.connected = false;
    },
    subscribed: (state, action) => {
      const {
        playerId,
        mediaId,
        mediaType,
      }: {
        playerId: string;
        mediaId: string;
        mediaType: "audio" | "video";
      } = action.payload;

      if (!state.players[playerId]) {
        state.players[playerId] = {};
      }

      if (!state.players[playerId][mediaType]) {
        state.players[playerId][mediaType] = [];
      }

      // we manually keep this array to 1 entry
      // to avoid double connections and attachment
      // confusion / mute status clarity,
      // and we format as array because it's how
      // twilio-video node module has set up typescript
      const media = state.players[playerId][mediaType];
      if (Array.isArray(media)) {
        media[0] = mediaId;
      }
    },
    unsubscribed: (state, action) => {
      const {
        playerId,
        mediaType,
      }: { playerId: string; mediaType: "audio" | "video" } = action.payload;

      if (!state.players[playerId]) {
        return;
      }

      if (!state.players[playerId][mediaType]) {
        state.players[playerId][mediaType] = [];
      }

      state.players[playerId][mediaType] = [];
    },
    participantConnected: (state, action) => {
      const { playerId, twilioId } = action.payload;

      if (!state.players[playerId]) {
        state.players[playerId] = {};
      }

      state.players[playerId].twilio_id = twilioId;
      state.players[playerId].state = STATE.CONNECTED;
    },
    participantReconnecting: (state, action) => {
      const { playerId, twilioId } = action.payload;

      if (!state.players[playerId]) {
        state.players[playerId] = {};
      }

      state.players[playerId].twilio_id = twilioId;
      state.players[playerId].state = STATE.RECONNECTING;
    },
  },
});

// =============================== //
//            Actions              //
// ================================//
export const {
  twilioClientId,
  subscribed,
  unsubscribed,
  participantConnected,
  participantReconnecting,
  connected,
  connecting,
  disconnected,
  sessionMediaActive,
  reconnecting,
  error,
  clearError,
  permissionsError,
  clearPermissionsError,
} = slice.actions;

// =============================== //
//            Reducers             //
// ================================//
export default slice.reducer;

// =============================== //
//            Selectors            //
// ================================//
const selectTwilio = (state: RootState) => state.twilio;
export const selectIsTwilioConnected = createSelector(
  selectTwilio,
  ({ connected }) => ({ isTwilioConnected: connected })
);
export const selectIsTwilioConnecting = createSelector(
  selectTwilio,
  ({ connecting }) => ({ isTwilioConnecting: connecting })
);
export const selectTwilioPlayerByPlayerId = (playerId = "") =>
  createSelector(selectTwilio, ({ players }) => ({
    twilioPlayer: players[playerId] || null,
  }));
export const selectTwilioIdByPlayerId = (playerId = "") =>
  createSelector(
    selectTwilioPlayerByPlayerId(playerId),
    ({ twilioPlayer }) => ({
      twilioId: twilioPlayer?.twilio_id ?? null,
    })
  );
export const selectIsTwilioPlayerMuted = (playerId = "") =>
  createSelector(selectTwilio, (twilio) => {
    const media = twilio.players[playerId]?.audio;
    if (Array.isArray(media)) {
      return { isTwilioPlayerMuted: media.length === 0 };
    }
    return {
      isTwilioPlayerMuted: !twilio.players[playerId]?.audio,
    };
  });
export const selectTwilioMedia = (playerId = "", type = "") =>
  createSelector(
    selectTwilioPlayerByPlayerId(playerId),
    ({ twilioPlayer }) => ({
      twilioMedia: twilioPlayer?.[type as "video" | "audio"] ?? null,
    })
  );
export const selectLatestTwilioMedia = (playerId = "", type = "") =>
  createSelector(selectTwilioMedia(playerId, type), ({ twilioMedia }) => {
    if (twilioMedia && Array.isArray(twilioMedia) && twilioMedia.length > 0) {
      return { twilioLatestMedia: twilioMedia[twilioMedia.length - 1] };
    }
    return { twilioLatestMedia: null };
  });
export const selectLatestTwilioAudioMedia = (playerId = "") =>
  createSelector(
    selectLatestTwilioMedia(playerId, "audio"),
    ({ twilioLatestMedia }) => ({ twilioLatestAudioMedia: twilioLatestMedia })
  );
export const selectLatestTwilioVideoMedia = (playerId = "") =>
  createSelector(
    selectLatestTwilioMedia(playerId, "video"),
    ({ twilioLatestMedia }) => ({ twilioLatestVideoMedia: twilioLatestMedia })
  );
export const selectTwilioSessionMediaState = (mediaType = "") =>
  createSelector(selectTwilio, ({ sessionMediaActive }) => ({
    twilioSessionMediaState: sessionMediaActive[mediaType as "video" | "audio"],
  }));
export const selectTwilioError = createSelector(selectTwilio, (twilio) => ({
  twilioError: twilio.error ?? twilio.permissionsError,
}));
export const selectTwilioPermissionsError = createSelector(
  selectTwilio,
  ({ permissionsError }) => ({ twilioPermissionsError: permissionsError })
);
export const selectAnyTwilioError = createSelector(selectTwilio, (twilio) => ({
  twilioError:
    twilio.error.type !== TwilioErrorType.NONE ||
    twilio.permissionsError.audio ||
    twilio.permissionsError.video,
}));
