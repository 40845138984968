/**
 * connection/slice.ts
 *
 * A redux store that accepts an initial state based on Socket/API response,
 * an object of reducer function to set the connection flag,
 * and extra reducers to handle thunk response
 */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TableErrorType } from "poker-cows-common";
import { RootState } from "../store";
import { updateTable } from "../shared";
import { joinTable } from "../table/thunks";
import { ConnectionState } from "../logicTypes";

// =============================== //
//          Initial State          //
// ================================//
export const initialState: ConnectionState = {
  updateNumber: 0, // Server number of updates
  updateTime: 0,
  connected: false,
  connectionAttempted: false,
  requestStatus: null,
  requestInitiatedAt: null,
  error: { type: TableErrorType.NONE, message: "" },
};

// =============================== //
//              Slice              //
// ================================//
export const slice = createSlice({
  name: "connection",
  initialState: initialState,
  reducers: {
    setConnected: (state, action: PayloadAction<boolean>) => {
      state.connected = action.payload;
    },
    setConnectionAttempted: (state, action: PayloadAction<boolean>) => {
      state.connectionAttempted = action.payload;
    },
    setRequestInitiatedAt: (state, action: PayloadAction<number>) => {
      state.requestInitiatedAt = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(joinTable.fulfilled, (state) => {
      return {
        ...state,
        error: { type: TableErrorType.NONE, message: "" },
      };
    });
    builder.addCase(
      joinTable.rejected,
      (
        state,
        action: {
          type: string;
          payload: any;
        }
      ) => {
        return {
          ...state,
          error: {
            type: action.payload.errorType,
            message: action.payload.message,
          },
        };
      }
    );
    builder.addCase(updateTable, (state, action) => {
      return {
        ...state,
        updateTime: action.payload.updateTime,
        updateNumber: action.payload.updateNumber,
      };
    });
    builder.addMatcher(
      (action) =>
        ["/pending", "/fulfilled", "/rejected"].includes(
          action.type.substr(action.type.lastIndexOf("/"), action.type.length)
        ),
      (state, action) => ({
        ...state,
        requestStatus: action.meta.requestStatus,
        requestInitiatedAt:
          action.meta.requestStatus === "pending"
            ? state.requestInitiatedAt
            : null,
      })
    );
  },
});

// =============================== //
//            Actions              //
// ================================//
export const { setConnected, setConnectionAttempted, setRequestInitiatedAt } =
  slice.actions;

// =============================== //
//            Reducers             //
// ================================//
export default slice.reducer;

// =============================== //
//            Selectors            //
// ================================//
const selectConnection = (state: RootState) => state.connection;
export const selectUpdateNumber = createSelector(
  selectConnection,
  ({ updateNumber }) => ({ updateNumber })
);
export const selectUpdateTime = createSelector(
  selectConnection,
  ({ updateTime }) => ({ updateTime })
);
export const selectIsConnected = createSelector(
  selectConnection,
  ({ connected }) => ({ isConnected: connected })
);
export const selectConnectionAttempted = createSelector(
  selectConnection,
  ({ connectionAttempted }) => ({ connectionAttempted })
);
export const selectRequestInitiatedAt = createSelector(
  selectConnection,
  ({ requestInitiatedAt }) => ({ requestInitiatedAt })
);
export const selectIsRequestPending = createSelector(
  selectConnection,
  ({ requestStatus }) => ({ isRequestPending: requestStatus === "pending" })
);
export const selectIsTableValid = createSelector(
  selectConnection,
  ({ error }) => ({
    isTableValid: !(
      error.type === TableErrorType.TABLE_NOT_FOUND ||
      error.type === TableErrorType.INTERNAL_ERROR
    ),
  })
);
export const selectIsNameTaken = createSelector(
  selectConnection,
  ({ error }) => ({
    isNameTaken: error.type === TableErrorType.PLAYER_NAME_TAKEN,
  })
);
export const selectIsSeatAvailable = createSelector(
  selectConnection,
  ({ error }) => ({
    isSeatAvailable: !(error.type === TableErrorType.NO_SEATS_AVAILABLE),
  })
);
export const selectIsOnlyOnePlayerAllowed = createSelector(
  selectConnection,
  ({ error }) => ({
    isOnlyOnePlayerAllowed:
      error.type === TableErrorType.ONLY_ONE_PLAYER_ALLOWED,
  })
);
