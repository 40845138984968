/**
 * WelcomeModal.tsx
 *
 * This is just a wrapper around the welcome component
 */
import React from "react";

import { ModalProps } from "../types/modalTypes";
import { Welcome } from "../../Welcome/Welcome";

export const WelcomeModal = (props: ModalProps): JSX.Element => {
  return <Welcome {...props} />;
};
