import React from "react";
import PlayerInfo from "../PlayerInfo";
import { formatMoneyString } from "../../../utils/MoneyFormat";
import { useSeatContext } from "./SeatContext";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLocalPlayerSeated } from "../../../logic/localPlayer/slice";
import {
  selectPlayerBankAtTablePos,
  selectIsSeatTakenAtTablePos,
  selectPlayerNameAtTablePos,
} from "../../../logic/player/slice";
import { chooseSeat } from "../../../logic/table/thunks";

export const SeatInfoContents = () => {
  const dispatch = useDispatch();
  const { tablePosition } = useSeatContext();
  const { playerName } = useSelector(selectPlayerNameAtTablePos(tablePosition));
  const { isSeatTaken } = useSelector(
    selectIsSeatTakenAtTablePos(tablePosition)
  );
  const { playerBank } = useSelector(selectPlayerBankAtTablePos(tablePosition));
  const { isLocalPlayerSeated } = useSelector(selectIsLocalPlayerSeated);

  const sitAction = () =>
    dispatch(
      chooseSeat({
        seatPosition: tablePosition,
      })
    );

  if (isSeatTaken) {
    return (
      <PlayerInfo name={playerName} balance={formatMoneyString(playerBank)} />
    );
  }

  if (!isLocalPlayerSeated) {
    return (
      <div className="seatSpot button-glow open-seat" onClick={sitAction}>
        Click To Sit
      </div>
    );
  }

  return null;
};
