/**
 * TableRules.tsx
 *
 * This is a central component around TableRulesModal to give static table rules
 */
import React from "react";

import { BettingStructureContent } from "./BettingStructureContent";
import { ChipDenominationContent } from "./ChipDenominationContent";
import { RulesRow } from "../RulesRow";
import { SpreadLimitSetupTitle } from "../../../ActionArea/MasterStartupComponents/SpreadLimitSetupTitle";
import { ChipDenomTitle } from "../../../ActionArea/MasterStartupComponents/ChipDenomTitle";
import { ModalProps } from "../../../Modal/types/modalTypes";
import { NoLimitSetupTitle } from "../../../ActionArea/MasterStartupComponents/NoLimitSetupTitle";
import NoLimitContent from "./NoLimitContent";
import { useSelector } from "react-redux";
import { selectNextPrefs } from "../../../../../logic/table/slice";
import { formatMoneyString } from "../../../../../utils/MoneyFormat";
import { InitialBuyInTitle } from "../../../ActionArea/MasterStartupComponents/InitialBuyInTitle";
import { LowMethodSetupTitle } from "../../../ActionArea/MasterStartupComponents/LowMethodSetupTitle";
import LowMethodContent from "./LowMethodContent";

export const TableRules = (props: ModalProps) => {
  const { nextPrefs } = useSelector(selectNextPrefs);
  const initialBuyIn = formatMoneyString(nextPrefs?.initialBuyIn);

  return (
    <div className="rulesSection tableRules tableSettingRules">
      <RulesRow
        className="section"
        title={<InitialBuyInTitle />}
        content={<>{initialBuyIn} per Player</>}
      />
      <RulesRow
        className="section"
        title={<SpreadLimitSetupTitle />}
        content={<BettingStructureContent />}
      />
      <RulesRow
        className="section"
        title={<ChipDenomTitle />}
        content={<ChipDenominationContent />}
      />
      <RulesRow
        className="section"
        title={<LowMethodSetupTitle />}
        content={<LowMethodContent />}
      />
      <RulesRow
        className="section"
        title={<NoLimitSetupTitle isRulesPage={true} />}
        content={<NoLimitContent />}
      />
    </div>
  );
};
