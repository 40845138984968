/**
 * TableLedger.tsx
 *
 * This is a component used to show the table of the players bank, the actions that you can take, and the option to leave the table
 */
import "./TableLedger.css";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectDeclarationForPlayerById } from "../../../logic/gameInstance/slice";
import {
  selectIsLocalPlayerHost,
  selectGameType,
  selectNextPrefs,
  selectIsTableInPreShutdown,
} from "../../../logic/table/slice";
import { selectAllPlayers } from "../../../logic/player/slice";
import {
  selectLocalPlayerId,
  selectLocalPlayerName,
} from "../../../logic/localPlayer/slice";
import {
  BankingHistoryInterface,
  BankingTransactionTypes,
  Game,
  PlayerInterface,
} from "poker-cows-common";
import { TableLedgerRow } from "./TableLedgerRow";
import {
  playerRequestChips,
  selectAnyDeclarePrompt,
  selectPlayerRequestChipsPrompt,
  selectIsGameActive,
  togglePreShutdown,
  selectLowChipPrompt,
  submitLowChipPrompt,
} from "../../../logic/prompts/slice";
import {
  formatMoneyString,
  formatMoneyStringWithSign,
} from "../../../utils/MoneyFormat";
import { ModalName } from "../Modal/ModalConfig";
import { useModal } from "../Modal/hooks/useModal";
import { ModalProps } from "../Modal/types/modalTypes";
import classNames from "classnames";
import { Tooltip } from "../Tooltip/Tooltip";
import Button from "../Button";
import { ChipNotification } from "../Settings/HostControls/BankingLedger/ChipNotification";
import {
  CASH_OUT_AND_EXIT_BUTTON,
  ONE_X_BUY_LEDGER_BUTTON,
  TOP_OFF_LEDGER_BUTTON,
  TWO_X_BUY_LEDGER_BUTTON,
} from "../../../test-identifiers";

const ChipTooltip = () => (
  <Tooltip
    body={
      <div className="bupChipTooltipContainer">
        <div className="buyChipTooltip">
          <strong>Buying Chips</strong>
          <div>
            You can only buy chips when your current stack is less than 50% of
            your initial buy-in. In No-Limit games, you can’t buy chips while
            playing in the current hand.
          </div>
        </div>

        <div className="buyChipTooltip">
          <strong>Top Off Button</strong>
          <div>Brings your stack back up to the initial buy-in balance.</div>
        </div>

        <div className="buyChipTooltip">
          <strong>1x Buy-In Button</strong>
          <div>
            Only available when the largest stack at the table is greater than 2
            times the initial buy-in.
          </div>
        </div>

        <div className="buyChipTooltip">
          <strong>2x Buy-In Button</strong>
          <div>
            Only available when the largest stack at the table is greater than 4
            times the initial buy-in.
          </div>
        </div>
      </div>
    }
  ></Tooltip>
);

const TableLedger = (props: ModalProps) => {
  const { showModal } = useModal();
  const dispatch = useDispatch();

  const { playerRequestChipsPrompt } = useSelector(
    selectPlayerRequestChipsPrompt
  );
  const { localPlayerId } = useSelector(selectLocalPlayerId);
  const { localPlayerName } = useSelector(selectLocalPlayerName);
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);
  const { allPlayers } = useSelector(selectAllPlayers);
  const { gameType } = useSelector(selectGameType);
  const { isGameActive } = useSelector(selectIsGameActive);
  const { nextPrefs } = useSelector(selectNextPrefs);
  const { isTableInPreShutdown } = useSelector(selectIsTableInPreShutdown);
  const { anyDeclarePrompt } = useSelector(selectAnyDeclarePrompt);
  const { lowChipPrompt } = useSelector(selectLowChipPrompt);
  const { playerDeclaration } = useSelector(
    selectDeclarationForPlayerById(localPlayerId)
  );

  const [showNotification, setShowNotification] = useState(false);
  const [requestChipsValue, setRequestChipsValue] = useState("0");

  if (allPlayers.length === 0) {
    return <div>No Players</div>;
  }

  const navigate = (page: ModalName) => {
    showModal({
      name: page,
      title: localPlayerName ? `${localPlayerName}'s Bank` : `My Bank`,
    });
  };

  const handleNavClick = () => {
    if (isLocalPlayerHost) {
      if (!isTableInPreShutdown) {
        dispatch(togglePreShutdown());
      }
      navigate(ModalName.CashOutModal);
      return;
    }
    navigate(ModalName.CashMeOutModal);
  };

  let selectedPlayer = {} as PlayerInterface;
  let bankingHistory: BankingHistoryInterface[] = [];
  let largestStack: number = 0;
  for (const player of allPlayers) {
    if (player.name === localPlayerName) {
      selectedPlayer = player;
      bankingHistory = player.bankingHistory ?? [];
    }

    // get largest stack from players
    largestStack =
      (player.bank ?? 0) > largestStack ? player.bank : largestStack;
  }

  const makeDateString = (timestamp: number) => {
    const date = new Date(timestamp);
    let otherString = date.toLocaleString();
    return otherString;
  };

  const setRequestChipValue = (value: number) => {
    const requestChipValueInString = String(value);
    setRequestChipsValue(requestChipValueInString);
    dispatch(playerRequestChips(requestChipValueInString));

    // if low chip prompt exist then acknowledge the prompt that an action has taken
    if (lowChipPrompt) {
      dispatch(submitLowChipPrompt());
    }

    // if it was a success, trigger a notification (still needs validation)
    triggerRequestNotification();
  };

  const triggerRequestNotification = () => {
    setTimeout(() => setShowNotification(true));
    setTimeout(() => {
      setShowNotification(false);
      props.onClose();
    }, 2000);
  };

  const ChipButtons = () => {
    // not allow player to buy chips
    // when no limit texas holdem playing
    if (gameType === Game.TexasHoldem && isGameActive) {
      return (
        <ChipNotification
          className="danger"
          message={
            <>
              You can't buy chips while
              <br />
              playing the current hand
            </>
          }
        />
      );
    }

    // set request chip value for top off, 1x buy-in and 2x buy-in
    const initialBuyIn = nextPrefs?.initialBuyIn ?? 0;
    const playerBank = selectedPlayer.bank ?? 0;
    let topOffChipValue =
      initialBuyIn > playerBank ? initialBuyIn - playerBank : 0;
    let oneXBuyInChipValue = initialBuyIn * 1;
    let twoXBuyInChipValue = initialBuyIn * 2;

    // table preference chip is restricted
    if (nextPrefs?.chipRestricted) {
      topOffChipValue =
        playerBank < initialBuyIn / 2 ? initialBuyIn - playerBank : 0;
      oneXBuyInChipValue =
        largestStack > 2 * initialBuyIn ? initialBuyIn * 1 : 0;
      twoXBuyInChipValue =
        largestStack > 4 * initialBuyIn ? initialBuyIn * 2 : 0;
    }

    // for TOPOFF  current balance less than initial-buy-in/2 or
    // for 1xBUY-IN pot amount greater than 2 times of Initial buy in or
    // for 2xBUY-IN pot amount greater than 4 times of Initial buy in
    if (nextPrefs?.chipRestricted && topOffChipValue <= 0) {
      return (
        <ChipNotification
          className="danger"
          message={
            <>
              Chips unavailable until your
              <br /> Current Stack drops below{" "}
              {formatMoneyString(initialBuyIn / 2)}
            </>
          }
        />
      );
    }

    return (
      <div className="chipRequestButtons">
        <Button
          data-testid={TOP_OFF_LEDGER_BUTTON}
          className="chipRequestButton actionButton green"
          disabled={topOffChipValue <= 0}
          onClick={() => setRequestChipValue(topOffChipValue)}
        >
          TOP OFF
          <span className="chipAmount">
            {formatMoneyString(topOffChipValue)}
          </span>
        </Button>
        <Button
          data-testid={ONE_X_BUY_LEDGER_BUTTON}
          className="chipRequestButton actionButton green"
          disabled={oneXBuyInChipValue <= 0}
          onClick={() => setRequestChipValue(oneXBuyInChipValue)}
        >
          1x BUY-IN
          <span className="chipAmount">
            {formatMoneyString(oneXBuyInChipValue)}
          </span>
        </Button>
        <Button
          data-testid={TWO_X_BUY_LEDGER_BUTTON}
          className="chipRequestButton actionButton green"
          disabled={twoXBuyInChipValue <= 0}
          onClick={() => setRequestChipValue(twoXBuyInChipValue)}
        >
          2x BUY-IN
          <span className="chipAmount">
            {formatMoneyString(twoXBuyInChipValue)}
          </span>
        </Button>
      </div>
    );
  };

  const ChipRequest = () => {
    return (
      <>
        <div className="playerBankIndicatorTitle">
          BUY CHIPS HERE <ChipTooltip />
        </div>
        <ChipButtons />
      </>
    );
  };

  const Notification = () => {
    const amountRequested = formatMoneyString(
      parseFloat(requestChipsValue).toFixed(2)
    );

    return (
      <div className="playerBankIndicatorTitle notificationText">
        <span className="amount">{amountRequested}</span>
        has been added to your stack!
      </div>
    );
  };

  // remove win or loss transactinos from ledger, showing net balance is sufficient according to client
  const filteredHistory = bankingHistory?.filter(
    (entry) => entry.description !== "banking_type_win_or_loss"
  );

  return (
    <div className="section tableLedgerContainer">
      <div className="tableContainer">
        <div className="table">
          <TableLedgerRow
            isHeader={true}
            leftContent="Date/Time"
            centerContent="Amount"
            rightContent="Description"
          />
          {filteredHistory?.map((entry, i) => {
            const dateString = makeDateString(entry.datetime);
            return (
              <TableLedgerRow
                key={i}
                isHeader={false}
                leftContent={dateString}
                centerContent={formatMoneyString(entry.amount)}
                rightContent={
                  BankingTransactionTypes[
                    entry.description as keyof typeof BankingTransactionTypes
                  ]
                }
              />
            );
          })}
        </div>
      </div>
      <footer className="modalFooter">
        <div className="playerBankRowsContainer">
          <div className="playerBankRow">
            <div className="playerBankIndicator totalBuyInsIndicator">
              <div className="playerBankIndicatorTitle">Total Buy-In</div>
              <div className="playerBankIndicatorContent">
                {selectedPlayer.deposit && selectedPlayer.deposit > 0
                  ? `-` + formatMoneyString(selectedPlayer.deposit)
                  : formatMoneyString(selectedPlayer.deposit ?? 0)}
              </div>
            </div>
            <div className="playerBankIndicator currentChipCountIndicator">
              <div className="playerBankIndicatorTitle">Current Stack</div>
              <div className="playerBankIndicatorContent">
                {formatMoneyString(selectedPlayer.bank)}
              </div>
            </div>
            <div className="playerBankIndicator netPlayerBalanceIndicator">
              <div className="playerBankIndicatorTitle">Net Balance</div>
              <div
                className={classNames("playerBankIndicatorContent", {
                  positiveDigit:
                    (selectedPlayer.bank ?? 0) -
                      (selectedPlayer.deposit ?? 0) >=
                    0,
                })}
              >
                {formatMoneyStringWithSign(
                  selectedPlayer.bank && selectedPlayer.deposit
                    ? selectedPlayer.bank - selectedPlayer.deposit
                    : 0
                )}
              </div>
            </div>
          </div>
          <div className="playerBankRow">
            <div
              className={classNames("chipRequestContainer", {
                inactive: !playerRequestChipsPrompt,
                notification: showNotification,
              })}
            >
              {showNotification ? <Notification /> : <ChipRequest />}
            </div>
            <div className="playerCashMeOutContent">
              <Button
                data-testid={CASH_OUT_AND_EXIT_BUTTON}
                className={classNames(
                  "actionButton cashMeOutButton playerBank",
                  {
                    disabledButton: playerDeclaration && anyDeclarePrompt,
                  }
                )}
                disabled={
                  !!(playerDeclaration && anyDeclarePrompt) || showNotification
                }
                onClick={handleNavClick}
              >
                Cash Out
                <br />
                and Exit
                <br /> PokerCows
              </Button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TableLedger;
