/**
 * This file serves as the markup and styling for the content of the warning modal that renders after a no-limit game
 * is selected.
 */
import React from "react";
import { useDispatch } from "react-redux";

import classNames from "classnames";
import Button from "../Button";
import { selectGame } from "../../../logic/prompts/slice";
import { NO_LIMIT_HEADS_UP_CONTINUE_BUTTON } from "../../../test-identifiers";

import { useModal } from "../Modal/hooks/useModal";
import { ModalName } from "../Modal/ModalConfig";
import { ModalProps } from "../Modal/types/modalTypes";

import styles from "./NoLimitHeadsUpModalContent.module.css";

const BoldTexasHoldem = () => (
  <span className={styles.bold}>No Limit Texas Hold’em</span>
);

/** TODO: Do we have to use indeces for `selectGame()`? */
const NO_LIMIT_TEXAS_HOLDEM = 0;

export const NoLimitHeadsUpModalContent = (props: ModalProps) => {
  const { showModal } = useModal();

  const dispatch = useDispatch();

  return (
    <div className={styles.topLevelElement} {...props}>
      <section className={styles.warningText}>
        <p>
          <BoldTexasHoldem /> is dealt for a complete orbit around the table,
          i.e., the game continues and the deal rotates until all players have
          acted as dealers.
        </p>

        <p>
          After orbit is complete, you may continue playing <BoldTexasHoldem />{" "}
          or select another game.
        </p>
      </section>

      <div className={styles.footerWithButtons}>
        <Button
          data-testid={NO_LIMIT_HEADS_UP_CONTINUE_BUTTON}
          className={classNames("actionButton", "green", styles.continueButton)}
          // TODO: We don't want to rely on the order of any enum (using indeces), but how should we do this?
          onClick={() => dispatch(selectGame(NO_LIMIT_TEXAS_HOLDEM))}
        >
          CONTINUE
        </Button>

        <Button
          className="actionButton"
          onClick={() => showModal({ name: ModalName.GameTypeListModal })}
        >
          CANCEL
        </Button>
      </div>
    </div>
  );
};
