/**
 * SellChip.tsx
 *
 * Used to sell chips to players
 * Host can only sell the chips to player
 * Rendering this component from host control
 */
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlayerIdType } from "poker-cows-common";
import React, { ReactNode, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectActivePlayers } from "../../../../../logic/player/slice";
import { hostSellChips } from "../../../../../logic/prompts/slice";
import { formatMoneyString } from "../../../../../utils/MoneyFormat";
import Button from "../../../Button";
import { PlayerSelect } from "./PlayerSelect";

type SellInfoType = {
  amount: string;
  toPlayer: PlayerIdType | "ALL";
};

type SellChipProps = {
  back: () => void;
  showNotification: (message: ReactNode) => void;
};
export const ChipSale = (props: SellChipProps): JSX.Element => {
  const { activePlayers } = useSelector(selectActivePlayers);
  const disabled = activePlayers.length < 2; // chip transfer disabled if only 1 player

  const [sellChip, setSellChip] = useState<SellInfoType>({
    amount: "100",
    toPlayer: "ALL",
  });

  const dispatch = useDispatch();
  const onClickSellChip = () => {
    const argString =
      parseFloat(sellChip.amount).toString() + " " + sellChip.toPlayer + " ";

    dispatch(hostSellChips(argString));

    const message = (
      <>
        <span className="title">COMPLETE!</span>
        <span>
          You sold {formatMoneyString(parseFloat(sellChip.amount).toFixed(2))}{" "}
          in chips to {getPlayerName()}
        </span>
      </>
    );
    props.showNotification(message);
  };

  const updateSellChip = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSellChip({
      ...sellChip,
      toPlayer: event.target.value,
    });
  };

  const closeButton = (
    <span className="close" onClick={props.back}>
      <FontAwesomeIcon
        className="close-icon"
        title="Close"
        icon={faClose as IconProp}
      />
    </span>
  );

  const getPlayerName = () => {
    if (sellChip.toPlayer === "ALL") {
      return "All Players";
    } else {
      const toPlayer = activePlayers.find(
        (player) => player.id === sellChip.toPlayer
      );

      return toPlayer?.name;
    }
  };

  const sellChipAmountOptions = [
    100, 500, 1000, 2000, 4000, 5000, 10000, 20000, 50000,
  ];

  return (
    <div className="chipSellContainer">
      <div className="chipSellHeader">
        SELL CHIPS TO PLAYERS
        {closeButton}
      </div>
      <div className="chipSell">
        Sell
        <div className="fieldGroup">
          <select
            className="field"
            name="sell-chip"
            value={sellChip.amount}
            onChange={(event) => {
              setSellChip({
                ...sellChip,
                amount: event.target.value,
              });
            }}
          >
            {sellChipAmountOptions.map((value) => (
              <option value={value} key={value}>
                {formatMoneyString(value)}
              </option>
            ))}
          </select>
        </div>
        in Chips to
        <div className="fieldGroup">
          <PlayerSelect
            value={sellChip.toPlayer}
            options={activePlayers}
            toOrFrom="toPlayer"
            onChange={updateSellChip}
            disabled={disabled}
            showAllPlayerOption={true}
          />
        </div>
      </div>
      <Button className="actionButton green" onClick={onClickSellChip}>
        SELL CHIPS
      </Button>
    </div>
  );
};
