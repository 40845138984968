/**
 * The "as" prop is a very common concept in relation to reusable React components.
 * It allows the user to specify the underlying element that should be rendered.
 * This is useful for components that need to render different elements depending on
 * the context they are used in.
 *
 * This is a very common pattern in the React ecosystem, but it is not supported by
 * TypeScript out of the box. This file adds support for it.
 *
 * Rather than only export types for the polymoprhic prop, we export a component called "Box".
 * It can be used to leverage polymorphic components in a type-safe manner. One may also use
 * `BoxProps` to add the polymorphic "as" prop to another component's interface.
 *
 * @source https://gist.github.com/kripod/4434e7cecfdecee160026aee49ea6ee8
 */

import React from "react";

type PropsOf<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
> = JSX.LibraryManagedAttributes<E, React.ComponentPropsWithRef<E>>;

interface BoxOwnProps<E extends React.ElementType = React.ElementType> {
  as?: E;
}

export type BoxProps<E extends React.ElementType> = BoxOwnProps<E> &
  Omit<PropsOf<E>, keyof BoxOwnProps>;

const DEFAULT_ELEMENT = "div";

/**
 * Box is simply a polymorphic component with a default element of "div".
 *
 * @example
 * type PolymorphicComponentProps<E extends React.ElementType, P> = P &
 *   BoxProps<E>;
 *
 * interface CustomComponentOwnProps {
 *   customProp?: number;
 *   onClick: boolean;
 * }
 *
 * type CustomComponentProps<E extends React.ElementType> =
 *   PolymorphicComponentProps<E, CustomComponentOwnProps>;
 *
 * function CustomComponent<E extends React.ElementType>(
 *   props: CustomComponentProps<E>
 * ): JSX.Element {
 *   return <Box {...props} />;
 * };
 *
 */
export const Box = React.forwardRef(
  ({ as, ...restProps }: BoxOwnProps, ref: React.Ref<Element>) => {
    const Element = as || DEFAULT_ELEMENT;

    return <Element ref={ref} {...restProps} />;
  }
) as <E extends React.ElementType = typeof DEFAULT_ELEMENT>(
  props: BoxProps<E>
) => JSX.Element;
