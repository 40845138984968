// ChipMovementContainer.tsx
//
// This component encapsulates the selling chips and transferring chips among the players

import React, { ReactNode, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";
import { Game, PrefInterface } from "poker-cows-common";

import {
  selectNextPrefs,
  selectGameType,
} from "../../../../../logic/table/slice";
import {
  changePrefs,
  selectIsGameActive,
} from "../../../../../logic/prompts/slice";
import Button from "../../../Button";
import { Tooltip } from "../../../Tooltip/Tooltip";
import { ChipNotification } from "./ChipNotification";
import { ChipSale } from "./ChipSale";
import { ChipTransfer } from "./ChipTransfer";

// Chip info props
type ChipInfoProps = {
  className?: string;
  children?: React.ReactNode;
};

// chip info component
// display chip related information to player
const ChipInfo = (props: ChipInfoProps) => {
  return (
    <div className={classNames("chipInfo", props.className)}>
      <>{props.children}</>
    </div>
  );
};

type DisplayType = "TRANSFER" | "SELL" | "NOTIFICATION" | null;

export const ChipMovementContainer = () => {
  const dispatch = useDispatch();
  const { gameType } = useSelector(selectGameType);
  const { isGameActive } = useSelector(selectIsGameActive);
  const { nextPrefs } = useSelector(selectNextPrefs);

  const [newPrefs] = useState<Partial<PrefInterface>>(nextPrefs ?? {});

  // display, which "screen" to display e.g. notification, chipSale, ...
  const [display, setDisplay] = useState<DisplayType>();
  const [notificationMessage, setNotificationMessage] =
    useState<ReactNode>(null);

  const resetChipsMovementDisplay = useCallback(() => {
    setDisplay(null);
  }, []);

  const onSetChipRestricted = (event: React.ChangeEvent<HTMLInputElement>) => {
    const prefsString = JSON.stringify({
      ...newPrefs,
      chipRestricted: !event.target.checked,
    });
    dispatch(changePrefs({ newPrefs: prefsString }));
  };

  const showNotification = (message: ReactNode) => {
    setNotificationMessage(message);
    setDisplay("NOTIFICATION");
  };

  // host is not allowed to transfer chip
  // when no limit texas hold'em game is playing
  if (gameType === Game.TexasHoldem && isGameActive) {
    return (
      <ChipInfo className="chip-alert">
        <span>
          You can't sell or transfer chips during an active
          <br /> No-Limit Texas Hold'em game.
        </span>
      </ChipInfo>
    );
  }

  // render chip transaction component
  if (display === "TRANSFER") {
    return (
      <ChipTransfer
        back={resetChipsMovementDisplay}
        showNotification={showNotification}
      />
    );
  }

  // render chip sell component
  if (display === "SELL") {
    return (
      <ChipSale
        back={resetChipsMovementDisplay}
        showNotification={showNotification}
      />
    );
  }

  if (display === "NOTIFICATION") {
    return (
      <ChipNotification
        className="info"
        message={notificationMessage}
        back={() => setDisplay(null)}
      />
    );
  }

  return (
    <div className="chipTransaction">
      <div>
        <span className="chipTransactionText">CHIP TRANSACTIONS</span>
      </div>
      <div className="chipButtons">
        <Button
          className="actionButton green"
          onClick={() => setDisplay("SELL")}
        >
          SELL CHIPS TO PLAYERS
        </Button>
        <Button
          className="actionButton green"
          onClick={() => setDisplay("TRANSFER")}
        >
          TRANSFER CHIPS BETWEEN
          <br /> PLAYERS
        </Button>
      </div>
      <div className="unrestrictedChip">
        <div className="checkbox">
          <input
            type="checkbox"
            checked={!nextPrefs?.chipRestricted}
            id="allowUnrestrictedChip"
            onChange={onSetChipRestricted}
          />
          <label htmlFor="allowUnrestrictedChip">
            Allow unrestricted chip purchases directly by players
          </label>
        </div>
        <Tooltip
          title="Allow Unrestricted Chip Purchases"
          body="Players may make unlimited and unrestricted chip purchases directly from the bank."
        ></Tooltip>
      </div>
    </div>
  );
};
