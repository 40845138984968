/** NoLimitContent.tsx
 *
 * This is a rulesRow component used in Table Setting
 * It displays no limit small and big blind
 */
import React from "react";
import { useSelector } from "react-redux";
import { selectNextPrefs } from "../../../../../logic/table/slice";
import { formatMoneyString } from "../../../../../utils/MoneyFormat";

const NoLimitContent = () => {
  const { nextPrefs } = useSelector(selectNextPrefs);
  const smallBlind = formatMoneyString(nextPrefs?.smallBlind);
  const bigBlind = formatMoneyString(nextPrefs?.bigBlind);

  return (
    <div className="noLimitContent">
      <div className="minBet flexColumn">
        <span className="caption">Small Blind:</span>
        <span className="value">{smallBlind}</span>
      </div>
      <div className="maxBet flexColumn">
        <span className="caption">Big Blind:</span>
        <span className="value">{bigBlind}</span>
      </div>
    </div>
  );
};

export default NoLimitContent;
