/** SpreadLimitSetup.tsx
 *
 * This is a rulesRow component used in Master Startup Modal
 * It displays 2 dropdown select inputs for setting the Ante, Min. Bet, and Max. Bet
 */
import React from "react";

import { filteredDenominations, PrefInterface } from "poker-cows-common";
import { Tooltip } from "../../Tooltip/Tooltip";
import { Dropdown } from "../../Dropdown/Dropdown";
import { arrowStyle } from "./NoLimitSetup";

interface BettingStructureProps {
  startupPrefs: Partial<PrefInterface>;
  onUpdateInitialSettingsByPref: (
    prefsName: string,
    prefsValue: string
  ) => void;
}

const SpreadLimitSetup = (props: BettingStructureProps) => {
  return (
    <div className="dropdownContainer customDropdown">
      <div className="ante">
        <div className="dropdownCaption">
          Ante: &nbsp;
          <Tooltip
            title="Ante"
            body="Forced bet on all players to start each game."
          />
        </div>
        <Dropdown
          value={String(props.startupPrefs.ante)}
          setValue={(newValue) =>
            props.onUpdateInitialSettingsByPref("ante", newValue)
          }
          arrowStyle={arrowStyle}
          options={[{ label: "No Ante", value: 0 }, ...filteredDenominations]}
        />
      </div>
      <div className="minBet">
        <div className="dropdownCaption">Min Bet:</div>
        <Dropdown
          value={String(props.startupPrefs.spreadMinRaise)}
          setValue={(newValue) =>
            props.onUpdateInitialSettingsByPref("spreadMinRaise", newValue)
          }
          options={filteredDenominations}
          arrowStyle={arrowStyle}
        />
      </div>
      <div className="maxBet">
        <div className="dropdownCaption">Max Bet:</div>
        <Dropdown
          value={String(props.startupPrefs.spreadMaxRaise)}
          setValue={(newValue) =>
            props.onUpdateInitialSettingsByPref("spreadMaxRaise", newValue)
          }
          options={filteredDenominations}
          arrowStyle={arrowStyle}
        />
      </div>
    </div>
  );
};

export default SpreadLimitSetup;
