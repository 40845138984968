import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import * as React from "react";
import "./PlayerDeclarationIndicator.css";

interface PlayerDeclareProps {
  high: boolean;
  low: boolean;
  both: boolean;
}

export function PlayerDeclarationIndicator(props: PlayerDeclareProps) {
  const className = classNames(
    "playerDeclareIndicatorContainer",
    { declareIndicatorHigh: props.high },
    { declareIndicatorLow: props.low },
    { declareIndicatorBoth: props.both }
  );
  return (
    <div className={className}>
      {props.high && <FontAwesomeIcon icon={faArrowUp as IconProp} />}
      {props.low && <FontAwesomeIcon icon={faArrowDown as IconProp} />}
      {props.both && (
        <>
          <FontAwesomeIcon icon={faArrowUp as IconProp} />
          <FontAwesomeIcon
            icon={faArrowDown as IconProp}
            style={{ marginTop: "-.7em" }}
          />
        </>
      )}
    </div>
  );
}
