// this file handles:
// on mount
//      attaching the latest video and audio content
//      made available in redux state by TwilioClient to
//      a mutable element ref, which is then used
//      by the Twilio Media components as content for
//      audio and video elements
// on unmount
//      detaching the same

import { MutableRefObject, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useSeatContext } from "../../ui/components/Seat/SeatContext";
import { selectPlayerIdAtTablePos } from "../player/slice";
import {
  selectIsTwilioConnected,
  selectTwilioIdByPlayerId,
  selectLatestTwilioAudioMedia,
  selectLatestTwilioVideoMedia,
} from "./slice";
import { TwilioClient } from "./TwilioClient";

export function useTwilioMedia(twilioClient: TwilioClient, local: boolean) {
  const { tablePosition } = useSeatContext();
  // if local, use localPlayerId, if remote, use seat position to select id
  const { playerId } = useSelector(selectPlayerIdAtTablePos(tablePosition));

  const { twilioId } = useSelector(selectTwilioIdByPlayerId(playerId));
  const videoRef = useRef() as MutableRefObject<HTMLVideoElement>;
  const audioRef = useRef() as MutableRefObject<HTMLAudioElement>;
  const { twilioLatestVideoMedia } = useSelector(
    selectLatestTwilioVideoMedia(playerId)
  );
  const { twilioLatestAudioMedia } = useSelector(
    selectLatestTwilioAudioMedia(playerId)
  );
  const { isTwilioConnected } = useSelector(selectIsTwilioConnected);

  useEffect(() => {
    if (
      !twilioLatestVideoMedia ||
      !isTwilioConnected ||
      !videoRef ||
      !twilioId
    ) {
      return;
    }
    twilioClient.attachMedia(videoRef, twilioId, twilioLatestVideoMedia);
    return () => {
      twilioClient.detachMedia(twilioId, twilioLatestVideoMedia);
    };
  }, [
    twilioLatestVideoMedia,
    isTwilioConnected,
    twilioId,
    videoRef,
    twilioClient,
  ]);

  useEffect(() => {
    if (
      !twilioLatestAudioMedia ||
      !isTwilioConnected ||
      !audioRef ||
      !twilioId
    ) {
      return;
    }
    twilioClient.attachMedia(audioRef, twilioId, twilioLatestAudioMedia);
    return () => {
      twilioClient.detachMedia(twilioId, twilioLatestAudioMedia);
    };
  }, [
    twilioLatestAudioMedia,
    isTwilioConnected,
    twilioId,
    audioRef,
    twilioClient,
  ]);

  return { videoRef, audioRef };
}
