/**
 * This Redux slice serves as the source of truth for position highlighting.
 * It is typically interfaced with via the "eyeball button" in the HandValueItem
 * component, but this module's state can also be reset via thunks in other modules.
 */
import { createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { HighlightState } from "../logicTypes";

// =============================== //
//          Initial State          //
// ================================//
const initialState: {
  highlight: HighlightState | null;
} = { highlight: null };

/**
 * All cards are dealt, and the order that they are dealt in won't change for the duration of each game. We are using
 * this fact as an easy way to identify cards that needs to be dimmed/highlight.
 */
// =============================== //
//              Slice              //
// ================================//
const slice = createSlice({
  name: "positionHighlight",
  initialState,
  reducers: {
    setHighlight(state, action) {
      state.highlight = action.payload;
    },
    /**
     * Reseting happens via clicking an active eyeball button, but there are also many events which require reseting.
     * Search for references to this method in order to see all those spots!
     */
    resetHighlight(state) {
      state.highlight = initialState.highlight;
    },
    resetHighlightByPlayerId(state, action) {
      if (state.highlight?.playerId === action.payload) {
        state.highlight = initialState.highlight;
      }
    },
  },
});

// =============================== //
//            Actions              //
// ================================//
export const { setHighlight, resetHighlight, resetHighlightByPlayerId } =
  slice.actions;

// =============================== //
//            Reducers             //
// ================================//
export default slice.reducer;

// =============================== //
//            Selectors            //
// ================================//
const selectPositionHighlight = (state: RootState) => state.positionHighlight;
export const selectHighlightedPosition = createSelector(
  selectPositionHighlight,
  ({ highlight }) => ({ highlightedPosition: highlight })
);
export const selectHighlightedPositionIds = createSelector(
  selectPositionHighlight,
  ({ highlight }) => ({ highlightedPositionIds: highlight?.dealNumbers ?? [] })
);
