import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "./LogoutButton";
import UserInfo from "./UserInfo";
import LoginButton from "./LoginButton";
import "./AuthButton.css";

const AuthWidget = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return null;
  }

  return (
    <div className="authWidgetContainer">
      {isAuthenticated && !isLoading && <UserInfo />}
      <div className="authButtonColumn">
        {!isAuthenticated && !isLoading && <LoginButton />}
        {isAuthenticated && <LogoutButton />}
      </div>
    </div>
  );
};

export default AuthWidget;
