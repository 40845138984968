import React from "react";
import { useSelector } from "react-redux";

import {
  selectConnectionAttempted,
  selectIsConnected,
} from "../../../logic/connection/slice";

import "./ConnectionLost.css";

export function ConnectionLost() {
  const { isConnected } = useSelector(selectIsConnected);
  const { connectionAttempted } = useSelector(selectConnectionAttempted);

  const hiddenCssClass =
    connectionAttempted && !isConnected ? "" : "connection-lost-hidden";

  return (
    <div className={"connection-lost " + hiddenCssClass}>
      <div className="connection-lost__text">
        <p>
          Your connection to the server has been lost, attempting to
          reconnect...
        </p>
      </div>
    </div>
  );
}
