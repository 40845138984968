/**
 * This is a component responsible for rendering a card withint the context of a table, hand, or showdown.
 */
import React, { CSSProperties, useMemo } from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";

import {
  CardInterface,
  WildcardConstraint,
  CardMetadataInterface,
} from "poker-cows-common";
import {
  selectIsCardSelected,
  selectSelectedCards,
} from "../../logic/cardSelection/slice";
import {
  selectIsCardWildPrivate,
  selectIsCardWild,
} from "../../logic/gameInstance/slice";
import {
  selectAnyCardSelectionPrompt,
  selectAnyDealPrompt,
} from "../../logic/prompts/slice";
import { useSeatContext } from "./Seat/SeatContext";
import {
  CardElement,
  CardElementProps,
} from "./Elements/CardElement/CardElement";
import { selectHighlightedPositionIds } from "../../logic/positionHighlight/slice";

import "./Card.css";

export interface CardProps extends Pick<CardElementProps, "rank" | "suit"> {
  up: boolean;
  showdownOverride: boolean;
  dealNumber: number;
  show?: boolean;
  styleClass?: string;
  style?: CSSProperties;
  selected?: boolean;
  tableCards?: boolean;
  metadata?: CardMetadataInterface | null;
  cardClickCallback?: (
    card: Partial<CardInterface>,
    isSelected: boolean
  ) => void;
}

const Card = (props: CardProps) => {
  const cardData = { rank: props.rank, suit: props.suit } as WildcardConstraint;
  const fullCardData = {
    up: props.up,
    orderDealt: props.dealNumber,
    rank: cardData.rank ?? null,
    suit: cardData.suit ?? null,
    metadata: props.metadata ?? null,
  };

  const { tablePosition } = useSeatContext();
  const tableCards = props.tableCards === undefined ? false : props.tableCards;
  const { anyDealPrompt } = useSelector(selectAnyDealPrompt);
  const { isCardSelected } = useSelector(selectIsCardSelected(fullCardData));
  const { highlightedPositionIds } = useSelector(selectHighlightedPositionIds);
  const { isCardWild } = useSelector(selectIsCardWild(cardData));
  const { isCardWildPrivate } = useSelector(
    selectIsCardWildPrivate(cardData, tablePosition)
  );
  const { selectedCards } = useSelector(selectSelectedCards);
  const { anyCardSelectionPrompt } = useSelector(selectAnyCardSelectionPrompt);
  const isSelecting = !!anyCardSelectionPrompt && !anyDealPrompt;
  const maxSelectAmount = anyCardSelectionPrompt?.data?.maxNumberOfCards ?? 0;
  const discardsCount = useMemo(() => selectedCards.length, [selectedCards]);

  // orderDealt < 0 -- Delay reveal of already visible down cards
  const isFaceUp = useMemo(
    () => !!(props.up || props.show || props.showdownOverride),
    [props.up, props.show, props.showdownOverride]
  );
  const isLocalPlayerHand = props.show;

  const clickCard = () => {
    if (discardsCount >= maxSelectAmount && !isCardSelected) {
      return;
    }
    if (props.metadata?.discarded) {
      return;
    }
    if (props.up && anyCardSelectionPrompt?.data?.onlyAllowDownCards) {
      return;
    }

    // allows a selected card to be unselected by clicking anywhere on the card while in the cardSelectArea - not when cards are grayed out in hand
    props.cardClickCallback?.(
      {
        orderDealt: props.dealNumber,
        rank: props.rank!,
        suit: props.suit!,
        selected: props.selected,
      },
      false
    );
  };

  /** 🚧🚧🚧🚧🚧🚧 DO NOT CROSS (talk to Ned or Kyle, blame Kyle) 🚧🚧🚧🚧🚧🚧 */
  const isHighlightModeEngaged = highlightedPositionIds.length > 0;

  const isHighlightedWithoutASelection =
    (highlightedPositionIds.includes(props.dealNumber) && !isCardSelected) ||
    highlightedPositionIds.includes(-props.dealNumber);

  const isSelectedAndHighlighted = highlightedPositionIds.includes(
    -props.dealNumber
  );

  const highlightModeState = {
    darkened:
      isHighlightModeEngaged &&
      !highlightedPositionIds.includes(props.dealNumber) &&
      !highlightedPositionIds.includes(-props.dealNumber) &&
      isFaceUp,
    brightened:
      isHighlightModeEngaged &&
      (isHighlightedWithoutASelection || isSelectedAndHighlighted) &&
      isFaceUp,
  };
  /** ************************************************************************** */

  return (
    <CardElement
      className={classNames(props.styleClass, "card", {
        down: !isFaceUp,
        "hole-card": !props.up && !tableCards,
        "private-wild": isFaceUp && isCardWildPrivate,
        wild: isFaceUp && (isCardWild || isCardWildPrivate),
        selected: isCardSelected && props.dealNumber > 0,
        "opponent-card":
          !isLocalPlayerHand &&
          !props.showdownOverride &&
          isCardWildPrivate &&
          !tableCards,
        cardDiscarded: props.metadata?.discarded,
        cardSelected:
          props.metadata?.selected && isFaceUp && !props.showdownOverride,
        showdownOverride: props.showdownOverride && !props.up,
        fullBrightness: highlightModeState.brightened,
        darkOverlay: highlightModeState.darkened,
      })}
      onClick={() => {
        if (isLocalPlayerHand && isSelecting) {
          clickCard();
        }
      }}
      rank={cardData.rank}
      style={props.style}
      suit={cardData.suit}
      isFaceUp={isFaceUp}
    />
  );
};

export default Card;
