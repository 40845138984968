import React from "react";
import Seat from "../Seat/Seat";
import { ErrorBoundary } from "../../../error_handling/ErrorBoundary";
import { SeatContext } from "../Seat/SeatContext";

interface Props {
  screenPositions: number[];
  bottomContent: JSX.Element;
  className: string;
}

export const TableColumn = (props: Props) => {
  const mapPlayers = () =>
    props.screenPositions.map((screenPosition: number) => (
      <ErrorBoundary key={screenPosition + "-seatBoundary"}>
        <SeatContext.Provider value={screenPosition}>
          <Seat />
        </SeatContext.Provider>
      </ErrorBoundary>
    ));

  return (
    <div className={props.className}>
      <div className="seatingArea">{mapPlayers()}</div>
      <ErrorBoundary>
        <div className="buttonsBottom">{props.bottomContent}</div>
      </ErrorBoundary>
    </div>
  );
};
