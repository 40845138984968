// reference enumeration for banking transactions

export enum BankingTransactionTypes {
  banking_type_initial_buyin = "Initial Buy In",
  banking_type_win_or_loss = "Win or Loss",
  banking_type_chips_from_bank = "Chips from Bank",
  banking_type_give_or_take_chips = "Give or Take Chips",
  banking_type_transfer_from_player = "Transfer from Player",
  banking_type_transfer_to_player = "Transfer to Player",
}
