import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export function initSentry() {
  Sentry.init({
    dsn: "https://5b0d16523e534d008e8f1a39d0f26f98@o1410940.ingest.sentry.io/6748685",
    integrations: [new BrowserTracing()],

    // Performance data tracking (1 for 100% of performance data, 0 off)
    tracesSampleRate: 1.0,
  });
}
