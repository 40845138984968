import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { CardInterface } from "poker-cows-common";
import { CardSelectionInterface } from "../logicTypes";
import { RootState } from "../store";

export function getCardId(cardData: CardInterface) {
  if (typeof cardData.suit === "number" && typeof cardData.rank === "number") {
    return `${cardData.suit}-${cardData.rank}`;
  }
  return cardData.orderDealt.toString();
}

// =============================== //
//              Slice              //
// ================================//
const adapter = createEntityAdapter<CardSelectionInterface>();
export const slice = createSlice({
  name: "cardSelection",
  initialState: adapter.getInitialState(),
  reducers: {
    setCardSelected(
      state,
      action: {
        type: string;
        payload: CardInterface[];
      }
    ) {
      const cards = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      const upserts: CardSelectionInterface[] = [];
      const removes = [];
      for (const card of cards) {
        const id = getCardId(card);
        if (!card.selected) {
          removes.push(id);
        } else {
          upserts.push({ ...card, id });
        }
      }

      if (!cards?.[0]?.selected) {
        return adapter.removeMany(state, removes);
      }

      return adapter.upsertMany(state, { payload: upserts, type: action.type });
    },
    clearSelectedCards(state) {
      return adapter.removeAll(state);
    },
  },
});

// =============================== //
//            Actions              //
// ================================//
export const { setCardSelected, clearSelectedCards } = slice.actions;

// =============================== //
//            Reducers             //
// ================================//
export default slice.reducer;

// =============================== //
//            Selectors            //
// ================================//
const selectCardSelection = adapter.getSelectors<RootState>(
  (state) => state.cardSelection
);
export const selectIsCardSelected =
  (cardData: CardInterface) => (state: RootState) => ({
    isCardSelected:
      selectCardSelection.selectById(state, getCardId(cardData))?.selected ??
      false,
  });
export const selectSelectedCards = (state: RootState) => ({
  selectedCards: selectCardSelection
    .selectAll(state)
    .filter((entry) => entry.selected),
});
