import React from "react";
import { useRedirect } from "../../../hooks/useRedirect";
import { ExtraSession } from "../extraSession/ExtraSession";

interface InvalidTableParams {
  code: string;
}

const InvalidTable = (params: InvalidTableParams) => {
  const gotoHome = useRedirect("/");

  const handleClick = () => {
    gotoHome();
  };

  return (
    <div>
      <ExtraSession
        title={`Table ${params.code} not found`}
        contents={<></>}
        actionButtonText="Continue"
        actionButtonOnClick={handleClick}
      />
    </div>
  );
};

export default InvalidTable;
