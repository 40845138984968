/**
 * StartTestTable.tsx
 *
 * Start a deterministic test table (a table whose deck is always the same given the seed
 * parameter is the same). The route takes a seed param that can be used to control the
 * test scenarios if needed to be different.
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { isToggleOn } from "../../../logic/toggle/api";
import { createTable } from "../../../logic/table/thunks";
import { selectTableCode } from "../../../logic/table/slice";
import { Toggle } from "poker-cows-common";
import { SessionKeys, setSession } from "../../../utils/SessionSettings";

const DEFAULT_TEST_SEED = "42";

export function useTester() {
  const [tester, setTester] = useState(false);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    isToggleOn(Toggle.TEST_TABLE).then((on) => {
      setTester(on);
      setFetched(true);
    });
  }, []);

  return {
    isTester: fetched ? tester : undefined,
  };
}

export const StartTestTable = () => {
  const dispatch = useDispatch();
  const { isTester } = useTester();
  const { tableCode } = useSelector(selectTableCode);
  const { seed } = useParams<{ seed?: string }>();

  useEffect(() => {
    if (isTester) {
      setSession(SessionKeys.isBotTable, "false");

      dispatch(
        createTable({
          name: "Test Table",
          seed: seed ?? DEFAULT_TEST_SEED,
          isTestTable: true,
        })
      );
    }
  }, [seed, isTester, dispatch]);

  if (tableCode) {
    const url = `/table/${tableCode}`;
    return <Redirect to={url} />;
  }

  if (isTester === false) {
    return <Redirect to="/" />;
  }

  return <Loading />;
};
