/**
 * Enumeration of possible twilio error types.
 * full definitions can be found here:
 * https://www.twilio.com/docs/video/build-js-video-application-recommendations-and-best-practices#handling-errors
 */

export enum TwilioErrorType {
  NONE = "none",
  MEDIA = "media",
  AUDIO = "audio",
  VIDEO = "video",
  CONNECT = "connect",
  DISCONNECT = "disconnect",
  REMOTE = "remote",
}
