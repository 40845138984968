import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const FoldIndicator = () => (
  <div className="foldContainer">
    <div className="foldIndicator">
      <FontAwesomeIcon icon={faTimes as IconProp} />
      <div className="foldIndicatorText">FOLD</div>
    </div>
  </div>
);
