/**
 * defaultOptions.ts
 *
 * This file in houses all the default options ie. (value, description, default?, tooltip?) based on GameOptionsInterface
 * The options here are related to option, wildcard and winner and information related to version goes in respective Game's Options file within this directory
 */
import { BuyLastCardUpSetting } from "../enums/BuyLastCardUpSetting";
import { HighLowSetting } from "../enums/HighLowSetting";
import { RollYourOwnSetting } from "../enums/RollYourOwnSetting";
import { WildcardOption } from "../enums/WildcardOption";

export const optionNone = {
  description: "None",
  value: RollYourOwnSetting.DISABLED,
};
export const optionNoneDefault = {
  ...optionNone,
  default: true,
};
export const optionRollYourOwn = {
  description: "Roll Your Own",
  value: RollYourOwnSetting.FIRST,
  tooltip:
    "Players selects which card to turn up after initial cards are dealt.",
};
export const optionRollYourOwnDefault = {
  ...optionRollYourOwn,
  default: true,
};
export const optionRollYourOwnAlways = {
  description: "Roll Your Own (Always)",
  value: RollYourOwnSetting.ALWAYS,
};
export const optionRollYourOwnAlwaysDefault = {
  ...optionRollYourOwnAlways,
  default: true,
};
export const optionBuyLastCardUp = {
  description: "Buy Last Card Up",
  value: BuyLastCardUpSetting.ENABLED,
};
export const optionBuyLastCardUpDefault = {
  ...optionBuyLastCardUp,
  default: true,
};

export const wildcardNone = {
  description: "None",
  value: WildcardOption.NONE,
};
export const wildcardNoneDefault = {
  ...wildcardNone,
  default: true,
};
export const wildcardQueensAndFollowers = {
  description: "Queens and Followers",
  value: WildcardOption.QUEENS_AND_FOLLOWERS,
};
export const wildcardQueensAndFollowersDefault = {
  ...wildcardQueensAndFollowers,
  default: true,
};
export const wildcardLowHole = {
  description: "Low Hole",
  value: WildcardOption.LOW_HOLE,
  tooltip:
    "Each player's lowest hole card is wild for them, as well as any cards of matching value within their hand and community cards. Players have option to buy their last card up for 2X min bet.",
};
export const wildcardLowHoleWithBuy = {
  ...wildcardLowHole,
  value: WildcardOption.LOW_HOLE_WITH_BUY,
};
export const wildcardDeuces = {
  description: "Deuces",
  value: WildcardOption.DEUCES,
};
export const wildcardOneEyedJacks = {
  description: "One Eyed Jacks",
  value: WildcardOption.ONE_EYED_JACKS,
};
export const wildcardCenterCard = {
  description: "Center Card",
  value: WildcardOption.CENTER_CARD,
  tooltip:
    "The center card of the the community cards is wild for everybody, as well as any cards of matching value within the community cards and players' hands.",
};

export const winnerHighOnly = {
  description: "High Only",
  value: HighLowSetting.HIGH,
  tooltip: "Highest ranking poker hand wins the pot.",
};
export const winnerHighOnlyDefault = {
  ...winnerHighOnly,
  default: true,
};
export const winnerLowOnly = {
  description: "Low Only",
  value: HighLowSetting.LOW,
  tooltip: "Lowest ranking poker hand wins the pot.",
};
export const winnerLowOnlyDefault = {
  ...winnerLowOnly,
  default: true,
};
export const winnerHighLowSplit = {
  description: "High-Low Split",
  value: HighLowSetting.HI_LO,
  tooltip:
    'High hand splits the pot with low hand. At showdown, players declare if their hand is to be evaluated as "High" or "Low" or "Both."',
};
export const winnerHighLowSplitDefault = {
  ...winnerHighLowSplit,
  default: true,
};
