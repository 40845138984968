/**
 * HelpModal.tsx
 *
 * This is just a wrapper around the HelpPage
 */
import React from "react";

import { HelpContents } from "../../Settings/HelpPage";

import { ModalProps } from "../types/modalTypes";

export const HelpModal = (props: ModalProps): JSX.Element => {
  return <HelpContents {...props} />;
};
