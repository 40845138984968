/* DeclareArea.tsx
 * This file is the component responsible for showing the "High", "Low", and "Both"
 * buttons that are presented to players in the Declare Round in Hi-Lo split games.
 * It is ultimately rendered in ActionArea.tsx when the Declare Prompt is available for a player.
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faArrowsUpDown,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  declareBoth,
  declareHigh,
  declareLow,
} from "../../../logic/prompts/slice";
import "./DeclareArea.css";
import { selectGameType } from "../../../logic/table/slice";
import { Game } from "poker-cows-common";
import Button from "../Button";
import {
  DECLARE_BOTH_BUTTON,
  DECLARE_HIGH_BUTTON,
  DECLARE_LOW_BUTTON,
} from "../../../test-identifiers";

const useAutomaticDelcareHighForHoldem = (): { isTexasHoldem: boolean } => {
  const dispatch = useDispatch();
  const [hasDeclared, setHasDeclared] = useState(false);
  const isTexasHoldem =
    useSelector(selectGameType).gameType === Game.TexasHoldem;

  useEffect(() => {
    if (isTexasHoldem && !hasDeclared) {
      dispatch(declareHigh());
      setHasDeclared(true);
    }

    // Unset for future games
    return () => {
      setHasDeclared(false);
    };
  }, [dispatch, hasDeclared, isTexasHoldem]);

  return { isTexasHoldem };
};

export const DeclareArea = () => {
  const dispatch = useDispatch();

  const clickHigh = () => dispatch(declareHigh());
  const clickLow = () => dispatch(declareLow());
  const clickBoth = () => dispatch(declareBoth());

  const { isTexasHoldem } = useAutomaticDelcareHighForHoldem();

  if (isTexasHoldem) return null;

  return (
    <div className="declareActions">
      <div className="declareTitleText">DECLARE:</div>
      <div className="declareButtonsContainer">
        <Button
          data-testid={DECLARE_LOW_BUTTON}
          className="actionButton blue"
          onClick={clickLow}
          type="button"
        >
          <div className="declareIcon">
            <FontAwesomeIcon icon={faArrowDown as IconProp} />
          </div>
          Low
        </Button>

        <Button
          data-testid={DECLARE_HIGH_BUTTON}
          className="actionButton green"
          onClick={clickHigh}
          type="button"
        >
          <div className="declareIcon">
            <FontAwesomeIcon icon={faArrowUp as IconProp} />
          </div>
          High
        </Button>

        <Button
          data-testid={DECLARE_BOTH_BUTTON}
          className="actionButton yellow"
          onClick={clickBoth}
          type="button"
        >
          <div className="declareIcon">
            <FontAwesomeIcon icon={faArrowsUpDown as IconProp} />
          </div>
          Both
        </Button>
      </div>
    </div>
  );
};
