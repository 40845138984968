// NoLimit.ts
//
// Implementation for No Limit betting structure. Each player can put as much money
// as they want in the pot

import { BetInfo } from "./BetInfo";
import { BettingStructure } from "./BettingStructure";
import { RoundBetInfo } from "./RoundBetInfo";

/**
 *  No Limit betting configuration
 *
 *  Players are allowed to bet all the money they have in the bank
 */
export class NoLimit extends BettingStructure {
  private validatePrefs(prefs: any) {
    if (!prefs.allowAllIn) {
      throw new Error("Players should be allowed to go all in.");
    }
    this.validatePositiveNumber(prefs.smallBlind, "smallBlind");
    this.validatePositiveNumber(prefs.bigBlind, "bigBlind");
  }

  public constructor(prefs: any) {
    super(prefs);
    this.validatePrefs(prefs);
  }

  public validateBet(bet: BetInfo, round: RoundBetInfo) {
    this.validatePositiveNumber(bet.amount, "bet amount");

    if (bet.amount + bet.personalInPot < round.currentBet && !bet.isAllIn) {
      throw new Error(
        "Not enough money to match the bet and the player is not all in."
      );
    }
  }
}
