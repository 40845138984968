/* DeclareInOutArea.tsx
 * This file is the component responsible for showing the "In" and "Out"
 * buttons that are presented to players in Guts.
 * It is ultimately rendered in ActionArea.tsx when the Declare In Out Prompt is available for a player.
 */

import React from "react";
import { useDispatch } from "react-redux";

import Button from "../Button";
import { declareIn, declareOut } from "../../../logic/prompts/slice";
import {
  DECLARE_IN_BUTTON,
  DECLARE_OUT_BUTTON,
  DECLARE_IN_AND_OUT_AREA,
} from "../../../test-identifiers";

import "./DeclareArea.css";

export const DeclareInOutArea = () => {
  const dispatch = useDispatch();

  const clickIn = () => dispatch(declareIn());
  const clickOut = () => dispatch(declareOut());

  return (
    <div className="declareActions">
      <div className="declareTitleText">DECLARE:</div>
      <div
        className="declareButtonsContainer"
        data-testid={DECLARE_IN_AND_OUT_AREA}
      >
        <Button
          className="actionButton green"
          onClick={clickIn}
          data-testid={DECLARE_IN_BUTTON}
        >
          In
        </Button>

        <Button
          className="actionButton red"
          onClick={clickOut}
          data-testid={DECLARE_OUT_BUTTON}
        >
          Out
        </Button>
      </div>
    </div>
  );
};
