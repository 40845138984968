/**
 * Tooltip.tsx
 * Tooltip          Component to display text in a popup
 */
import React, { ReactNode } from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import "./Tooltip.css";
import classNames from "classnames";
import useDevice from "../../../hooks/useDevice";

type TooltipProps = {
  title?: ReactNode;
  body: ReactNode;
  buttonClassNames?: string;
};

// Tooltip component used to display popup with content
export const Tooltip = (props: TooltipProps) => {
  const { isMobile } = useDevice();

  if (isMobile) {
    return <></>;
  }

  const content = (
    <>
      <div className="tooltipTitle" aria-label={props.title?.toString()}>
        {props.title}
      </div>
      {props.body}
    </>
  );

  return (
    <TooltipPrimitive.Root>
      <TooltipPrimitive.Trigger asChild>
        <div
          role="button"
          className={classNames("tooltipButton", props.buttonClassNames)}
        >
          <span className="sr-only">Reveal tooltip</span>
        </div>
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content sideOffset={4} className="tooltipContent">
          {content}
          <TooltipPrimitive.Arrow className="tooltipArrow" />
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
};
