/** FollowTheQueenOptions.ts
 *
 * Game Options for FollowTheQueen.
 */
import { BetLimit } from "../bets/BetLimit";
import { CardVersionSetting } from "../enums/CardVersionSetting";
import { GameOptionsInterface } from "../interfaces";
import {
  optionBuyLastCardUp,
  optionNoneDefault,
  wildcardQueensAndFollowersDefault,
  winnerHighLowSplit,
  winnerHighOnlyDefault,
  winnerLowOnly,
} from "./defaultOptions";

export const FollowTheQueenOptions: GameOptionsInterface = {
  versions: [
    {
      title: "7 Cards",
      description: "",
      value: CardVersionSetting.SEVEN_CARD,
      default: true,
      flavor: 7,
      betLimit: BetLimit.SPREAD_LIMIT,
    },
  ],
  winners: [winnerHighOnlyDefault, winnerLowOnly, winnerHighLowSplit],
  options: [optionNoneDefault, optionBuyLastCardUp],
  wildcards: [wildcardQueensAndFollowersDefault],
};
