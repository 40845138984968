/**
 * ChooseCardArea.tsx
 *
 * Components used for the selection of cards and displaying selected cards
 *
 * The design these componets are based on can be found at:
 * https://www.figma.com/file/RPfXM0eOC23wvmMby2oGBD/Poker-Cows---Final-Screens?node-id=9%3A136
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";

import { CardInterface, Game } from "poker-cows-common";
import Card from "../../Card";
import { useCardSelection } from "./useCardSelection";
import { selectGameType } from "../../../../logic/table/slice";
import { selectUpdateTime } from "../../../../logic/connection/slice";
import Button from "../../Button";

import "./ChooseCardArea.css";
import styles from "../../Elements/CardElement/CardElement.module.css";
import { SELECT_CARDS_BUTTON } from "../../../../test-identifiers";

/**
 * Component for selection of cards.
 * Comprised of a label/button component on the left, and a "selected cards" ui to the right
 */
export const ChooseCardArea = () => {
  const cardSelection = useCardSelection();
  const { gameType } = useSelector(selectGameType);

  return (
    <div className="chooseCardContainer">
      <SelectionLabelAndButton {...cardSelection} />
      {gameType === Game.FiveCardDraw ? (
        <FiveCardDrawSelectionButton />
      ) : (
        <SelectedCards {...cardSelection} />
      )}
    </div>
  );
};

/**
 * Component for 5 Card Draw card selection Label
 */
function FiveCardDrawSelectionLabel() {
  return <div>Select up to 3 cards to replace</div>;
}

/**
 * Component for 5 Card Draw card selection button
 */
function FiveCardDrawSelectionButton() {
  const cardSelection = useCardSelection();
  return (
    <>
      <div className="discardContainer">
        {!cardSelection.toDiscard.length ? (
          <div className="fiveCardDrawSkipButtonContainer">
            <Button
              className="fiveCardDrawSkipButton actionButton green"
              onClick={cardSelection.submitSelectedCards}
            >
              I'm good, no draw
            </Button>
          </div>
        ) : (
          <SelectedCards {...cardSelection} />
        )}
      </div>
    </>
  );
}

interface SelectedCardsParams {
  maxSelectAmount?: number; // The actual number of cards that player selected
  toDiscard: CardInterface[];
  onCardClicked: (card: CardInterface, isCardSelected: boolean) => void;
}

/**
 * Component for displaying the currently selected cards
 */
function SelectedCards(props: SelectedCardsParams) {
  const { gameType } = useSelector(selectGameType);
  const containerClass =
    gameType === Game.Texas ? " texDiscardCardContainer" : "";

  return (
    <div className={"discardCardContainer" + containerClass}>
      {Array(Math.max(props.maxSelectAmount ?? 0, props.toDiscard.length))
        .fill("")
        .map((_, index) => {
          if (!props.toDiscard[index]) {
            return (
              <div
                className={classNames(styles.card, "blankCard")}
                key={index}
              />
            );
          }

          const card = props.toDiscard[index];

          return (
            <div
              className={"cardAndDeselectContainer"}
              key={index}
              onClick={() => props.onCardClicked(card, true)}
            >
              <button
                onClick={() => props.onCardClicked(card, true)}
                className={"deselectCardButton"}
              >
                X
              </button>
              <Card
                show={true}
                rank={card.rank}
                suit={card.suit}
                selected={false}
                up={true}
                showdownOverride={false}
                dealNumber={card.orderDealt as number}
                cardClickCallback={() => {
                  props.onCardClicked(card, true);
                }}
              />
            </div>
          );
        })}
    </div>
  );
}

interface SelectionParams {
  toDiscard?: CardInterface[];
  // If the player must select exactly x cards then: minSelectAmount = maxSelectAmount = x
  minSelectAmount?: number; // The maximum number of cards a player can choose
  maxSelectAmount?: number; // The minimum number of cards a player can choose
  selectingString: string;
  selectedString: string;
  submitSelectedCards: () => void;
}

/**
 * Component for displaying label/button for card selection area
 */
function SelectionLabelAndButton({
  maxSelectAmount = 0,
  minSelectAmount = 0,
  selectedString,
  selectingString,
  submitSelectedCards,
  toDiscard,
}: SelectionParams) {
  const [markedUpdateTime, setMarkedUpdateTime] = useState(0);

  const { gameType } = useSelector(selectGameType);
  const { updateTime } = useSelector(selectUpdateTime);
  const cardSelection = useCardSelection();
  const moreThanOneCard = cardSelection.toDiscard.length > 1;

  useEffect(() => {
    if (markedUpdateTime !== 0 && markedUpdateTime < updateTime) {
      setMarkedUpdateTime(0);
    }
  }, [updateTime, markedUpdateTime]);

  function onClickPassCardButton() {
    setMarkedUpdateTime(updateTime);
    submitSelectedCards();
  }

  const getSelectCardLabel = () => {
    if (gameType === Game.Texas) {
      return <>Pick a card to drop</>;
    }
    if (gameType === Game.FiveCardDraw) {
      return <FiveCardDrawSelectionLabel />;
    }

    let selectedText = "Select ";
    if (minSelectAmount === 0) {
      selectedText += "up to";
    } else if (minSelectAmount !== maxSelectAmount) {
      selectedText += `${minSelectAmount} to`;
    }

    return (
      <>
        {`${selectedText} ${maxSelectAmount}`}
        <br />
        {`card${maxSelectAmount > 1 ? "s" : ""} to ${selectingString}`}
      </>
    );
  };

  return (
    <div className="selectCardsContainer">
      {toDiscard?.length === 0 ||
      (toDiscard && toDiscard.length < minSelectAmount) ? (
        <>
          {markedUpdateTime === 0 && (
            <div className="selectCards">{getSelectCardLabel()}</div>
          )}
          {/* minSelectAmount <= 0 &&
                    <button onClick={onClickPassCardButton} className="skipSelectButton">
                    I'm good
                    </button> */}
        </>
      ) : (
        <Button
          data-testid={SELECT_CARDS_BUTTON}
          className="passTheseCardsButton"
          disabled={toDiscard ? toDiscard.length < minSelectAmount : false}
          onClick={onClickPassCardButton}
        >
          <svg
            width="100%"
            height="100%"
            viewBox="-1 -1 160 115"
            preserveAspectRatio="none"
          >
            <path
              xmlns="http://www.w3.org/2000/svg"
              strokeWidth="3"
              className="st0"
              d="M6.06,113.1c-2.82,0-5.12-2.3-5.12-5.12V5.49c0-2.56,2.08-4.65,4.64-4.65h117.6c1.99,0,3.78,1.1,4.68,2.87   l25.84,50.85c0.72,1.41,0.72,3.08,0,4.49l-26.13,51.41c-0.83,1.63-2.47,2.64-4.3,2.64H6.06z"
            />
          </svg>

          <div className="passTheseCardsButtonText">
            {selectedString.toUpperCase()}
            <br />
            {moreThanOneCard && "THESE \nCARDS"}
            {!moreThanOneCard && "THIS \nCARD"}
          </div>
        </Button>
      )}
    </div>
  );
}
