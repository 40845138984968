/* eslint-disable @typescript-eslint/ban-types */
// This is a reusable dropdown input component for selecting players from a game
import React from "react";

import { PlayerInterface } from "poker-cows-common";

interface PlayerSelectProps {
  name?: string;
  toOrFrom?: string;
  options: Array<PlayerInterface>;
  onChange: Function;
  value?: string;
  disabled?: boolean;
  showAllPlayerOption?: boolean
}

export const PlayerSelect = (props: PlayerSelectProps) => {
  return (
    <select
      value={props.value}
      className="field"
      name={props.toOrFrom}
      disabled={props.disabled}
      id=""
      onChange={(event) => {
        props.onChange(event);
      }}
    >
      {props.showAllPlayerOption && <option value="all">ALL PLAYERS</option>}
      {props.options.map((player) => {
        return (
          <option key={player.id} value={player.id}>
            {player.name}
          </option>
        );
      })}
    </select>
  );
};
