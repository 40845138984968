// this file defines the TwilioClient class which manages all interaction with
// the twilio API, and all changes to the redux state regarding twilio.
// Every player will have only one instance of this class per table.

// how it works:
// On render, <LocalTwilioMedia /> checks for a TwilioClient instance for the tableId,
// if it does not exist, it creates one.
// <LocalTwilioMedia /> then manages the twilio connection via a useEffect:
//     • connecting on mount and disconnecting on unmount.

// connecting to twilio there, then sets off a chain reaction of events here.
// see this.onTwilioConnectSuccess() below.
// this updates the redux store with all our participants and tracks, and creates event listeners.

// to actually *use* the video and audio streams from twilio, a component then uses
// the hook useTwilioMedia, which requires the TwilioClient instance (to perform the methods available here,
// like start / stop / mute / unmute / attach / detach).
// useTwilioMedia also requires a reference to the <video> and <audio> tags you wish to render streams into
// it listens to events in the state and re-renders the components as necessary to display video and audio streams

import { Dispatch, MutableRefObject } from "react";
import {
  LocalTrack,
  LocalAudioTrack,
  Participant,
  RemoteParticipant,
  LocalDataTrack,
  LocalAudioTrackPublication,
  LocalVideoTrackPublication,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
  connect as twilioConnect,
  LocalParticipant,
  LocalTrackPublication,
  RemoteAudioTrack,
  RemoteVideoTrack,
  TwilioError,
  createLocalAudioTrack,
  createLocalVideoTrack,
} from "twilio-video";
import {
  connected,
  connecting,
  disconnected,
  participantConnected as participantConnectedAction,
  participantReconnecting,
  reconnecting,
  subscribed,
  unsubscribed,
  error,
  clearError,
  permissionsError,
  sessionMediaActive,
  twilioClientId,
  clearPermissionsError,
} from "./slice";
import { TwilioErrorType } from "poker-cows-common";
import { LocalVideoTrack } from "twilio-video/tsdef/LocalVideoTrack";
import { VideoRoomMonitor } from "@twilio/video-room-monitor";
import { getSessionMediaIsActive } from "../../utils/SessionMediaSettings";

export class TwilioClient {
  id: string;
  playerId: string;
  twilioRoom?: Room;
  localVideoTrack?: LocalVideoTrack;
  localAudioTrack?: LocalAudioTrack;
  dispatch: Dispatch<any>;
  // we store permissions settings locally if an error occurs,
  // since TwilioClient doesn't have access to redux state
  // see its use in startLocalMedia()
  permissions: {
    audio: boolean;
    video: boolean;
  };

  // instances of this class are managed in a class factory pattern.
  // player joins TableA for the first time - ClientA is created.
  // player then joins TableB in the same tab for the first time - ClientB is created.
  // player then returns to TableA in the same tab - the previously created ClientA is used.
  private static instances: Map<string, TwilioClient> = new Map();
  private constructor(
    id: string,
    playerId: string,
    dispatch: Dispatch<any>
  ) {
    this.id = id;
    this.playerId = playerId; // useful to keep track of the playerId for state management
    this.dispatch = dispatch;
    this.permissions = {
      // assume we have permission until an error occurs
      audio: true,
      video: true,
    };
    dispatch(twilioClientId(id));
  }
  private static createInstanceId = (
    tableId: string,
    playerId: string
  ) => {
    return tableId.toString() + ":" + playerId.toString();
  };
  // we need to set the client instance id to tableId+playerId to make it work in dev
  // otherwise all players get() first created instance because they're in the
  // same browser using the same tableId
  public static create(
    tableId: string,
    playerId: string,
    dispatch: Dispatch<any>
  ) {
    // force string
    const instanceId = this.createInstanceId(tableId, playerId);

    // check to make sure there isn't an instance
    const existingInstance = this.get(tableId, playerId);
    if (existingInstance) {
      return existingInstance;
    } else {
      // otherwise create one
      const inst = new TwilioClient(instanceId, playerId, dispatch);
      this.instances.set(instanceId, inst);
      return inst;
    }
  }
  public static get(tableId: string, playerId: string) {
    // protect against undefined
    if (tableId && playerId) {
      // force string
      const instanceId = this.createInstanceId(tableId, playerId);
      return this.instances.get(instanceId);
    } else {
      return undefined;
    }
  }

  // we connect to twilio by first creating local tracks (accessing camera and microphone)
  connect = (token: string, playerId: string, debug?: boolean) => {
    // tell redux we are connecting
    this.dispatch(connecting());

    // check localStorage for persisted media controls settings
    // twilioClient.id is used to getSessionMediaIsActive() to allow it to work in dev
    // where there are multiple players.
    const sessionAudioSetActive = getSessionMediaIsActive(this.id, "audio");
    const sessionVideoSetActive = getSessionMediaIsActive(this.id, "video");

    // the redux state can't access the twilioId on startup, to populate initialState.
    // so we set it here manually.
    this.dispatch(
      sessionMediaActive({ mediaType: "audio", active: sessionAudioSetActive })
    );
    this.dispatch(
      sessionMediaActive({ mediaType: "video", active: sessionVideoSetActive })
    );

    // access camera and microphone
    // note this is our own custom createLocalTracks function,
    // not the twilio api function.
    this.createLocalMediaTracks(
      sessionAudioSetActive,
      sessionVideoSetActive
    ).then(() => {
      // now connect to twilio, and pass our tracks, if any..
      const tracks = [];
      if (this.localAudioTrack) {
        tracks.push(this.localAudioTrack);
      }
      if (this.localVideoTrack) {
        tracks.push(this.localVideoTrack);
      }

      twilioConnect(token, { name: this.id, tracks: tracks })
        .then((newRoom) => {
          this.onTwilioConnectSuccess(newRoom, debug);
        })
        .catch((error) => {
          this.handleTwilioError(error, TwilioErrorType.CONNECT);
        });
    });
  };

  // local public methods
  disconnect = () => {
    // make sure there's a room
    // (sometimes in dev, there is no connection)
    if (this.twilioRoom) {
      this.localMediaStop(this.getTrack("video"));
      this.localMediaStop(this.getTrack("audio"));
      this.getTwilioRoom().disconnect();
      // and we can assume if there is no room, that we are not connected
      this.dispatch(disconnected());
    }
  };

  // starts and stops local audio and video tracks.
  // in the case that the track doesn't exist, it creates one
  // and then starts it
  startLocalMedia = (mediaType: "audio" | "video") => {
    let localTrack, accessLocalMedia;

    if (mediaType === "audio") {
      localTrack = this.localAudioTrack;
      accessLocalMedia = this.getLocalAudio;
    } else {
      localTrack = this.localVideoTrack;
      accessLocalMedia = this.getLocalVideo;
    }

    // if no local track, and we have permission, get it.
    // this covers the case of:
    //   • player turns off audio or video
    //   • player refreshes
    //   • player turns on audio or video
    // and checking that we haven't had permissions errors
    // keeps this from going into recursion
    if (!localTrack && this.permissions[mediaType]) {
      accessLocalMedia(this).then(() => {
        // then start over
        this.startLocalMedia(mediaType);
      });
    } else {
      // otherwise just start
      this.localMediaStart(mediaType);
      this.dispatch(sessionMediaActive({ mediaType: mediaType, active: true }));
    }
  };

  stopLocalMedia = (mediaType: "audio" | "video") => {
    this.localMediaStop(this.getTrack(mediaType));
    this.dispatch(sessionMediaActive({ mediaType: mediaType, active: false }));
  };

  attachMedia = (
    ref: MutableRefObject<any>,
    participantId: Participant.SID,
    mediaId: string
  ) => {
    this.attach(this.getTrack("", mediaId, participantId), ref);
  };

  detachMedia = (participantId: string, mediaId: string) => {
    const publication = this.getTrack("", mediaId, participantId);
    this.detach(publication?.track);
  };

  // private methods
  private onTwilioConnectSuccess = (newRoom: Room, debug?: boolean) => {
    // clear error state
    this.dispatch(clearError());

    this.twilioRoom = newRoom;

    // use getter to handle potential undefined error
    const twilioRoom = this.getTwilioRoom();

    // if player closes tab or navigates away, disconnect
    this.addDisconnectListeners(twilioRoom);

    // if we have twilio=debug in the url
    // show the room monitor
    if (debug) {
      VideoRoomMonitor.registerVideoRoom(twilioRoom);
      VideoRoomMonitor.openMonitor();
    }

    // tell redux we're connected to the room
    this.dispatch(connected());

    // we add listeners for local player
    this.addLocalParticipantListeners(twilioRoom);

    // tell redux our local participant is connected and add our tracks to state
    // (we do this manually and separately from the participants.forEach() below because
    // localParticipant is not included in twilioRoom.participants)
    this.dispatch(
      participantConnectedAction(
        this.getParticipantData(twilioRoom.localParticipant)
      )
    );

    // subscribe to local tracks, as in, add them to the redux state
    twilioRoom.localParticipant.tracks.forEach(
      (trackPublication: LocalTrackPublication) => {
        this.dispatch(subscribed(this.getLocalTrackData(trackPublication)));
      }
    );

    // tell redux participant connected and add listeners
    twilioRoom.participants.forEach((participant) => {
      this.participantConnected(participant);
    });

    // attach event listeners to room
    this.addTwilioRoomEventListeners(twilioRoom);
  };

  // twilio provides a createLocalTracks function, but
  // we define our own to handle permissions errors.
  // args specify which media we want to get
  private async createLocalMediaTracks(audio: boolean, video: boolean) {
    if (audio && video) {
      // wait for both before continuing on
      return Promise.all([this.getLocalAudio(this), this.getLocalVideo(this)]);
    }
    if (audio && !video) {
      return this.getLocalAudio(this);
    }
    if (video && !audio) {
      return this.getLocalVideo(this);
    }
    if (!audio && !video) {
      return;
    }
  }

  // we pass in twilioClient since these are called async sometimes
  // in a different scope, so "this" doesn't always = twilioClient
  private async getLocalAudio(twilioClient: TwilioClient) {
    await createLocalAudioTrack()
      .then((track: LocalAudioTrack) => {
        twilioClient.localAudioTrack = track;
        twilioClient.permissions.audio = true;
        twilioClient.dispatch(clearPermissionsError({ type: "audio" }));
      })
      .catch((error) => {
        twilioClient.handleTwilioError(error, TwilioErrorType.AUDIO);
      });
  }

  private async getLocalVideo(twilioClient: TwilioClient) {
    await createLocalVideoTrack()
      .then((track: LocalVideoTrack) => {
        twilioClient.localVideoTrack = track;
        twilioClient.permissions.video = true;
        twilioClient.dispatch(clearPermissionsError({ type: "video" }));
      })
      .catch((error) => {
        twilioClient.handleTwilioError(error, TwilioErrorType.VIDEO);
      });
  }

  private handleTwilioError(errorEvent: TwilioError, type: TwilioErrorType) {
    const { code, name, message } = errorEvent;
    // first, if it's a permissions problem...
    if (type === TwilioErrorType.AUDIO || type === TwilioErrorType.VIDEO) {
      this.permissions[type] = false;
      this.dispatch(permissionsError({ type }));
    } else {
      // or if any other error...
      this.dispatch(error({ code, name, message, type }));
    }
  }

  private getTwilioRoom = () => {
    if (!this.twilioRoom) {
      throw Error("Twilio Room undefined");
    } else {
      return this.twilioRoom;
    }
  };

  private subscribeToRoom = (room: Room) => {
    this.twilioRoom = room;
  };

  private getLocalTrackData = (
    track: LocalTrackPublication | RemoteTrackPublication
  ) => {
    return {
      playerId: this.playerId,
      mediaId: track.trackSid,
      mediaType: track.kind,
    };
  };

  // publishing local tracks to the twilio room is done at first by the twilioConnect function.
  // this localMediaStart function is only used when a player turns back on audio/video after muting/pausing.
  // we manually publish the local tracks, using the references stored in the class instance in createLocalTracks(),
  // and we use these references rather than using this.getTrack() because:
  // this.getTrack() looks in the twilioRoom object,
  // and if this method is being used, we can assume the track has been previously UNpublished,
  // and therefore is not part of the twilioRoom object.
  // so we want to get it from memory and re-publish it here.
  private localMediaStart(type: string) {
    const track =
      type === "video" ? this.localVideoTrack : this.localAudioTrack;
    if (!track) {
      return;
    }

    if (!(track instanceof LocalDataTrack)) {
      if (track.isStopped) {
        track.restart();
      }

      track.enable();
    }

    const twilioRoom = this.getTwilioRoom();
    twilioRoom.localParticipant.publishTrack(track);
  }

  private localMediaStop(
    publication:
      | LocalAudioTrackPublication
      | LocalVideoTrackPublication
      | undefined
  ) {
    if (!publication || !publication.track) {
      return;
    }

    if (!(publication.track instanceof LocalDataTrack)) {
      publication.track.disable();
      publication.track.stop();
    }

    // TODO: do we need to unpublish? or only disable?
    // here we may be able to simply disable() and stop(),
    // without having to unpublish.
    // but look into Twilio API correct usage
    publication.unpublish();
    this.getTwilioRoom().localParticipant.unpublishTrack(publication.track);

    // remove track from redux state
    this.dispatch(unsubscribed(this.getLocalTrackData(publication)));
  }

  private attach(
    media: RemoteTrackPublication | LocalTrackPublication | undefined,
    ref: MutableRefObject<any>
  ) {
    if (!media || !media.track) {
      return;
    }

    if ("attach" in media.track) {
      media.track.attach(ref.current);
    }
  }

  private detach(track: RemoteTrack | LocalTrack | null) {
    if (!track) {
      return;
    }

    if ("detach" in track) {
      track.detach().forEach((el) => (el.srcObject = null));
    }
  }

  // this retrieves a track from the twilio room object
  // if you want a local track and you know which type:
  //     • pass 'audio' or 'video' for trackType, without mediaId or participantId
  // for a local or remote track by id
  //     • pass '' for trackType, and include mediaId and participantId
  private getTrack = (
    trackType: string,
    mediaId?: string,
    participantId?: string
  ) => {
    const twilioRoom = this.getTwilioRoom();

    let participant;
    // if participantId is localParticipant's id
    // or participantId was not supplied then
    // we are looking for localParticipant
    if (twilioRoom.localParticipant.sid === participantId || !participantId) {
      participant = twilioRoom.localParticipant;
    } else {
      // otherwise, get the participant from the room
      participant = twilioRoom.participants.get(participantId);
    }
    if (!participant) {
      return undefined;
    }

    let videoTrack;
    // we have a mediaId but no track type
    if (mediaId && trackType === "") {
      videoTrack = participant.tracks.get(mediaId);
    } else if (trackType !== "") {
      // we have a track type but no mediaId
      videoTrack =
        trackType === "video"
          ? twilioRoom.localParticipant.videoTracks.values().next().value
          : twilioRoom.localParticipant.audioTracks.values().next().value;
    }
    if (!videoTrack) {
      return undefined;
    }

    return videoTrack;
  };

  private getTrackData(
    participant: RemoteParticipant,
    track: RemoteAudioTrack | RemoteVideoTrack
  ) {
    return {
      playerId: participant.identity,
      mediaId: track.sid,
      mediaType: track.kind,
    };
  }

  private getParticipantData(
    participant: RemoteParticipant | LocalParticipant
  ) {
    return {
      playerId: participant.identity,
      twilioId: participant.sid,
    };
  }

  // handling twilio events
  private addDisconnectListeners = (room: Room) => {
    // Listen to the "beforeunload" event on window to leave the Room
    // when the tab/browser is being closed.
    window.addEventListener("beforeunload", () => this.disconnect());

    // iOS Safari does not emit the "beforeunload" event on window.
    // Use "pagehide" instead.
    window.addEventListener("pagehide", () => this.disconnect());

    room.once("disconnected", (room, error) => {
      if (error) {
        this.handleTwilioError(error, TwilioErrorType.DISCONNECT);
      }
    });
  };

  private participantConnected = (participant: RemoteParticipant) => {
    this.dispatch(
      participantConnectedAction(this.getParticipantData(participant))
    );
    this.addParticipantEventListeners(participant);
  };

  private participantDisconnected = (participant: RemoteParticipant) => {
    participant.tracks.forEach((track) => this.trackUnpublished(track));
  };

  private participantReconnecting = (participant: RemoteParticipant) => {
    this.dispatch(
      participantReconnecting(this.getParticipantData(participant))
    );
    this.participantDisconnected(participant);
  };

  private trackSubscribed(
    track: RemoteAudioTrack | RemoteVideoTrack,
    participant: RemoteParticipant
  ) {
    this.dispatch(subscribed(this.getTrackData(participant, track)));
  }

  private trackUnpublished = (publication: RemoteTrackPublication) =>
    this.detach(publication.track);

  private trackUnsubscribed(
    track: RemoteAudioTrack | RemoteVideoTrack,
    participant: RemoteParticipant
  ) {
    this.detach(track);
    this.dispatch(unsubscribed(this.getTrackData(participant, track)));
  }

  private addTwilioRoomEventListeners = (twilioRoom: Room) => {
    // TODO: create seperate functions for handling these errors
    twilioRoom.on("participantConnected", this.participantConnected);
    twilioRoom.on("participantDisconnected", this.participantDisconnected);
    twilioRoom.on("reconnecting", (error) => {
      this.dispatch(reconnecting());
      if (error) {
        this.handleTwilioError(error, TwilioErrorType.CONNECT);
      }
    });
    twilioRoom.on("reconnected", () => {
      this.dispatch(connected());
      this.subscribeToRoom(twilioRoom);
    });
    twilioRoom.on("disconnected", (room, error) => {
      this.dispatch(disconnected());
      if (error) {
        this.handleTwilioError(error, TwilioErrorType.DISCONNECT);
      }
    });
    twilioRoom.on("trackSubscriptionFailed", (error) => {
      this.handleTwilioError(error, TwilioErrorType.REMOTE);
    });
  };

  private addParticipantEventListeners = (participant: RemoteParticipant) => {
    participant.tracks.forEach((publication) =>
      this.addTrackEventListeners(publication, participant)
    );
    participant.on("trackPublished", (publication) =>
      this.addTrackEventListeners(publication, participant)
    );
    participant.on("trackUnpublished", (publication) =>
      this.trackUnpublished(publication)
    );
    participant.on("reconnecting", () =>
      this.participantReconnecting(participant)
    );
    participant.on("reconnected", () => this.participantConnected(participant));
  };

  private subscribeToLocalTracks = (twilioRoom: Room) => {
    twilioRoom.localParticipant.tracks.forEach((track) => {
      this.dispatch(subscribed(this.getLocalTrackData(track)));
    });
  };

  private addLocalParticipantListeners = (twilioRoom: Room) => {
    twilioRoom.localParticipant.on(
      "trackPublished",
      (publication: LocalAudioTrackPublication) => {
        this.dispatch(subscribed(this.getLocalTrackData(publication)));
      }
    );
  };

  private addTrackEventListeners = (
    publication: RemoteTrackPublication,
    participant: RemoteParticipant
  ) => {
    publication.on("subscribed", (track: RemoteAudioTrack | RemoteVideoTrack) =>
      this.trackSubscribed(track, participant)
    );
    publication.on(
      "unsubscribed",
      (track: RemoteAudioTrack | RemoteVideoTrack) =>
        this.trackUnsubscribed(track, participant)
    );
    publication.on("subscriptionFailed", (error) => {
      this.handleTwilioError(error, TwilioErrorType.REMOTE);
    });
  };
}
