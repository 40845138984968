// reference enumeration for all available denominations

export enum Denomination {
  ONE_CENT = 1,
  TWO_CENT = 2,
  FIVE_CENT = 5,
  TEN_CENT = 10,
  TWENTY_FIVE_CENT = 25,
  FIFTY_CENT = 50,
  ONE_DOLLAR = 100,
  TWO_DOLLAR = 200,
  THREE_DOLLAR = 300,
  FOUR_DOLLAR = 400,
  FIVE_DOLLAR = 500,
  TEN_DOLLAR = 1000,
  TWENTY_DOLLAR = 2000,
  TWENTY_FIVE_DOLLAR = 2500,
  FORTY_DOLLAR = 4000,
  FIFTY_DOLLAR = 5000,
  HUNDRED_DOLLAR = 10000,
  TWO_HUNDRED_DOLLAR = 20000,
  THREE_HUNDRED_DOLLAR = 30000,
  FIVE_HUNDRED_DOLLAR = 50000,
}
