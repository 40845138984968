/*
 * GameInterface.ts
 *
 * This Interface file acts as contract between the BE and FE for everything related to Game
 * It is mostly used to match what we send from BE (ie. update_table socket) and FE (redux-store)
 *
 * Game defines the game that are playable, so when BE+FE introduces new game, it has to be updated
 */
import { BetLimit } from "../bets/BetLimit";
import { BuyLastCardUpSetting } from "../enums/BuyLastCardUpSetting";
import { CardVersionSetting } from "../enums/CardVersionSetting";
import { HighLowSetting } from "../enums/HighLowSetting";
import { RollYourOwnSetting } from "../enums/RollYourOwnSetting";
import { WildcardOption } from "../enums/WildcardOption";

export enum Game {
  FiveCardDraw = "FIVE_CARD_DRAW",
  FiveCardStud = "FIVE_CARD_STUD",
  SevenCardStud = "SEVEN_CARD_STUD",
  TexasHoldem = "TEXAS_HOLDEM",
  FollowTheQueen = "FOLLOW_THE_QUEEN",
  LowChicago = "LOW_CHICAGO",
  HighChicago = "HIGH_CHICAGO",
  RollYourOwn = "ROLL_YOUR_OWN",
  IronCross = "IRON_CROSS",
  HollywoodSquares = "HOLLYWOOD_SQUARES",
  TheSlut = "THE_SLUT",
  Omaha = "OMAHA",
  SevenTwentySeven = "SEVEN_TWENTY_SEVEN",
  Texas = "TEXAS",
  PassTheTrash = "PASS_THE_TRASH",
  Guts = "GUTS",
}

export interface GameRulesInterface {
  objectives: string[];
  actions: string[];
  title?: string;
  noteTitle?: string;
  notes?: string[];
  card: Record<string, string[]>;
  image: Record<string, any>;
}

export interface GameOptionsInterface {
  versions: GameVersionInterface[];
  winners: GameWinnerInterface[];
  options: GameOptionInterface[];
  wildcards: GameWildcardInterface[];
}

export interface GameVersionInterface {
  title: string;
  description: string;
  value: CardVersionSetting;
  flavor: number;
  default?: boolean;
  betLimit: BetLimit;
}

export interface GameWinnerInterface {
  description: string;
  value: HighLowSetting;
  tooltip?: string;
  default?: boolean;
}

export interface GameWildcardInterface {
  description: string;
  value: WildcardOption;
  tooltip?: string;
  default?: boolean;
}

export interface GameOptionInterface {
  description: string;
  value: RollYourOwnSetting | BuyLastCardUpSetting;
  tooltip?: string;
  default?: boolean;
}
